import React, { useEffect, useState } from "react";
import httpService from "../../../services/http.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons";
import { getErrorMessage, getInfoMessage } from "../../Helper/Helper";
import "./AccessRequest.css";
import { Button } from "primereact/button";
import AcceptIcon from "../../../assets/AcceptIcon.svg";
import RejectIcon from "../../../assets/RejectIcon.svg";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";

const AccessRequest = ({ props }) => {
  const [isApproveButtonLoading, setIsApproveButtonLoading] = useState(false);
  const [isRejectButtonLoading, setIsRejectButtonLoading] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);

  useEffect(() => {
    const id = props.rowData?.["S No"]?.value;

    props?.selectedUser?.length > 0
      ? setDisableButtons(
          props?.selectedUser?.some((item) => item?.["S No"]?.value === id)
        )
      : setDisableButtons(false);
  }, [props.selectedUser]);

  const acceptRequest = () => {
    let finalPayload = [];
    let payload = {
      pid: props.val,
      role: props.rowData["Access"]["value"],
      username: props.rowData["Name"]["value"],
    };
    setDisableButtons(true);
    finalPayload.push(payload);
    httpService
      .acceptRoleRequest(finalPayload)
      .then((data) => {
        setDisableButtons(false); // Move inside then block
        if (data?.["operation"]?.toLowerCase() === "success") {
          getInfoMessage(data);
        } else {
          getErrorMessage(data);
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setDisableButtons(false));
      });
  };

  const rejectRequest = () => {
    let finalPayload = [];
    let payload = {
      pid: props.val,
      username: props.rowData["Name"]["value"],
    };
    setDisableButtons(true);
    finalPayload.push(payload);
    httpService
      .declineRoleRequest(finalPayload)
      .then((data) => {
        setDisableButtons(false);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          getInfoMessage(data);
        } else {
          getErrorMessage(data);
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setDisableButtons(false));
      });
  };

  return (
    <div className="accessBtn">
      <>
        <FontAwesomeIcon
          className={`mx-2 ${
            disableButtons ? "disabledReqIcon" : "acceptIcon"
          }`}
          icon={faCircleCheck}
          disabled={disableButtons}
          onClick={() => {
            if (!disableButtons) {
              acceptRequest();
              // setDisableButtons(true);
            }
          }}
        />
        <FontAwesomeIcon
          className={`mx-2 ${
            disableButtons ? "disabledReqIcon" : "rejectIcon"
          }`}
          icon={faCircleXmark}
          onClick={() => {
            if (!disableButtons) {
              rejectRequest();
              // setDisableButtons(true);
            }
          }}
        />
        {/* <Button
  className="d-inline-flex align-items-center "
  onClick={() => {
    acceptRequest();
  }}
  loadingIcon={"pi pi-spin pi-spinner"}
  loading={isApproveButtonLoading}
  disabled={isRejectButtonLoading || disableButtons}
  style={{ backgroundColor: 'transparent', border: 'none'}} 
>
  <img src={AcceptIcon} alt="Approve" style={{ fill: 'green' }} /> 
</Button> */}

        {/* <Button
 
  onClick={() => {
    rejectRequest();
  }}
  loadingIcon={"pi pi-spin pi-spinner"}
  loading={isRejectButtonLoading}
  disabled={isApproveButtonLoading || disableButtons}
  style={{ backgroundColor: 'transparent', border: 'none' }} 
>
  <img src={RejectIcon} alt="Reject" style={{ fill: 'red' }} /> 
  Reject
</Button> */}
      </>
    </div>
  );
};

export default AccessRequest;
