import { faCamera, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import profileService from "../../../services/UserProfile/profile.service";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { setUserAvatar } from "../../../actions/auth";

const UserAvatar = ({ userData }) => {

  
  const userProfileUrl = useSelector((state) => state.auth.userProfile.avatar);
  const firstname=useSelector((state) => state.auth.userProfile.firstName);
  const lastname=useSelector((state) => state.auth.userProfile.lastName);
  const dicebarUrl=`https://api.dicebear.com/7.x/initials/svg?seed=${firstname} ${lastname}`
  const username = useSelector((state) => state.auth.userProfile.username);

  const [imageUrl, setImageUrl] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [photoEditLoading,setPhotoEditLoading]=useState(false)
  const dispatch = useDispatch();

  const handleAvatarChange = (e) => {

    
    const file = e.target.files[0];

    if (file) {
      setPhotoEditLoading(true);
      const formData = new FormData();

      formData.append("file", file);
      formData.append("type", "new");
      

      profileService
        .setUserPic(formData)
        .then((data) => {
          getUserPic();
          
          setIsImageUploaded(true);
          
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(()=>setPhotoEditLoading(false));
    }
  };

  const deletePic = () => {
    if (isImageUploaded) {
      setPhotoEditLoading(true)
      const formData = new FormData();
      formData.append("type", "remove");
      

      profileService
        .setUserPic(formData)
        .then((data) => {
          getUserPic();
          setIsImageUploaded(false);
          
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(()=>setPhotoEditLoading(false));
    }
  };

  const getUserPic = () => {
    setPhotoEditLoading(true)
    profileService
      .getUserDetails(username)
      .then((data) => {
       
        if (data?.data?.profilePic) {
          setImageUrl(
            `${data?.data?.profilePic}&token=${localStorage.getItem(
              "usertoken"
            )}`
          );
          dispatch(setUserAvatar(data?.data?.profilePic));
       
        } else {
          let url = `https://api.dicebear.com/7.x/initials/svg?seed=${data?.data?.firstname} ${data?.data?.lastname}`;
          setImageUrl(url);
          dispatch(setUserAvatar(url));
  
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      })
      .finally(()=>setPhotoEditLoading(false));
  };

  useEffect(() => {
    if (userData?.profilePic) {
      setIsImageUploaded(true);
      setImageUrl(userData?.profilePic);
    }else{
      setImageUrl(dicebarUrl);
    }
    
  

  }, []);

  return (
    <div className="mx-lg-5 mx-2 text-center">
      <div className="avatar-container">
        <div className="editAvatar rounded-circle">
          {photoEditLoading?(
            <FontAwesomeIcon
            className="fs-4 fa-spin pointer"
            onClick={() => document.getElementById("avatarInput").click()}
            icon={faSpinner}
          />
          ):(
            <>
            <FontAwesomeIcon
            className="fs-4 me-2 pointer"
            onClick={() => document.getElementById("avatarInput").click()}
            icon={faCamera}
          />
          <FontAwesomeIcon
            className={`fs-4 ms-2 ${isImageUploaded ? "pointer" : "disable"}`}
            onClick={() => deletePic()}
            icon={faTrashCan}
          />
          </>
          )}
        </div>
        <img
          src={imageUrl}
          className="userAvatar rounded-circle"
          alt="User Avatar"
        />
      </div>
      <input
        id="avatarInput"
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        onChange={(e) => handleAvatarChange(e)}
      />
    </div>
  );
};

export default UserAvatar;
