import Admin from "../../Layouts/Admin/Admin";
import "./AdminPage.css";
const AdminPage = () => {
  return (
    <>
      <div className="container-fluid">
        <Admin />
      </div>
    </>
  );
};

export default AdminPage;
