import { Link, useNavigate } from "react-router-dom";
import "./NoAccess.css";

const NoAccess = (props) => {
  // const navigate = useNavigate();

  return (
    <div>
      <div className="mt-5 px-4 text-center">
        <div className="card d-flex align-items-center py-3">
          <div className="boldText">
            {`${
              props?.errorMessage
                ? props.errorMessage
                : "You don't have Project(s) access ! Kindly request the same."
            }`}
          </div>
          <div className="my-3">
            {/* <Link to="/admin"> */}
            {!props?.errorMessage ? (
              <button
                className="btn btn-success themeBtn ms-2"
                onClick={() => {
                  window.location.href = "/#/admin";
                  // navigate("/admin");
                }}
              >
                Request Access
              </button>
            ) : (
              <button
                className="btn btn-success themeBtn"
                onClick={() => {
                  window.location.href = "/#/autolytics";
                }}
              >
                Go to Autolytics page
              </button>
            )}
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
