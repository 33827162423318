import React, { useEffect, useRef, useState } from "react";
import UserInput from "../../Helper/UserProfile/UserInput";
import {
  faCircleCheck,
  faCircleDot,
  faEdit,
  faEnvelope,
  faLock,
  faRotateRight,
  faSpinner,
  faTimesCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Password } from "primereact/password";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Profile.css";

import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";

import { SelectButton } from "primereact/selectbutton";
import profileService from "../../../services/UserProfile/profile.service";
import httpService from "../../../services/http.service";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import {
  getSuccessToast,
  handleToastErrorCatch,
  statementError,
} from "../../Helper/ToastHelper";
import md5Hex from "md5-hex";
import { useDispatch, useSelector } from "react-redux";

import { getToastMessage, logPageTitleForGA } from "../../Helper/Helper";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

const UserDetails = ({ userData, emailUpdate, setEmailUpdate }) => {
  const [notification, setNotification] = useState("");
  const [disableNotification, setDisableNotification] = useState(false);
  const [userDetailEdit, setUserDetailEdit] = useState(false);
  const [passworEdit, setPasswordEdit] = useState(false);
  const [selectedModule,setSelectedModule]=useState([]);
  const [data, setData] = useState(null);
  const [getNotif, setGetNotif] = useState("");
  const [getNotifUpdate, setGetNotifUpdate] = useState(false);
  const [username, setUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [emailUpdateLoading, setEmailUpdateLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [relatiiveVal, setRelativeVal] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [password, setPassword] = useState(null);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [verify, setVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const firstUpdate = useRef(true);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [enableAllNotifLoading, setEnableAllNotifLoading] = useState(false);
  const [saveNotifLoading, setSaveNotifLoading] = useState(false);
  const [ResetNotif, setResetNotif] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const [newUserData, setNewUserData] = useState(null);
  const [passwordFormat, setPasswordFormat] = useState({
    isUpperValid: false,
    isLowerValid: false,
    isSpecialValid: false,
    isLengthValid: false,
  });

  const INITIAL_SECONDS = 30;
  const [secondsLeft, setSecondsLeft] = useState(INITIAL_SECONDS);
  const formattedSeconds =
    secondsLeft < 10
      ? secondsLeft.toString().padStart(2, "0")
      : secondsLeft.toString();
  const [isResendOtpButtonDisabled, setIsResendOtpButtonDisabled] =
    useState(true);
  const [isVerifyOtpButtonDisabled, setIsVerifyOtpButtonDisabled] =
    useState(false);

  useEffect(() => {
    let interval;

    if (isResendOtpButtonDisabled) {
      interval = setInterval(() => {
        setSecondsLeft((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(interval);
            setIsResendOtpButtonDisabled(false);
            return 0;
          }
        });
      }, 500);
    }

    return () => clearInterval(interval);
  }, [isResendOtpButtonDisabled]);

  function handleResendOtp() {
    setOtp("");
    setSecondsLeft(INITIAL_SECONDS);
    setIsResendOtpButtonDisabled(true);
    getSuccessToast("OTP sent again");

    httpService.sendSignUpOtp(username).catch(() => {
      statementError("Something went wrong with the OTP service", 5000);
    });
  }

  function handleOtpVerification() {
    setIsVerifyOtpButtonDisabled(true);
    httpService
      .verifySignUpOtp(username, otp)
      .then((data) => {
        getToastMessage(data);

        setVerifiedEmail(true);
        setVerify(false);
        setOtp("");
      })
      .catch((data) => {
        statementError(data.response.data.message, 5000);
        setVerifiedEmail(false);
        setOtp("");
      })
      .finally(() => {
        setIsVerifyOtpButtonDisabled(false);
        setVerifiedEmail(false);
        setOtp("");
      });
  }

  useEffect(() => {
    if (firstUpdate.current) {
      
      setGetNotifUpdate(false);
      setUsername(userData?.username);
      setUserEmail(userData?.email);
      profileService
        .getNotifications(userData?.username)
        .then((data) => {
       
          if (data?.data && data?.data?.Duration?.toLowerCase() !== "reset") {
           
            setGetNotif(data?.data?.Duration?.toLowerCase());
            setSelectedModule(data?.data?.Modules);
          } else if (
            data &&
            data?.message?.toLowerCase() === "emails are enabled"
          ) {
            setDisableNotification(!disableNotification);
          }
          setGetNotifUpdate(true);
          firstUpdate.current = false;
        })
        .catch((err) =>
          handleToastErrorCatch(err, () => {
            setGetNotifUpdate(true);
            firstUpdate.current = false;
          })
        );
    }
  }, []);

  const setNotificationDetails = (val) => {
    let currDate = new Date();

    if (val === "8 hours") {
      const epochTime = new Date(
        new Date(currDate.getTime() + 8 * 60 * 60 * 1000)
      ).getTime();

      setData({
        username: userData?.username,
        email: userData?.email,
        pausePeriod: epochTime,
        relativeValue: "8 hours",
        type: "jewel",
        module:selectedModule
      });
    } else if (val === "24 hours") {
      const epochTime = new Date(
        new Date(currDate.getTime() + 24 * 60 * 60 * 1000)
      ).getTime();

      setData({
        username: userData?.username,
        email: userData?.email,
        pausePeriod: epochTime,
        relativeValue: "24 hours",
        type: "jewel",
        module:selectedModule
      });
    } else if (val === "1 week") {
      const epochTime = new Date(
        new Date(currDate.getTime() + 7 * 24 * 60 * 60 * 1000)
      ).getTime();

      setData({
        username: userData?.username,
        email: userData?.email,
        pausePeriod: epochTime,
        relativeValue: "1 week",
        type: "jewel",
        module:selectedModule
      });
    } else if (val === "Disable Permanently") {
      setData({
        username: userData?.username,
        email: userData?.email,
        pausePeriod: 0,
        relativeValue: "disable permanently",
        type: "jewel",
        module:selectedModule
      });
    }
  };

  const PostNotification = () => {
    setEnableAllNotifLoading(true);
    setNotification("");
    setRelativeVal("");
    setGetNotif("");
    let dat = {
      username: userData?.username,
      email: userData?.email,
      pausePeriod: 0,
      relativeValue: "reset",
      type: "jewel",
    };

    profileService
      .setNotifications(dat)
      .then((data) => {
        getToastMessage(data);
        setDisableNotification(true);
        setSelectedModule([])
        setConfirmDialog(false);
        setEnableAllNotifLoading(false);
      })
      .catch((err) =>
        handleToastErrorCatch(err, () => setConfirmDialog(false))
      );
  };

  useEffect(() => {
    if (data && !firstUpdate.current) {
      setSaveNotifLoading(true);
      profileService
        .setNotifications(data)
        .then((data) => {
          getToastMessage(data);
          setSaveNotifLoading(false);
        })
        .catch((err) => {
          handleToastErrorCatch(err,()=>{
            setRelativeVal("");
          setNotification("");
          setSaveNotifLoading(false);
          });
          
        });
    }
  }, [data]);

  const handleRelativeVal = (val) => {
    setGetNotif("");

    setNotification(val);

    if (relatiiveVal === "" || (relatiiveVal && val !== relatiiveVal)) {
      setRelativeVal(val);
    } else if (relatiiveVal && relatiiveVal === val) {
      setRelativeVal("");
    }
  };

  const editUser = () => {
    if (username === userData?.username && userEmail !== userData?.email) {
      if (verifiedEmail) {
        setNewUserData({
          currentUsername: userData?.username,
          email: userEmail,
          type: "email",
        });
        setUserDetailEdit(false);
      } else {
        statementError("Email not valid");
      }
    } else {
      setUserDetailEdit(false);
    }
  };

  useEffect(() => {
    if (newUserData) {
      setEmailUpdateLoading(true);
      profileService
        .editUserProfile(newUserData)
        .then((data) => {
          getToastMessage(data);
          setEmailUpdate(true);
          setEmailUpdateLoading(false);
        })
        .catch((err) =>
          handleToastErrorCatch(err, () => setEmailUpdateLoading(false))
        );
    }
  }, [newUserData]);

  const validatePassword = (input) => {
    const hasUppercase = /[A-Z]/.test(input);
    const hasLowercase = /[a-z]/.test(input);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(input);
    const isMinLength = input.length >= 8;

    if (hasLowercase && hasUppercase && hasSpecialChar && isMinLength) {
      setValidPassword(true);
    }

    setPasswordFormat({
      ...passwordFormat,
      isUpperValid: hasUppercase,
      isLowerValid: hasLowercase,
      isSpecialValid: hasSpecialChar,
      isLengthValid: isMinLength,
    });
  };

  const editUserPassword = () => {
    if (newPassword === confirmNewPassword) {
      setPassword({
        username: userData?.username,
        newPassword: md5Hex(newPassword),
        oldPassword: md5Hex(oldPassword),
        confirmPassword: md5Hex(confirmNewPassword),
      });
    } else
      statementError(
        "New password and confirm password values are not identical"
      );
  };

 

  useEffect(() => {
    if (password) {
      setResetPasswordLoading(true);
      profileService
        .resetUserPassword(password)
        .then((data) => {
          getToastMessage(data);

          setPasswordEdit(false);
          setNewPassword("");
          setOldPassword("");
          setConfirmNewPassword("");
          setPasswordFormat({
            isUpperValid: false,
            isLowerValid: false,
            isSpecialValid: false,
            isLengthValid: false,
          });
          setResetPasswordLoading(false);
        })
        .catch((err) => {
          handleToastErrorCatch(err, () => setResetPasswordLoading(false));
        });
    }
  }, [password]);

  useEffect(() => {
    if (passworEdit) {
      validatePassword(newPassword);
    }
  }, [newPassword]);

  const itemTemplate = (option) => {
    return (
      <div
        className={`d-flex align-items-center ${
          getNotif === option?.name?.toLowerCase() ? "green" : ""
        }`}
        onClick={() => handleRelativeVal(option.name)}
      >
        <FontAwesomeIcon
          className="fs-6"
          icon={
            relatiiveVal === option.name ||
            getNotif === option?.name?.toLowerCase()
              ? faCircleCheck
              : faCircle
          }
        />
        <p className="disableOptions">
          {option.name === "Disable Permanently"
            ? `${option.name}`
            : `Disable for ${option.name}`}
        </p>
      </div>
    );
  };

  return (
    <div className="mx-2 pb-3">
      <div className="d-flex justify-content-end"></div>
      <div className="row my-3 mx-0 px-3 py-3 userPasswordRow">
        <p className="fs-5 my-2 ps-4 col-6">User Details</p>
        <div className="col-6">
          {userDetailEdit ? (
            <div className="d-flex justify-content-end">
              {verifiedEmail ? (
                <FontAwesomeIcon
                  onClick={() => editUser()}
                  icon={emailUpdateLoading ? faSpinner : faCircleCheck}
                  className={`editBtn fs-2 ${
                    emailUpdateLoading ? "fa-spin" : ""
                  }`}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="disabled fs-2"
                />
              )}
              <FontAwesomeIcon
                onClick={() => {
                  setUserEmail(userData?.email);
                  setUserDetailEdit(false);
                }}
                icon={faTimesCircle}
                className="editBtn fs-2"
              />
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              <FontAwesomeIcon
                onClick={() => setUserDetailEdit(true)}
                icon={faEdit}
                className="editBtn userEdit fs-4"
              />
            </div>
          )}
        </div>
        <UserInput
          edit={userDetailEdit}
          type="text"
          placeholder="Username"
          icon={faUser}
          val={username}
          setVal={() => {}}
        />
        <UserInput
          edit={userDetailEdit}
          type="email"
          placeholder="Email"
          icon={faEnvelope}
          val={userEmail}
          setVal={setUserEmail}
          verify={verify}
          setVerify={setVerify}
          username={userData?.username}
          verifiedEmail={verifiedEmail}
          setVerifiedEmail={setVerifiedEmail}
          userData={userData}
          setOtp={setOtp}
        />
        <Dialog
          header="Verify otp"
          visible={verify}
          style={{ width: "50vw" }}
          onHide={() => setVerify(false)}
        >
          <div className="d-flex card">
            <div className="d-flex flex-column align-items-center card-body py-5 px-lg-5 px-md-5 px-sm-4 px-xs-2 otp-verification-card">
              <h1>Email Verification</h1>
              <h2>Please enter verification code sent to</h2>
              <h3>{userEmail}</h3>

              <InputText
                id="signup-otp"
                keyfilter="pint"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="OTP"
                maxLength={6}
                className="my-3"
              />

              <div className="d-flex mt-3 justify-content-center">
                <button
                  className="mx-3 btn btn-outline-primary px-3 otp-verification-card_outlined-button "
                  onClick={handleResendOtp}
                  disabled={isResendOtpButtonDisabled}
                >
                  Resend OTP{" "}
                  {secondsLeft > 0 && <span>{`in 0:${formattedSeconds}`}</span>}
                </button>
                <button
                  className="mx-3 btn btn-primary px-3 "
                  label="Verify OTP"
                  onClick={handleOtpVerification}
                  disabled={otp.length < 6 || isVerifyOtpButtonDisabled}
                >
                  Verify OTP
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
      <div className="row my-3 mx-0 px-3 py-3 userPasswordRow">
        <p className="fs-5 my-2 ps-4 col-6">Change Password</p>
        <div className="col-6">
          {passworEdit ? (
            <div className="d-flex justify-content-end">
              {validPassword ? (
                <FontAwesomeIcon
                  onClick={() => editUserPassword()}
                  icon={resetPasswordLoading ? faSpinner : faCircleCheck}
                  className={`editBtn fs-2 ${
                    resetPasswordLoading ? "fa-spin" : ""
                  }`}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="disabled fs-2"
                />
              )}
              <FontAwesomeIcon
                onClick={() => {
                  setNewPassword("");
          setOldPassword("");
          setConfirmNewPassword("");
                  setPasswordEdit(false);
                }}
                icon={faTimesCircle}
                className="editBtn fs-2"
              />
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              <FontAwesomeIcon
                onClick={() => setPasswordEdit(true)}
                icon={faEdit}
                className="editBtn userEdit fs-4"
              />
            </div>
          )}
        </div>
        <div className="col-12 col-lg-4 my-2 my-lg-0">
          <FontAwesomeIcon icon={faLock} className="userPasswordIcon" />
          <Password
            disabled={!passworEdit}
            placeholder="Old password"
            className="userPassword py-1"
            feedback={false}
            toggleMask={passworEdit}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
        <div className="col-12 col-lg-4 my-2 my-lg-0">
          <FontAwesomeIcon icon={faLock} className="userPasswordIcon" />
          <Password
            disabled={!passworEdit}
            toggleMask={passworEdit}
            placeholder="New password"
            feedback={false}
            className="userPassword py-1"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {passworEdit && (
            <div className="row ps-2  ">
              <div
                className={`col-12 ms-4 my-1 d-flex align-items-center ${
                  passwordFormat.isUpperValid ? "green" : "red"
                }`}
              >
                <FontAwesomeIcon
                  className="me-2"
                  icon={
                    passwordFormat.isUpperValid ? faCircleCheck : faTimesCircle
                  }
                />
                <p className="m-0 checkFont">At least one uppercase</p>
              </div>
              <div
                className={`col-12 ms-4 my-1 d-flex align-items-center ${
                  passwordFormat.isLowerValid ? "green" : "red"
                }`}
              >
                <FontAwesomeIcon
                  className="me-2"
                  icon={
                    passwordFormat.isLowerValid ? faCircleCheck : faTimesCircle
                  }
                />
                <p className="m-0 checkFont">At least one Lowercase</p>
              </div>
              <div
                className={`col-12 ms-4 my-1 d-flex align-items-center ${
                  passwordFormat.isSpecialValid ? "green" : "red"
                }`}
              >
                <FontAwesomeIcon
                  className="me-2"
                  icon={
                    passwordFormat.isSpecialValid
                      ? faCircleCheck
                      : faTimesCircle
                  }
                />
                <p className="m-0 checkFont">At least one special character</p>
              </div>
              <div
                className={`col-12 ms-4 my-1 d-flex align-items-center ${
                  passwordFormat.isLengthValid ? "green" : "red"
                }`}
              >
                <FontAwesomeIcon
                  className="me-2"
                  icon={
                    passwordFormat.isLengthValid ? faCircleCheck : faTimesCircle
                  }
                />
                <p className="m-0 checkFont">Minimum 8 characters </p>
              </div>
            </div>
          )}
        </div>
        <div className="col-12 col-lg-4 my-2 my-lg-0">
          <FontAwesomeIcon icon={faLock} className="userPasswordIcon" />
          <Password
            disabled={!passworEdit}
            placeholder="Confirm password"
            feedback={false}
            className="userPassword py-1 "
            toggleMask={passworEdit}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
      </div>
      <Dialog
        header=""
        visible={confirmDialog}
        style={{ width: "20vw" }}
        onHide={() => setConfirmDialog(false)}
      >
        <div className="d-flex flex-column align-items-center card-body py-1 px-lg-2 px-md-2 px-sm-1 px-xs-1 ">
          <h2 className="mb-3 fs-5 text-center">
            Are you sure to enable all notifications
          </h2>

          <div className="d-flex mt-1 justify-content-center">
            <button
              className="mx-3 btn btn-primary px-3 confirmBtn"
              onClick={() => {
                PostNotification();
              }}
              disabled={enableAllNotifLoading}
            >
              Yes
              {enableAllNotifLoading && (
                <FontAwesomeIcon icon={faSpinner} className="fa fa-spin ms-2" />
              )}
            </button>
            <button
              className="mx-3 btn btn-primary px-3 confirmBtn"
              onClick={() => {
                setConfirmDialog(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>
      <div className="userNotificationRow mb-3 px-3 py-4">
        <div className="row ps-4 my-2">
          <p className="fs-5 col-3 my-0 d-flex align-items-center">Enable Email Notifications</p>
          <div className="col-7">
          <MultiSelect
  className="moduleDialog-dropdown"
  filter
  filterBy="moduleName"
  disabled={disableNotification}
  value={selectedModule}
  options={[
    { label: 'Admin', value: 'admin' },
    { label: 'Account', value: 'account' },
    { label: 'Reporting', value: 'reporting' }
  ]}
  onChange={(e) => {
    setSelectedModule(e.value);
  }}
  placeholder="Select Module"
/>
          </div>
          {getNotifUpdate ? (
            <InputSwitch
              checked={disableNotification}
              onChange={(e) =>
                disableNotification
                  ? setDisableNotification(false)
                  : setConfirmDialog(true)
              }
              className="col-2 ms-4 d-flex align-items-center"
            />
          ) : (
            <FontAwesomeIcon icon={faSpinner} className="fa fa-spin col-2 fs-4" />
          )}
        </div>
        <div className="ps-4 d-flex align-items-center">
          <SelectButton
            className="notificationsSelect mx-3"
            optionLabel="Disable Notifications"
            value={notification}
            options={[
              { name: "8 hours" },
              { name: "24 hours" },
              { name: "1 week" },
              { name: "Disable Permanently" },
            ]}
            disabled={disableNotification}
            onChange={(e) => setNotification(e.value)}
            itemTemplate={itemTemplate}
          />
          <Button
            label="Save"
            disabled={disableNotification || !relatiiveVal || selectedModule?.length===0}
            type="button"

            loadingIcon={"pi pi-spin pi-spinner me-2"}

          loading={saveNotifLoading}
          iconPos="right"
            className="btn-success btn btn-sm themeUserbtn mx-2 py-2 px-3 mt-1 me-2 p-ThemeBtn"
            onClick={() => {
              setNotificationDetails(relatiiveVal);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
