import { faSquareArrowUpRight, faRotateRight,faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { encodeParam,decodeParam, createFilters } from "../../Helper/Helper";
import {
  copyToClipboard,
  getErrorMessage,
} from "../../Helper/Helper";
import { useRef } from "react";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import ComingSoonMain from "../../Pages/ComingSoon/ComingSoonMain";
import NoAccess from "../../UI/NoAccess/NoAccess";
import "./Extent.css";
import ReportHeader from "./SecurityReport/ReportHeader";
import TabComponent from "./SecurityReport/TabComponent";
import securityService from "../../../services/SecurityReport/security.service";
// import { useSearchParams } from "react-router-dom";
import Dast from "./SecurityReport/Dast";
import Overview from "./SecurityReport/Overview";
import Sast from "./SecurityReport/Sast";


const SecurityMetricsReport = (props) => {
  const [SecureData, setSecureData] = useState(null);
  const [dastDataLoading,setDastDataLoading]=useState(false);
  const [sastDataLoading,setSastDataLoading]=useState(false);
  const [lastFiveRunDats, setLastFiveRunData] = useState(null);
  const [dastData,setDastData]=useState(null);
  const [sastData,setSastData]=useState(null);
  const [errorLoading, setErrorLoading] = useState({SecurityDashboard:false,lastFive:false,dast:false,sast:false});
  const [errorMessage, setErrorMessage] = useState(null);
  const [noAccess, setNoAccess] = useState({SecurityDashboard:false,lastFive:false,dast:false,sast:false});
  const [mergedDastData,setMergedDastData]=useState(null)
  const [mergedSastData,setMergedSastData]=useState(null)
  const [filters,setFilters]=useState({});
  const [sastFilters,setSastFilters]=useState({});
  // const [searchParams] = useSearchParams();
  const tableref=useRef();
  
  

 

  const SecurityApi={

    getSecurityDashboard:()=>{
      securityService
      .getSecurityDashboard(props.s_run_id)
      .then((data) => {
        getErrorMessage(data);
        if (data?.operation?.toLowerCase() === "failure") {
          setErrorLoading({...errorLoading,SecurityDashboard:true});
          setErrorMessage(
            data?.message ? data?.message : "Something went wrong!",
          );
          return;
        }
        setSecureData(data);
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          setNoAccess({...noAccess,SecurityDashboard:true});
          return;
        } else if (
          err?.response?.data?.operation?.toLowerCase() ===
          "failure"
        ) {
          setErrorLoading({...noAccess,SecurityDashboard:true});
          setErrorMessage(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Something went wrong!",
          );
          return;
        } else {
          handleToastErrorCatch(err);
        }
      });
    },
    getLastFiveData:()=>{
      securityService
      .getSecurityLastFiveRuns(props.s_run_id)
      .then((data) => {
        getErrorMessage(data);
        if (data?.operation?.toLowerCase() === "failure") {
          setErrorLoading({...noAccess,lastFive:true});
          setErrorMessage(
            data?.message ? data?.message : "Something went wrong!",
          );
          return;
        }
        setLastFiveRunData(data);
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          setNoAccess({...noAccess,lastFive:true});
          return;
        } else if (
          err?.response?.data?.operation?.toLowerCase() ===
          "failure"
        ) {
          setErrorLoading({...noAccess,lastFive:true});
          setErrorMessage(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Something went wrong!",
          );
          return;
        } else {
          handleToastErrorCatch(err);
        }
      });
    },
    getSecurityDAST:()=>{
      setDastDataLoading(true);
      securityService
      .getSecurityDastData(props.s_run_id)
      .then((data) => {
        
        tableref.current=undefined
        if (data?.operation?.toLowerCase() === "failure") {
          setErrorLoading({...noAccess,dast:true});
          
          setDastDataLoading(false);
          return;
        }
        
        setDastData(data);
        setDastDataLoading(false);
 
      
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          setNoAccess({...noAccess,dast:true});
          return;
        } else if (
          err?.response?.data?.operation?.toLowerCase() ===
          "failure"
        ) {
          setErrorLoading({...noAccess,dast:true});
          setErrorMessage(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Something went wrong!",
          );
          return;
        } else {
          handleToastErrorCatch(err);
        }
        setDastDataLoading(false);
      });
    },
    handleDastData:(dastDat)=>{
        if(dastDat){
          const dat=dastDat?.data?.data?.[0];
       
        const items=Object.keys(dat);
      
        const finalData=[];
   
        
    
        items.forEach((itm)=>{
              dat?.[itm].forEach((entry)=>{
                finalData.push(entry);
    
            
              })
            })
           
            setMergedDastData(finalData);
        }else{
          return null;
        }
    },
    getSecuritySAST:()=>{
      setSastDataLoading(true);
      securityService
      .getSecuritySastData(props.s_run_id)
      .then((data) => {
       
        tableref.current=undefined
        if (data?.operation?.toLowerCase() === "failure") {
          setErrorLoading({...noAccess,sast:true});
          setSastDataLoading(false);
          return;
        }
        
        setSastData(data);
 setSastDataLoading(false);
      
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          setNoAccess({...noAccess,sast:true});
          return;
        } else if (
          err?.response?.data?.operation?.toLowerCase() ===
          "failure"
        ) {
          setErrorLoading({...noAccess,dast:true});
          setErrorMessage(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Something went wrong!",
          );
          return;
        } else {
          handleToastErrorCatch(err);
        }
        setSastDataLoading(false);
      });
    },
    handleSastData:(sastDat)=>{
      if(sastDat){
        const dat=sastDat?.data?.data?.[0];
     
      const items=Object.keys(dat);
    
      const finalData=[];
 
      
  
      items.forEach((itm)=>{
            dat?.[itm].forEach((entry)=>{
              finalData.push(entry);
  
          
            })
          })
         
          setMergedSastData(finalData);
      }else{
        return null;
      }
  },
  }

  useEffect(() => {
    SecurityApi.getSecurityDashboard();
    SecurityApi.getLastFiveData();
    SecurityApi.getSecurityDAST();
    SecurityApi.getSecuritySAST();
  }, []);

  useEffect(()=>{
    SecurityApi.handleDastData(dastData);
  },[dastData])

  useEffect(()=>{
    SecurityApi.handleSastData(sastData);
  },[sastData])

  useEffect(() => {

    const filterData =
      dastData&&mergedDastData &&
      dastData?.data &&
      dastData?.data?.headers
        ? createFilters(dastData?.data?.headers)
        : {};
        setFilters(filterData);
      
    
  }, [dastData,mergedDastData]);

  useEffect(() => {

    const filterSastData =
      sastData&&mergedSastData &&
      sastData?.data &&
      sastData?.data?.headers
        ? createFilters(sastData?.data?.headers)
        : {};
        setSastFilters(filterSastData);
      
    
  }, [sastData,mergedSastData]);


  const tabData = [
    { name: "Overview", id: "pills-overview-tab-", target: "#pills-overview-",fn:()=>{} },
    { name: "DAST", id: "pills-dast-tab-", target: "#pills-dast-",fn:()=>{
      SecurityApi.handleDastData(dastData);
    } },
    { name: "SAST", id: "pills-sast-tab-", target: "#pills-sast-",fn:()=>{
      SecurityApi.handleSastData(sastData);
    } },
    { name: "API", id: "pills-api-tab-", target: "#pills-api-",fn:()=>{} },
  ];


  const iconData = [
    {
      title: "Copy Report Link",
      icon: <FontAwesomeIcon className="mx-2 tabIcon" icon={faCopy} />,
      onClick: () => {
        copyToClipboard(window.location.origin +
          "/#/autolytics/security-report?s_run_id=" +
          props.s_run_id +
          "&p_id=" +
          props.p_id +
          "&filters=" +
          encodeParam(JSON.stringify(filters)));
      },
    },
    {
      title: "Open in New Window",
      icon:<FontAwesomeIcon className="mx-2 tabIcon" icon={faSquareArrowUpRight} />,
      onClick:() => {
        window.open(
          window.location.origin +
            "/#/autolytics/security-report?s_run_id=" +
            props.s_run_id +
            "&p_id=" +
            props.p_id +
            "&filters=" +
            encodeParam(JSON.stringify(filters)),
          "_blank"
        );
      }
    },
    
    {
      title: "Reload",
      icon: <FontAwesomeIcon className="mx-2 tabIcon" icon={faRotateRight} />,
      onClick: () => {
        setSecureData(null);
        setDastData(null);
        setSastData(null)
        setTimeout(()=>{
          SecurityApi.getSecurityDashboard();
          SecurityApi.getLastFiveData();
          SecurityApi.getSecurityDAST()
          SecurityApi.getSecuritySAST()
          SecurityApi.handleDastData(dastData);
          SecurityApi.handleSastData(sastData)
        },1000);
      },
    },
  ];

  const renderComponent = () => {
    return (
      <>
        <>
          <ReportHeader
            title="Security Testing Dashboard"
            showIcon={true}
            iconsData={iconData}
            extentData={null}
            className="justify-content-between"
          />
          <TabComponent data={tabData} id={props.id} activeTab={"Overview"}/>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id={`pills-overview-${props.id}`}
              role="tabpanel"
              aria-labelledby={`pills-overview-${props.id}`}
            >
              <Overview SecureData={SecureData} lastFiveRunDats={lastFiveRunDats}/>
            </div>
            <div
              className="tab-pane fade"
              id={`pills-dast-${props.id}`}
              role="tabpanel"
              aria-labelledby={`pills-dast-${props.id}`}
            >
              <Dast dastDataLoading={dastDataLoading} SecureData={SecureData} dastData={dastData} filters={filters} props={props} setFilters={setFilters} mergedDastData={mergedDastData} tableref={tableref}/>
            </div>
            <div
              className="tab-pane fade "
              id={`pills-sast-${props.id}`}
              role="tabpanel"
              aria-labelledby={`pills-sast-${props.id}`}
            >
              <Sast sastDataLoading={sastDataLoading} SecureData={SecureData} sastData={sastData} filters={sastFilters} props={props} setFilters={setSastFilters} mergedSastData={mergedSastData} tableref={tableref}/>
            </div>
            <div
              className="tab-pane fade "
              id={`pills-api-${props.id}`}
              role="tabpanel"
              aria-labelledby={`pills-api-${props.id}`}
            >
              <ComingSoonMain />
            </div>
          </div>
        </>
      </>
    );
  };

  return (
    <div>
      {(noAccess.SecurityDashboard && noAccess.dast && noAccess.lastFive) || (errorLoading.SecurityDashboard && errorLoading.dast && errorLoading.lastFive) ? (
        <NoAccess errorMessage={errorMessage} />
      ) : (
        <>{renderComponent()}</>
      )}
    </div>
  );
};

export default SecurityMetricsReport;