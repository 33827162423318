import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import httpService from "../../../services/http.service";
import { getToastMessage } from "../../Helper/Helper";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";

const ChangeRole = ({ props }) => {
  const [changedRole, setChangedRole] = useState(props.val);
  const [disabled, setDisabled] = useState(true);

  const changeExistingRole = (e, value) => {
    let finalPayload = [];
    let payload = {
      pid: props?.rowData?.Action?.value,
      role: value,
      username: props?.rowData?.Name?.value,
    };
    finalPayload.push(payload);
    httpService
      .changeExistingProjectRole(finalPayload)
      .then((data) => {
        getToastMessage(data);
        if (data?.["operation"]?.toLowerCase() === "success") {
          setDisabled(true);
          props?.edit_roles?.editProjectAccess(
            e,
            props.rowData["Action"]["value"],
            props.project_name
          );
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setChangedRole("Admin");
          setDisabled(true);
        });
      });
  };
  return (
    <div>
      <Dropdown
        className="dialog-dropdown ms-3"
        style={{ minWidth: "10vw", maxWidth: "10vw" }}
        options={["Admin", "Dev", "QA", "Viewer"]}
        value={changedRole}
        onChange={(e) => {
          setChangedRole(e.value);
          setDisabled(props.val.toLowerCase() === e.value.toLowerCase());
        }}
        disabled={props?.rowData?.Name?.disableSelf}
        placeholder={changedRole}
        maxSelectedLabels={1}
      />
      <FontAwesomeIcon
        className={`ms-4 ${disabled ? "disabledIcon" : "changeRoleIcon"}`}
        style={{ fontSize: "1.2rem" }}
        icon={faCheckCircle}
        onClick={(e) => (!disabled ? changeExistingRole(e, changedRole) : null)}
        title="Change Role"
      />
    </div>
  );
};

export default ChangeRole;
