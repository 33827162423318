import { Tooltip } from 'primereact/tooltip';
import { useRef } from 'react';
import { useState } from 'react';
import DeleteDialog from '../TestTool/ALab/DeleteDialog';
import { handleToastErrorCatch } from '../../Helper/ToastHelper';
import { getToastMessage } from '../../Helper/Helper';
import httpService from '../../../services/http.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const KillExecution = ({
    button,
    s_run_id,
    pid
}) => {
    const toDeleteId = useRef(null);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const deleteFile = (id, setLoading) => {
        setLoading(true);
        httpService
            .killExecution(id, pid)
            .then((data) => {
                getToastMessage(data);
                setLoading(false);
                onHideDeleteDialog();
            })
            .catch((err) => {
                handleToastErrorCatch(err, () => {
                    setLoading(false);
                    onHideDeleteDialog();
                })
            })
    }

    const onHideDeleteDialog = () => {
        setDeleteDialog(false);
        toDeleteId.current = null;
    }

    return (
        <>
            {button ?
                <button
                    type="submit"
                    className="btn btn-primary themeBtn text-center px-3 py-2 d-flex align-items-center"
                    onClick={(e) => {
                        toDeleteId.current = s_run_id;
                        setDeleteDialog(true);
                    }}
                >
                    <i
                        style={{ color: "#FFFFFF" }}
                        className="pi pi-minus-circle me-2"
                    />
                    Kill Execution
                </button>
                :
                <>
                    <Tooltip target=".killIcon"></Tooltip>
                    <i
                        onClick={() => {
                            toDeleteId.current = s_run_id;
                            setDeleteDialog(true);
                        }}
                        data-pr-tooltip="Kill Execution"
                        data-pr-position="bottom"
                        style={{ color: "red" }}
                        className="pi pi-minus-circle killIcon me-3"
                    />
                </>
            }
            <DeleteDialog
                header="Kill/Abort Execution"
                killExeDialog={true}
                toDeleteType="file"
                toDeleteId={toDeleteId}
                deleteDialog={deleteDialog}
                onHideDeleteDialog={onHideDeleteDialog}
                deleteFile={deleteFile}
            />
        </>
    )
}

export default KillExecution