import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import "./MainNav.css";
import { useDispatch, useSelector } from "react-redux/es/exports";
const MainNav = () => {
  return (
    <>
      <nav className="navbar-expand-lg navbar main-nav navbar-light">
        <Link to="/" className="ms-5 mt-2">
          <img src={logo} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-1 text-center">
            <li className="nav-item ms-5 mt-2">
              <Link to="/pricing">Pricing</Link>
            </li>
            {/* <li className="nav-item ms-5 mt-2">

<Link to={"/howitworks"}>
How it Works?
</Link>
</li> */}
          </ul>
          <ul className="navbar-nav ms-auto mt-2 mt-lg-1 text-center">
            <li className="nav-item ms-5 mt-2">
              <Link to="/login">
                <button
                  type="button"
                  //   onClick={() => {
                  //     login();
                  //   }}
                  className="btn btn-outline-primary btn-header"
                >
                  <span className="btn-text-login fw-normal">Log in</span>
                </button>
              </Link>
            </li>
            <li className="nav-item ms-5 mt-2">
              <Link to="/signup">
                <button type="button" className="btn btn-primary btn-header">
                  <span className="btn-text-signup fw-bold">Sign up</span>
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default MainNav;
