import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import "./userProfile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import httpService from "../../../services/http.service";
import { statementError } from "../ToastHelper";
import { toast } from "react-toastify";

const UserInput = ({
  edit,
  type,
  placeholder,
  icon,
  val,
  setVal,
  verify,
  setVerify,
  username,
  verifiedEmail,
  setVerifiedEmail,
  userData,
  setOtp,
}) => {
  const [userEmailFocus, setUserEmailFocus] = useState(false);

  const sendOtp = () => {
    setOtp("");
    httpService.sendSignUpOtp(username).catch(() => {
      statementError(
        "OTP cannot be sent at the moment, Please try again later!",
        5000
      );
    });
    setVerify(true);
  };

  const handleFocus = (e) => {
    if (e.target.type === "email") {
      setUserEmailFocus(true);
    }
  };

  return (
    <div
      className={`col-12 col-lg-6 my-2 my-lg-0 ${
        type === "text" && edit ? "disabledInput" : ""
      }`}
    >
      <FontAwesomeIcon icon={icon} className="userInputIcon" />
      <InputText
        onFocus={(e) => handleFocus(e)}
        disabled={type === "text" ? true : !edit}
        type={type}
        className="userInput"
        placeholder={placeholder}
        onChange={(e) => {
          setVal(e.target.value);
          if (type === "email") {
            setVerifiedEmail(false);
          }
        }}
        value={val}
      />
      {edit && userEmailFocus ? (
        <div>
          {verifiedEmail ? (
            <div className={`col-12 my-2 mx-3 d-flex align-items-center green`}>
              <FontAwesomeIcon className="me-2" icon={faCircleCheck} />
              <p className="my-0 ms-2 checkFont">
                Email Verified! Proceed to save
              </p>
            </div>
          ) : (
            <Button
              label="Verify"
              type="button"
              disabled={val === userData?.email || val?.length===0}
              className="btn-success btn btn-sm verifyBtn mx-2 p-1 mt-2 me-2 p-ThemeBtn"
              onClick={() => {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) {
                  toast.error("Invalid Email !", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                  return;
                } else {
                  sendOtp();
                }
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default UserInput;
