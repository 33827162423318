import axios from "axios";
import { MICRO_API_URL_TESTEXECUTION } from "../../config/API_URLS";
import authHeader from "../auth-header";

class DashboardService {
  getAutomationCoverage = async (pid, type) => {
    return await axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL +
          "v1/dashboard/automation-coverage",
        pid,
        {
          headers: authHeader(),
          params: { type: type },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  };

  getExecutionCount = async (pid, duration, env) => {
    return await axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/execution-count",
        pid,
        {
          headers: authHeader(),
          params: { duration: duration, env: env },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  };
  getSourceAutomatedTestRuns = async (pid, duration, env) => {
    return await axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/runs-source",
        pid,
        {
          headers: authHeader(),
          params: { duration: duration, env: env },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  };
  getAutomationScore = async (pid, duration, env) => {
    return await axios
      .post(MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/scores", pid, {
        headers: authHeader(),
        params: { duration: duration, env: env },
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  };

  getSaveFilter = async () => {
    return await axios
      .get(MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/filters", {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  };

  postSaveFilter = async (payload) => {
    return await axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/filters",
        payload,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  };

  getTrends = async (metrics, pageNo, pageSize, payload) => {
    return await axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/trend-metrics",
        payload,
        {
          headers: authHeader(),
          params: { metricsType: metrics, pageNo: pageNo, pageSize: pageSize },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  };

  getTrendsChart = async (metrics, payload, env) => {
    return await axios
      .post(
        MICRO_API_URL_TESTEXECUTION.baseURL + "v1/dashboard/v1/monthly-trends",
        payload,
        {
          headers: authHeader(),
          params: { metricsType: metrics, env: env },
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  };
}

export default new DashboardService();
