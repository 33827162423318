import { BREADCRUMB_DATA, CLEAR_BREADCRUMB_DATA } from "../actions/type.js";
const initialState = [];

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case BREADCRUMB_DATA:
            return { arr: payload };
        case CLEAR_BREADCRUMB_DATA:
            return { arr: [] };
        default:
            return state;
    }
}