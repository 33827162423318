import { useEffect } from "react";
import HeroSection from "../../Layouts/MainScreen/HeroSection";
import Whatis from "../../Layouts/MainScreen/Whatis";
import Footer from "../../UI/Footer/Footer";
import MainNav from "../../UI/MainNav/MainNav";
import "./MainScreen.css";
import { logPageTitleForGA } from "../../Helper/Helper";


const MainScreen = () => {
    useEffect(() => {
        let title = "Jewel";
        document.title = title;
        logPageTitleForGA(title);
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [window.location.hash]);
    return (<>
        <div className="heroSection pb-4">
            <div className="mx-5 mb-5">
                <MainNav />
                <HeroSection />
            </div>
        </div>
        <Whatis />
        <Footer />
    </>);
}

export default MainScreen;