import { MICRO_API_URL_FRAMEWORK } from "../../config/API_URLS";
import authHeader from "../auth-header";
import axios from "axios";


class SecurityService{
    getSecurityDastData=async (id) =>{
        return await axios
          .get(MICRO_API_URL_FRAMEWORK.baseURL + "security/v1/securityMetrics/dast", {
            headers: authHeader(),
            params: { id: id },
          })
          .then((data) => {
            return Promise.resolve(data.data ? data.data : []);
          })
          .catch((err) => Promise.reject(err));
      }
      getSecuritySastData=async (id) =>{
        return await axios
          .get(MICRO_API_URL_FRAMEWORK.baseURL + "security/v1/securityMetrics/sast", {
            headers: authHeader(),
            params: { id: id },
          })
          .then((data) => {
            return Promise.resolve(data.data ? data.data : []);
          })
          .catch((err) => Promise.reject(err));
      }
      getSecurityDashboard=async(id)=> {
        return await axios
          .get(MICRO_API_URL_FRAMEWORK.baseURL + "security/v1/securityDashboard", {
            headers: authHeader(),
            params: { id: id },
          })
          .then((data) => {
            return Promise.resolve(data.data ? data.data : []);
          })
          .catch((err) => Promise.reject(err));
      }
    
      getSecurityLastFiveRuns=async(id)=> {
        return await axios
          .get(MICRO_API_URL_FRAMEWORK.baseURL + "security/v1/lastFive", {
            headers: authHeader(),
            params: { id: id },
          })
          .then((data) => {
            return Promise.resolve(data.data ? data.data : []);
          })
          .catch((err) => Promise.reject(err));
      }
}

export default new SecurityService()