import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react'
import NoData from '../../../UI/NoData/NoData';
import { createColumns, createFilters, getErrorMessage, getToastMessage } from '../../../Helper/Helper';
import SkeletonUtility from '../../../UI/Skeleton/SkeletonUtility';
import { handleToastErrorCatch } from '../../../Helper/ToastHelper';
import service from '../../../../services/RecycleBin/service';
import FolderAction from '../FolderAction';
import RestoreDeleteAction from '../RestoreDeleteAction';
import './index.css';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import DeleteConfirmDialog from '../../../UI/DeleteConfirmation';

const ModuleData = ({ module, alabMessage, mlabMessage }) => {
    const [folderData, setFolderData] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [folderFilters, setFolderFilters] = useState({});
    const [fileFilters, setFileFilters] = useState({});
    const [selectedTcs, setSelectedTcs] = useState(null);
    const [selectedFolderTcs, setSelectedFolderTcs] = useState(null);
    const [status, setStatus] = useState(null);
    const [multipleDeleteLoading, setMultipleDeleteLoading] = useState(false);
    const [isMultipleTcSelected, setIsMultipleTcSelected] = useState(false);
    const [deleteTcsDialog, setDeleteTcsDialog] = useState(false);


    useEffect(() => {
        getDeletedFolders(module);
        getDeletedTc(module);
    }, []);

    const getDeletedFolders = (module, ws) => {
        service
            .getDeletedFolders(module)
            .then((data) => {
                ws && getErrorMessage(data);
                setFolderData(data?.data);
            })
            .catch((err) => handleToastErrorCatch(err))
            .finally(() => setDeleteTcsDialog(false))
    }

    const getDeletedTc = (module, ws) => {
        service
            .getDeletedTc(module)
            .then((data) => {
                ws && getErrorMessage(data);
                setFileData(data?.data);
            })
            .catch((err) => handleToastErrorCatch(err))
            .finally(() => setDeleteTcsDialog(false))
    }

    const deleteAll = () => {
        if (selectedTcs?.length > 0) {
            setMultipleDeleteLoading(true);
            let TcIds = [];
            selectedTcs?.forEach((item) => TcIds.push(item?.Action?.value));
            service
                .deleteTestcase(TcIds)
                .then((data) => {
                    getToastMessage(data);
                })
                .catch((err) => handleToastErrorCatch(err))
                .finally(() => setMultipleDeleteLoading(false));
        }
        else if (selectedFolderTcs?.length > 0) {
            setMultipleDeleteLoading(true);
            let TcFolderIds = [];

            selectedFolderTcs?.forEach((item) => TcFolderIds.push(item?.Action?.value));


            service
                .deleteFolder(TcFolderIds)
                .then((data) => {
                    getToastMessage(data);
                })
                .catch((err) => handleToastErrorCatch(err))
                .finally(() => setMultipleDeleteLoading(false));
        }
    }

    useEffect(() => {

        if (alabMessage) {
            switch (alabMessage.toLowerCase()) {
                case 'alab folder restored':
                case 'alab folder deleted':
                    getDeletedFolders("AUTOMATION", true);
                    break;
                case 'alab testcase restored':
                case 'alab testcase deleted':
                    getDeletedTc("AUTOMATION", true);
                    break;

                default:
                    break;
            }
        }
    }, [alabMessage])

    useEffect(() => {
        if (mlabMessage) {
            switch (mlabMessage.toLowerCase()) {
                case 'mlab folder restored':
                case 'mlab folder deleted':
                    getDeletedFolders("MANUAL", true);
                    break;
                case 'mlab testcase restored':
                case 'mlab testcase deleted':
                    getDeletedTc("MANUAL", true);
                    break;

                default:
                    break;
            }
        }
    }, [mlabMessage])

    useEffect(() => {
        if (Object.keys(folderFilters)?.length === 0) {
            const filterData = folderData?.headers
                ? createFilters(folderData.headers)
                : {};
            setFolderFilters(filterData);
        }
    }, [folderData])

    useEffect(() => {
        if (Object.keys(fileFilters)?.length === 0) {
            const fileFilterData = fileData?.headers
                ? createFilters(fileData.headers)
                : {};
            setFileFilters(fileFilterData);
        }
    }, [fileData])

    useEffect(() => {
        if (selectedTcs?.length > 0 || selectedFolderTcs?.length > 0) {
            setIsMultipleTcSelected(true);
        }
        else {
            setIsMultipleTcSelected(false);
        }


    }, [selectedTcs, selectedFolderTcs])

    const getTabData = (data, key) => {
        return (
            data ? (
                <div className='m-3'>
                    <DataTable
                        reorderableColumns

                        onFilter={(e) => {
                            key === "folder" ? setFolderFilters(e.filters) : setFileFilters(e.filters);
                        }}
                        resizableColumns
                        stripedRows
                        columnResizeMode="expand"
                        value={data?.data}
                        paginator
                        rows={25}
                        // dataKey="id"
                        filters={key === "folder" ? folderFilters : fileFilters}
                        rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        scrollHeight={"55vh"}
                        filterDisplay="menu"
                        responsiveLayout="scroll"
                        emptyMessage={<NoData />}
                        currentPageReportTemplate="Total {totalRecords} Record(s) Found"
                        removableSort

                        className="recycleBinTable mt-4"
                        selectionMode="checkbox"
                        selection={key === "folder" ? selectedFolderTcs : selectedTcs}
                        onRowClick={(e) => e.preventDefault()}
                        onSelectionChange={(e) => {
                            Object.keys(e.value)?.length <= 0 && setStatus(null);

                            if (key === "folder") {
                                setSelectedFolderTcs(e?.value);

                            } else {
                                setSelectedTcs(e?.value);
                            }

                        }}
                    >
                        <Column
                            selectionMode="multiple"
                            headerClassName={`headercolumnsPrime`}
                            className={"columnsPrime"}
                            headerStyle={{ width: "3em" }}
                        ></Column>
                        {createColumns(
                            data.headers,
                            true,
                            true,
                            data.data,
                            false,
                            null,
                            { customAction: FolderAction },
                            {
                                crud_restore: true,
                            },
                            {
                                crud_restore: RestoreDeleteAction,
                            },
                            {
                                crud_restore_props:{
                                    isMultipleSelected:isMultipleTcSelected,
                                    
                                }
                            }
                        )}
                    </DataTable>
                </div>
            ) : (
                <SkeletonUtility
                    paginator={false}
                    totalRows={5}
                />
            )
        )
    }
    return (
        <div className='me-3'>
            <div className='recyclebin-section'>
                <div className="d-flex pt-3 mt-3 mx-3">

                    <ul className="nav nav-pills" id={"pills-tab"} role="tablist">
                        <li className="nav-item mr-1" role="presentation">
                            <div
                                className="fontSizeGeneral nav-link subTabs active"
                                id={`folder-tab-${module}`}
                                data-toggle="pill"
                                onClick={() => {
                                    setIsMultipleTcSelected(false);
                                    setSelectedTcs(null);
                                }}
                                data-target={`#folder-${module}`}
                                type="button"
                                role="tab"
                                aria-selected="true"
                            >
                                Folders
                            </div>
                        </li>
                        <li className="nav-item mr-1" role="presentation">
                            <div
                                className="fontSizeGeneral nav-link subTabs fontSizeGeneral"
                                id={`file-tab-${module}`}
                                data-toggle="pill"
                                data-target={`#file-${module}`}
                                type="button"
                                onClick={() => {
                                    setIsMultipleTcSelected(false);
                                    setSelectedFolderTcs(null);
                                }}
                                role="tab"
                                aria-selected="false"
                            >
                                Testcases
                            </div>
                        </li>
                    </ul>
                    {isMultipleTcSelected && (
                        <div className='ms-auto'>

                            <Button
                                label="Delete all"
                                onClick={() => setDeleteTcsDialog(true)}
                                className="btn-success ms-2 py-2 btn themeBtn"
                                loadingIcon={"pi pi-spin pi-spinner me-2"}
                                loading={multipleDeleteLoading}
                                iconPos="right"
                            />
                        </div>
                    )}
                </div>
                <DeleteConfirmDialog
                    deleteDialog={deleteTcsDialog}
                    setDeleteDialog={setDeleteTcsDialog}
                    toDeleteId={"testcase"}
                    toDeleteName={"Testcases"}
                    deleteFn={deleteAll}
                />
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active mt-3 mb-2"
                        id={`folder-${module}`}
                        role="tabpanel"
                        aria-labelledby={`folder-tab-${module}`}
                    >
                        {getTabData(folderData, "folder")}
                    </div>
                    <div
                        className="tab-pane fade mt-3 my-2"
                        id={`file-${module}`}
                        role="tabpanel"
                        aria-labelledby={`file-tab-${module}`}
                    >
                        {getTabData(fileData, "file")}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModuleData