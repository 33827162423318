import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import httpService from "../../../services/http.service";
import {
  createColumns,
  createFilters,
  getErrorMessage,
  getToastMessage,
} from "../../Helper/Helper";
import "./CompanyAdmin.css";
import { Chips } from "primereact/chips";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import "./CompanyAdmin.css";
import { Button } from "primereact/button";
import NoData from "../../UI/NoData/NoData";
const CompanyAdmin = () => {
  const [companyDetailsData, setCompanyDetailsData] = useState(null);
  const [companyUsers, setCompanyUsers] = useState(null);
  const [filters, setFilters] = useState({});
  const [addDomainDialog, setAddDomainDialog] = useState(false);
  const [existingDomains, setExistingDomains] = useState([]);
  const [domainDisabled, setDomainDisabled] = useState(true);
  const [isUpdateDomainBtnLoading, setIsUpdateDomainBtnLoading] =
    useState(false);

  useEffect(() => {
    getCompanyDetails();
    getAllUsers();
  }, []);

  useEffect(() => {
    const filterData =
      companyUsers && companyUsers["headers"]
        ? createFilters(companyUsers["headers"])
        : {};

    setFilters(filterData);
  }, [companyUsers]);

  const blockUser = (e, data) => {
    let blockData = [];
    blockData.push(data);

    let payload = {
      username: blockData,
    };
    httpService
      .blockExisitingUser(payload)
      .then(async (data) => {
        getToastMessage(data);
        getCompanyDetails();
        getAllUsers();
        const user_block = new CustomEvent("onUserBlock", {
          cancelable: true,
        });
        document.dispatchEvent(user_block);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
                Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  useEffect(() => {
    document.addEventListener("onUserUnblock", getAllUsers);
    return () => {
      document.removeEventListener("onUserUnblock", getAllUsers);
    };
  }, []);

  const addNewUser = (e, data) => {
    httpService
      .addNewCompanyUser(data)
      .then((data) => {
        getToastMessage(data);
        getCompanyDetails();
        getAllUsers();
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
                        Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  const addDomain = (e) => {
    e.preventDefault();

    let payload = {
      domains: existingDomains,
    };
    if (existingDomains.length === 0) {
      toast.error("No Domains entered !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "mt-5",
      });
      return;
    }

    setIsUpdateDomainBtnLoading(true);
    httpService
      .addCompanyDomain(payload)
      .then(async (data) => {
        getToastMessage(data);
        setDomainDisabled(true);
        getCompanyDetails();
        getAllUsers();
        setAddDomainDialog(false);
        setIsUpdateDomainBtnLoading(false);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
                    Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
        setDomainDisabled(true);
        setIsUpdateDomainBtnLoading(false);
      });
  };

  const userCompany_action = (val, data) => {
    return (
      <div>
        {val.toString().toUpperCase() === "VERIFIED" ? (
          <FontAwesomeIcon
            title="Block User"
            icon={faBan}
            onClick={(e) => blockUser(e, data["Username"]["value"])}
            className="actionIcon fail-color mx-2"
          />
        ) : (
          <div className="d-flex">
            <FontAwesomeIcon
              title="Verify User"
              icon={faCircleCheck}
              onClick={(e) => addNewUser(e, data["Username"]["value"])}
              className="actionIcon pass-color mx-2"
            />
            <FontAwesomeIcon
              title="Block User"
              icon={faBan}
              onClick={(e) => blockUser(e, data["Username"]["value"])}
              className="actionIcon fail-color mx-2"
            />
          </div>
        )}
      </div>
    );
  };
  const domain_action = () => {
    return (
      <button
        onClick={() => setAddDomainDialog(true)}
        className="btn btn-success themeBtn px-2 py-1 mx-2"
      >
        Edit Domains
      </button>
    );
  };

  const getCompanyDetails = () => {
    httpService
      .companyDetails()
      .then(async (data) => {
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "failure"
        ) {
          getErrorMessage(data);
        }
        setCompanyDetailsData(await data["data"]);
        setExistingDomains(await data["data"]["data"][0]["Domains"]["value"]);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
                        Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  const getAllUsers = () => {
    httpService
      .companyUsers()
      .then(async (data) => {
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "failure"
        ) {
          getErrorMessage(data);
        }
        setCompanyUsers(await data["data"]);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
                    Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  return (
    <>
      <div className="mt-4 mx-2">
        {companyDetailsData ? (
          <div>
            <DataTable
              reorderableColumns
              resizableColumns
              stripedRows
              columnResizeMode="expand"
              value={
                companyDetailsData["data"] ? companyDetailsData["data"] : null
              }
              dataKey="id"
              responsiveLayout="scroll"
              emptyMessage={<NoData />}
              removableSort
              className="test-stepTable"
            >
              {createColumns(
                companyDetailsData["headers"],
                false,
                false,
                companyDetailsData["data"],
                false,
                null,
                {
                  customAction: domain_action,
                }
              )}
            </DataTable>

            <div className="mt-4">
              <div className="overHeadAnalytics text-center ms-2">
                <div className="fontSizeHeadGeneral">Users</div>
              </div>
              <div className="mt-3">
                {companyUsers ? (
                  <DataTable
                    reorderableColumns
                    onFilter={(e) => {
                      setFilters(e.filters);
                    }}
                    resizableColumns
                    stripedRows
                    columnResizeMode="expand"
                    value={companyUsers ? companyUsers["data"] : null}
                    paginator
                    rows={25}
                    dataKey="id"
                    filters={filters}
                    rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    scrollHeight={"65vh"}
                    filterDisplay="menu"
                    responsiveLayout="scroll"
                    emptyMessage={<NoData />}
                    currentPageReportTemplate="Total {totalRecords} User(s) Found"
                    removableSort
                    className="test-stepTable"
                  >
                    {createColumns(
                      companyUsers["headers"],
                      true,
                      true,
                      companyUsers["data"],
                      true,
                      null,
                      {
                        customAction: userCompany_action,
                      }
                    )}
                  </DataTable>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <Dialog
              blockScroll={true}
              draggable={false}
              header="Edit Domains"
              visible={addDomainDialog}
              onHide={() => {
                setAddDomainDialog(false);
                setDomainDisabled(true);
              }}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "35vw" }}
            >
              <div>
                <div className="w-100">
                  <Chips
                    value={existingDomains}
                    allowDuplicate={false}
                    onChange={(e) => {
                      setExistingDomains(e.value);
                      setDomainDisabled(false);
                    }}
                    className="w-100 p-fluid chipsenv"
                  />
                </div>
                <div className="ms-1 mb-1 mt-1 pDetails-smheading">
                  Note: Press Enter to Add Domains.
                </div>
                <div className="mt-2">
                  <Button
                    label="Update Domains"
                    className="btn-success btn fail-btn mx-1 py-2"
                    onClick={(e) => {
                      addDomain(e);
                    }}
                    loadingIcon={"pi pi-spin pi-spinner me-2"}
                    disabled={domainDisabled}
                    loading={isUpdateDomainBtnLoading}
                    iconPos="right"
                  />
                </div>
              </div>
            </Dialog>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CompanyAdmin;
