import BridgeToken from "../../Layouts/BridgeToken/BridgeToken";
import "./BridgeTokenPage.css";
const BridgeTokenPage = () => {
  return (
    <>
      <div className="container-fluid">
        <BridgeToken />
      </div>
    </>
  );
};
export default BridgeTokenPage;
