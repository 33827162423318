
import { useEffect, useRef } from 'react';
import reportService from '../../../../services/ShareReport/report.service';
import { useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faShareNodes, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import NoData from '../../../UI/NoData/NoData';
import { convertEpochtoDate, encodeParam, getToastMessage } from '../../../Helper/Helper';
import ShareReportDialog from './ShareReportDialog';
import DeleteConfirmDialog from '../../../UI/DeleteConfirmation';
import { handleToastErrorCatch } from '../../../Helper/ToastHelper';
import { connectSocket } from '../../../Helper/SocketHelper';
import { useSelector } from 'react-redux';

const ReportCards = ({ type,setIsReportDataFetching }) => {

    const [reportCardData, setReportCardData] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const toDeleteId = useRef(null);
    const toDeleteName = useRef(null);
    const [isShareLoading,setIsshareLoading]=useState(false);
    const currentCardAction = useRef(null);
    const reportId=useRef(null)
    const username= useSelector((state)=>state.auth.userProfile.username)

    const notifyClient = (e) => {
       
        if (e?.body) {
          let wsBody = JSON.parse(e?.body);
          
          
          if((wsBody?.message?.toLowerCase()==="report deleted" || wsBody?.message?.toLowerCase()==="report updated" || wsBody?.message?.toLowerCase()==="report shared" || wsBody?.message?.toLowerCase()==="report saved") && wsBody?.users?.includes(username)){
            getAllCards(type)
          }
          
          
        }
      
      };

    useEffect(()=>{
        connectSocket("report", "report/private", reportId, notifyClient);

        return () => {
          reportId?.current?.unsubscribe();
        };
    },[])

    


    useEffect(() => {
        setReportCardData(null)
        setIsReportDataFetching(true)
        reportService
            .getAllReports(type)
            .then((data) => {
                setReportCardData(data?.data)
              
            })
            .catch((err)=>handleToastErrorCatch(err))
            .finally(()=>setIsReportDataFetching(false))
    }, [type]);

    const getAllCards=(val)=>{
        setReportCardData(null)
        setIsReportDataFetching(true)
        reportService
            .getAllReports(val)
            .then((data) => {
                setReportCardData(data?.data)
                
            })
            .catch((err)=>handleToastErrorCatch(err))
            .finally(()=>setIsReportDataFetching(false))
    }

    const deleteReport = (setDeleteLoad) => {
        setDeleteLoad(true);
        reportService
            .deleteReport(toDeleteId.current)
            .then((data) => {
                setDeleteDialog(false);
                setDeleteLoad(false);
                toDeleteId.current = null;
                toDeleteName.current = null;
                getToastMessage(data);
            })
            .catch((err) => {
                handleToastErrorCatch(err, () => {
                    setDeleteDialog(false);
                    setDeleteLoad(false);
                    toDeleteId.current = null;
                    toDeleteName.current = null;
                })
            });
    }

    const getCards = () => {
        let cards = reportCardData?.map((report,index) => {
            return (
                <div className='col-md-4 my-4 px-3'>
                    <div className="card reportCards">
                        <div className="card-body">
                            <div className='d-flex mb-2'>
                                <span className="ms-auto">
                                    {report.reportAccessType?.toLowerCase() === "write" &&
                                        <>
                                            <FontAwesomeIcon
                                                id={report?.reportId}
                                                key={index}
                                                className={`reportShareIcons me-1 ${isShareLoading && currentCardAction.current===report?.reportId? "disableState":""}`}
                                                icon={faTrashCan}
                                                onClick={(e) => {
                                                    if(!isShareLoading){
                                                        toDeleteId.current = report?.reportId;
                                                    toDeleteName.current = report?.reportName;
                                                    setDeleteDialog(true);
                                                    }
                                                }}
                                                title="Delete Report"
                                            />
                                            <ShareReportDialog
                                                className='reportShareIcons me-1'
                                                setIsShareLoading={setIsshareLoading}
                                                currentCardAction={currentCardAction}
                                                type="edit"
                                                reportType={report?.reportType}
                                                reportPayload={report?.reportPayload}
                                                reportId={report?.reportId}
                                            />
                                        </>
                                    }
                                    <FontAwesomeIcon
                                        className={`reportShareIcons ${isShareLoading && (currentCardAction.current===report?.reportId)? "disableState":""}`}
                                        icon={faArrowUpRightFromSquare}
                                        onClick={(e) => {
                                            if(!isShareLoading){
                                                window.open(
                                                    window.location.origin +
                                                    "/#/autolytics/shared-report?rule=" +
                                                    encodeParam(
                                                        JSON.stringify(report?.reportPayload)
                                                    )
                                                    + "&sharedReportId=" + report?.reportId
                                                    + "&reportName=" + report?.reportName
                                                    // +"&filters=" +
                                                    // encodeParam(JSON.stringify(filters))
                                                    ,
                                                    "_blank"
                                                );
                                            }
                                        }}
                                        title="Open Report"
                                    />
                                </span>
                            </div>
                            <div className='reportHeading mt-1 ms-1'>{report.reportName}  {type==="all" && `(${report.reportType})`}</div>
                            <div className='mt-2 ms-1'>
                                <span className='reportSubHeading'>Created By: </span>
                                <span>{report.createdByName} {`(${report.createdBy})`}</span>
                            </div>
                            <div className='mt-2 ms-1'>
                                <span className='reportSubHeading'>Last Modified: </span>
                                <span>{convertEpochtoDate(report?.updatedAt)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        return cards;
    }

    const skeletonCards = () => {
        let cards = [1, 2, 3, 4, 5, 6, 7, 8]?.map((report) => {
            return (
                <div className='col-md-4 my-4'>
                    <Skeleton width='24vw' height='32vh' className='px-3' />
                </div>
            )
        })
        return cards;
    }

    return (
        reportCardData ?
            <>
                {reportCardData?.length > 0 ?
                    <div className='row mt-3'>
                        {getCards()}
                    </div>
                    : <div className='mt-5'><NoData /></div>
                }
                <DeleteConfirmDialog
                    deleteDialog={deleteDialog}
                    setDeleteDialog={setDeleteDialog}
                    toDeleteId={toDeleteId.current}
                    toDeleteName={toDeleteName.current}
                    deleteFn={deleteReport}
                />
            </>
            :
            <div className='row'>
                {skeletonCards()}
            </div>
    )
}

export default ReportCards