import { InputSwitch } from 'primereact/inputswitch';
import { useState } from 'react';
import httpService from '../../../../services/http.service';
import { handleToastErrorCatch } from '../../../Helper/ToastHelper';
import { getToastMessage } from '../../../Helper/Helper';

const StatusToggle = ({ props }) => {
    const { val, rowData } = props;
    const [checked, setChecked] = useState(val?.toLowerCase() === 'active');
    const [loading, setLoading] = useState(false);

    const changeStatus = () => {
        let jobId = rowData?.["Job Id"]?.value;
        let status = val?.toLowerCase() === "active" ? "inactive" : "active";
        httpService
            .editJobStatus(jobId, status)
            .then((data) => {
                getToastMessage(data);
                setLoading(false);
                setChecked(!checked);
            })
            .catch((err) => {
                handleToastErrorCatch(err, () => setLoading(false));
            })
    }

    return (
        <div>
            {!loading ?
                <InputSwitch
                    checked={checked}
                    onChange={(e) => {
                        if (!(props?.disabledToggle)) {
                            setLoading(true);
                            changeStatus();
                        }
                    }}
                    className={`${props.disabledToggle ? "disabledToggleIcon" : ""}`}
                    title={props?.disabledToggle ? "Either all manual testcases or No Active testcase with run flag 'Y' found in the suite." : ""}
                    // style={{ transform: "scale(0.9)" }}
                    data-pr-position="right"
                    data-pr-at="right+2 top  "
                />
                :
                <i className='pi pi-spin pi-spinner' />
            }
        </div>
    )
}

export default StatusToggle