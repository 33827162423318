import React, { useEffect } from "react";
import WorkMinutesHeroSection from "../../Layouts/WorkMinutes/WorkMinutesHeroSection/WorkMinutesHeroSection";
import Header from "../../UI/Header/Header";
import NewFooter from "../../UI/NewFooter/NewFooter";
import WorkMinutesFeature from "../../Layouts/WorkMinutes/WorkMinutesFeature/WorkMinutesFeature";
import WorkMinutesUserCapabilities from "../../Layouts/WorkMinutes/WorkMinutesUserCapabilities/WorkMinutesUserCapabilities";

const WorkMinutes = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  });
  return (
    <>
      <Header />
      <WorkMinutesHeroSection />
      <WorkMinutesFeature />
      <WorkMinutesUserCapabilities />
      <NewFooter />
    </>
  );
};

export default WorkMinutes;
