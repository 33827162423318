import { IS_OPEN, IS_CLOSE } from "./type.js";
export const expand = (isopen) => (dispatch) => {
  if (isopen) {
    return dispatch({
      type: IS_OPEN,
    });
  } else {
    return dispatch({
      type: IS_CLOSE,
    });
  }
};
