import { useEffect, useState } from "react";
import "./BreadCrumb.css";
import SeeMoreText from "../SeeMoreText/SeeMoreText";

const BreadCrumb = (props) => {
  const [breadcrumbArr, setBreadcrumbArr] = useState(props.breadList);

  useEffect(() => {
    setBreadcrumbArr(props.breadList);
  }, [props.breadList]);

  const breadCrumbAction = (e, data, index) => {
    e?.preventDefault();
    let tempList = [];
    tempList = [...props.breadList];
    tempList.splice(index + 1, props.breadList.length - 1 - index);
    props.setBreadList(tempList);

    if (props.onClickEvent) {
      props.onClickEvent(data, props.component.toLowerCase(), tempList);
    }

    const getAllRequirements = new CustomEvent("onBreadcrumbClick", {
      detail: {
        data: data,
        index: index,
        list: tempList,
        type: props.component.toLowerCase(),
      },
      cancelable: true,
    });
    document.dispatchEvent(getAllRequirements);
  };

  const getBreadCrumbs = (data) => {
    const links = data
      ? data.map((link, i) => {
          return (
            <span key={`${i}+${link}`} className="d-flex flex-shrink-0">
              <span className={i < 1 ? "" : `mx-1 ${props?.classname}`}>
                {`${i < 1 ? "" : ">"}`}
              </span>
              <span
                className={`breadLink ${props?.classname}`}
                onClick={(e) => breadCrumbAction(e, link, i)}
                title={link?.name}
              >
                {link?.name.substring(0, 20)}
                {link?.name.length > 20 && "..."}
              </span>
            </span>
          );
        })
      : null;
    return links;
  };

  return (
    <div className="d-flex flex-wrap">{getBreadCrumbs(breadcrumbArr)}</div>
  );
};

export default BreadCrumb;
