import React, { useEffect } from 'react'
import ExecutionInfo from './ExecutionInfo';
import { ConvertTexttoChart, convertEpochtoDate } from '../../../Helper/Helper';
import { Skeleton } from 'primereact/skeleton';
import InfraCard from './InfraCard';
import OverviewSkeleton from './OveriewSkeleton';


const Overview = ({ SecureData, lastFiveRunDats }) => {

  return (
    <>
      {SecureData ? (
        <>
          <div className="mt-2">
            <ExecutionInfo
              projectTitle="Project"
              projectName={
                SecureData?.data?.projectName
              }
              env={SecureData?.data?.env}
              status={SecureData?.data?.status}
              isSecure={true}
              className="fw-bold"
            />
          </div>
          <div className="p-3">
            <div className="d-flex row justify-content-between">
              <div className="extentCard justify-content-center card my-2 col-lg-6 col-md-6 col-sm-12 col-12 ">
                <div className="extentHeading text-center">
                  Overall Vulnerability Distribution
                </div>
                <div className="row d-flex">
                  <div className="col-md-6 my-auto">
                    {ConvertTexttoChart(
                      SecureData?.data?.vulnerabilityDistribution?.data,
                      "doughnut_chart",
                      false,
                      null,
                    )}
                  </div>
                  <div className="col-md-6">
                    <table className="doughnut-legend d-flex flex-column align-items-center justify-content-center">
                      {SecureData?.data?.vulnerabilityDistribution?.data?.labels.map((label, index) => {
                        return (
                          <tr
                            key={index}
                            className="w-100 py-2 px-3 legend"
                          >
                            <td>
                              <span
                                className={`secure-${label.toLowerCase()}-btn doughnut-legend-span`}
                              />
                            </td>
                            <td className="w-50 legend-label text-capitalize">
                              {label}
                            </td>
                            <td className="w-50 text-end doughnut-legend-val ">
                              {
                                SecureData?.data?.vulnerabilityDistribution?.data?.datasets?.[0]?.data?.[index]
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
              {lastFiveRunDats ? (
                <div className="extentCard card my-2 col-lg-5 col-md-6 col-sm-12 col-12 ">
                  {ConvertTexttoChart(
                    lastFiveRunDats?.data?.value,
                    "stacked_bar_chart",
                    false,
                    lastFiveRunDats?.data?.heading,
                    "",
                    "",
                    "",
                    "",
                    true,
                  )}
                </div>
              ) : (
                <div className="extentCard card my-2 col-lg-5 col-md-6 col-sm-12 col-12">
                  <div className="text-left extentHeading d-flex justify-content-between align-items-center fontSizeGeneral lexendFont extentHeading mb-3">
                    Timeline Graph
                  </div>
                  <Skeleton width="100%" height="10rem"></Skeleton>
                </div>
              )}
            </div>
          </div>
          <div class="row d-flex ">
            <InfraCard heading="Project Details">
              <div className="row d-flex align-items-stretch flex-wrap justify-content-between mt-3">
                <div className="col-md-6 col-12 d-flex align-items-stretch mt-1 w-0">
                  <div>
                    <div className="py-auto">
                      <span className="execution-headers">
                        Start Date
                      </span>
                      <br />
                      <span className="cardHeadercontent fw-bold">
                        {SecureData?.data?.startDate
                          ? convertEpochtoDate(
                            SecureData?.data?.startDate,
                          )
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 d-flex align-items-stretch mt-1 w-0">
                  <div>
                    <div className="py-auto">
                      <span className="execution-headers">Owner</span>
                      <br />
                      <span className="cardHeadercontent fw-bold">
                        {SecureData?.data?.owner
                          ? SecureData?.data?.owner
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 d-flex align-items-stretch mt-2 w-0">
                  <div>
                    <div className="py-auto">
                      <span className="execution-headers">Website-Url</span>
                      <br />
                      {SecureData?.data?.websiteUrl ? (
                        <a
                          href={SecureData?.data?.websiteUrl}
                          className="cardHeadercontent fw-bold text-decoration-underline "
                          target="_blank"
                        >
                          {SecureData?.data?.websiteUrl}
                        </a>
                      ) : "-"}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 d-flex align-items-stretch mt-2 w-0">
                  <div>
                    <div className="py-auto">
                      <span className="execution-headers">Github-Repository</span>
                      <br />
                      {SecureData?.data?.gitRepository ? (
                        <a
                          href={SecureData?.data?.gitRepository}
                          className="cardHeadercontent fw-bold text-decoration-underline "
                          target="_blank"
                        >
                          {SecureData?.data?.gitRepository}
                        </a>
                      ) : "-"}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-12 d-flex align-items-stretch mt-2 w-0">
                  <div>
                    <div className="py-auto">
                      <span className="execution-headers">Swagger-Link</span>
                      <br />
                      {SecureData?.data?.swaggerLink ? (
                        <a
                          href={SecureData?.data?.swaggerLink}
                          className="cardHeadercontent fw-bold text-decoration-underline "
                          target="_blank"
                        >
                          {SecureData?.data?.swaggerLink}
                        </a>
                      ) : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </InfraCard>
            <InfraCard heading="Jewel User Details">
              <div className="row d-flex align-items-stretch flex-wrap justify-content-between mt-3">
                <div className="col-md-6 col-12 d-flex align-items-stretch mt-1 w-0">
                  <div>
                    <div className="py-auto">
                      <span className="execution-headers">
                        Machine Base User
                      </span>
                      <br />
                      <span className="cardHeadercontent fw-bold">
                        {SecureData?.data?.baseUser
                          ? SecureData?.data?.baseUser
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 d-flex align-items-stretch mt-1 w-0">
                  <div>
                    <div className="py-auto">
                      <span className="execution-headers">
                        Jewel Token User
                      </span>
                      <br />
                      <span className="cardHeadercontent fw-bold">
                        {SecureData?.data?.tokenUser
                          ? SecureData?.data?.tokenUser
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </InfraCard>
          </div>
        </>
      ) : (
        <OverviewSkeleton />
      )}
    </>
  )
}

export default Overview
