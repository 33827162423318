import { DataTable } from 'primereact/datatable';
import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Column } from 'primereact/column';
import { useState } from 'react';
import Paginator from './../Paginator/Paginator';


const SkeletonUtility = (props) => {

    const [paginator, setPaginator] = useState(props.paginator)
    const skeletonBridgeRows = Array.from({ length: props?.totalRows ? props?.totalRows : 5 });
    const skeletonBridgeTemplate = () => {
        return <Skeleton></Skeleton>;
    };
    const tableSkeleton = () => {
        return (
            <DataTable
                responsiveLayout="scroll"
                header={props.header ? props.headerValue : false}
                value={skeletonBridgeRows}
                className="p-datatable-striped mt-3 test-stepTable"
                footer={
                    paginator ?
                        <Paginator
                            skeleton={true}
                            currentPage={props.currentPage}
                            totalElements={"..."}
                            rowsPerPage={props.rowsPerPage}
                            pageStartValue={props.pageStartValue}
                            pageEndValue={props.pageEndValue}
                        />
                        : false
                }
            >
                <Column
                    header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                    style={{ width: "5%" }}
                    body={skeletonBridgeTemplate}
                ></Column>
                <Column
                    header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                    style={{ width: "30%" }}
                    body={skeletonBridgeTemplate}
                ></Column>
                <Column
                    header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                    style={{ width: "15%" }}
                    body={skeletonBridgeTemplate}
                ></Column>
                <Column
                    header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                    style={{ width: "25%" }}
                    body={skeletonBridgeTemplate}
                ></Column>
                <Column
                    header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                    style={{ width: "25%" }}
                    body={skeletonBridgeTemplate}
                ></Column>
            </DataTable>
        );
    };

    return (
        <div>{tableSkeleton()}</div>
    )
}

export default SkeletonUtility