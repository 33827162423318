import React from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import SideBarRevamp from "./components/UI/SideBarRevamp/SideBarRevamp";

const GuardedRoute = ({ user, children }) => {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  if (!user) {
    if (!pathname.includes("super-admin")) {
      sessionStorage.setItem("redirect", pathname + search);
    }

    return <Navigate to="/login" replace />;
  }

  const showSidebar =
    searchParams.get("sidebar") === null ||
    searchParams.get("sidebar") === "true";

  return showSidebar ? (
    <SideBarRevamp>{children}</SideBarRevamp>
  ) : (
    <>{children}</>
  );
};

export default GuardedRoute;
