import React from "react";
import "./YouMayAlsoLike.css";
import { youMayAlsoLikeCardsData } from "../../../Helper/MockData/LandingPageMockData";
import YouMayAlsoLikeCard from "./YouMayAlsoLikeCard/YouMayAlsoLikeCard";

const YouMayAlsoLike = () => {
  return (
    <div className="component-padding max-w-1440">
      <p className="component-heading ">You may also like</p>
      <div className="you-may-cards-wrapper">
        {youMayAlsoLikeCardsData.map((item) => {
          return (
            <React.Fragment key={item.heading}>
              <YouMayAlsoLikeCard
                icon={item.icon}
                heading={item.heading}
                description={item.description}
                buttonLabel={item.buttonLabel}
                buttonLink={item.buttonLink}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default YouMayAlsoLike;
