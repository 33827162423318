import autolyticsInfoDataAnalysisIcon from "../../../assets/autolyticsInfoDataAnalysisIcon.svg";
import autolyticsInfoPerformanceTrackingIcon from "../../../assets/autolyticsInfoPerformanceTrackingIcon.svg";
import autolyticsInfoStrategicPlanningIcon from "../../../assets/autolyticsInfoStrategicPlanningIcon.svg";
import autolyticsInfoDashBoardAnalysisIcon from "../../../assets/autolyticsInfoDashBoardAnalysisIcon.svg";
import autolyticsInfoReportingFunctionalityIcon from "../../../assets/autolyticsInfoReportingFunctionalityIcon.svg";
import autolyticsInfoDetailedReportsIcon from "../../../assets/autolyticsInfoDetailedReportsIcon.svg";
import autolyticsInfoSmartAnalysisIcon from "../../../assets/autolyticsInfoSmartAnalysisIcon.svg";

export const usageOfAutolyticsDashboardCardsData = [
  {
    icon: autolyticsInfoDataAnalysisIcon,
    heading: "Data Analysis",
    description:
      "Dedicated to crafting automated test cases using GemJar, GemPyp, and GemPrf.",
  },
  {
    icon: autolyticsInfoPerformanceTrackingIcon,
    heading: "Performance Tracking",
    description:
      "Serving as a central hub for efficient suite management, combining both automated and manual test cases.",
  },
  {
    icon: autolyticsInfoStrategicPlanningIcon,
    heading: "Strategic Planning",
    description:
      "Designed for meticulous creation and organization of manual test cases, offering flexibility and structural clarity.",
  },
];

export const featureOfAutolyticsDashboardCardsData = [
  {
    icon: autolyticsInfoDashBoardAnalysisIcon,
    heading: "Dashboard Analysis",
    description:
      "Autolytics features a user-friendly dashboard providing insights into running suites, executed runs and their detailed analysis supported by descriptive charts.",
  },
  {
    icon: autolyticsInfoReportingFunctionalityIcon,
    heading: "Reporting Functionality",
    description:
      "Autolytics offers six comprehensive reports at both suite and testcase levels, adaptable to selected projects, environments, and customizable timelines.",
  },
  {
    icon: autolyticsInfoDetailedReportsIcon,
    heading: "Detailed Reports",
    description:
      "Autolytics provides detailed suite and test case reports, enabling granular insights and facilitating performance tracking over time.",
  },
  {
    icon: autolyticsInfoSmartAnalysisIcon,
    heading: "Smart Analysis",
    description:
      "Autolytics conducts health analysis, introduces stability scores, identifies unstable test cases, and swiftly detects broken indexes for efficient issue resolution.",
  },
];
