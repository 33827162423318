import React, { useEffect, useRef, useState } from "react";
import UserAbstract from "./UserAbstract";
import TabNavigation from "./TabNavigation";
import profileService from "../../../services/UserProfile/profile.service";
import { useSelector } from "react-redux";
import ProfileSkeleton from "./ProfileSkeleton";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { logPageTitleForGA } from "../../Helper/Helper";

const MainProfile = () => {
  const [userData, setUserData] = useState(null);
  const [emailUpdate, setEmailUpdate] = useState(false);
  const firstUpdate = useRef(true);
  const profileUsername = useSelector(
    (state) => state.auth.userProfile.username
  );

  useEffect(() => {
    let title = "Jewel | Profile";
    document.title = title;
    logPageTitleForGA(title);
    getUserData(profileUsername);
    firstUpdate.current = false;
  }, []);

  useEffect(() => {
    if (!firstUpdate.current && emailUpdate) {
      getUserData(profileUsername);
      setEmailUpdate(false);
    }
  }, [emailUpdate]);

  const getUserData = (username) => {
    profileService
      .getUserDetails(username)
      .then((data) => {
        setUserData(data?.data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };
  return (
    <div>
      {userData ? (
        <>
          <UserAbstract userData={userData} />
          <TabNavigation
            userData={userData}
            emailUpdate={emailUpdate}
            setEmailUpdate={setEmailUpdate}
          />
        </>
      ) : (
        <ProfileSkeleton />
      )}
    </div>
  );
};

export default MainProfile;
