import { useEffect, useState } from "react";
import "./CustomDialog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const CustomDialog = ({
  heading,
  footer,
  align,
  showDialog,
  onClose,
  children,
  showBackdrop,
  width = "50%",
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (showDialog) {
      const timer = setTimeout(() => {
        setIsTransitioning(true);
      }, 50);

      return () => clearTimeout(timer);
    } else {
      setIsTransitioning(false);
    }
  }, [showDialog]);

  return (
    <>
      {showBackdrop && showDialog && <div className="backdrop"></div>}
      {showDialog && (
        <div
          className={`custom-dialog ${align} ${
            isTransitioning ? "show-dialog" : ""
          }`}
          style={{ width }}
        >
          <div className="dialog-header d-flex align-items-center">
            <div className="header-content d-flex">
              <span className="ms-4">{heading}</span>
              <span className="d-flex ms-auto me-3">
                <FontAwesomeIcon
                  onClick={onClose}
                  className="close-button"
                  icon={faXmark}
                />
              </span>
            </div>
          </div>
          <div className="dialog-content">
            <div className="content-container">{children}</div>
          </div>
          <div className="dialog-footer">{footer}</div>
        </div>
      )}
    </>
  );
};

export default CustomDialog;
