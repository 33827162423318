import LandingPageALabIcon from "../../../assets/LandingPageALabIcon.svg";
import LandingPageAutolyticsIcon from "../../../assets/LandingPageAutolyticsIcon.svg";
import LandingPageMLabIcon from "../../../assets/LandingPageMLabIcon.svg";
import testpadPageALabImage from "../../../assets/testpadPageALabImage.png";
import testpadAlabAutomatedTestcaseCreationIcon from "../../../assets/testpadAlabAutomatedTestcaseCreationIcon.svg";
import testpadElabSuiteManagementIcon from "../../../assets/testpadElabSuiteManagementIcon.svg";
import testpadElabTestCaseImportAndCopyIcon from "../../../assets/testpadElabTestCaseImportAndCopyIcon.svg";
import testpadElabSuiteExecutionIcon from "../../../assets/testpadElabSuiteExecutionIcon.svg";
import testpadElabEmailFunctionalityIcon from "../../../assets/testpadElabEmailFunctionalityIcon.svg";
import testpadElabGitIntegrationIcon from "../../../assets/testpadElabGitIntegrationIcon.svg";
import testpadElabScheduledExecutionIcon from "../../../assets/testpadElabScheduledExecutionIcon.svg";
import testpadMlabTestCaseCreationIcon from "../../../assets/testpadMlabTestCaseCreationIcon.svg";
import testpadMlabTestStepsIcon from "../../../assets/testpadMlabTestStepsIcon.svg";
import testpadMlabImportAndCopyIcon from "../../../assets/testpadMlabImportAndCopyIcon.svg";
import testpadMlabRequirementsAttachmentIcon from "../../../assets/testpadMlabRequirementsAttachmentIcon.svg";
import testpadAlabImportFunctionalityIcon from "../../../assets/testpadAlabImportFunctionalityIcon.svg";

export const SpecializedModulesCardData = [
  {
    icon: LandingPageALabIcon,
    heading: "ALab",
    description:
      "Dedicated to crafting automated test cases using GemJar, GemPyp, and GemPrf.",
  },
  {
    icon: LandingPageAutolyticsIcon,
    heading: "ELab",
    description:
      "Serving as a central hub for efficient suite management, combining both automated and manual test cases.",
  },
  {
    icon: LandingPageMLabIcon,
    heading: "MLab",
    description:
      "Designed for meticulous creation and organization of manual test cases, offering flexibility and structural clarity.",
  },
];

export const ALabCardDataMobile = [
  {
    heading: "Automated Test Case Creation",
    description:
      "Develop automated test cases with GemJar, GemPyp, and gemPrf.",
    image: testpadAlabAutomatedTestcaseCreationIcon,
  },
  {
    heading: "Import Functionality",
    description:
      "Seamlessly import manual test cases for a smooth transition between manual and automated testing.",
    image: testpadAlabImportFunctionalityIcon,
  },
  {
    heading: "Folder Import",
    description:
      "Efficiently import entire test case folders to ensure a streamlined migration of test scenarios.",
    image: testpadPageALabImage,
  },
];
export const ALabCardDataDesktop = {
  contentArray: [
    {
      heading: "Automated Test Case Creation",
      description:
        "Develop automated test cases with GemJar, GemPyp, and GemPrf.",
    },
    {
      heading: "Import Functionality",
      description:
        "Seamlessly import manual test cases for a smooth transition between manual and automated testing.",
    },
    {
      heading: "Folder Import",
      description:
        "Efficiently import entire test case folders to ensure a streamlined migration of test scenarios.",
    },
  ],
  imagesArray: [
    {
      image: testpadAlabAutomatedTestcaseCreationIcon,
    },
    {
      image: testpadAlabImportFunctionalityIcon,
    },
    {
      image: testpadPageALabImage,
    },
  ],
};

export const ELabCardDataMobile = [
  {
    heading: "Suite Management",
    description:
      "Elab is a central hub for creating and managing test suites with both automated and manual test cases.",
    image: testpadElabSuiteManagementIcon,
  },
  {
    heading: "Test Case Import and Copy",
    description:
      "Import and copy automated and manual test cases within Elab for enhanced reusability.",
    image: testpadElabTestCaseImportAndCopyIcon,
  },
  {
    heading: "Suite Execution",
    description:
      "Execute test suites to streamline the testing process and ensure comprehensive coverage.",
    image: testpadElabSuiteExecutionIcon,
  },
  {
    heading: "Email Functionality",
    description:
      "Automatically email reports upon suite execution, fostering effective communication and collaboration.",
    image: testpadElabEmailFunctionalityIcon,
  },
  {
    heading: "Git Integration",
    description:
      "Seamlessly integrate test cases and steps with Git for a version-controlled test environment.",
    image: testpadElabGitIntegrationIcon,
  },
  {
    heading: "Scheduled Execution",
    description:
      "Schedule suite execution at specific times, enhancing automation and ensuring timely testing procedures.",
    image: testpadElabScheduledExecutionIcon,
  },
];
export const ELabCardDataDesktop = {
  contentArray: [
    {
      heading: "Suite Management",
      description:
        "Elab is a central hub for creating and managing test suites with both automated and manual test cases.",
    },
    {
      heading: "Test Case Import and Copy",
      description:
        "Import and copy automated and manual test cases within Elab for enhanced reusability.",
    },
    {
      heading: "Suite Execution",
      description:
        "Execute test suites to streamline the testing process and ensure comprehensive coverage.",
    },
    {
      heading: "Email Functionality",
      description:
        "Automatically email reports upon suite execution, fostering effective communication and collaboration.",
    },
    {
      heading: "Git Integration",
      description:
        "Seamlessly integrate test cases and steps with Git for a version-controlled test environment.",
    },
    {
      heading: "Scheduled Execution",
      description:
        "Schedule suite execution at specific times, enhancing automation and ensuring timely testing procedures.",
    },
  ],
  imagesArray: [
    {
      image: testpadElabSuiteManagementIcon,
    },
    {
      image: testpadElabTestCaseImportAndCopyIcon,
    },
    {
      image: testpadElabSuiteExecutionIcon,
    },
    {
      image: testpadElabEmailFunctionalityIcon,
    },
    {
      image: testpadElabGitIntegrationIcon,
    },
    {
      image: testpadElabScheduledExecutionIcon,
    },
  ],
};
export const MLabCardDataMobile = [
  {
    heading: "Test Case Creation",
    description:
      "Create manual test cases in Mlab, organizing them into folders for better structure.",
    image: testpadMlabTestCaseCreationIcon,
  },
  {
    heading: "Test Steps",
    description:
      "Add detailed test steps to each manual test case for comprehensive testing procedures.",
    image: testpadMlabTestStepsIcon,
  },
  {
    heading: "Import and Copy",
    description:
      "Import test steps and copy them within the same project for flexibility.",
    image: testpadMlabImportAndCopyIcon,
  },
  {
    heading: "Suite Integration",
    description:
      "Attach project requirements to test cases for traceability and documentation.",
    image: testpadPageALabImage,
  },
  {
    heading: "Requirements Attachment",
    description:
      "Link test cases to suites for the creation of comprehensive test suites.",
    image: testpadMlabRequirementsAttachmentIcon,
  },
];
export const MLabCardDataDesktop = {
  contentArray: [
    {
      heading: "Test Case Creation",
      description:
        "Create manual test cases in Mlab, organizing them into folders for better structure.",
    },
    {
      heading: "Test Steps",
      description:
        "Add detailed test steps to each manual test case for comprehensive testing procedures.",
    },
    {
      heading: "Import and Copy",
      description:
        "Import test steps and copy them within the same project for flexibility.",
    },
    {
      heading: "Suite Integration",
      description:
        "Attach project requirements to test cases for traceability and documentation.",
    },
    {
      heading: "Requirements Attachment",
      description:
        "Link test cases to suites for the creation of comprehensive test suites.",
    },
  ],
  imagesArray: [
    {
      image: testpadMlabTestCaseCreationIcon,
    },
    {
      image: testpadMlabTestStepsIcon,
    },
    {
      image: testpadMlabImportAndCopyIcon,
    },
    {
      image: testpadPageALabImage,
    },
    {
      image: testpadMlabRequirementsAttachmentIcon,
    },
  ],
};
