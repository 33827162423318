import axios from "axios";
import authHeader from "../auth-header";
import { MICRO_API_URL_TESTEXECUTION, MICRO_API_URL_TESTPAD } from "../../config/API_URLS";

class Service {
    getDeletedFolders=async(type)=> {
        return await axios
            .get(
                MICRO_API_URL_TESTPAD.baseURL +
                "folderManagement/v1/recycleBin?type=" +
                type,
                {
                    headers: authHeader(),
                }
            )
            .then((data) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => Promise.reject(err));
    }

    getDeletedTc=async(type)=> {
        return await axios
            .get(
                MICRO_API_URL_TESTEXECUTION.baseURL +
                "v1/recycleBin?type=" +
                type,
                {
                    headers: authHeader(),
                }
            )
            .then((data) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => Promise.reject(err));
    }

    deleteFolder=async(payload)=> {
        return await axios
            .delete(MICRO_API_URL_TESTPAD.baseURL + "folderManagement/v2/delete/folder" , {
                headers: authHeader(),
                data:payload
            })
            .then((data) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => Promise.reject(err));
    }

    deleteTestcase=async(payload)=> {
        return await axios
            .delete(MICRO_API_URL_TESTEXECUTION.baseURL + "v1/testcase/delete",
                {
                    headers: authHeader(),
                    data:payload
                })
            .then((data) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => Promise.reject(err));
    }

    restoreFolder=async(payload)=> {
        return await axios
            .get(
                MICRO_API_URL_TESTPAD.baseURL +
                "folderManagement/v1/recycleBin/restore?folderId=" +payload,
                
                {
                    headers: authHeader(),
                }
            )
            .then((data) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => Promise.reject(err));
    }

    restoreTestcase=async(payload)=> {
        return await axios
            .get(
                MICRO_API_URL_TESTEXECUTION.baseURL +
                "v1/recycleBin/restore?isolatedVersionId="+payload,
                
                {
                    headers: authHeader(),
                }
            )
            .then((data) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => Promise.reject(err));
    }
}

export default new Service();