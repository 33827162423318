import {useState,useEffect} from 'react'
import mlabService from '../../../services/Mlab/mlab.service';
import { getSuccessToast, handleToastErrorCatch } from '../../Helper/ToastHelper';
import { DataTable } from 'primereact/datatable';
import { createColumns } from '../../Helper/Helper';
import NoData from '../../UI/NoData/NoData';
import httpService from '../../../services/http.service';
import { Dropdown } from 'primereact/dropdown';
import "./ManualTC.css";
import { Column } from 'primereact/column';
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import SkeletonUtility from '../../UI/Skeleton/SkeletonUtility';
import { Dialog } from 'primereact/dialog';
import { useSelector } from 'react-redux';

const userOptionTemplate = (option) => {
  return (
    <>
      <div>
        <span>{`${option.Name} ( ${option.Username} )`}</span>
      </div>
    </>
  );
};

const DropdownHeader=({users,setAssignees,assignBtnLoading,selectedAssignedToUser,setSelectedAssignedToUser,selectedTcs,setSelectedTcs})=>{
  return(
    <div>
      {selectedTcs?.length>0?(
        <div className='d-flex justify-content-between align-items-center'>
        <div className="manualFormInputs assignDropdown">
        {users?.length > 0 ? (
          <Dropdown
            value={selectedAssignedToUser}
            onChange={(e) => setSelectedAssignedToUser(e.value)}
            options={users}
            optionLabel="Name"
            optionValue="Username"
            virtualScrollerOptions={{ itemSize: 38 }}
            itemTemplate={userOptionTemplate}
            placeholder="Select User"
            className="dialog-dropdown w-100"
         
          />
        ) : (
          <Dropdown
            className="dialog-dropdown w-100"
            options={[]}
            emptyMessage="No User(s) Found"
            placeholder="Select User"
          />
        )}
      </div>
      </div>
      ):(
        <div className="manualFormInputs assignDropdown">
                   
                      <Dropdown
                        className="dialog-dropdown w-100"
                        disabled
                        placeholder="No Testcase Selected"
                      />
                  </div>
      )}
      

    </div>
  )
}

const AssignTc = ({folderId,pid,getUsers,users,fName,assignTcDialog,setAssignTcDialog,allManualTC,setAllManualTC}) => {
    const [assignTcData,setassignTcData]=useState(null);
    const [selectedAssignedToUser,setSelectedAssignedToUser]=useState(null)
    const [selectedTcs,setSelectedTcs]=useState(null)
    const [assignTcDataLoading,setAssignTcDataLoading]=useState(false)
    const [assignBtnLoading,setAssignBtnLoading]=useState(false)

    const company = useSelector((state) => state.auth.userProfile.socket);

    const getAssignees=(id)=>{
      setAssignTcDataLoading(true)
       mlabService
       .getTestcaseAssignees(id)
       .then((data)=>{
        setassignTcData(data?.data);
       })
       .catch((err)=>handleToastErrorCatch(err))
       .finally(()=>setAssignTcDataLoading(false))
    }

    const setAssignees=()=>{
      setAssignBtnLoading(true)
      const tcIds=selectedTcs?.map((item)=>item?.id);
      mlabService
      .setTestcaseAssignees(selectedAssignedToUser,company,pid,tcIds)
      .then((data)=>{
        getSuccessToast(data?.message)
        setSelectedAssignedToUser(null);
        setSelectedTcs(null);
      
        getAssignees(folderId)
      })
      .catch((err)=>handleToastErrorCatch(err))
      .finally(()=>setAssignBtnLoading(false))
    }

    useEffect(()=>{
        getAssignees(folderId)
        getUsers(pid);
    },[])

  return (
      <Dialog
     blockScroll={true}
     draggable={false}
     header={`Assign Multiple Testcases - ${fName}`}
     footer={
      <>
      {selectedAssignedToUser && (
        <div>
        <Button
              label="Assign"
              className="btn-success btn themeBtn mx-2 p-2 mt-1"
              onClick={(e) => setAssignees()}
              loadingIcon={"pi pi-spin pi-spinner me-2"}
              loading={assignBtnLoading}
              iconPos="right"
            />
            <Button
              label="Cancel"
              className="btn-success btn themeBtnOutline mx-2 p-2 mt-1"
              onClick={(e) => setSelectedAssignedToUser(null)}
              loadingIcon={"pi pi-spin pi-spinner me-2"}
              iconPos="right"
            />
        </div>
      )}
      </>
     }
     visible={assignTcDialog}
     onHide={() => {
       setAssignTcDialog(false);
     }}
     breakpoints={{ "960px": "75vw" }}
     style={{ width: "50vw" }}
     >
      
    
      {!assignTcDataLoading?(
        <DataTable
        reorderableColumns
        header={<DropdownHeader users={users} assignBtnLoading={assignBtnLoading} setAssignees={setAssignees} selectedAssignedToUser={selectedAssignedToUser} setSelectedAssignedToUser={setSelectedAssignedToUser} selectedTcs={selectedTcs} setSelectedTcs={setSelectedTcs}/>}
        resizableColumns
        stripedRows
        value={assignTcData ? assignTcData?.data : null}
        paginator
        rows={25}
        rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        scrollHeight={"40vh"}
        filterDisplay="menu"
        responsiveLayout="scroll"
        emptyMessage={<NoData />}
        currentPageReportTemplate="Total {totalRecords} Record(s) Found"
        removableSort
        selectionMode="checkbox"
        selection={selectedTcs}
        onRowClick={(e) => e.preventDefault()}
        onSelectionChange={(e) => {
             setSelectedTcs(e?.value);
          }}
        className="test-stepTable"
        >
          <Column
                      selectionMode="multiple"
                      headerClassName={`headercolumnsPrime`}
                      className={"columnsPrime"}
                      headerStyle={{ width: "3em" }}
                    ></Column>
          {createColumns(
              assignTcData?.headers,
              true,
              false,
              assignTcData?.data,
              false,
              null,
          )}
        </DataTable>
      ):(
        <SkeletonUtility
              paginator={false}
              totalRows={8}
            />
      )}
      </Dialog>
    
  )
}

export default AssignTc
