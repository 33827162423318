import { toast } from "react-toastify";
import httpService from "../../../services/http.service";
import { getErrorMessage, getInfoMessage, loadingData } from "../../Helper/Helper";
import "./EditUserAccess.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import DeleteDialog from "../TestTool/ALab/DeleteDialog";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import DeleteConfirmDialog from "../../UI/DeleteConfirmation";

const EditUserAccess = ({ props }) => {

  const [isDialogVisible,setIsDialogVisible]=useState(false);
  const [isUserDeleteLoading,setIsUserDeleteLoading]=useState(false)

  const removeUser = (e) => {
    setIsUserDeleteLoading(true);
    let payload = {
      pid: props.val,
      username: props.rowData?.Name?.value,
    };

    httpService
      .removeProjectUser(payload)
      .then((data) => {
        getInfoMessage(data);
        getErrorMessage(data);
        if (data?.operation?.toLowerCase() === "success") {
          props?.["edit_roles"]?.["editProjectAccess"](e, props.val, props.project_name);
        }
        setIsDialogVisible(false);
        setIsUserDeleteLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err,()=>{
          setIsDialogVisible(false);
          setIsUserDeleteLoading(false);
        })
      });
  };
  return (
    <div>
     <DeleteConfirmDialog
     deleteDialog={isDialogVisible}
     setDeleteDialog={setIsDialogVisible}
     toDeleteId={props.rowData?.Name?.value}
     toDeleteName="user"
     deleteFn={removeUser}
     />
      <FontAwesomeIcon
        className="removeUserIcon"
        icon={faTrashCan}
        onClick={(e) => setIsDialogVisible(true)}
        title="Remove User"
      />
    </div>
  );
};

export default EditUserAccess;
