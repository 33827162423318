import "../Extent.css";

const TabComponent = ({ id, data,activeTab }) => {
  return (
    <>
      <ul
        className="nav nav-pills extentUl mb-3 mt-3"
        id={"pills-tab-" + id}
        role="tablist"
      >
        {data.map((tab) => (
          <li className="nav-item mr-1" role="presentation">
            <div
              className={`fontSizeGeneral nav-link extentPill ${
                tab.name === `${activeTab}` && `active`
              }`}
              id={tab.id + id}
              data-toggle="pill"
              data-target={tab.target + id}
              type="button"
              role="tab"
              aria-selected="true"
              onClick={tab.fn}
            >
              {tab.name}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default TabComponent;