import "./AutoRefresh.css";
import { fancyTimeFormat } from "../../Helper/Helper";
import { ToggleButton } from "primereact/togglebutton";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowRotateRight,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";

const AutoRefresh = (props) => {
  const [checked1, setChecked1] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [reftimer, setRefTimer] = useState(120);
  const [timer, setTimer] = useState(null);
  const toggleref = useRef(null);

  useEffect(() => {
    // props.ref.current = toggleref.current;

    document.addEventListener("ontabclose", (e) => {
      if (
        toggleref.current.getElement().id === "toggle-btn-" + e.detail.tabid &&
        toggleref.current.getElement().classList.contains("p-highlight")
      ) {
        toggleref.current.getElement().click();
      }
    });
    return () => {
      clearInterval(timer);
      clearInterval(refresh);
    };
  }, []);

  return (
    <>
      <div className="d-flex flex-wrap">
        {!props.shared ? (
          <label title="Refresh">
            <div
              className="btn-sm btn-success me-2 reportIcons"
              onClick={() => {
                props.refreshData("manual");
              }}
            >
              <FontAwesomeIcon
                className="mx-2 tabIcon"
                icon={faArrowRotateRight}
              />
            </div>
          </label>
        ) : null}
        {/* <ToggleButton
          ref={toggleref}
          id={props.id ? "toggle-btn-" + props.id : "toggle-btn-noId"}
          className={`autoRefresh  ${props.className}`}
          checked={checked1}
          onChange={(e) => {
            setChecked1(e.value);
            if (e.value) {
              let refreshInterval = setInterval(() => {
                //   httpService.getExtentReportData(props.s_run_id).then((data) => {
                //     setExtentData(data);
                //   });
                props.refreshData("auto");
              }, 120000);
              let count = 0;
              let timerInterval = setInterval(() => {
                count += 1;
                if (count > 120) {
                  count = 0;
                }
                setRefTimer(120 - count);
              }, 1000);
              setTimer(timerInterval);
              setRefresh(refreshInterval);
            } else {
              clearInterval(refresh);
              clearInterval(timer);
              setRefTimer(120);
            }
          }}
          onLabel={`Refreshing in ${fancyTimeFormat(reftimer, false)}`}
          offLabel="Auto Refresh"
          offIcon="pi pi-refresh"
          // onIcon="pi pi-spin pi-spinner"
          style={{ width: "8em" }}
          aria-label="Confirmation"
        /> */}
      </div>
    </>
  );
};

export default AutoRefresh;
