import AdvantagesCustomizationOfRequirementsIcon from "../../../assets/AdvantagesCustomizationOfRequirementsIcon.svg";
import AdvantagesEffectiveProjectPlanningIcon from "../../../assets/AdvantagesEffectiveProjectPlanningIcon.svg";
import AdvantagesIntegratedTestCaseManagementIcon from "../../../assets/AdvantagesIntegratedTestCaseManagementIcon.svg";
import AdvantagesInterlinkedRequirementsIcon from "../../../assets/AdvantagesInterlinkedRequirementsIcon.svg";
import UserCapabilitiesCreatingDependenciesIcon from "../../../assets/UserCapabilitiesCreatingDependenciesIcon.svg";
import UserCapabilitiesStrategicTaskManagementIcon from "../../../assets/UserCapabilitiesStrategicTaskManagementIcon.svg";
import UserCapabilitiesTestCaseIntegrationIcon from "../../../assets/UserCapabilitiesTestCaseIntegrationIcon.svg";
import UserCapabilitiesCustomizingRequirementsIcon from "../../../assets/UserCapabilitiesCustomizingRequirementsIcon.svg";



export const AdvantageCardData = [
    {
      icon: AdvantagesCustomizationOfRequirementsIcon,
      id: "1",
      heading: "Efficient Requirement Management",
      description: "By utilizing task boards, both functional and non-functional project requirements can be systematically created and assigned to the respective team members. This ensures that business requirements are effectively addressed, leading to smoother project execution and easier adherence to deadlines. ",
    },
    {
      icon: AdvantagesEffectiveProjectPlanningIcon,
      id: "2",
      heading: "Prioritization of requirements",
      description: "Tasks can be organized based on importance, urgency, and deadlines, ensuring that the most critical tasks are addressed first and leading to better project management. ",
    },
    {
      icon: AdvantagesIntegratedTestCaseManagementIcon,
      id: "3",
      heading: "InterLink Related Testcases",
      description: "Taskboard seamlessly supports the attachment of test cases to requirements. This integration allows for comprehensive testing, ensuring that project deliverables consistently meet and exceed quality standards.  ",
    },
    {
      icon: AdvantagesInterlinkedRequirementsIcon,
      id: "4",
      heading: "Agile Management",
      description: "Task boards align well with agile project management methodologies, enabling teams to adapt quickly to changing requirements, fostering collaboration, and promoting iterative development cycles for enhanced project flexibility and efficiency. ",
    }
  ];

  export const userCapabilitiesCardData = [
    {
      icon: UserCapabilitiesCustomizingRequirementsIcon,
      heading: "Folder Hierarchy",
      description: "Organized folders for systematic arrangement of requirements, ensuring easy navigation and logical grouping of related items for efficient management.",
    },
    {
      icon: UserCapabilitiesStrategicTaskManagementIcon,
      heading: "Shareable Links",
      description: "Unique links for each folder and requirement, facilitating seamless sharing and collaboration among team members for enhanced communication.",
    },
    {
      icon: UserCapabilitiesTestCaseIntegrationIcon,
      heading: "Interlinked Requirements",
      description: "Connecting requirements based on roles or tasks, aiding in understanding dependencies and fostering coherence within the project's scope.",
    },
    {
      icon: UserCapabilitiesCreatingDependenciesIcon,
      heading: "Defined Workflow",
      description: "Clear stages for requirement management, guiding tasks from creation to validation, ensuring progress tracking and accountability throughout the process.",
    }
  ];
  