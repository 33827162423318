import { faCheck, faLinkSlash, faPencil, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";
import "./EditTestcase.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react';
import { Dialog } from "primereact/dialog";
import { getErrorMessage, getToastMessage, loadingData } from "../../Helper/Helper";
import httpService from "../../../services/http.service";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";

const EditTestcase = ({ props }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const deleteAction = (id) => {
    setDeleteLoading(true);
    httpService
      .unlinkFromSuite(id, props?.sid)
      .then((data) => {
        getToastMessage(data);
        setDeleteLoading(false);
        props.getSuiteTestcases();
        setDeleteDialog(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setDeleteLoading(false);
          setDeleteDialog(false);
        })
      });
  }


  return (
    <>
      <div className="d-flex">
        <div>
          <FontAwesomeIcon
            className="mx-2 tabIcon actionBtn"
            icon={faPencil}
            onClick={(e) => {
              props?.setAction("edit")
              props?.setEditTcId(props?.val)
            }
            }
          />
        </div>
        <div>
          <FontAwesomeIcon
            className="mx-2 tabIcon actionBtn"
            icon={faLinkSlash}
            title="Unlink from suite"
            onClick={(e) =>
              setDeleteDialog(true)
            }
          />
        </div>
      </div>
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Unlink Testcase from suite"
        visible={deleteDialog}
        onHide={() => {
          setDeleteDialog(false);
          setDeleteLoading(false);
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        {deleteLoading ? (
          <div className="py-5">
            {loadingData("Unlinking " + props?.rowData?.["Testcase Name"]?.value)}
          </div>
        ) : (
          <>
            <div className="text-center mt-3 py-2 mx-3">
              <h5>
                Are you Sure you want to unlink : {props?.rowData?.["Testcase Name"]?.value}
              </h5>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div
                onClick={() => {
                  deleteAction(props?.val)
                }}
                className="btn btn-success"
              >
                <FontAwesomeIcon
                  className="ms-1 tabIcon me-1"
                  icon={faCheck}
                />
                Yes
              </div>
              <div
                onClick={() => {
                  setDeleteDialog(false);
                  setDeleteLoading(false);
                }}
                className="btn btn-danger mx-2"
              >
                <FontAwesomeIcon
                  className="ms-1 tabIcon me-1"
                  icon={faXmark}
                />
                No
              </div>
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};

export default EditTestcase;
