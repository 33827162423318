import React, { useEffect } from "react";
import HeroSection from "../../Layouts/LandingPage/HeroSection/HeroSection";
import StandoutFeatures from "../../Layouts/LandingPage/StandoutFeaturesHeading/StandoutFeatures";
import HighlightsAndBenefits from "../../Layouts/LandingPage/HighlightsAndBenefits/HighlightsAndBenefits";
import YouMayAlsoLike from "../../Layouts/LandingPage/YouMayAlsoLike/YouMayAlsoLike";
import NewFooter from "../../UI/NewFooter/NewFooter";
import Header from "../../UI/Header/Header";
import { logPageTitleForGA } from "../../Helper/Helper";

const LandingPage = () => {
  useEffect(() => {
    let title = "Jewel";
    document.title = title;
    logPageTitleForGA(title);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.hash]);
  return (
    <>
      <Header />
      <HeroSection />
      <StandoutFeatures />
      <HighlightsAndBenefits />
      <YouMayAlsoLike />
      <NewFooter />
    </>
  );
};

export default LandingPage;
