import { useState } from "react";
const SeeMoreText = (props) => {
  const [showMore, setShowMore] = useState(false);
  return props?.text?.length > 0 ? (
    <>
      <div className="seeMoreWrap">
        {showMore ? (
          props.text
        ) : (
          <>
            {props.text.substring(0, props.maxLimit)}
            {props.text.length >= props.maxLimit ? "..." : ""}
          </>
        )}
        {props.text.length >= props.maxLimit ? (
          <span className="seeMoreLink ms-2">
            <span
              onClick={(event) => {
                event.stopPropagation();
                setShowMore(!showMore);
              }}
            >
              {showMore ? "Show Less" : "Show More"}
            </span>
          </span>
        ) : null}
      </div>
    </>
  ) : "-";
};

export default SeeMoreText;
