import { useState, useEffect } from 'react'
import AttachedRequirements from './AttachedRequirements'
import AttachedTestcases from './AttachedTestcases'
import Properties from './Properties'
import './RequirementFile.css';
import VersionHistory from './VersionHistory'
import { logPageTitleForGA } from '../../Helper/Helper';

const RequirementFile = (props) => {

    const [activeLink, setActiveLink] = useState("properties");
    const [noAccessRights, setNoAccessRights] = useState(false);


    useEffect(() => {
        let title = "Jewel | TaskBoard : " + props.req_id;
        document.title = title;
        logPageTitleForGA(title);
    }, [])

    useEffect(() => {
        if (noAccessRights) {
            props.setNoAccess(true);
        }
    }, [noAccessRights])

    return (
        <div className=''>
            <div className="mt-2" id={props.req_id}>
                <ul
                    className="nav nav-pills mx-2 mb-3 requirementList"
                    id={"pills-tab"}
                    role="tablist"
                >
                    <li className="nav-item" role="presentation">
                        <div
                            className={`fontSizeGeneral nav-link requirementPill active`}
                            id={"pills-properties-tab"}
                            data-toggle="pill"
                            data-target={"#pills-properties"}
                            type="button"
                            role="tab"
                            aria-selected="false"
                            onClick={(e) => setActiveLink("properties")}
                        >
                            Properties
                        </div>
                    </li>
                    <li className="nav-item" role="presentation">
                        <div
                            className={`fontSizeGeneral nav-link requirementPill`}
                            id={"pills-attachedReq-tab"}
                            data-toggle="pill"
                            data-target={"#pills-attachedReq"}
                            type="button"
                            role="tab"
                            aria-selected="false"
                            onClick={(e) => setActiveLink("attachedReq")}
                        >
                            Attached Requirements
                        </div>
                    </li>
                    <li className="nav-item" role="presentation">
                        <div
                            className={`fontSizeGeneral nav-link requirementPill`}
                            id={"pills-attachedTC-tab"}
                            data-toggle="pill"
                            data-target={"#pills-attachedTC"}
                            type="button"
                            role="tab"
                            aria-selected="false"
                            onClick={(e) => setActiveLink("attachedTC")}
                        >
                            Attached Testcases
                        </div>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <div
                            className={`fontSizeGeneral nav-link requirementPill`}
                            id={"pills-versionHistory-tab"}
                            data-toggle="pill"
                            data-target={"#pills-versionHistory"}
                            type="button"
                            role="tab"
                            aria-selected="false"
                            onClick={(e) => setActiveLink("versionHistory")}
                        >
                            Version History
                        </div>
                    </li> */}
                </ul>

                <div className="tab-content ms-2 me-4" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id={"pills-properties"}
                        role="tabpanel"
                        aria-labelledby={"pills-properties-tab"}
                    >
                        {activeLink === "properties" ?
                            <Properties id={props.req_id} noAccess={noAccessRights} setNoAccess={setNoAccessRights} />
                            : null}
                    </div>
                    <div
                        className="tab-pane fade"
                        id={"pills-attachedReq"}
                        role="tabpanel"
                        aria-labelledby={"pills-attachedReq-tab"}
                    >
                        <div className="mx-2">
                            {activeLink === "attachedReq" ? <AttachedRequirements id={props.req_id} noAccess={noAccessRights} setNoAccess={setNoAccessRights} /> : null}
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id={"pills-attachedTC"}
                        role="tabpanel"
                        aria-labelledby={"pills-attachedTC-tab"}
                    >
                        <div className="mx-2">
                            {activeLink === "attachedTC" ? <AttachedTestcases id={props.req_id} noAccess={noAccessRights} setNoAccess={setNoAccessRights} /> : null}
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id={"pills-versionHistory"}
                        role="tabpanel"
                        aria-labelledby={"pills-versionHistory-tab"}
                    >
                        <div className="mx-2">
                            {activeLink === "versionHistory" ? <VersionHistory noAccess={noAccessRights} setNoAccess={setNoAccessRights} /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequirementFile