import { createColumns, createFilters } from "../../Helper/Helper";
import { DataTable } from "primereact/datatable";
import FailedSuiteEdit from "./FailedSuiteEdit";
import { useState } from "react";
import { useEffect } from "react";
import NoData from "../../UI/NoData/NoData";

const TCSubSteps = (props) => {
  const [testStepFilters, setTestStepFilters] = useState({});

  useEffect(() => {
    if (Object.keys(testStepFilters)?.length === 0) {
      const filterData = props?.step?.["SUB_STEPS"]?.["headers"]
        ? createFilters(props.step["SUB_STEPS"]["headers"])
        : {};
      setTestStepFilters(filterData);
    }
  }, [props.step]);

  return (
    <>
      <div className="fontSizeGeneral boldText pb-2">Sub Steps: </div>
      <DataTable
        reorderableColumns
        onFilter={(e) => {
          setTestStepFilters(e.filters);
        }} // showGridlines
        alwaysShowPaginator={false}
        resizableColumns
        stripedRows
        columnResizeMode="expand"
        value={props.step["SUB_STEPS"] ? props.step["SUB_STEPS"]["data"] : null}
        paginator
        rows={25}
        dataKey="id"
        filters={testStepFilters}
        filterDisplay="menu"
        responsiveLayout="scroll"
        emptyMessage={<NoData />}
        currentPageReportTemplate="Total {totalRecords} Step(s) Found"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "
        removableSort
        className="test-stepTable"
      >
        {createColumns(
          props.step["SUB_STEPS"]["headers"],
          true,
          true,
          props.step["SUB_STEPS"]["data"],
          true,
          false,
          false,
          { falseVariance: true },
          {
            falseVariance: FailedSuiteEdit,
          },
          {
            falseVariance_props: {
              level: "subStep",
              s_run_id: props.s_run_id,
            },
          }
        )}
      </DataTable>
    </>
  );
};

export default TCSubSteps;
