import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import httpService from "../../../../services/http.service";
import {
  convert24hrto12hrformat,
  getErrorMessage,
  getToastMessage,
} from "../../../Helper/Helper";
import {
  AVAILABLE_SUITE,
  CLEAR_EDIT_JOB_DETAILS,
} from "../../../../actions/type";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
const JobTemplate = ({
  s_id,
  setDataMain,
  setBreadList,
  setJobAction,
  edit,
}) => {
  const dispatch = useDispatch();
  const [jobDetailsLoad, setJobDetailsLoad] = useState(false);
  const [jobName, setJobName] = useState("");
  const [startTime, setStartTime] = useState(
    new Date(new Date("July 1, 1999, 00:00:00"))
  );
  const [endTime, setEndTime] = useState(
    new Date(new Date("July 1, 1999, 23:59:00"))
  );
  const [inputFields, setInputFields] = useState([{ interval: "" }]);
  const [isJobActive, setIsJobActive] = useState(true);
  const availableMode = useRef(["Sequence", "Optimize"]);
  const [mode, setMode] = useState(null);
  const [env, setEnv] = useState(null);
  const [availableEnv, setAvailableEnv] = useState([]);
  const [savebuttonLoading, setSaveButtonLoading] = useState(false);

  const days = [
    { name: "Monday", key: "Monday" },
    { name: "Tuesday", key: "Tuesday" },
    { name: "Wednesday", key: "Wednesday" },
    { name: "Thursday", key: "Thursday" },
    { name: "Friday", key: "Friday" },
    { name: "Saturday", key: "Saturday" },
    { name: "Sunday", key: "Sunday" },
  ];
  const [selectedDays, setSelectedDays] = useState([]);

  const job_id = useSelector((state) => state?.TestTool?.edit_job_details?.val);
  useEffect(() => {
    httpService.getAvailableSuiteTestCases(s_id).then(async (data) => {
      getErrorMessage(data);
      setAvailableEnv(data["data"]["env"]);
    });

    if (edit) {
      setJobDetailsLoad(true);
      httpService.getScheduledJobData(job_id).then((data) => {
        getErrorMessage(data);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          setJobDetailsLoad(false);
          let jobDetails = data.data;
          let run_days = [];
          let inputIntervals = [];
          jobDetails?.run_days?.forEach((data, index) => {
            run_days[index] = { name: data, key: data };
          });
          jobDetails?.interval?.forEach((data, index) => {
            inputIntervals[index] = { interval: data / (60 * 1000) };
          });
          if (jobDetails?.job_name) {
            setJobName(jobDetails?.job_name);
          }
          if (jobDetails?.environment) {
            setEnv(jobDetails.environment);
          }
          setMode(jobDetails?.mode ? jobDetails.mode : "Sequence");

          if (inputIntervals?.length > 0) {
            setInputFields(inputIntervals);
          } else {
            setInputFields([{ interval: "" }]);
          }
          setSelectedDays(run_days);
          if (jobDetails?.run_start_time) {
            setStartTime(
              new Date(
                convert24hrto12hrformat(jobDetails?.run_start_time) + ":05"
              )
            );
          }
          if (jobDetails?.run_end_time) {
            setEndTime(
              new Date(
                convert24hrto12hrformat(jobDetails?.run_end_time) + ":05"
              )
            );
          }

          setIsJobActive(jobDetails?.status === "ACTIVE" ? true : false);
        }
      });
    }

    return () => {
      dispatch({
        type: CLEAR_EDIT_JOB_DETAILS,
      });
    };
  }, []);
  const editJobwithJobId = (e) => {
    e.preventDefault();
  };
  const scheduleJob = async (e) => {
    e.preventDefault();
    let interval = [];
    let runDays = [];
    let count = 0;
    inputFields?.forEach((data, index) => {
      if (data["interval"]) {
        interval[count++] = parseInt(data["interval"] * 60 * 1000, 10);
      }
    });

    selectedDays?.forEach((days, index) => {
      runDays[index] = days["name"];
    });
    if (jobName && runDays && runDays.length > 0 && env) {
      let payload = {
        mode: mode ? mode : "Sequence",
        environment: env,
        s_id: s_id,
        job_name: jobName,
        run_days: runDays,
        job_id: edit ? job_id : null,
        run_start_time: startTime
          ? (startTime.getHours() < 10
            ? "0" + startTime.getHours()
            : startTime.getHours()) +
          ":" +
          (startTime.getMinutes() < 10
            ? "0" + startTime.getMinutes()
            : startTime.getMinutes())
          : "00:00",
        run_end_time: endTime
          ? (endTime.getHours() < 10
            ? "0" + endTime.getHours()
            : endTime.getHours()) +
          ":" +
          (endTime.getMinutes() < 10
            ? "0" + endTime.getMinutes()
            : endTime.getMinutes())
          : null,
        interval: interval?.length > 0 ? interval : null,
        offset: "" + new Date().getTimezoneOffset(),
        status: isJobActive ? "ACTIVE" : "INACTIVE",
      };
      setSaveButtonLoading(true);
      await httpService.createScheduleJobs(payload).then(async (data) => {
        getToastMessage(data);
        if (data?.operation?.toLowerCase() === "success") {
          setBreadList([{ name: "Scheduled Jobs", type: "jobs" }]);
          setJobAction("jobs");
        }
      });
      setSaveButtonLoading(false);
    } else {
      toast.error("Please ensure to fill the mandatory Fields.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "mt-5",
      });
    }
  };
  const CreateMultipleInputs = (inputFields) => {
    const final = inputFields.map((input, index) => {
      return (
        <div>
          <InputText
            style={{ minWidth: "4vw", maxWidth: "4vw" }}
            type="text"
            key={"interval" + index}
            name={"interval"}
            value={input?.interval}
            className="ms-1 px-1 py-1"
            onChange={(e) => {
              handleValueChange(index, e, inputFields);
            }}
          />
        </div>
      );
    });
    return final;
  };
  const handleValueChange = (index, event, inputFields) => {
    let data = [];
    data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };
  const addInputs = () => {
    let newfield = { interval: "" };
    if (inputFields[inputFields?.length - 1]?.interval) {
      setInputFields([...inputFields, newfield]);
    }
  };
  const onDayChange = (e) => {
    let _selectedDays = [...selectedDays];

    if (e.checked) {
      _selectedDays.push(e.value);
    } else {
      for (let i = 0; i < _selectedDays.length; i++) {
        const selectedDays = _selectedDays[i];

        if (selectedDays.key === e.value.key) {
          _selectedDays.splice(i, 1);
          break;
        }
      }
    }
    setSelectedDays(_selectedDays);
  };

  return (
    <>
      <form>
        <div>
          <div className="my-1 pDetails-heading">
            Job Name <sup>*</sup>
          </div>

          <div className="mb-2">
            <InputText
              style={{ minWidth: "30vw", maxWidth: "30vw" }}
              type="text"
              value={jobName}
              className="inputHeight"
              placeholder={"Job Name"}
              onChange={(e) => setJobName(e.target.value)}
            />
          </div>

          <div className="row mb-2">
            <div className="col d-flex">
              <div>
                <div className="my-1 pDetails-heading">Mode</div>

                <div className="">
                  <Dropdown
                    filter
                    className="inputHeight w-100"
                    value={mode ? mode : ""}
                    name="key"
                    options={availableMode.current}
                    onChange={(e) => {
                      setMode(e.target.value);
                    }}
                    placeholder="Select Mode"
                  />
                </div>
              </div>
            </div>

            <div className="col d-flex">
              <div>
                <div className="my-1 pDetails-heading">
                  Environment<sup>*</sup>
                </div>

                <div className="">
                  <Dropdown
                    filter
                    className="inputHeight w-100"
                    value={env ? env : ""}
                    name="key"
                    options={availableEnv}
                    onChange={(e) => {
                      setEnv(e.target.value);
                    }}
                    placeholder="Select Environment"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-1 mt-3 pDetails-heading">
            Run Days<sup>*</sup>
          </div>
          <div className="d-flex mb-2 justify-content-between">
            {days.map((day) => {
              return (
                <div key={day.key} className="field-checkbox">
                  <Checkbox
                    inputId={day.key}
                    name="category"
                    value={day}
                    onChange={onDayChange}
                    checked={selectedDays.some((item) => item.key === day.key)}
                  />
                  <label htmlFor={day.key} className="ms-2">
                    {day.name}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="mb-1 mt-3 pDetails-heading">Start Time</div>

          <div className="d-flex mb-2 justify-content-start">
            <div>
              <Calendar
                id="starttime"
                value={startTime}
                className={"borderBox"}
                onChange={(e) => setStartTime(e.value)}
                timeOnly
                stepMinute={5}
                hourFormat="12"
              />
            </div>
            {/* <div className="ms-2 mt-2">To</div>
            <div className="ms-2 ">
              <Calendar
                id="endtime"
                value={endTime}
                className="borderBox"
                onChange={(e) => setEndTime(e.value)}
                timeOnly
                hourFormat="12"
              />
            </div> */}
          </div>
          {/* <div className="mb-1 pDetails-heading">Run Minute</div>
          <div className="d-flex mb-2 flex-wrap justify-content-start">
            {CreateMultipleInputs(inputFields)}
            <div
              className="btn btn-success themeBtn ms-1"
              onClick={() => {
                addInputs();
              }}
            >
              +
            </div>
          </div> */}
          <div className="mt-3 d-flex pDetails-heading">
            <span> Active</span>
            <InputSwitch
              className="ms-2"
              checked={isJobActive}
              onChange={(e) => setIsJobActive(e.value)}
            />
          </div>
          <div className="w-100 d-flex  justify-content-end">
            <Button
              label="Save"
              type="submit"
              className="ms-auto btn py-1 btn-success themeBtn"
              onClick={(e) => scheduleJob(e)}
              loadingIcon={"pi pi-spin pi-spinner me-2"}
              loading={savebuttonLoading}
              iconPos="right"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default JobTemplate;
