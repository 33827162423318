import { Dialog } from "primereact/dialog";
import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "./FileViewer.css";

const FileViewer = () => {
  const [searchParams] = useSearchParams();
  const [s3Url, sets3Url] = useState("");
  const [err, setErr] = useState("");
  const extension = useRef("");
  const [showErrDialog, setShowErrDialog] = useState(false);
  const [iFrameHeight, setiFrameHeight] = useState("0px");
  const [iFrameWidth, setiFrameWidth] = useState("0px");

  useEffect(() => {
    if (searchParams.get("url")) {
      sets3Url(
        searchParams.get("url") + "&token=" + localStorage.getItem("usertoken")
      );
      setTimeout(() => {
        window.open(
          searchParams.get("url") +
            "&token=" +
            localStorage.getItem("usertoken")
        );
      }, [3000]);
      // httpService
      //   .readFilefromUrl(searchParams.get("url"))
      //   .then(async (data) => {
      //     var reader = new FileReader();
      //     reader.readAsDataURL(await data["data"]);

      //     reader.onload = function (e) {

      //       document
      //         .getElementById("iframe")
      //         .setAttribute("src", e.target.result);
      //     };
      //   });
    }
  }, [searchParams.get("url")]);

  return (
    <>
      <div className="container-fluid">
        <div className="row text-center">
          <div className="mt-4">
            <h1> File Viewer </h1>
            <br></br>
            <h4>
              <strong>
                If File is Browser Compatible it will open in new Window else it
                will download the file.
              </strong>
            </h4>
          </div>
        </div>

        <div className="container-fluid">
          {/* <iframe
              sandbox=""
              id="iframe"
              src={s3Url}
              onLoad={() => {
                const obj = document.getElementById("iframe");
                setiFrameHeight(
                  obj.contentWindow.document.body.scrollHeight + 30 + "px"
                );
                setiFrameWidth(
                  obj.contentWindow.document.body.scrollWidth + "px"
                );
              }}
              height={iFrameHeight}
              width={"100%"}
              // style={{ overflow: "hidden", height: "100%", width: "100%" }}
            ></iframe> */}
        </div>
      </div>

      <Dialog
        blockScroll={true}
        className="error"
        header={`Error`}
        visible={showErrDialog}
        onHide={() => {
          setShowErrDialog(false);
        }}
        draggable={false}
        breakpoints={{ "960px": "75vw" }}
        // onMaximize={(e) => setDialogMax(true)}
        style={{
          width: "60vw",
        }}
      >
        {err
          ? err.toUpperCase()
          : "Something Went Wrong. Please Refresh and try Again."}
      </Dialog>
    </>
  );
};

export default FileViewer;
