import React, { useEffect, useState,useRef } from 'react'
import { ConvertTexttoChart } from '../../../Helper/Helper'
import "./index.css"
import dashboardService from '../../../../services/Dashboard/dashboard.service';
import { handleToastErrorCatch } from '../../../Helper/ToastHelper';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';

const TrendCharts = ({metric,pid,allProject,options,env}) => {
  const [chartData,setChartData]=useState(null);
  const [chartPid,setChartPid]=useState([pid]);
  const [showFilters,setShowFilters]=useState(false)
  const [chartLoading,setChartLoading]=useState(false)
    const filterButtonRef=useRef(null);
    const filterDropdownRef=useRef(null);
    const [selectedProjects, setSelectedProjects] = useState([]);
  

  useEffect(()=>{
    if(chartPid?.length>=1){
      getChartData()
    }
  },[chartPid])

  const handleClickOutside = (event) => {
    if (
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target) &&
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target)
    ) {
      setShowFilters(false);
    }
  };

  useEffect(() => {
    if (showFilters) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showFilters]);


  const getChartData=()=>{
    setChartLoading(true)
    dashboardService
    .getTrendsChart(metric,chartPid,env?.toLowerCase())
    .then((data)=>{
      setChartData(data?.data)
    })
    .catch((err)=>handleToastErrorCatch(err))
    .finally(()=>setChartLoading(false))
  }

  const applyFilter = (type) => {
    const selectedObjects = selectedProjects.map((value) => {
      return allProject.find((project) => project["pid"] === value);
    });
    const newPid=selectedObjects?.map((proj)=>proj?.["pid"])
    setChartPid(newPid)

  };

  useEffect(()=>{
    const newProj=allProject?.filter(proj=>proj?.["pid"]===pid);
    setSelectedProjects([newProj?.[0]?.["pid"]])
  },[])

  return (
    <div>
      <div className='d-flex m-3 justify-content-end'>
      <div className="my-auto filter-container">
              <Button
                label="Apply Filters"
                type="button"
                onClick={() => setShowFilters(true)}
                icon={
                  <FontAwesomeIcon icon={faFilter} className="filterIcon me-1" />
                }
                iconPos="left"
                ref={filterButtonRef}
                className="btn-success btn btn-sm applyFilterBtn px-2 py-2 p-applyFilterBtn d-flex align-items-center filterProject"
              />
              {showFilters && (
                <div
                  className="mt-2 py-3 px-4 row filterDropdowns "
                  ref={filterDropdownRef}
                  onClick={(e) => e.stopPropagation()}
                >
                  <MultiSelect
                     value={selectedProjects}
                     options={options.map((option) => ({
                       ...option,
                       disabled: selectedProjects.length >= 5 && !selectedProjects.includes(option.value),
                     }))}
                     onChange={(e) => {
                       if (e.value.length <= 5) {
                         setSelectedProjects(e.value);
                       }
                     }}
                     showSelectAll={false}
                    placeholder="Select Projects"
                    maxSelectedLabels={1}
                    // display="chip"
                    filter
                    className="w-75 col-12 mx-auto mt-2 mb-3"
                  />
                  <div className="col-12 d-flex justify-content-end gap-2 px-0">
                    <Button
                      label="Apply"
                      type="button"
                      disabled={selectedProjects?.length === 0}
                      onClick={() => applyFilter("apply")}
                      className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                    />
                  </div>
                </div>
              )}
          </div>
      </div>
    {!chartLoading?(
      <div className='trend-chart p-4'>
      {chartData && ConvertTexttoChart(chartData,"line_chart")}
     </div>
    ):(
      <Skeleton height='60vh' width='75vw'/>
    )}
    </div>
  )
}

export default TrendCharts
