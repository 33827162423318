import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react'
import { loadingData } from '../../Helper/Helper';

const DeleteConfirmDialog = ({ deleteDialog, setDeleteDialog, toDeleteId, toDeleteName, deleteFn }) => {

    const [deleteLoad, setDeleteLoad] = useState(false);
    return (
        <Dialog
            blockScroll={true}
            className="createTestcaseDialog"
            header={`Delete`}
            visible={deleteDialog}
            onHide={() => {
                setDeleteDialog(false);
                toDeleteId = null;
                toDeleteName = null;
            }}
            draggable={false}
            breakpoints={{ "960px": "75vw" }}
            // onMaximize={(e) => setDialogMax(true)}
            style={{
                width: "60vw",
            }}
        >
            {!deleteLoad ? (
                <>
                    {" "}
                    <div className="text-center mt-3 py-2 mx-3 deletePrompt">
                        <h5>
                            Are you Sure you want to delete{" "}
                            {toDeleteName}
                        </h5>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <div
                            onClick={() => {
                                deleteFn(setDeleteLoad);
                            }}
                            className="btn btn-success"
                        >
                            <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faCheck} />
                            Yes
                        </div>
                        <div
                            onClick={() => {
                                setDeleteDialog(false);
                            }}
                            className="btn btn-danger mx-2"
                        >
                            <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faXmark} />
                            No
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="py-5">
                        {loadingData("Deleting " + toDeleteName)}
                    </div>
                </>
            )}
        </Dialog>
    )
}

export default DeleteConfirmDialog