import { Skeleton } from "primereact/skeleton";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import httpService from "../../../services/http.service";
import "./ManualTCFile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { over } from "stompjs";
import { useSelector } from "react-redux";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { connectSocket } from "../../Helper/SocketHelper";

const AttachedTestcases = (props) => {
  const ref_id = useRef(props.id);
  const [attachedTC, setAttachedTC] = useState(null);
  const company = useSelector((state) => state.auth.userProfile.socket);
  const firstUpdate = useRef(false);
  const pidArray = useRef(null);
  const projectData = useSelector(
    (state) => state.manualTC.project_data_manualtc
  );
  let stompClient = null;
  const tcConnection = useRef(null);

  const getAttachedTC = () => {
    httpService
      .linkedManualTC(ref_id.current)
      .then((data) => {
        setAttachedTC(data["data"]);
        if (!firstUpdate.current) {
          firstUpdate.current = true;
          connectSocket("execution", company.toString().toUpperCase() + "_MANUAL/private", tcConnection, notifyTcClient);
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          props?.setNoAccess(true);
          tcConnection?.current?.unsubscribe();
          return;
        }
        handleToastErrorCatch(err);
      });
  };
  useEffect(() => {
    getAttachedTC();
    return () => {
      tcConnection?.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (projectData?.length > 0) {
      let pids = projectData.map((data) => data["pid"]);
      pidArray.current = pids;
    }
  }, [projectData]);

  const notifyTcClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (
        pidArray.current?.includes(wsBody["pid"]) &&
        wsBody["message"]?.toLowerCase() === "draft saved"
      ) {
        getAttachedTC();
      }
    }
  };

  const getSkeleton = () => {
    const skelCards = Array.from(Array(9).keys()).map((card) => {
      return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-items-center my-2">
          <Skeleton
            width="23rem"
            height="7.5rem"
            className="card propertiesCardSkel"
          ></Skeleton>
        </div>
      );
    });
    return skelCards;
  };

  const createAttachmentCards = (data) => {
    const cards =
      data?.length > 0 ? (
        data.map((attachmentData) => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-items-center my-2">
              <div className="card propertiesCard pb-4 px-4 pt-3">
                <div className="d-flex">
                  <span className="ms-auto">
                    <FontAwesomeIcon
                      className="deleteReq me-3"
                      icon={faArrowUpRightFromSquare}
                      onClick={(e) => {
                        window.open(
                          window.location.origin +
                          "/#/testpad/MLAB?mtc_id=" +
                          attachmentData["Testcase Id"]["value"],
                          "_blank"
                        );
                      }}
                      title="Show Attachment"
                    />
                  </span>
                </div>
                <div className="my-2">
                  <span className="boldText">Testcase ID : </span>
                  <span className="attach-cardData">
                    {attachmentData["Testcase Id"]["value"]}
                  </span>
                </div>
                <div className="my-2">
                  <span className="boldText">Testcase Name : </span>
                  <span className="attach-cardData">
                    {attachmentData["Testcase Name"]["value"]}
                  </span>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="mt-3 px-5 text-center">
          <div className="card d-flex align-items-center py-3">
            <div className="boldText">
              No Testcases Found ! Click on Attach New Testcases to add.{" "}
            </div>
          </div>
        </div>
      );

    return cards;
  };

  return (
    <div>
      {attachedTC ? (
        <div className="row d-flex mt-2">
          {attachedTC["data"]
            ? createAttachmentCards(attachedTC["data"])
            : null}
        </div>
      ) : (
        <div className="row d-flex">{getSkeleton()}</div>
      )}
    </div>
  );
};

export default AttachedTestcases;
