import axios from "axios";
import { MICRO_API_URL_TESTEXECUTION } from "../../config/API_URLS";
import authHeader from "../auth-header";


class ElabService {
    setSuiteConfig=async(payload)=>{
        return await axios
      .post(MICRO_API_URL_TESTEXECUTION.baseURL + "v2/suite/configuration", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => 
        Promise.reject(err));
    }
}

export default new ElabService();