import "./AllJobs.css";
import { useEffect, useState } from "react";
import {
  createColumns,
  createExpandedRowData,
  createFilters,
  getErrorMessage,
} from "../../../Helper/Helper";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import httpService from "../../../../services/http.service";
import { Skeleton } from "primereact/skeleton";
import ActionScheduler from "./ActionScheduler";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { handleToastErrorCatch } from "../../../Helper/ToastHelper";
import SkeletonUtility from "../../../UI/Skeleton/SkeletonUtility";
import { InputSwitch } from "primereact/inputswitch";
import StatusToggle from "./StatusToggle";
import NoData from "../../../UI/NoData/NoData";

const AllJobs = ({
  setBreadList,
  breadList,
  setJobAction,
  s_id,
  setSuiteData,
  suiteName,
}) => {
  const [mainData, setMainData] = useState(null);
  const [filters, setFilters] = useState({});
  const [expandedRows, setExpandedRows] = useState(null);
  const [loadSkeleton, setLoadSkeleton] = useState(false);
  const [isCreateJobButtonDisabled, setIsCreateJobButtonDisabled] =
    useState(true);
  const [isCreateJobButtonLoading, setIsCreateJobButtonLoading] =
    useState(true);

  useEffect(() => {
    getAllJobs();
    checkTestCaseRunFlags();
  }, []);

  const getAllJobs = () => {
    setLoadSkeleton(true);
    httpService
      .getAllScheduledJobs(s_id)
      .then((data) => {
        setLoadSkeleton(false);
        getErrorMessage(data);

        setMainData(data["data"]);
        const filterData =
          data["data"] && data["data"]["headers"]
            ? createFilters(data["data"]["headers"])
            : {};

        setFilters(filterData);
      })
      .catch((err) => {
        setLoadSkeleton(false);
        handleToastErrorCatch(err, () => setLoadSkeleton(false));
      });
  };

  const checkTestCaseRunFlags = async () => {
    let dataArray;
    await httpService.getAvailableSuiteTestCases(s_id).then((data) => {
      dataArray = data?.data?.data;
    });
    dataArray.forEach((item) => {
      if (item?.["RUN FLAG"]?.value === "Y") {
        setIsCreateJobButtonDisabled(false);
      }
    });
    setIsCreateJobButtonLoading(false);
  };

  return (
    <>
      {isCreateJobButtonLoading ? (
        <Skeleton width={100} height={25} />
      ) : (
        <div className="d-flex align-items-center">
          <Button
            label="Create Job"
            className="btn btn-success py-1 themeBtn "
            disabled={isCreateJobButtonDisabled}
            onClick={() => {
              let list = [...breadList, { name: "Create Job", type: "add" }];
              setBreadList(list);
              setJobAction("add");
            }}
          />
          {isCreateJobButtonDisabled && (
            <FontAwesomeIcon
              icon={faCircleInfo}
              title="Either all manual testcases or No Active testcase with run flag 'Y' found in the suite."
              className="infoicon fa-sm ms-2 "
              data-pr-position="right"
              data-pr-at="right+2 top  "
            />
          )}
        </div>
      )}

      <div className="mt-2">
        {!loadSkeleton ? (
          <DataTable
            reorderableColumns
            header={
              suiteName && s_id ? `${suiteName}(${s_id})` : "Scheduled Jobs"
            }
            onFilter={(e) => {
              setFilters(e.filters);
            }}
            filters={filters}
            collapsedRowIcon="pi pi-arrow-circle-right"
            expandedRowIcon="pi pi-arrow-circle-down"
            removableSort
            scrollHeight={"40vh"}
            rowExpansionTemplate={(mainData) => (
              <>
                {mainData?.job_details?.data &&
                mainData?.job_details?.data?.length > 0 ? (
                  <>
                    <div className="mt-4 text-center overHeadAnalytics">
                      <h4>Job Details</h4>
                    </div>
                    <div className="d-flex justify-content-center ">
                      <div className="w-50">
                        <table className=" shadow-sm mt-3 mb-3 table table-borderless testcaseTable">
                          <tbody>
                            {createExpandedRowData(mainData?.["job_details"])}{" "}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="text-center my-3">
                    <h5>
                      <strong> No Data Found </strong>
                    </h5>
                  </div>
                )}
              </>
            )}
            expandedRows={expandedRows}
            onRowToggle={(e, v) => {
              setExpandedRows(e.data);
            }}
            resizableColumns
            stripedRows
            columnResizeMode="expand"
            value={mainData ? mainData.data : null}
            paginator
            rows={25}
            filterDisplay="menu"
            responsiveLayout="scroll"
            emptyMessage={<NoData />}
            currentPageReportTemplate="Total {totalRecords} Job(s) Found"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
            rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
            className="test-stepTable"
          >
            <Column
              headerClassName={`headercolumnsPrime`}
              className={"columnsPrime"}
              expander={mainData?.data ? true : false}
              style={{ width: "10em" }}
            />

            {mainData?.data &&
              createColumns(
                mainData["headers"],
                true,
                true,
                mainData["data"],
                false,
                "maxWidthTableText",
                null,
                {
                  action_scheduler: true,
                  status_toggle: true,
                },
                {
                  action_scheduler: ActionScheduler,
                  status_toggle: StatusToggle,
                },
                {
                  action_scheduler_props: {
                    getAllJobs: getAllJobs,
                    setSuiteData: setSuiteData,
                    setJobAction: setJobAction,
                    setBreadList: setBreadList,
                    breadList: breadList,
                    isEditButtonDisabled: isCreateJobButtonDisabled,
                  },
                  status_toggle_props: {
                    disabledToggle: isCreateJobButtonDisabled,
                  },
                }
              )}
          </DataTable>
        ) : (
          <SkeletonUtility
            header={true}
            headerValue={"Scheduled Jobs"}
            paginator={false}
          />
        )}
      </div>
    </>
  );
};

export default AllJobs;
