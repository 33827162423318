import { standoutFeaturesCarouselCardsData } from "../../../Helper/MockData/LandingPageMockData";
import StandoutFeaturesCarouselCard from "./StandoutFeaturesCarouselCard/StandoutFeaturesCarouselCard";
import { Carousel } from "primereact/carousel";
import "./StandoutFeatures.css";

const StandoutFeatures = () => {
  const carouselResponsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  return (
    <div className="component-padding max-w-1440">
      <p className="component-heading">Standout Features</p>
      <div className="carousel-wrapper">
        <Carousel
          value={standoutFeaturesCarouselCardsData}
          itemTemplate={StandoutFeaturesCarouselCard}
          numVisible={3}
          responsiveOptions={carouselResponsiveOptions}
        />
      </div>
    </div>
  );
};

export default StandoutFeatures;
