import { getErrorMessage, getInfoMessage } from "../../../Helper/Helper";
import httpService from "../../../../services/http.service";
import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { createColumns, createFilters } from "../../../Helper/Helper";
import AccessRequest from "../AccessRequest";
import { handleToastErrorCatch } from "../../../Helper/ToastHelper";
import "./AdminAccessApproval.css";

const AdminAccessApproval = (props) => {
  const [accessApprovalData, setAccessApprovalData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [disableBulkButtons, setDisableBulkButtons] = useState([]);
  const [isApproveAllButtonLoading, setIsApproveAllButtonLoading] =
    useState(false);
  const [isRejectAllButtonLoading, setIsRejectAllButtonLoading] =
    useState(false);
  const [filters, setFilters] = useState({});

  // Disable buttons if array length is 0, enable otherwise
  useEffect(() => {
    if (selectedUser?.length > 0) {
      setDisableBulkButtons(selectedUser);
    } else {
      setDisableBulkButtons([]);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      const accessFilters = props?.accessApprovalData?.headers
        ? createFilters(props.accessApprovalData.headers)
        : {};
      setFilters(accessFilters);
    }
  }, [props.accessApprovalData]);

  const acceptBulkRequest = (disableBulkButtons) => {
    // Create an array of objects with pid, role, and username keys
    let payload = disableBulkButtons.map((entry) => ({
      pid: entry.Action.value,
      role: entry.Access.value,
      username: entry.Name.value,
    }));
    setIsApproveAllButtonLoading(true);
    httpService
      .acceptRoleRequest(payload)
      .then((data) => {
        setIsApproveAllButtonLoading(false);
        if (data?.["operation"]?.toLowerCase() === "success") {
          props.setAccessApprovalDialog(false);
          getInfoMessage(data);
        } else {
          getErrorMessage(data);
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setIsApproveAllButtonLoading(false));
      });
  };

  const rejectBulkRequest = async (disableBulkButtons) => {
    // Create an array of objects with pid, role, and username keys
    let payload = disableBulkButtons.map((entry) => ({
      pid: entry.Action.value,
      username: entry.Name.value,
    }));

    setIsRejectAllButtonLoading(true);
    httpService
      .declineRoleRequest(payload)
      .then((data) => {
        setIsRejectAllButtonLoading(false);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          props.setAccessApprovalDialog(false);
          getInfoMessage(data);
        } else {
          getErrorMessage(data);
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setIsRejectAllButtonLoading(false));
      });
  };

  const approvalDialogContent = () => {
    return (
      <div className="pt-2 mt-3">
        {props.accessApprovalData &&
        Object.keys(props.accessApprovalData).length > 0 ? (
          <>
            <DataTable
              reorderableColumns
              onFilter={(e) => {
                setFilters(e.filters);
              }}
              selection={selectedUser}
              onSelectionChange={(e) => {
                const filteredObjArray = e.value.map((item) => {
                  return {
                    id: item?.Action?.value,
                    value: item?.Project?.value,
                  };
                });
                setSelectedUser(e.value);
              }}
              dragSelection
              resizableColumns
              stripedRows
              columnResizeMode="expand"
              selectionMode="multiple"
              value={
                props.accessApprovalData
                  ? props.accessApprovalData["data"]
                  : null
              }
              paginator
              rows={25}
              filters={filters}
              rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              scrollHeight={"65vh"}
              filterDisplay="menu"
              responsiveLayout="scroll"
              emptyMessage={
                <center>
                  <strong>No Pending Request(s)</strong>
                </center>
              }
              currentPageReportTemplate="Total {totalRecords} Request(s) Found"
              removableSort
              className="test-stepTable"
            >
              <Column
                selectionMode="multiple"
                headerClassName={`headercolumnsPrime`}
                className={"columnsPrime"}
                headerStyle={{ width: "3em" }}
              ></Column>
              {createColumns(
                props.accessApprovalData["headers"],
                true,
                true,
                props.accessApprovalData["data"],
                true,
                true,
                true,
                {
                  crud_access: true,
                },
                {
                  crud_access: AccessRequest,
                },
                {
                  crud_access_props: {
                    selectedUser: selectedUser,
                    setSelectedUser: setSelectedUser,
                    disableBulkButtons: disableBulkButtons,
                  },
                }
              )}
            </DataTable>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };
  const footer = (
    <div>
      {disableBulkButtons.length > 0 && (
        <Button
          label="Reject All"
          className="mx-1 py-2 mt-2"
          onClick={() => {
            rejectBulkRequest(disableBulkButtons);
          }}
          disabled={isApproveAllButtonLoading}
          loadingIcon={"pi pi-spin pi-spinner me-2"}
          loading={isRejectAllButtonLoading}
          iconPos="right"
          style={{
            backgroundColor: "white",
            color: "red",
            border: "1px solid red",
          }}
        />
      )}
      {disableBulkButtons.length > 0 && (
        <Button
          label="Approve All"
          className="btn-success themeBtn mx-1 py-2 "
          onClick={() => {
            acceptBulkRequest(disableBulkButtons);
          }}
          disabled={isRejectAllButtonLoading}
          loadingIcon={"pi pi-spin pi-spinner me-2"}
          loading={isApproveAllButtonLoading}
          iconPos="right"
        />
      )}
    </div>
  );
  return (
    <Dialog
      className="adminAccessDialog"
      footer={footer}
      blockScroll={true}
      draggable={false}
      header="Access Approvals"
      visible={props.accessApprovalDialog}
      onHide={() => {
        props.setAccessApprovalDialog(false);
        setSelectedUser(null);
      }}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "65vw", height: "70vh" }}
    >
      {approvalDialogContent()}
    </Dialog>
  );
};

export default AdminAccessApproval;
