import { InputText } from "primereact/inputtext"
import { useState } from 'react';
import './index.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faShareNodes, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { MultiSelect } from "primereact/multiselect";
import userService from "../../../../services/UserService/user.service";
import reportService from "../../../../services/ShareReport/report.service";
import { useSelector } from "react-redux";
import { handleToastErrorCatch, statementError } from "../../../Helper/ToastHelper";
import { Dialog } from "primereact/dialog";
import { headerTemplate } from "./helper";
import { getToastMessage } from "../../../Helper/Helper";
import { useRef } from 'react';
import { Button } from "primereact/button";
import { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";


const ShareReportDialog = ({setIsShareLoading,type,reportType, reportPayload, reportId, className, currentCardAction }) => {
    const [reportName, setReportName] = useState("");
    const [viewAccessUsers, setViewAccessUsers] = useState([]);
    const [editAccessUsers, setEditAccessUsers] = useState([]);
    const [viewAccessOptions, setViewAccessOptions] = useState([]);
    const [editAccessOptions, setEditAccessOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const company = useSelector((state) => state.auth.userProfile.socket);
    const [showShareReport, setShowShareReport] = useState(false);
    const currentAction = useRef(null);
    const [searchParams] = useSearchParams();


    const getAccessUsers = async () => {
       
        
            currentCardAction.current = reportId;
        
      
        currentAction.current = "share";
        setLoading(true);
        setIsShareLoading(true);

        try {
            if (type === "edit" || searchParams?.get("sharedReportId")) {
                let id = type === "edit" ? reportId : searchParams.get("sharedReportId");
                const data = await reportService.getShareReportData(id);
                let reportData = data?.data;
                if (reportData?.["reportName"]) {
                    setReportName(reportData?.["reportName"]);
                }
                if (reportData?.["readAccessUsers"] && reportData?.["readAccessUsers"]?.length > 0) {
                    setViewAccessUsers(reportData.readAccessUsers);
                }
                if (reportData?.["writeAccessUsers"] && reportData?.["writeAccessUsers"]?.length > 0) {
                    setEditAccessUsers(reportData.writeAccessUsers);
                }
            }

            const editData = await userService.getUsersForGivenProjectAndCompanyName(reportPayload?.["projectid"], company, "write");
            setEditAccessOptions(editData.data.data);

            const viewData = await userService.getUsersForGivenProjectAndCompanyName(reportPayload?.["projectid"], company, null);
            setViewAccessOptions(viewData.data.data);

            setShowShareReport(true);

        } catch (err) {
            handleToastErrorCatch(err, () => {
                setLoading(false);
                
            });
        } finally {
            setLoading(false);
            setIsShareLoading(false);
        }
    };


    const validatedData = (action) => {
        if (action === 'save') {
            if (reportName.length === 0) {
                statementError('Report name is required!')
                return false;
            }
        }
        else {
            if (reportName.length === 0 || (editAccessUsers.length === 0 && viewAccessUsers.length === 0)) {
                statementError('Please fill all mandatory fields!');
                return false;
            }
        }

        return true;
    }

    const onSuccessUpdate = (data) => {
        setShowShareReport(false);
        getToastMessage(data);
        setSaveLoading(false);
        setReportName("");
        setEditAccessUsers([]);
        setViewAccessUsers([]);
      
            currentCardAction.current = null;
        
    }

    const shareReport = () => {
        
        if (!validatedData('share')) {
            return;
        }
        let payload = {};
        let newReportPayload = { ...reportPayload, reportName: reportName }
        if (type === "edit" && reportType!=="SAVED") {
            payload["reportId"] = reportId;
        }
        else if (searchParams?.get("sharedReportId")) {
            payload["reportId"] = parseInt(searchParams.get("sharedReportId"))
        }
        payload["reportName"] = reportName;
        payload["readAccessUsers"] = viewAccessUsers;
        payload["writeAccessUsers"] = editAccessUsers;
        payload["reportPayload"] = newReportPayload;

        setSaveLoading(true);

        if ((type === "edit" || searchParams?.get("sharedReportId")) && reportType!=="SAVED") {
            reportService
                .updateReport(payload)
                .then((data) => {
                    onSuccessUpdate(data);
                })
                .catch((err) => handleToastErrorCatch(err, () => setSaveLoading(false)))
        }
        else {
            reportService
                .shareReport(payload)
                .then((data) => {
                    onSuccessUpdate(data);
                })
                .catch((err) => handleToastErrorCatch(err, () => setSaveLoading(false)))
        }
    }

    const saveReport = () => {
        if (!validatedData('save')) {
            return;
        }
        let payload = {};
        let newReportPayload = { ...reportPayload, reportName: reportName }
        payload["reportName"] = reportName;
        payload["reportPayload"] = newReportPayload;
        setSaveLoading(true);

        reportService
            .saveReport(payload)
            .then((data) => {
                getToastMessage(data);
                setSaveLoading(false);
                setShowShareReport(false);
                setReportName("")
            })
            .catch((err) => handleToastErrorCatch(err, () => setSaveLoading(false)))
    }

    const userOptionTemplate = (option) => {
        return (
            <>
                <div>
                    <span>{`${option.Name} ( ${option.Username} )`}</span>
                </div>
            </>
        );
    };

    const shareReportDialogBody = () => {
        return (
            <div className='p-4'>
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                    Report Name<sup> *</sup>
                </div>
                <InputText
                    type={"text"}
                    value={reportName}
                    onChange={(e) => setReportName(e.target.value)}
                    className="w-100 shareReportInput"
                    placeholder={"Report Name"}
                // disabled={type === "edit"}
                />
                {currentAction.current === "share" &&
                    <div>
                        <div className="mt-2">
                            <div className="ms-1 mb-1 mt-3 pDetails-heading">
                                Viewer Access<sup> *</sup>
                            </div>
                            <MultiSelect
                                value={viewAccessUsers}
                                options={viewAccessOptions}
                                className="w-100"
                                filter
                                filterBy="Name"
                                itemTemplate={userOptionTemplate}
                                onChange={(e) => setViewAccessUsers(e.value)}
                                optionLabel="Name"
                                optionValue="Username"
                                placeholder="Viewer Access"
                                display="chip" />
                        </div>
                        <div className="mt-2">
                            <div className="ms-1 mb-1 mt-3 pDetails-heading">
                                Edit Access<sup> *</sup>
                            </div>
                            <MultiSelect
                                value={editAccessUsers}
                                options={editAccessOptions}
                                filter
                                filterBy="Name"
                                className="w-100"
                                itemTemplate={userOptionTemplate}
                                onChange={(e) => setEditAccessUsers(e.value)}
                                optionLabel="Name"
                                optionValue="Username"
                                placeholder="Edit Access"
                                display="chip" />
                        </div>
                    </div>}
                <div className="mt-5 d-flex justify-content-center">
                    {currentAction.current === "share" ?
                        <Button
                            label="Share"
                            // icon={faShareNodes}
                            onClick={(e) => {
                                shareReport();
                            }}
                            className='btn-success btn mx-2 py-2 px-3 mt-1 themeBtnOutline'
                            loadingIcon={"pi pi-spin pi-spinner me-2"}
                            loading={saveLoading}
                            iconPos="left"
                        />
                        :
                        <Button
                            label="Save"
                            // icon={faFloppyDisk}
                            onClick={(e) => {
                                saveReport();
                            }}
                            className='btn-success btn mx-2 py-2 px-3 mt-1 themeBtnOutline'
                            loadingIcon={"pi pi-spin pi-spinner me-2"}
                            loading={saveLoading}
                            iconPos="left"
                        />
                    }
                </div>
            </div>
        )
    };

    return (
        <>
            <label title="Share Report">
                <div
                    className={`btn-sm btn-success ${className ? className : 'reportIcons'} ${type !== "edit" ? 'me-2' : ''}`}
                    onClick={() => !loading && getAccessUsers()}
                >
                    {loading ?
                        <FontAwesomeIcon icon={faSpinner} className="fa fa-spin mx-2" />
                        : <FontAwesomeIcon
                            className="mx-2 tabIcon"
                            icon={faShareNodes}
                        />}
                </div>
            </label>

            {type !== "edit" && <label title="Save Report">
                <div
                    className={`btn-sm btn-success reportIcons me-2`}
                    onClick={() => {
                        currentAction.current = "save";
                        setShowShareReport(true)
                    }}
                >
                    <FontAwesomeIcon
                        className="mx-2 tabIcon"
                        icon={faFloppyDisk}
                    />
                </div>
            </label>}

            <Dialog
                blockScroll={true}
                draggable={false}
                header={headerTemplate}
                visible={showShareReport}
                onHide={() => {
                    setShowShareReport(false);
                    setReportName("");
                    setEditAccessUsers([]);
                    setViewAccessUsers([]);
                }}
                breakpoints={{ "960px": "60vw" }}
                style={{ width: "60vw" }}
            >
                {shareReportDialogBody()}
            </Dialog>
        </>

    )
}

export default ShareReportDialog