import "./RequirementFile.css";
import {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { toast } from "react-toastify";
import httpService from "../../../services/http.service";
import {
  encodeParam,
  getToastMessage,
  toCamelCase,
  toCapitalizeFirstChar,
} from "../../Helper/Helper";
import { Button } from "primereact/button";
import { useSearchParams } from "react-router-dom";

const ReqForm = forwardRef((props, ref) => {
  useImperativeHandle(
    ref,
    () => {
      return {
        clearFormData,
      };
    },
    []
  );
  const [reqName, setReqName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("New");
  const [selectedType, setSelectedType] = useState("Functional");
  const [selectedSeverity, setSelectedSeverity] = useState("Low");
  const [selectedPriority, setSelectedPriority] = useState("Low");
  const [description, setDescription] = useState("");
  const [selectedAssignedToUser, setSelectedAssignedToUser] = useState(null);
  const [targetBuildDate, setTargetBuildDate] = useState(null);
  const [targetQaDate, setTargetQaDate] = useState(null);
  const [targetDevDate, setTargetDevDate] = useState(null);
  const [targetProdDate, setTargetProdDate] = useState(null);
  const [targetUATDate, setTargetUATDate] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [variables, setVariables] = useState({});
  const manualTestcaseId = useRef([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [saveRequirementLoadingButton, setSaveRequirementLoadingButton] =
    useState(false);
  const [isSaveRequirementButtonDisabled, setIsSaveRequirementButtonDisabled] =
    useState(true);
  useEffect(() => {
    const data =
      props.type === "edit" && props.editData
        ? (setReqName(props.editData["name"]),
          setSelectedStatus(
            toCapitalizeFirstChar(
              props.editData["status"].toLowerCase().split("_").join(" ")
            )
          ),
          setProjectName(props.editData["projectName"]),
          setSelectedType(
            toCapitalizeFirstChar(
              props.editData["requirementType"]
                .toLowerCase()
                .split("_")
                .join(" ")
            )
          ),
          setSelectedPriority(
            toCapitalizeFirstChar(
              props.editData["priority"].toLowerCase().split("_").join(" ")
            )
          ),
          setSelectedSeverity(
            toCapitalizeFirstChar(
              props.editData["severity"].toLowerCase().split("_").join(" ")
            )
          ),
          setDescription(props.editData["description"]),
          setSelectedAssignedToUser(props.editData["assignedTo"]),
          setTargetBuildDate(
            props.editData["targetBuildDate"]
              ? new Date(props.editData["targetBuildDate"])
              : null
          ),
          setTargetDevDate(
            props.editData["targetDevDate"]
              ? new Date(props.editData["targetDevDate"])
              : null
          ),
          setTargetProdDate(
            props.editData["targetProdDate"]
              ? new Date(props.editData["targetProdDate"])
              : null
          ),
          setTargetQaDate(
            props.editData["targetQADate"]
              ? new Date(props.editData["targetQADate"])
              : null
          ),
          setTargetUATDate(
            props.editData["targetUATDate"]
              ? new Date(props.editData["targetUATDate"])
              : null
          ),
          (manualTestcaseId.current = props.editData["manualTestCaseId"]
            ? props.editData["manualTestCaseId"]
            : []))
        : props.f_id
        ? (setProjectName(props?.p_name), props.getUsers(props.p_id))
        : null;
  }, []);

  // To check if the user has edited anything in the form. Disable "save requirement" button if there is no change
  useEffect(() => {
    if (props.type === "edit" && props.editData) {
      if (
        props.editData?.name === reqName &&
        toCapitalizeFirstChar(
          props.editData.status?.toLowerCase().split("_").join(" ")
        ) === selectedStatus &&
        props.editData.projectName === projectName &&
        toCapitalizeFirstChar(
          props.editData.requirementType?.toLowerCase().split("_").join(" ")
        ) === selectedType &&
        toCapitalizeFirstChar(
          props.editData.severity?.toLowerCase().split("_").join(" ")
        ) === selectedSeverity &&
        props.editData.description === description &&
        toCapitalizeFirstChar(
          props.editData.priority?.toLowerCase().split("_").join(" ")
        ) === selectedPriority &&
        props.editData.assignedTo === selectedAssignedToUser &&
        // DO NOT CHANGE "==" TO "===". It is comapring null == undefined
        (props.editData.targetDevDate
          ? new Date(props.editData.targetDevDate).getTime()
          : null) == targetDevDate?.getTime() &&
        (props.editData.targetQADate
          ? new Date(props.editData.targetQADate).getTime()
          : null) == targetQaDate?.getTime() &&
        (props.editData.targetBuildDate
          ? new Date(props.editData.targetBuildDate).getTime()
          : null) == targetBuildDate?.getTime() &&
        (props.editData.targetProdDate
          ? new Date(props.editData.targetProdDate).getTime()
          : null) == targetProdDate?.getTime() &&
        (props.editData.targetUATDate
          ? new Date(props.editData.targetUATDate).getTime()
          : null) == targetUATDate?.getTime()
      ) {
        setIsSaveRequirementButtonDisabled(true);
      } else {
        setIsSaveRequirementButtonDisabled(false);
      }
    }
  }, [
    reqName,
    selectedStatus,
    projectName,
    selectedType,
    selectedSeverity,
    description,
    selectedPriority,
    selectedAssignedToUser,
    targetDevDate,
    targetQaDate,
    targetBuildDate,
    targetProdDate,
    targetUATDate,
  ]);
  const userOptionTemplate = (option) => {
    return (
      <>
        <div>
          <span>{`${option.Name} ( ${option.Username} )`}</span>
        </div>
      </>
    );
  };

  function updateBreadcrumbsAndSearchParams(newName, p_id, req_id) {
    const tempNewBreadList = props?.breadList?.map((item) => {
      if (item?.p_id == p_id && item?.req_id == req_id) {
        return { ...item, name: newName };
      }
      return item;
    });
    // update searchParams
    if (
      tempNewBreadList.length > 0 &&
      req_id == searchParams.get("task_id") &&
      p_id == searchParams.get("p_id") &&
      searchParams.get("list")
    ) {
      const newListParam = encodeParam(JSON.stringify(tempNewBreadList));
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("list", newListParam);
      setSearchParams(newSearchParams);
    }
    // breadlist will update automatically in parent's useEffect (searchParams is the dependency)
  }

  const createReq = async (e) => {
    e.preventDefault();
    if (
      reqName.length > 0 &&
      (selectedProject || projectName.length > 0) &&
      selectedStatus &&
      selectedType &&
      selectedPriority &&
      selectedSeverity &&
      description.length > 0
    ) {
      setSaveRequirementLoadingButton(true);

      let payload = {
        folderId: props.f_id,
        name: reqName,
        pid:
          props.type === "create"
            ? props.f_id
              ? props.p_id
              : selectedProject
            : props.editData["pid"],
        status: selectedStatus?.toUpperCase().split(" ").join("_"),
        requirementType: selectedType?.toUpperCase().split(" ").join("_"),
        description: description,
        priority: selectedPriority?.toUpperCase(),
        severity: selectedSeverity?.toUpperCase(),
        assignedTo: selectedAssignedToUser,
        variables: variables,
        targetBuildDate: targetBuildDate
          ? new Date(targetBuildDate).getTime()
          : null,
        targetDevDate: targetDevDate ? new Date(targetDevDate).getTime() : null,
        targetQADate: targetQaDate ? new Date(targetQaDate).getTime() : null,
        targetUATDate: targetUATDate ? new Date(targetUATDate).getTime() : null,
        targetProdDate: targetProdDate
          ? new Date(targetProdDate).getTime()
          : null,
      };

      if (props.type === "create") {
        await httpService
          .createNewRequirement(payload)
          .then(async (data) => {
            getToastMessage(data);
            // props.getSuiteRequirements();
            if (
              data["operation"] &&
              data["operation"].toLowerCase() === "success"
            ) {
              clearFormData();
              props.setNewReqDialog(false);
            }
          })
          .catch((err) => {
            toast.error(
              err["response"]
                ? err["response"]["data"]["message"]
                : `Something Went Wrong. 
        Please refresh the Page / Try Again`,
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: "mt-5",
              }
            );
            clearFormData();
          });
        setSaveRequirementLoadingButton(false);
      } else {
        payload["isolatedVersionId"] = props.req_id;
        payload["manualTestCaseId"] = manualTestcaseId.current;
        await httpService
          .editRequirement(payload)
          .then(async (data) => {
            getToastMessage(data);
            const updateRequirement = new CustomEvent("onRequirementEdit", {
              cancelable: true,
            });
            document.dispatchEvent(updateRequirement);
            if (
              data["operation"] &&
              data["operation"].toLowerCase() === "success"
            ) {
              if (props?.breadList.length > 0) {
                updateBreadcrumbsAndSearchParams(
                  data?.data?.name,
                  data?.data?.pid,
                  data?.data?.isolatedVersionId
                );
              }
              props.setNewReqDialog(false);
            }
          })
          .catch((err) => {
            toast.error(
              err["response"]
                ? err["response"]["data"]["message"]
                : `Something Went Wrong. 
            Please refresh the Page / Try Again`,
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: "mt-5",
              }
            );
          });
        setSaveRequirementLoadingButton(false);
      }
    } else {
      toast.error("Fill out mandatory fields !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "mt-5",
      });
    }
  };

  const clearFormData = (e) => {
    setReqName("");
    setSelectedStatus("New");
    setSelectedProject(null);
    setSelectedType("Functional");
    setSelectedPriority("Low");
    setSelectedSeverity("Low");
    setDescription("");
    setSelectedAssignedToUser(null);
    setTargetBuildDate(null);
    setTargetDevDate(null);
    setTargetProdDate(null);
    setTargetQaDate(null);
    setTargetUATDate(null);
    props.setEditData(null);
  };
  const createForm = (type) => {
    return (
      <div className="p-3">
        <form>
          <div>
            <div className="row mb-3 mt-1">
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Requirement Name<sup> *</sup>
                </div>
                <div className="reqFormInputs">
                  <InputText
                    type={"text"}
                    value={reqName}
                    onChange={(e) => setReqName(e.target.value)}
                    className="w-100"
                    placeholder={"Requirement Name"}
                    disabled={saveRequirementLoadingButton}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Status<sup> *</sup>
                </div>
                <div className="reqFormInputs">
                  <Dropdown
                    className="dialog-dropdown w-100"
                    // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                    options={[
                      "New",
                      "Under Review",
                      "Approved",
                      "Decommissioned",
                      "In Prod",
                    ]}
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.value)}
                    placeholder="Select Status"
                    maxSelectedLabels={1}
                    disabled={saveRequirementLoadingButton}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Project Name<sup> *</sup>
                </div>
                <div className="reqFormInputs">
                  {!props.req_id ? (
                    props.f_id ? (
                      <InputText
                        type={"text"}
                        value={projectName}
                        disabled
                        className="w-100"
                      />
                    ) : (
                      <Dropdown
                        className="dialog-dropdown px-3 w-100"
                        options={props.projectsWithAccess}
                        optionValue="P ID"
                        optionLabel="Project Name"
                        value={selectedProject}
                        onChange={(e) => {
                          setSelectedProject(e.value);
                          props.getUsers(e.value);
                          setSelectedAssignedToUser(null);
                        }}
                        filter
                        placeholder="Select Project"
                        maxSelectedLabels={1}
                        disabled={saveRequirementLoadingButton}
                      />
                    )
                  ) : (
                    <InputText
                      type={"text"}
                      value={projectName}
                      disabled
                      className="w-100"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-8 col-12">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="ms-1 mb-1 mt-1 pDetails-heading">
                      Type<sup> *</sup>
                    </div>
                    <div className="reqFormInputs">
                      <Dropdown
                        className="dialog-dropdown w-100"
                        // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                        options={["Functional", "Non Functional"]}
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.value)}
                        placeholder="Select Type"
                        maxSelectedLabels={1}
                        disabled={saveRequirementLoadingButton}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="ms-1 mb-1 mt-1 pDetails-heading">
                      Severity<sup> *</sup>
                    </div>
                    <div className="reqFormInputs">
                      <Dropdown
                        className="dialog-dropdown w-100"
                        // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                        options={["Critical", "High", "Medium", "Low"]}
                        value={selectedSeverity}
                        onChange={(e) => setSelectedSeverity(e.value)}
                        placeholder="Select Severity"
                        maxSelectedLabels={1}
                        disabled={saveRequirementLoadingButton}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 col-12">
                    <div className="ms-1 mb-1 mt-1 pDetails-heading">
                      Priority<sup> *</sup>
                    </div>
                    <div className="reqFormInputs">
                      <Dropdown
                        className="dialog-dropdown w-100"
                        // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                        options={["Critical", "High", "Medium", "Low"]}
                        value={selectedPriority}
                        onChange={(e) => setSelectedPriority(e.value)}
                        placeholder="Select Priority"
                        maxSelectedLabels={1}
                        disabled={saveRequirementLoadingButton}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="ms-1 mb-1 mt-1 pDetails-heading">
                      Assigned To
                    </div>
                    <div className="reqFormInputs">
                      {props?.users?.length > 0 ? (
                        <Dropdown
                          value={selectedAssignedToUser}
                          onChange={(e) => setSelectedAssignedToUser(e.value)}
                          options={props.users}
                          optionLabel="Name"
                          optionValue="Username"
                          virtualScrollerOptions={{ itemSize: 38 }}
                          itemTemplate={userOptionTemplate}
                          placeholder="Select User"
                          className="dialog-dropdown w-100"
                          disabled={saveRequirementLoadingButton}
                        />
                      ) : (
                        <Dropdown
                          className="dialog-dropdown w-100"
                          options={[]}
                          emptyMessage="No User(s) found"
                          placeholder="Select User"
                          disabled={saveRequirementLoadingButton}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Description<sup> *</sup>
                </div>
                <div className="">
                  <InputTextarea
                    value={description}
                    id="desc"
                    className="w-100 reqFormInputs"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    rows={5}
                    autoResize
                    disabled={saveRequirementLoadingButton}
                  />
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Target Dev Date
                </div>
                <div className="reqFormInputs">
                  <Calendar
                    showButtonBar
                    minDate={new Date()}
                    value={targetDevDate}
                    onChange={(e) => setTargetDevDate(e.value)}
                    showIcon
                    dateFormat="dd/mm/yy"
                    className="w-100"
                    placeholder="Select Date"
                    disabled={saveRequirementLoadingButton}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Target QA Date
                </div>
                <div className="reqFormInputs">
                  <Calendar
                    minDate={new Date()}
                    showButtonBar
                    value={targetQaDate}
                    onChange={(e) => setTargetQaDate(e.value)}
                    showIcon
                    dateFormat="dd/mm/yy"
                    className="w-100"
                    placeholder="Select Date"
                    disabled={saveRequirementLoadingButton}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Target Build Date
                </div>
                <div className="reqFormInputs">
                  <Calendar
                    showButtonBar
                    minDate={new Date()}
                    value={targetBuildDate}
                    onChange={(e) => setTargetBuildDate(e.value)}
                    showIcon
                    dateFormat="dd/mm/yy"
                    className="w-100"
                    placeholder="Select Date"
                    disabled={saveRequirementLoadingButton}
                  />
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Target Prod Date
                </div>
                <div className="reqFormInputs">
                  <Calendar
                    showButtonBar
                    minDate={new Date()}
                    value={targetProdDate}
                    onChange={(e) => setTargetProdDate(e.value)}
                    showIcon
                    dateFormat="dd/mm/yy"
                    className="w-100"
                    placeholder="Select Date"
                    disabled={saveRequirementLoadingButton}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Target UAT Date
                </div>
                <div className="reqFormInputs">
                  <Calendar
                    showButtonBar
                    minDate={new Date()}
                    value={targetUATDate}
                    onChange={(e) => setTargetUATDate(e.value)}
                    showIcon
                    dateFormat="dd/mm/yy"
                    className="w-100"
                    placeholder="Select Date"
                    disabled={saveRequirementLoadingButton}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              {props.type === "edit" ? (
                <Button
                  label="Save Requirement"
                  type="submit"
                  disabled={isSaveRequirementButtonDisabled}
                  className="btn-success btn themeBtn mx-2 p-2 mt-2"
                  onClick={(e) => createReq(e)}
                  loadingIcon={"pi pi-spin pi-spinner me-2"}
                  loading={saveRequirementLoadingButton}
                  iconPos="right"
                />
              ) : (
                <Button
                  label=" Create Requirement"
                  type="submit"
                  className="btn-success btn themeBtn mx-2 p-2 mt-2"
                  onClick={(e) => createReq(e)}
                  loadingIcon={"pi pi-spin pi-spinner me-2"}
                  loading={saveRequirementLoadingButton}
                  iconPos="right"
                />
              )}
            </div>
          </div>
        </form>
      </div>
    );
  };

  return <div className="card reqFormCard">{createForm(props.type)}</div>;
});

export default ReqForm;
