import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef } from "react";
const UploadScreenshot = ({ props }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const uploadRef = useRef(null);
  const onUpload = async (event) => {
    event.preventDefault();
    setUploadedFile(event.target.files[0].name);
  };
  return (
    <>
      <div className="d-flex justify-content-center flex-wrap">
        {!uploadedFile ? (
          <>
            {" "}
            <label className="custom-file-upload themeBtn">
              <input
                ref={uploadRef}
                id={`uploadImage${
                  props?.rowData?.["Id"]?.value + props?.allManuals?.tc_id
                }`}
                className="upload"
                type="file"
                onChange={(e) => {
                  onUpload(e);
                }}
              />
            </label>
            <div
              onClick={() => {
                uploadRef?.current?.click();
              }}
              className="btn btn-primary themeBtn mx-1"
            >
              Upload
            </div>
          </>
        ) : (
          <>
            <div className="d-flex flex-wrap justify-content-between">
              <div> {uploadedFile} </div>
              <div>
                {" "}
                <FontAwesomeIcon
                  className="ms-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => setUploadedFile(null)}
                  icon={faXmark}
                />{" "}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UploadScreenshot;
