import React, { useState, useRef, useEffect } from "react";
import "./Paginator.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "primereact/dropdown";

const Paginator = ({
  totalElements,
  rowsPerPage,
  skeleton,
  currentPage,
  pageStartValue,
  setPageStartValue,
  pageEndValue,
  setPageEndValue,
  sortColumn,
  sortValue,
  onPagination
}) => {

  //calculates the array of total page numbers 
  const calculatePageValues = () => {
    if (skeleton) {
      return [];
    }
    const totalPages = Math.ceil(totalElements / rowsPerPage.current);
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }

  const calculateCurrentPageValue = (showFive) => {
    if (!skeleton) {
      return showFive ? pageValues?.slice(0, 5) : pageValues?.slice(pageStartValue, pageEndValue)
    }
    return Array.from(
      { length: pageEndValue - pageStartValue },
      (_, index) => pageStartValue + index + 1
    )
  }

  const [pageValues, setPageValues] = useState(calculatePageValues());
  const [activePage, setActivePage] = useState(currentPage.current);
  const rowsPerPageOptions = useRef([5, 10, 25, 50, 80, 100]);
  const currentPageValue = useRef(calculateCurrentPageValue());

  useEffect(() => {
    setPageValues(
      skeleton
        ? []
        : Array.from({ length: Math.ceil(totalElements / rowsPerPage.current) }, (_, index) => index + 1)
    );
    setActivePage(currentPage.current);
    if (currentPage.current > 1) {
      currentPageValue.current = calculateCurrentPageValue()
    } else {
      currentPageValue.current = calculateCurrentPageValue(true)
    }
  }, [totalElements]);


  //on click of page index
  const getPageData = (e, value) => {
    e?.preventDefault();
    if (!skeleton) {
      currentPage.current = value;
      setActivePage(value);
      onPagination(sortColumn, sortValue, false);
      if (
        value > pageValues[2] &&
        value < pageValues[pageValues?.length - 2] &&
        pageValues?.length > 5
      ) {
        setPageStartValue(value - 3);
        setPageEndValue(value + 2);
        return;
      }

      let setVal =
        pageValues?.length < 5
          ? (setPageStartValue(0),
            setPageEndValue(pageValues?.length))
          : null;
    }
  };

  const goToPrevPage = (e) => {
    e?.preventDefault();
    if (activePage > 1 && !skeleton) {
      currentPage.current = activePage - 1;
      setActivePage(activePage - 1);
      onPagination(sortColumn, sortValue, false);
      if (
        activePage - 1 > pageValues[2] &&
        activePage - 1 < pageValues[pageValues.length - 2] &&
        pageValues.length > 5
      ) {
        setPageStartValue(activePage - 4);
        setPageEndValue(activePage + 1);
        return;
      }

      let setVal =
        pageValues?.length > 5
          ? (setPageStartValue(
            activePage - 1 < pageValues[3] ? 0 : pageStartValue
          ),
            setPageEndValue(
              activePage - 1 < pageValues[3] ? 5 : pageEndValue
            ))
          : (setPageStartValue(0),
            setPageEndValue(pageValues?.length));
    }
  };

  const goToFirstPage = (e) => {
    e?.preventDefault();
    if (!(activePage === pageValues[0] || skeleton)) {
      currentPage.current = 1;
      setActivePage(1);
      onPagination(sortColumn, sortValue, false);
      if (pageValues?.length > 5) {
        setPageStartValue(0);
        setPageEndValue(5);
        return;
      }

      setPageStartValue(0);
      setPageEndValue(pageValues?.length);
    }
  };

  const goToLastPage = (e) => {
    e?.preventDefault();
    if (!(activePage === pageValues[pageValues.length - 1] || skeleton)) {
      currentPage.current = pageValues.length;
      setActivePage(pageValues.length);
      onPagination(sortColumn, sortValue, false);
      if (pageValues.length > 5) {
        setPageStartValue(pageValues.length - 5);
        setPageEndValue(pageValues.length);
        return;
      }

      setPageStartValue(0);
      setPageEndValue(pageValues.length);
    }
  };

  const goToNextPage = (e) => {
    e?.preventDefault();
    if (activePage < pageValues.length && !skeleton) {
      currentPage.current = activePage + 1;
      setActivePage(activePage + 1);
      onPagination(sortColumn, sortValue, false);

      if (
        activePage + 1 > pageValues[2] &&
        activePage + 1 < pageValues[pageValues.length - 2] &&
        pageValues.length > 5
      ) {
        setPageStartValue(activePage - 2);
        setPageEndValue(activePage + 3);
        return;
      }

      let setVal =
        pageValues.length < 5
          ? (setPageStartValue(0),
            setPageEndValue(pageValues.length))
          : null;
    }
  };

  const rowsPerPageChanged = (e) => {
    e?.preventDefault();
    if (
      e.value > rowsPerPage.current &&
      currentPage.current > Math.ceil(totalElements / e.value)
    ) {
      currentPage.current = Math.ceil(totalElements / e.value);
      setActivePage(Math.ceil(totalElements / e.value));
      setPageEndValue(Math.ceil(totalElements / e.value) + 1);
      setPageStartValue(
        Math.ceil(totalElements / e.value) - 5 > 0
          ? Math.ceil(totalElements / e.value) - 5
          : 0
      );
    }
    rowsPerPage.current = e.value;
    onPagination(sortColumn, sortValue, false);
  };

  const getLinks = () => {
    let pages = currentPageValue.current.map((value, index) => {
      return (
        <button
          className={`mx-1 pageLinks ${value === currentPage.current ? "activePage" : ""
            } ${skeleton ? "disabledLinks" : ""}`}
          onClick={(e) => getPageData(e, value)}
        >
          {value}{" "}
        </button>
      );
    });
    return pages;
  };

  return (
    <div className="customPaginator text-center">
      <span>
        <button
          className={`mx-1 pageLinks  ${activePage === pageValues[0] || skeleton
            ? "disabledLinks"
            : ""
            }`}
          onClick={(e) => goToFirstPage(e)}
        >
          <FontAwesomeIcon className="mx-1 prevNextBtn" icon={faAnglesLeft} />
        </button>
      </span>
      <span>
        <button
          className={`mx-1 pageLinks ${activePage <= 1 || skeleton ? "disabledLinks" : ""
            }`}
          onClick={(e) => goToPrevPage(e)}
        >
          <FontAwesomeIcon className="mx-1 prevNextBtn" icon={faAngleLeft} />
        </button>
      </span>
      <span className="">{getLinks()}</span>
      <span>
        <button
          className={`mx-1 pageLinks ${activePage >= pageValues.length || skeleton
            ? "disabledLinks"
            : ""
            }`}
          onClick={(e) => goToNextPage(e)}
        >
          <FontAwesomeIcon className="mx-1 prevNextBtn" icon={faAngleRight} />
        </button>
      </span>
      <span>
        <button
          className={`mx-1 pageLinks ${activePage === pageValues[pageValues.length - 1] || skeleton
            ? "disabledLinks"
            : ""
            }`}
          onClick={(e) => (!skeleton ? goToLastPage(e) : null)}
        >
          <FontAwesomeIcon className="mx-1 prevNextBtn" icon={faAnglesRight} />
        </button>
      </span>
      <span className="totalEle">
        Total {totalElements} Record(s) found
      </span>
      <span>
        <Dropdown
          className="dialog-dropdown ms-4 rowsPerPageDropdown"
          options={rowsPerPageOptions.current}
          value={rowsPerPage.current}
          onChange={(e) => rowsPerPageChanged(e)}
          placeholder="Select Status"
          maxSelectedLabels={1}
          disabled={skeleton}
        />
      </span>
    </div>
  );
};

export default Paginator;
