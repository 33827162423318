import Reporting from "./Reporting";
import NewTab from "./NewTab";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProjectData,
  clearReportData,
  getProjectData,
  getReportData,
} from "../../../actions/autolytics";
import NewTabRevamp from "../NewTab/NewTabRevamp";

const SharedReport = () => {

  const role = useSelector((state) => state.auth.userProfile.role);
  const firstName = useSelector((state) => state.auth.userProfile.firstName);
  const lastName = useSelector((state) => state.auth.userProfile.lastName);
  const imgUrl= useSelector((state)=>state.auth.userProfile.avatar);
  const username= useSelector((state)=>state.auth.userProfile.username);

  const userDetail={
    firstName,
    lastName,
    imgUrl,
    username
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReportData());
    dispatch(getProjectData());

    return () => {
      dispatch(clearReportData());
      dispatch(clearProjectData());
    };
  }, []);

  return (
    <>
      <div className="container-fluid">
        {/* <NewTab
          shared
          sharedReportName="Shared Report"
          component={Reporting}
          defaultTabName="New Report"
          role={role}
        /> */}
        <NewTabRevamp
        role={role}
        shared
        userDetail={userDetail}
        />
      </div>
    </>
  );
};

export default SharedReport;
