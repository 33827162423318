import React from "react";
import { AdvantageCardData } from "../../../Helper/MockData/TaskboardMockData";
import AdvantageCard from "./AdvantageCard/AdvantageCard";
import "./Advantages.css";

const Advantages = () => {
  return (
    <div className="component-padding max-w-1440">
      <p className="component-heading">Advantages</p>
      <div className="advantage-cards-wrapper">
        {AdvantageCardData.map((cardData) => (
          <React.Fragment key={cardData.heading}>
            <AdvantageCard
              heading={cardData.heading}
              icon={cardData.icon}
              description={cardData.description}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Advantages;
