import React from "react";
import "./TaskboardPageHeroSection.css";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

const TaskboardPageHeroSection = () => {
  return (
    <div className="taskboard-hero-section-background">
      <div className="max-w-1440 component-padding">
        <div className="hero-section-heading-wrapper ">
          <p className="taskBoard-hero-section-heading">
            “Empowering Project
            <br />
            Collaboration and Efficiency”
          </p>
          <div className="d-flex justify-content-center align-items-center ">
            <Link  to={
    process.env.REACT_APP_ENV === "prod"
      ? "https://gemecosystem.com/#/gem-consultancy#contact-for-resources"
      : "https://beta.gemecosystem.com/#/gem-consultancy#contact-for-resources"
  }
  target="_blank"
  >
              <Button
                className="base-button primary-button hover-right-arrow"
                label="Request Demo"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskboardPageHeroSection;
