import { Dialog } from "primereact/dialog";
import { useState, useContext } from "react";
import {
  availablePypTypes,
  availableTestcaseKeys,
  availableframeworks,
  availabletype,
} from "./AlabData";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Chips } from "primereact/chips";
import { useRef } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import {
  addFormField,
  createHeadersKeyValueInputs,
  getErrorMessage,
  getToastMessage,
} from "../../../Helper/Helper";
import httpService from "../../../../services/http.service";
import { handleToastErrorCatch } from "../../../Helper/ToastHelper";
import { AlabContext } from "./ALab";
import { createTestcase } from "./AutomationTestcaseUtils";
import { useEffect } from "react";
import ManualForm from "../../ManualTC/ManualForm";
import { MultiSelect } from "primereact/multiselect";

const NewTestcaseDialog = ({
  newTestcaseDialog,
  setTestcaseDialog,
  action,
  setAction,
  editTcId,
  setEditTcId,
  actionFrom,
  sid,
  setElabTcData,
  createNewTestcaseButtonLoading,
  setCreateNewTestcaseButtonLoading
}) => {
  const [framework, setFramework] = useState(null);
  const [testcaseName, setTestcaseName] = useState("");
  const [category, setCategory] = useState("");
  const [beforeClass, setBeforeClass] = useState("");
  const [beforeMethod, setBeforeMethod] = useState("");
  const [afterClass, setAfterClass] = useState("");
  const [afterMethod, setAfterMethod] = useState("");
  const [type, setType] = useState("");
  const [runFlag, setRunflag] = useState("Y");
  const [tcpills, showTcpills] = useState(false);
  const [beforeFile, setBeforeFile] = useState("");
  const [afterFile, setAfterFile] = useState("");
  const [scenarioSteps, setScenarioSteps] = useState("");
  const [scenarioExample, setScenarioExample] = useState("");
  const [tags, setTags] = useState([]);
  const testcasePillData = useRef(null);
  const [keyHeaders, setKeyHeaders] = useState([{ key: "", value: "" }]);
  const [testcaseOptions, setTestcaseOptions] = useState([]);
  const [disable, setDisable] = useState(false);
  const [showEditTestcase, setShowEditTestcase] = useState(false);
  const [sameCompanyUsers, setSameCompanyUsers] = useState([]);
  const [manualEditData, setManualEditData] = useState(null);
  const [selectedTestingCategory,setSelectedTestingCategory]=useState([])
  const contextVal = useContext(AlabContext);
  const manualRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (action === "edit") {
      setCreateNewTestcaseButtonLoading(true)
      let data = null;
      httpService
        .getEditTestCasesDetails(editTcId)
        .then((res) => {
          data = res?.data;
          setFramework(data?.framework);
          setEditData(data, data?.framework);
          setCreateNewTestcaseButtonLoading(false)
        })
        .catch((err) => {
          handleToastErrorCatch(err);
          setCreateNewTestcaseButtonLoading(false)
        });
    }
  }, [editTcId]);

  const setEditData = (data, framework) => {
    switch (framework?.toUpperCase()) {
      case "GEMPYP":
        setTestcaseName(data?.name);
        setCategory(data?.category);
        setType(data?.type);
        setSelectedTestingCategory(data?.automationType)
        setTags(data?.tags ? data.tags : "");
        setRunflag(data?.["run_flag"]);
        data?.type?.toLowerCase() !== "gempyp"
          ? showTcpills(true)
          : showTcpills(false);
        setTestcaseOptions(availableTestcaseKeys?.[data?.type?.toLowerCase()]);

        if (data?.frameworkDefinedData) {
          const frameworkDefinedDataKeys = Object.keys(
            data?.frameworkDefinedData
          );
          let tempKeyHeaders = [...keyHeaders];
          frameworkDefinedDataKeys?.forEach((keys, i) => {
            tempKeyHeaders[i] = {
              key: keys,
              value: data.frameworkDefinedData[keys],
            };
          });
          setKeyHeaders(tempKeyHeaders);
        }
        setTestcaseDialog(true);
        break;

      case "GEMJAR":
        setTestcaseName(data?.name);
        setScenarioSteps(data?.scenario_steps?.join("\n"));
        setCategory(data?.category);
        setTags(data?.tags ? data.tags : "");
        setRunflag(data?.run_flag);
        setSelectedTestingCategory(data?.automationType)
        setType(data?.type);
        if (data?.examples) {
          setScenarioExample(data.examples?.join("\n"));
        }
        setTestcaseDialog(true);
        break;

      case "MANUAL":
        setManualEditData(data);
        httpService.getSameCompanyUsers(data?.["pid"]).then((data) => {
          data?.["data"]?.["data"]
            ? setSameCompanyUsers(data["data"]["data"])
            : setSameCompanyUsers([]);
          getErrorMessage(data);
          setShowEditTestcase(true);
        });
        setTestcaseDialog(true);

        break;
      default:
        return "No Framework";
    }
  };

  const createFrameworkCards = () => {
    const finalCards =
      availableframeworks &&
      availableframeworks.map((data) => (
        <div
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-2 d-flex align-items-stretch"
          style={{ position: "relative" }}
        >
          <div className="card">
            <div className="card-body mb-2">
              <div className="mt-2">
                <img className="w-50" src={data?.logo} alt={data?.name} />
              </div>
              <div className="mt-3 ms-2">
                <h4>{data?.name}</h4>
              </div>
              <div className="mt-2 mb-4 ms-2">
                <p>{data?.content}</p>
              </div>
              <div className="mt-1 ms-2">
                <Button
                  onClick={() => {
                    setFramework(data?.name);
                  }}
                  disabled={["gemprf"].includes(data?.name?.toLowerCase())}
                  label="Select"
                  className={`p-button-outlined selectFramework px-4 ms-4 mb-2 py-2 ${
                    ["gemprf"].includes(data?.name?.toLowerCase())
                      ? "disabled"
                      : ""
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      ));
    return finalCards;
  };

  const createInputText = (value, setValue, placeholderText, isDisabled) => {
    return (
      <div>
        <InputText
          type="text"
          value={value}
          className="inputHeight"
          placeholder={placeholderText}
          onChange={(e) => setValue(e.target.value)}
          disabled={isDisabled}
        />
      </div>
    );
  };

  const createUpdateTc = (payload) => {
    setLoading(true);
    httpService
      .createTestcaseAlab(payload)
      .then((data) => {
        getToastMessage(data);
        setTestcaseDialog(false);
        clearData();
        setDisable(false);
        setLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setDisable(false);
          setLoading(false);
        });
      });
  };

  const editUpdateTc = async (payload, editFrom) => {
    if (!payload && editFrom === "manualEdit") {
      payload = await manualRef.current?.createManual();
      if (actionFrom === "elab") {
        payload["s_id"] = [sid];
      }
    }
    setLoading(true);
    httpService
      .editTestcaseTestTool(payload, editTcId)
      .then((data) => {
        getToastMessage(data);
        if (actionFrom === "elab") {
          httpService.getAvailableSuiteTestCases(sid).then((newdata) => {
            setElabTcData(newdata?.["data"]);
            getErrorMessage(newdata);
            clearData();
          });
        }
        setTestcaseDialog(false);
        clearData();
        setDisable(false);
        setLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setDisable(false);
          setLoading(false);
        });
      });
  };

  const paramsObject = {
    keyHeaders,
    testcaseName,
    category,
    type,
    selectedTestingCategory,
    runFlag,
    tags,
    contextVal,
    scenarioSteps,
    scenarioExample,
    action,
    actionFrom,
    sid,
    setShowEditTestcase,
    setDisable,
    createUpdateTc,
    editUpdateTc,
  };

  const createNewTestcase = () => {
    const returnData = (
      <>
        {createHeadersKeyValueInputs(
          keyHeaders,
          setKeyHeaders,
          testcaseOptions
        )}
        <button
          className="btn themeBtn mt-3"
          onClick={(e) => addFormField("headers", keyHeaders, setKeyHeaders)}
        >
          Add Data
        </button>
      </>
    );
    return returnData;
  };

  const createDropdowns = (
    value,
    setValue,
    options,
    placeholderText,
    onChangeFn,
    isDisabled
  ) => {
    return (
      <Dropdown
        filter
        className="inputHeight w-100"
        value={value}
        name="key"
        options={options}
        onChange={(e) => {
          setValue(e.target.value);
          onChangeFn && onChangeFn(e);
        }}
        disabled={isDisabled}
        placeholder={placeholderText}
      />
    );
  };
  const createInsideDialog = (framework) => {
    let finalReturn = null;
    switch (framework) {
      case "GEMPYP":
        finalReturn = (
          <>
            <div className="row gy-2">
                <div className="col-4">
                  <div className="mb-1 dropdown-heading">
                    Testcase Name<sup> *</sup>
                  </div>
                  {createInputText(
                    testcaseName,
                    setTestcaseName,
                    "Testcase Name",
                    loading
                  )}
                </div>
              
              <div className="col-4">
                <div>
                  <div className="mb-1 dropdown-heading">
                    Category<sup> *</sup>
                  </div>
                  {createInputText(category, setCategory, "Category", loading)}
                </div>
              </div>
              <div className="col-4">
                <div>
                  <div className="mb-1 dropdown-heading">
                    Type<sup> *</sup>
                  </div>
                  <div className="w-100">
                    {createDropdowns(
                      type,
                      setType,
                      availablePypTypes,
                      "Select Type",
                      (e) => {
                        if (e.target.value.toLowerCase() === "gempyp") {
                          if (testcasePillData.current) {
                            testcasePillData.current.click();
                          }
                          showTcpills(false);
                        } else {
                          showTcpills(true);
                        }
                        setTestcaseOptions(
                          availableTestcaseKeys[e.target.value.toLowerCase()]
                        );
                      },
                      loading
                    )}
                  </div>
                </div>
              </div>
                <div className="col-4">
                  <div className="mb-1  dropdown-heading">
                    Run Flag<sup> *</sup>
                  </div>
                  <div className="w-100">
                    {createDropdowns(
                      runFlag,
                      setRunflag,
                      ["Y", "N"],
                      "Select Run Flag",
                      null,
                      loading
                    )}
                  </div>
                </div>
                <div className="col-4">
                    <div className="mb-1  dropdown-heading">
                      Testing Classification
                    </div>
                    <div className="manualFormInputs">
                      <MultiSelect
                        className="dialog-dropdown w-100"
                        // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                        options={[
                          "REGRESSION",
                          "SMOKE",
                        ]}
                        value={selectedTestingCategory}
                        onChange={(e) => setSelectedTestingCategory(e.value)}
                        placeholder="Select Category"
                        maxSelectedLabels={1}
                        disabled={loading}
                      />
                    </div>
                  </div>
            </div>
            {contextVal?.currentP_Name?.current && (
              <div className="mt-1">
                <div className="mb-1 dropdown-heading">
                  Project Name<sup> *</sup>
                </div>
                {createInputText(
                  contextVal.currentP_Name.current,
                  contextVal.currentP_Name,
                  "Project Name",
                  true
                )}
              </div>
            )}
            <div className="row mt-1 d-flex me-4">
              <div className="col-12">
                <div>
                  <div className="mb-1 dropdown-heading">Tags</div>
                  <div className="w-100">
                    <Chips
                      value={tags}
                      allowDuplicate={false}
                      onChange={(e) => setTags(e.value)}
                      className="w-100 p-fluid chipsenv"
                      disabled={loading}
                    />
                  </div>
                  <div className="ms-1 mb-1 mt-1 pDetails-smheading">
                    Note: Press Enter to Add Multiple Tags.
                  </div>
                </div>
              </div>
            </div>
            <div className="overHeadAnalytics mt-4">Testcase Data</div>
            {tcpills && (
              <ul
                className="nav nav-pills d-flex justify-content-around mt-3 mb-3 ulpill"
                id={"pills-tab"}
                role="tablist"
              >
                <li className="nav-item mr-1" role="presentation">
                  <div
                    className="fontSizeGeneral nav-link createPill "
                    id={"pills-before-tab"}
                    data-toggle="pill"
                    data-target={"#pills-before"}
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    Before
                  </div>
                </li>
                <li className="nav-item mr-1" role="presentation">
                  <div
                    ref={testcasePillData}
                    className="fontSizeGeneral nav-link createPill active"
                    id={"pills-data-tab"}
                    data-toggle="pill"
                    data-target={"#pills-data"}
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    Testcase Data
                  </div>
                </li>
                <li className="nav-item mr-1" role="presentation">
                  <div
                    className={`fontSizeGeneral nav-link createPill`}
                    id={"pills-after-tab"}
                    data-toggle="pill"
                    data-target={"#pills-after"}
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    After
                  </div>
                </li>
              </ul>
            )}
            <div className="tab-content ms-2 me-4 pb-3" id="pills-tabContent">
              <div
                className="tab-pane fade show "
                id={"pills-before"}
                role="tabpanel"
                aria-labelledby={"pills-before-tab"}
              >
                <>
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <div>
                        <div className="mb-1 dropdown-heading">Class</div>
                        {createInputText(
                          beforeClass,
                          setBeforeClass,
                          "Class Name",
                          loading
                        )}
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center">
                      <div>
                        <div className="mb-1 dropdown-heading">
                          Method<sup> *</sup>
                        </div>
                        {createInputText(
                          beforeMethod,
                          setBeforeMethod,
                          "Method Name",
                          loading
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="mb-1 dropdown-heading">
                      Data<sup> *</sup>
                    </div>
                    <InputTextarea
                      id="beforeFile"
                      rows={8}
                      cols={30}
                      value={beforeFile}
                      onChange={(e) => setBeforeFile(e.target.value)}
                      // autoResize
                      className="w-100 mt-1"
                      disabled={loading}
                    />
                    <div
                      className="btn-success btn  themeBtn"
                      onClick={(e) => {
                        // uploadTestcaseFile(beforeFile, "before");
                      }}
                    >
                      Upload File
                    </div>
                  </div>
                </>
              </div>
              <div
                className="tab-pane fade show active"
                id={"pills-data"}
                role="tabpanel"
                aria-labelledby={"pills-data-tab"}
              >
                <div className="newTestcase container  mt-3">
                  {createNewTestcase()}
                </div>
              </div>
              <div
                className="tab-pane fade show "
                id={"pills-after"}
                role="tabpanel"
                aria-labelledby={"pills-after-tab"}
              >
                <>
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <div>
                        <div className="mb-1 dropdown-heading">Class</div>
                        {createInputText(
                          afterClass,
                          setAfterClass,
                          "Class Name",
                          loading
                        )}
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center">
                      <div>
                        <div className="mb-1 dropdown-heading">
                          Method<sup> *</sup>
                        </div>
                        {createInputText(
                          afterMethod,
                          setAfterMethod,
                          "Method Name",
                          loading
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="mb-1 dropdown-heading">
                      Data<sup> *</sup>
                    </div>
                    <InputTextarea
                      id="afterFile"
                      rows={8}
                      cols={30}
                      value={afterFile}
                      onChange={(e) => setAfterFile(e.target.value)}
                      // autoResize
                      className="w-100 mt-1"
                      disabled={loading}
                    />
                    <div
                      className="btn-success btn  themeBtn"
                      onClick={(e) => {
                        // uploadTestcaseFile(afterFile, "after");
                      }}
                    >
                      Upload File
                    </div>
                  </div>
                </>
              </div>
            </div>
          </>
        );
        break;

      case "GEMJAR":
        finalReturn = (
          <>
            <div className="row mx-5">
              <div className="col  ">
                <div>
                  <div className="mb-1 dropdown-heading">
                    Testcase Name<sup> *</sup>
                  </div>
                  {createInputText(
                    testcaseName,
                    setTestcaseName,
                    "Testcase Name",
                    loading
                  )}
                </div>
              </div>
              <div className="col">
                <div>
                  <div className="mb-1 dropdown-heading">
                    Type<sup> *</sup>
                  </div>
                  <div className="w-100">
                    {createDropdowns(
                      type,
                      setType,
                      availabletype,
                      "Select type",
                      null,
                      loading
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                {contextVal?.currentP_Name?.current && (
                  <div className="mt-1">
                    <div className="mb-1 dropdown-heading">
                      Project Name<sup> *</sup>
                    </div>
                    {createInputText(
                      contextVal.currentP_Name.current,
                      contextVal.currentP_Name,
                      "Project Name",
                      true
                    )}
                  </div>
                )}
              </div>
              <div className="col mt-1">
                    <div className="mb-1  dropdown-heading">
                      Testing Classification
                    </div>
                    <div className="manualFormInputs">
                      <MultiSelect
                        className="dialog-dropdown w-100"
                        // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                        options={[
                          "REGRESSION",
                          "SMOKE",
                        ]}
                        value={selectedTestingCategory}
                        onChange={(e) => setSelectedTestingCategory(e.value)}
                        placeholder="Select Category"
                        maxSelectedLabels={1}
                        disabled={loading}
                      />
                    </div>
                  </div>
            </div>
            <div className="row mx-5">
              <div>
                <div className="mt-2 mb-1 dropdown-heading">
                  Testcase/Scenario Steps<sup> *</sup>
                </div>
                <InputTextarea
                  value={scenarioSteps}
                  className="inputBorder w-100"
                  onChange={(e) => {
                    setScenarioSteps(e.target.value);
                  }}
                  rows={5}
                  autoResize
                  disabled={loading}
                />
              </div>
            </div>
            {type === "Scenario Outline" && (
              <div className="row mx-5">
                <div>
                  <div className="mt-2 mb-1 dropdown-heading">
                    Example<sup> *</sup>
                  </div>
                  <InputTextarea
                    value={scenarioExample}
                    className="inputBorder w-100"
                    onChange={(e) => {
                      setScenarioExample(e.target.value);
                    }}
                    rows={5}
                    autoResize
                    disabled={loading}
                  />
                </div>
              </div>
            )}
            <div className="row mx-5">
              <div className="col">
                <div>
                  <div className="mb-1 mt-2 dropdown-heading">Tags</div>
                  <div className="w-100">
                    <Chips
                      value={tags}
                      allowDuplicate={false}
                      onChange={(e) => setTags(e.value)}
                      className="w-100 p-fluid chipsenv"
                      disabled={loading}
                    />
                  </div>
                  <div className="ms-1 mb-1 mt-1 pDetails-smheading">
                    Note: Press Enter to Add Multiple Tags.
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-5">
              <div className="col">
                <div>
                  <div className="mb-1 mt-2 dropdown-heading">
                    Category<sup> *</sup>
                  </div>
                  {createInputText(category, setCategory, "Category", loading)}
                </div>
              </div>
              <div className="col">
                <div>
                  <div className="mb-1 mt-2 dropdown-heading">
                    Run Flag<sup> *</sup>
                  </div>
                  <div className="w-100">
                    {createDropdowns(
                      runFlag,
                      setRunflag,
                      ["Y", "N"],
                      "Select Run Flag",
                      null,
                      loading
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;

      case "MANUAL":
        finalReturn = (
          <ManualForm
            type={"editFromTestTool"}
            f_id={manualEditData ? manualEditData["folderId"] : null}
            mtc_id={manualEditData ? manualEditData["tc_id"] : null}
            users={sameCompanyUsers}
            ref={manualRef}
            editData={manualEditData}
            setEditData={setManualEditData}
            setManualDialog={setShowEditTestcase}
            p_id={manualEditData ? manualEditData["pid"] : null}
            p_name={manualEditData ? manualEditData["currentP_Name"] : null}
          />
        );
        break;

      default:
        return "No Framework Selected";
    }
    return finalReturn;
  };

  const clearData = () => {
    setKeyHeaders([{ key: "", value: "" }]);
    setCategory("");
    setTestcaseName("");
    setType("");
    setAfterClass("");
    setAfterFile("");
    setAfterMethod("");
    setBeforeClass("");
    setBeforeMethod("");
    setBeforeFile("");
    setScenarioSteps("");
    setTags("");
    setRunflag("Y");
    setScenarioExample("");
    showTcpills(false);
    setAction(null);
    setDisable(false);
    setEditTcId(null);
    setShowEditTestcase(false);
    setSameCompanyUsers([]);
    setManualEditData(null);
    setSelectedTestingCategory([])
    setFramework(null);
    manualRef.current?.clearManualFormData();
  };

  return (
    <Dialog
      blockScroll={true}
      className="createTestcaseDialog"
      header={`${action === "edit" ? "Edit" : "Create"} Testcase ${
        framework ? "(" + framework + ")" : ""
      }`}
      visible={newTestcaseDialog}
      onHide={() => {
        clearData();
        setTestcaseDialog(false);
      }}
      footer={() => (
        <>
          <div className="mt-4 d-flex justify-content-end">
            {framework && (
              <>
                {!(action === "edit") && (
                  <button
                    onClick={() => {
                      clearData();
                    }}
                    label="Back"
                    className="btn-success mt-3  btn themeBtn"
                  >
                    <i className="pi pi-arrow-circle-left"></i> Back
                  </button>
                )}
                <Button
                  disabled={disable}
                  onClick={(e) => {
                    createTestcase(
                      action ? action : "create",
                      framework.toUpperCase(),
                      paramsObject
                    );
                  }}
                  loading={loading}
                  loadingIcon={"pi pi-spin pi-spinner me-2"}
                  className="btn-success mt-3  btn themeBtn"
                  label={
                    action === "edit" ? "Save Testcase" : "Create Testcase"
                  }
                />
              </>
            )}
          </div>
        </>
      )}
      maximizable
      draggable={false}
      breakpoints={{ "960px": "75vw" }}
      style={{
        width: "60vw",
      }}
    >
      {framework ? (
        createInsideDialog(framework?.toUpperCase())
      ) : (
        <>
          <div className="container">
            <div className="row">{createFrameworkCards()}</div>
          </div>
        </>
      )}
    </Dialog>
  );
};

export default NewTestcaseDialog;
