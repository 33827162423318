import './Whatis.css';
import vr from '../../../assets/vr.svg';
import gt from '../../../assets/gt.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons";

const Whatis = () => {

    var pointerJson = {
        "e2ePointers": [
            "Quickly Scalable and Easily Maintainable.",
            "Form-based codeless approach for API Automation & Data Validation.",
            "Testcase-Shareable but secure."
        ],
        "runSuitePointers": [
            "One click run",
            "Mutliple modes and use-cases",
            "CI CD CT Ready",
            "AWS Lambda/Azure Functions- Unlimited Parallel Execution",
            "Openness to support plugin with other apps scheduler."
        ],
        "liveResultsPointers": [
            "Live Reporting",
            "Execution Prediction",
            "Jira Integration",
            "Flaky Prediction",
            "Test Reports- Shareable but secure",
            "Scoring- Developer Quality Score & Automation Quality Score"
        ]
    }

    const createHomePagePointers = (data) => {
        const mapPoints = data.map((dataEle) => (
            <div className='ms-2 mt-2 d-flex'>
                <div>
                    <FontAwesomeIcon
                        className="mx-2 circleCheckIcon"
                        icon={faCircleCheck}
                    />
                </div>
                <div>{dataEle}</div>
            </div>
        ))
        return mapPoints;
    }

    return (<>
        <div className='text-center mt-5' >
            <span className='headtext'>What Is Jewel ?</span>
            <br />
            <div className='under'>ABOUT US</div>

        </div>
        <div className="container mb-5">
            <div className="underText text-center px-5 my-5 mx-3">
                <b>Jewel</b> is a test management web solution that offers test suite creation using Gempyp and Gemjar, test execution with maximum parallelisation and load balancing along with live and analytical reporting for any framework that is integrated using Rest APIs.
                <div className='mt-5'>
                    <div className='row mt-5'>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                            <div className='card text-center customCard mx-xl-5 mx-lg-5 mx-md-3 mx-sm-0 mb-2'>
                                <div className='whatisHead mt-5 mb-3 px-2'><strong>JEWEL- End to End Test Management</strong></div>
                                <div className='whatisData mb-5 px-3'> {createHomePagePointers(pointerJson.e2ePointers)}</div>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center'>
                            <img src={vr} alt="View Report" className='mb-2'></img>
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center'>
                            <img src={gt} alt="View Report" className='mb-2'></img>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                            <div className='card text-center customCard mx-xl-5 mx-lg-5 mx-md-3 mx-sm-0'>
                                <div className='whatisHead mt-5 mb-3 px-2'><strong>Create &  Run Test Suites</strong></div>
                                <div className='whatisData mb-5 px-3'> {createHomePagePointers(pointerJson.runSuitePointers)} </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                            <div className='card text-center customCard mx-xl-5 mx-lg-5 mx-md-3 mx-sm-0 mb-2'>
                                <div className='whatisHead mt-5 mb-3 px-2'><strong>View Live and Analysis Results</strong></div>
                                <div className='whatisData mb-5 px-3'> {createHomePagePointers(pointerJson.liveResultsPointers)}</div>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center'>
                            <img src={vr} alt="View Report" className='mb-2'></img>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </>);
}

export default Whatis;