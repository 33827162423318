import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
import { useState, useEffect } from "react";
import BreadCrumb from "../../../UI/BreadCrumb/BreadCrumb";
import AllJobs from "./AllJobs";
import JobTemplate from "./JobTemplate";
import "./Scheduler.css";
import { Tooltip } from "primereact/tooltip";

const Scheduler = ({ props }) => {
  const { rowData, setDataMain, val, s_id } = props;
  const [showJobsDialog, setShowJobsDialog] = useState(false);
  const [breadList, setBreadList] = useState([
    { name: "Scheduled Jobs", type: "jobs" },
  ]);
  const [jobAction, setJobAction] = useState("jobs");

  useEffect(() => {
    document.addEventListener("onBreadcrumbClick", (e) => {
      setJobAction(e?.detail?.data?.type);
    });
    return () => {
      document.removeEventListener("onBreadcrumbClick", (e) => {
        setJobAction(e?.detail?.data?.type);
      });
    };
  }, []);

  const createBody = (job) => {
    switch (job) {
      case "add":
        return (
          <JobTemplate
            s_id={s_id}
            setDataMain={setDataMain}
            setBreadList={setBreadList}
            setJobAction={setJobAction}
          />
        );
      case "edit":
        return (
          <JobTemplate
            s_id={s_id}
            edit
            setDataMain={setDataMain}
            setBreadList={setBreadList}
            setJobAction={setJobAction}
          />
        );
      case "jobs":
        return (
          <AllJobs
            key={"allJob"}
            s_id={s_id}
            setBreadList={setBreadList}
            breadList={breadList}
            setJobAction={setJobAction}
            setSuiteData={setDataMain}
            suiteName={rowData?.["Suite Name"]?.value}
          />
        );
      default:
        break;
    }
  };
  return (
    <>
      <div
        onClick={() => {
          setJobAction("jobs");
          setShowJobsDialog(true);
        }}
      >
        <Tooltip target=".schedulerIcon" className="schedulerTooltip">
          All Jobs currently inactive
        </Tooltip>
        <i className={`pi pi-calendar iconclick actionBtn mr-4 p-text-secondary p-overlay-badge ${(!(rowData?.Scheduler?.activeJobs) && val > 0) && "schedulerIcon"}`}
          data-pr-position="bottom">
          {val > 0 && (
            <Badge
              style={{ fontSize: "0.4rem" }}
              value={val}
              severity={val > 0 ? "success" : "danger"}
              className={`tableBadge ${!(rowData?.Scheduler?.activeJobs) && "inactiveJobs"}`}
            ></Badge>
          )}
        </i>
      </div>

      <Dialog
        blockScroll={true}
        draggable={false}
        header={() => (
          <>
            <BreadCrumb
              className="fontSizeGeneral"
              breadList={breadList}
              component={"jobs"}
              setBreadList={setBreadList}
            />
          </>
        )}
        visible={showJobsDialog}
        onHide={() => {
          setShowJobsDialog(false);
          setBreadList([{ name: "Scheduled Jobs", type: "jobs" }]);
          setJobAction("");
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "60vw" }}
      >
        {createBody(jobAction)}
      </Dialog>
    </>
  );
};

export default Scheduler;
