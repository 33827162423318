export const API_URLS = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
};

export const MICRO_API_URL_USER = {
  baseURL: process.env.REACT_APP_MICRO_BASE_URL_USER,
};
export const AGILECRAFTER_URL = {
  baseURL: process.env.REACT_APP_AGILE_CRAFTER_URL,
};
export const MICRO_API_URL_DASHBOARD = {
  baseURL: process.env.REACT_APP_MICRO_BASE_URL_DASHBOARD,
};

export const MICRO_API_URL_PROJECT = {
  baseURL: process.env.REACT_APP_MICRO_BASE_URL_PROJECT,
};

export const MICRO_API_URL_TESTEXECUTION = {
  baseURL: process.env.REACT_APP_MICRO_BASE_URL_TESTEXECUTION,
};

export const MICRO_API_URL_TESTPAD = {
  baseURL: process.env.REACT_APP_MICRO_BASE_URL_TESTPAD,
};

export const MICRO_API_URL_REPORT = {
  baseURL: process.env.REACT_APP_MICRO_BASE_URL_REPORT,
};

export const MICRO_API_URL_S3 = {
  baseURL: process.env.REACT_APP_MICRO_BASE_URL_S3,
};

export const MICRO_API_URL_STANDUP = {
  baseURL: process.env.REACT_APP_MICRO_BASE_URL_STANDUP,
};
export const MICRO_API_URL_NOTIFICATION = {
  baseURL: process.env.REACT_APP_MICRO_BASE_URL_NOTIFICATION,
};

export const MICRO_API_URL_FRAMEWORK = {
  baseURL: process.env.REACT_APP_MICRO_BASE_URL_FRAMEWORK,
};