import React, { useEffect } from "react";
import TaskboardPageHeroSection from "../../Layouts/TaskboardPage/TaskboardPageHeroSection/TaskboardPageHeroSection";
import Overview from "../../Layouts/TaskboardPage/Overview/Overview";
import Advantages from "../../Layouts/TaskboardPage/Advantage/Advantages";
import UserCapabilities from "../../Layouts/TaskboardPage/UserCapabilities/UserCapabilities";
import NewFooter from "../../UI/NewFooter/NewFooter";
import Header from "../../UI/Header/Header";

const TaskboardPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  });
  return (
    <>
      <Header />
      <TaskboardPageHeroSection />
      <Overview />
      <Advantages />
      <UserCapabilities />
      <NewFooter />
    </>
  );
};

export default TaskboardPage;
