import React, { useEffect, useState } from "react";
import "./Header.css";

import { Link, useLocation } from "react-router-dom";
import MobileNavDrawer from "./mobileNavDrawer/MobileNavDrawer";

import mobileViewMenuDrawerIcon from "../../../assets/mobileViewMenuDrawerIcon.svg";
import navBarCrossIcon from "../../../assets/navBarCrossIcon.svg";
import { Button } from "primereact/button";
import newJewelIcon from "../../../assets/newJewelIcon.svg";
import { headerLinksData } from "../../Helper/MockData/LandingPageMockData";

const Header = () => {
  const [isMobileNavDrawerOpen, setIsMobileNavDrawerOpen] = useState(false);

  const location = useLocation();
  const [windowHash, setWindowHash] = useState("");
  useEffect(() => {
    setWindowHash(window.location.hash);
  }, [location]);

  const MenuOptionsHeading = ({ headingIcon, heading }) => {
    return (
      <div className=" menu-options-heading-wrapper ">
        {headingIcon && (
          <div>
            <img
              className="menu-options-heading-icon"
              src={headingIcon}
              alt={heading}
            />
          </div>
        )}
        <p className="header-link-menu-content-heading ">{heading}</p>
      </div>
    );
  };

  const HeaderLinkMenu = ({ icon, heading, description, menuContent }) => {
    return (
      <div className="header-link-menu-wrapper ">
        <img loading="lazy" src={icon} alt={heading} />
        <div>
          <p className="header-link-menu-heading">{heading}</p>
          <div className="relative">
            <p className="header-link-menu-description">{description}</p>
          </div>
          <div className="header-link-menu-content-wrapper ">
            {menuContent.map((item) => {
              return (
                <div key={item.id}>
                  <div className="w-fit">
                    {item.headingLink || item.headingBetaLink ? (
                      <Link
                        to={
                          //either env is prod OR beta env Link does not exist
                          process.env.REACT_APP_ENV === "prod" ||
                          !item.headingBetaLink
                            ? item.headingLink
                            : item.headingBetaLink
                        }
                        target="_blank"
                      >
                        <MenuOptionsHeading
                          headingIcon={item.headingIcon}
                          heading={item.heading}
                        />
                      </Link>
                    ) : (
                      <MenuOptionsHeading
                        headingIcon={item.headingIcon}
                        heading={item.heading}
                      />
                    )}
                  </div>
                  <div className="mt-3">
                    {item.links.map((child, index) => {
                      return (
                        <p key={index}>
                          <Link
                            to={
                              //either env is prod OR beta env Link does not exist
                              process.env.REACT_APP_ENV === "prod" ||
                              !child.betaLink
                                ? child.link
                                : child.betaLink
                            }
                            target={child.openInNewTab ? "_blank" : "_self"}
                            className={`header-link-menu-content-link ${
                              child.isBlueColor && "primary-color"
                            }`}
                          >
                            {child.name}
                          </Link>
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <header className="header-shadow sticky-header">
      <div className="header d-flex  items-center justify-content-between padding max-w-1440">
        <Link className="header-jewel-icon-link self-stretch" to="/">
          <div className="jewel-text-icon-wrapper">
            <img
              className="self-stretch header-jewel-icon"
              src={newJewelIcon}
              alt="Jewel"
            />
            <p className="jewel-header-text">Jewel</p>
          </div>
        </Link>
        <div className="mobile-hidden-desktop-flex header-links-wrapper h-full">
          {headerLinksData.map((item, index) => {
            return (
              <div key={item.id} className="header-link-wrapper ">
                {item.link ? (
                  <Link
                    to={item.link}
                    className={`header-link ${
                      item.hash === windowHash && "header-link-active"
                    }`}
                    target={item.openInNewTab && "_blank"}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <p className="header-link cursor-default">{item.name}</p>
                )}

                {/* {item?.headerLinkMenu && (
                <HeaderLinkMenu
                  icon={item.headerLinkMenu.icon}
                  heading={item.headerLinkMenu.heading}
                  description={item.headerLinkMenu.description}
                  menuContent={item.headerLinkMenu.menuChildren}
                />
              )} */}
              </div>
            );
          })}
        </div>
        <div className="mobile-hidden-desktop-flex header-buttons-wrapper ">
          <Link to={"/signup"}>
            <Button
              label="Sign up"
              className="base-button glow-button tertiary-button  "
            />
          </Link>
          <Link to={"/login"}>
            <Button
              label="Login"
              className="base-button glow-button primary-button   "
            />
          </Link>
        </div>
        <div className="desktop-hidden-mobile-flex align-items-center">
          <img
            loading="lazy"
            onClick={() => {
              setIsMobileNavDrawerOpen(!isMobileNavDrawerOpen);
            }}
            src={
              isMobileNavDrawerOpen ? navBarCrossIcon : mobileViewMenuDrawerIcon
            }
            alt="drawerMenu"
          />
          <MobileNavDrawer
            open={isMobileNavDrawerOpen}
            setOpen={setIsMobileNavDrawerOpen}
            windowHash={windowHash}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
