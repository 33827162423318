import MainNav from '../../UI/MainNav/MainNav';
import Footer from '../../UI/Footer/Footer';
import { useEffect } from 'react';
import ComingSoonMain from './ComingSoonMain';



function ComingSoon() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [window.location.hash]);
    return (
        <>
            <div className="mx-5">
                <MainNav />
            </div>
            <section className="heroSoon">
                <ComingSoonMain />
            </section>
            <Footer />
        </>
    )
}

export default ComingSoon;