import React from "react";
import TabComponent from "../Autolytics/SecurityReport/TabComponent";
import BridgeTokenPage from "../../Pages/BridgeTokenPage/BridgeTokenPage";
import UserDetails from "./UserDetails";

const tabData = [
  {
    name: "User Settings",
    id: "pills-users-tab-",
    target: "#pills-users-",
    fn: () => {},
  },
  {
    name: "Bridge Token",
    id: "pills-token-tab-",
    target: "#pills-token-",
    fn: () => {},
  },
];

const TabNavigation = ({ userData,emailUpdate,setEmailUpdate }) => {
  return (
    <div className="mx-4">
      <TabComponent
        data={tabData}
        id={userData?.firstname}
        activeTab={"User Settings"}
      />
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id={`pills-users-${userData?.firstname}`}
          role="tabpanel"
          aria-labelledby={`pills-users-${userData?.firstname}`}
        >
          <UserDetails userData={userData} emailUpdate={emailUpdate} setEmailUpdate={setEmailUpdate} />
        </div>
        <div
          className="tab-pane fade"
          id={`pills-token-${userData?.firstname}`}
          role="tabpanel"
          aria-labelledby={`pills-token-${userData?.firstname}`}
        >
          <BridgeTokenPage />
        </div>
      </div>
    </div>
  );
};

export default TabNavigation;
