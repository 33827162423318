import React,{useState} from "react";
import "./TestpadPageScrollAnimationMobile.css";
import { Link } from "react-router-dom";
import TestpadPageRightArrowIcon from "../../../assets/TestpadPageRightArrowIcon.svg";
import { Dialog } from "primereact/dialog";

const TestpadPageScrollAnimationMobile = ({
  heading,
  description,
  image,
  zIndex,
}) => {
  const [visible, setVisible] = useState(false);
  const handleImageClick = () => {
    setVisible(true);
  };
  return (
    <div
      key={heading}
      className="testpad-scroll-card-wrapper"
      style={{ zIndex: zIndex }}
    >
      <div>
        <p className="testpad-card-heading">{heading}</p>
        <p className="testpad-card-description">{description}</p>
        {/* <Link to="/try-it-now" className="testpad-card-link">
          <span>Try it now</span>
          <img
            src={TestpadPageRightArrowIcon}
            alt="right arrow icon"
            loading="lazy"
          />
        </Link> */}
      </div>
      {/* <div className="testpad-card-image-wrapper"> */}
      <img
        className="testpad-card-image"
        src={image}
        alt={heading}
        loading="lazy"
        onClick={handleImageClick} // Attach onClick event handler directly to the image
      />
      {/* </div> */}
      <Dialog
        header=""
        visible={visible}
        maximizable
        style={{ width: '60vw' }} // Add cursor style to make it pointer
        onHide={() => setVisible(false)}
      >
        <div>
        <img
           src={image}
           alt={heading}
          loading="lazy"
          style={{ width: '100%'}} // Set margin to 0 to reduce space around the image
        />
        </div>
      </Dialog>
    </div>
  );
};

export default TestpadPageScrollAnimationMobile;
