import { ADMIN_AVAILABLE_PROJECTS, CLEAR_ADMIN_AVAILABLE_PROJECTS } from "../actions/type";

const initialState = { admin_available_projects: null }

export default function (state = initialState, action) 
{
    const { type, payload } = action;
    switch(type)
    {
        case ADMIN_AVAILABLE_PROJECTS:
            return {
                ...state,
                admin_available_projects : payload
            }
        case CLEAR_ADMIN_AVAILABLE_PROJECTS:
            return {
                ...state,
                admin_available_projects : null
            } 
        default:
            return state;       
    }

}