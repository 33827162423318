import { IS_OPEN, IS_CLOSE } from "../actions/type.js";

const initialState = { isOpen: true };

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case IS_OPEN:
      return {
        ...state,
        isOpen: true,
      };
    case IS_CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
