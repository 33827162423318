import workMinutesFeatureImage from "../../../assets/workMinutesFeatureImage.png";
import workMinutesUserCapabilitiesCustomFieldsIcon from "../../../assets/workMinutesUserCapabilitiesCustomFieldsIcon.svg";
import workMinutesUserCapabilitiesIncreasedProductivityIcon from "../../../assets/workMinutesUserCapabilitiesIncreasedProductivityIcon.svg";
import workMinutesUserCapabilitiesStatusUpdatesIcon from "../../../assets/workMinutesUserCapabilitiesStatusUpdatesIcon.svg";
import workMinutesUserCapabilitiesStatusVisibilityIcon from "../../../assets/workMinutesUserCapabilitiesStatusVisibilityIcon.svg";
import workMinutesCustomizableAgileMeetingsIcon from "../../../assets/workMinutesCustomizableAgileMeetingsIcon.svg";
import workMinutesEffortlessCommunicationIcon from "../../../assets/workMinutesEffortlessCommunicationIcon.svg";
import workMinutesTransparentTeamProgressIcon from "../../../assets/workMinutesTransparentTeamProgressIcon.svg";
import workMinutesFlexibleStandupOrganizationIcon from "../../../assets/workMinutesFlexibleStandupOrganizationIcon.svg";

export const workMinutesFeatureCardDataMobile = [
  {
    heading: "Customizable Agile Meetings",
    description:
      "Work Minutes empowers users to conduct standups and retrospectives, allowing them to share their task status, issues and achievements etc. Custom fields offer flexibility for personalized data tracking.",
    image: workMinutesCustomizableAgileMeetingsIcon,
  },
  {
    heading: "Effortless Communication",
    description:
      "Reduce manual communication efforts through automated status emails feature. Easily send notifications to all project members and additional stakeholders, ensuring everyone stays informed and connected.",
    image: workMinutesEffortlessCommunicationIcon,
  },
  {
    heading: "Transparent Team Progress",
    description:
      "Gain insights into team dynamics with Work Minutes's status tracking. Users can view the status of team members who have submitted updates and identify defaulters, fostering accountability and transparency.",
    image: workMinutesTransparentTeamProgressIcon,
  },
  {
    heading: "Flexible Standup",
    description:
      "Tailor standups according to custom workflows. Work Minutes lets users customize the frequency of standups—daily, weekly, or monthly. Live standups provide a structured yet adaptable approach to team collaboration.",
    image: workMinutesFlexibleStandupOrganizationIcon,
  },
];
export const workMinutesFeatureCardDataDesktop = {
  contentArray: [
    {
      heading: "Customizable Agile Meetings",
      description:
        "Work Minutes empowers users to conduct standups and retrospectives, allowing them to share their task status, issues and achievements etc. Custom fields offer flexibility for personalized data tracking.",
    },
    {
      heading: "Effortless Communication",
      description:
        "Reduce manual communication efforts through automated status emails feature. Easily send notifications to all project members and additional stakeholders, ensuring everyone stays informed and connected.",
    },
    {
      heading: "Transparent Team Progress",
      description:
        "Gain insights into team dynamics with Work Minutes's status tracking. Users can view the status of team members who have submitted updates and identify defaulters, fostering accountability and transparency.",
    },
    {
      heading: "Flexible Standup",
      description:
        "Tailor standups according to custom workflows. Work Minutes lets users customize the frequency of standups—daily, weekly, or monthly. Live standups provide a structured yet adaptable approach to team collaboration.",
    },
  ],
  imagesArray: [
    {
      image: workMinutesCustomizableAgileMeetingsIcon,
    },
    {
      image: workMinutesEffortlessCommunicationIcon,
    },
    {
      image: workMinutesTransparentTeamProgressIcon,
    },
    {
      image: workMinutesFlexibleStandupOrganizationIcon,
    },
  ],
};

export const workMinutesUserCapabilitiesCardData = [
  {
    icon: workMinutesUserCapabilitiesStatusUpdatesIcon,
    heading: "Status Updates",
    description:
      "Users can efficiently fill in their task status, report issues faced, and share achievements through an interactive UI offering speech to text feature as well.",
  },
  {
    icon: workMinutesUserCapabilitiesCustomFieldsIcon,
    heading: "Custom Fields",
    description:
      "Users can accommodate specific information relevant to their tasks and responsibilities providing transparency in the work and challenges faced.",
  },
  {
    icon: workMinutesUserCapabilitiesStatusVisibilityIcon,
    heading: "Status Visibility",
    description:
      "Work Minutes empowers users to see the status of team members who have submitted updates, as well as identify any defaulters, promoting accountability and enabling a clear understanding of team progress.",
  },
  {
    icon: workMinutesUserCapabilitiesIncreasedProductivityIcon,
    heading: "Increased Productivity",
    description:
      "This seamless coordination fosters an environment where individuals can confidently dedicate their attention to their tasks, leading to increased productivity and successful project outcomes.",
  },
];
