import {
  ALabCardDataDesktop,
  ALabCardDataMobile,
} from "../../../Helper/MockData/TestpadPageMockData";
import TestpadPageScrollAnimationMobile from "../../../UI/TestpadPageScrollAnimationMobile/TestpadPageScrollAnimationMobile";
import TestpadPageScrollAnimationDesktop from "../../../UI/TestpadPageScrollAnimationDesktop/TestpadPageScrollAnimationDesktop";

const ALab = () => {
  return (
    <div className="component-padding max-w-1440">
      <p className="component-heading">ALab</p>
      <div className="mobile-block-desktop-hidden">
        {ALabCardDataMobile.map((item, index) => {
          return (
            <TestpadPageScrollAnimationMobile
              heading={item.heading}
              description={item.description}
              image={item.image}
              zIndex={index}
            />
          );
        })}
      </div>
      <div className="mobile-hidden-desktop-block">
        <TestpadPageScrollAnimationDesktop cardData={ALabCardDataDesktop} />
      </div>
    </div>
  );
};

export default ALab;
