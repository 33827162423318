import React from "react";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import "./AutolyticsInfoHeroSection.css";
import InfoAutolyticsHeroSectionVector from "../../../../assets/InfoAutolyticsHeroSectionVector.svg";

const AutolyticsInfoHeroSection = () => {
  return (
    <div className="autolytics-info-hero-section-background">
      <div className="autolytics-info-hero-section-wrapper component-padding max-w-1440">
        <div>
          <p className="autolytics-info-hero-section-heading">
            Swift Insights from Data
          </p>
          <p className="autolytics-info-hero-section-description">
          An Autolytics dashboard visually presents key data and metrics for
            efficient monitoring and decision-making across various fields.
          </p>
          <Link  to={
    process.env.REACT_APP_ENV === "prod"
      ? "https://gemecosystem.com/#/gem-consultancy#contact-for-resources"
      : "https://beta.gemecosystem.com/#/gem-consultancy#contact-for-resources"
  }
  target="_blank">
            <Button
              className="base-button primary-button hover-right-arrow"
              label="Request Demo"
            />
          </Link>
        </div>

        <img
          className="autolytics-info-hero-section-image"
          src={InfoAutolyticsHeroSectionVector}
          alt="screenshot of suite summary report"
        />
      </div>
    </div>
  );
};

export default AutolyticsInfoHeroSection;
