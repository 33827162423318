import axios from "axios";
import { MICRO_API_URL_REPORT } from "../../config/API_URLS";
import authHeader from "../auth-header";

class reportService {
    getAllReports(type) {
        return axios
            .get(
                MICRO_API_URL_REPORT.baseURL +
                "v1/report/" + type,
                {
                    headers: authHeader(),
                }
            )
            .then((data) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => Promise.reject(err));
    }

    shareReport(payload) {
        return (
            axios
                .post(MICRO_API_URL_REPORT.baseURL + "v1/report/share", payload, {
                    headers: authHeader(),
                })
                .then((data) => {
                    return Promise.resolve(data.data);
                })
                .catch((err) => Promise.reject(err))
        );
    }

    saveReport(payload) {
        return (
            axios
                .post(MICRO_API_URL_REPORT.baseURL + "v1/report/save", payload, {
                    headers: authHeader(),
                })
                .then((data) => {
                    return Promise.resolve(data.data);
                })
                .catch((err) => Promise.reject(err))
        );
    }

    updateReport(payload) {
        return (
            axios
                .put(MICRO_API_URL_REPORT.baseURL + "v1/report/update", payload, {
                    headers: authHeader(),
                })
                .then((data) => {
                    return Promise.resolve(data.data);
                })
                .catch((err) => Promise.reject(err))
        );
    }

    getShareReportData(reportId) {
        return axios
            .get(
                MICRO_API_URL_REPORT.baseURL +
                "v1/report/nameAndAccessDetails?reportId=" + reportId,
                {
                    headers: authHeader(),
                }
            )
            .then((data) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => Promise.reject(err));
    }

    deleteReport(id) {
        return axios
            .delete(
                MICRO_API_URL_REPORT.baseURL +
                "v1/report/delete?reportId=" + id,
                {
                    headers: authHeader(),
                }
            )
            .then((data) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => Promise.reject(err));
    }
}

export default new reportService();