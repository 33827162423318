import { Accordion, AccordionTab } from "primereact/accordion";
import React from "react";
import { Link } from "react-router-dom";

import crossIcon from "../../../assets/crossIcon.svg";
import plusIcon from "../../../assets/plusIcon.svg";
import heartIcon from "../../../assets/heartIcon.svg";
import JewelWhiteLogo from "../../../assets/JewelWhiteLogo.svg";

import "./NewFooter.css";
import {
  footerIcons,
  footerLinksData,
} from "../../Helper/MockData/LandingPageMockData";

const NewFooter = () => {
  const currentYear = new Date().getFullYear();
  const FooterMadeWithBharat = () => {
    return (
      <div className=" made-with-bharat-wrapper ">
        <Link className="d-flex align-items-center width-fit " to="/">
          <img
            loading="lazy"
            className="jewel-white-logo"
            src={JewelWhiteLogo}
            alt="GemEcosystem"
          />
          <p className="jewel-text-white">Jewel</p>
        </Link>
        <p className=" made-with-bharat-text ">
          Made with{" "}
          <img loading="lazy" className="mx-1" src={heartIcon} alt="love" /> in
          Bharat
        </p>
        <div className="d-flex align-items-center ">
          {footerIcons.map((item) => {
            return (
              <Link key={item.id} to={item.link} target="_blank">
                <img
                  loading="lazy"
                  className="social-media-icons "
                  src={item.icon}
                  alt={item.id}
                />
              </Link>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      {/* -------------Mobile Footer----------- */}
      <div className="accordion-wrapper  ">
        {footerLinksData.map((accordionItem) => {
          return (
            <React.Fragment key={accordionItem.id}>
              <Accordion
                expandIcon={
                  <img loading="lazy" src={plusIcon} alt="Expand Accordion" />
                }
                collapseIcon={
                  <img
                    loading="lazy"
                    src={crossIcon}
                    alt="Collapse Accordion"
                  />
                }
              >
                <AccordionTab
                  className="footer-accordion"
                  key={accordionItem.id}
                  header={accordionItem.heading}
                >
                  {accordionItem.children.map((linkItem) => {
                    return (
                      <p key={linkItem.id}>
                        <Link to={linkItem.link}>{linkItem.heading}</Link>
                      </p>
                    );
                  })}
                </AccordionTab>
              </Accordion>
              <hr className="footer-hr " />
            </React.Fragment>
          );
        })}

        <div className="d-flex justify-content-center mt-4">
          <FooterMadeWithBharat />
        </div>
        <hr className="footer-hr my-4 " />
        <p className="mobile-all-rights-reserved ">
          {`All rights reserved. Copyright© ${currentYear}`}
        </p>
      </div>

      {/* ------------Desktop Footer--------- */}
      <div className="">
        <div className=" show-desktop-footer ">
          <div className=" footer-background   ">
            <FooterMadeWithBharat />
          </div>
          <div className=" footer-links-wrapper-parent ">
            <div className="footer-links-wrapper">
              {footerLinksData.map((item) => {
                return (
                  <div key={item.id}>
                    <p className="footer-links-heading ">{item.heading}</p>
                    {item.children.map((child) => {
                      return (
                        <p key={child.id} className="footer-links-p">
                          <Link
                            className="footer-links"
                            to={
                              //either env is prod OR beta env Link does not exist
                              process.env.REACT_APP_ENV === "prod" ||
                              !child.betaLink
                                ? child.link
                                : child.betaLink
                            }
                            target={child.openLinkInNewTab ? "_blank" : "_self"}
                          >
                            {child.heading}
                          </Link>
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <p className="footer-copyright-text text-sm text-heading">
              {`All rights reserved. Copyright© ${currentYear}`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewFooter;
