import SockJS from "sockjs-client";
import {
  MICRO_API_URL_NOTIFICATION,
  MICRO_API_URL_PROJECT,
  MICRO_API_URL_REPORT,
  MICRO_API_URL_S3,
  MICRO_API_URL_STANDUP,
  MICRO_API_URL_TESTEXECUTION,
  MICRO_API_URL_TESTPAD,
  MICRO_API_URL_USER,
} from "../config/API_URLS";

class SocketService {
  createSocketConnection(connectionManager) {
    let baseUrl = "";
    switch (connectionManager) {
      case "execution":
        baseUrl = MICRO_API_URL_TESTEXECUTION.baseURL;
        break;
      case "testpad":
        baseUrl = MICRO_API_URL_TESTPAD.baseURL;
        break;
      case "user":
        baseUrl = MICRO_API_URL_USER.baseURL;
        break;
      case "project":
        baseUrl = MICRO_API_URL_PROJECT.baseURL;
        break;
      case "standup":
        baseUrl = MICRO_API_URL_STANDUP.baseURL;
        break;
      case "notification":
        baseUrl = MICRO_API_URL_NOTIFICATION.baseURL;
        break;
      case "report":
        baseUrl = MICRO_API_URL_REPORT.baseURL;
        break;
      default:
        break;
    }
    return new SockJS(baseUrl + "ws", null, {
      headers: { Authorization: `Bearer ${localStorage.getItem("usertoken")}` },
    });
  }
}

export default new SocketService();
