import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./FullHealthReport.css";
const FullHealthReport = () => {
  const [id, setId] = useState(null);
  const [p_id, setP_id] = useState(null);
  const [isHealthExe, setIsHealthExe] = useState(false);
  const [filters, setFilters] = useState(null);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setId(searchParams.get("h_run_id"));
    setFilters(searchParams.get("filters"));
    setP_id(searchParams.get("p_id"));
  }, [id]);

  //   const connect = () => {
  //     let Sock = httpService.createSocket("ws");
  //     stompClient = over(Sock);
  //     stompClient.debug = function () { };

  //     stompClient.connect(
  //       { Authorization: `Bearer ${localStorage.getItem("usertoken")}` },
  //       onConnected,
  //       onError
  //     );
  //   };
  //   const notifyClient = (e) => {
  //     if (e["body"]) {
  //       let ifRefresh = JSON.parse(e["body"]);
  //       if (ifRefresh[props.s_run_id]) {
  //         httpService.getExtentReportData(props.s_run_id).then((data) => {
  //           getErrorMessage(data);
  //           setExtentData(() => data);
  //           setSuiteStatus(() =>
  //             data["data"]["status"] ? data["data"]["status"] : null
  //           );
  //         });
  //       }
  //     }
  //   };
  //   const onError = (err) => { };

  //   const onConnected = (e) => {
  //     stompClient.subscribe("/user/" + props.p_id + "/private", notifyClient, {
  //       Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
  //     });
  //   };

  const createHealthCards = () => {
    return (
      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-6 col-12 d-flex align-items-stretch">
          <div className="execution-status healthCards">
            {isHealthExe ? (
              <>
                <div className="healthCardsHeading mt-3 ms-3">
                  Next Execution
                </div>
              </>
            ) : (
              <>
                <div className="d-flex">
                  <div className="currentExe mt-3 ms-3">
                    <span className="px-4 py-1">EXE</span>
                  </div>
                  <div className="expectedExeStatus mt-3 me-3 ms-auto pass-btn-health">
                    <span className="px-2 py-1">Might exp</span>
                  </div>
                </div>
                <div className="nextExeTime">
                  <div className="exeTime"></div>
                </div>
                <div className="d-flex completion-timeData">
                  <div className="completion-time ms-3">
                    <span className="title">Completing in</span>
                  </div>
                  <div className="completion-time ms-auto me-3">
                    <span className="data">x min xx sec</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-12 d-flex align-items-stretch">
          <div className="prevSuite-status healthCards">
            <div className="d-flex">
              <div className="healthCardsHeading mt-3 ms-3">
                Status (Prev Suite)
              </div>
              <div className="previousExeStatus mt-3 me-3 ms-auto pass-btn-health">
                <span className="px-2 py-1">PASS</span>
              </div>
            </div>
            <div className="prevExeInfo row align-items-center text-center pe-2">
              <div className="col-md-7 col-12">Graph</div>
              <div className="col-md-5 col-12 prevRunData">
                <div className="prevTime">9: 43 : 44 PM IST</div>
                <div className="prevTimeHeading mt-2">Execution Time</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-12 d-flex align-items-stretch">
          <div className="project-details healthCards">
            <div className="healthCardsHeading mt-3 ms-3">Project Details:</div>
            <div className="row align-items-center">
              <div className="col-12">
                <div className="projectDetails my-4 ms-3">
                  Name : <span className="pDetailsData ms-2">Project Name</span>
                </div>
                <div className="projectDetails my-4 ms-3">
                  Report :{" "}
                  <span className="pDetailsData ms-2">Report Name</span>
                </div>
                <div className="projectDetails my-4 ms-3">
                  Env : <span className="pDetailsData ms-2">Env</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-12 d-flex align-items-stretch">
          <div className="last5runs-status healthCards">
            <div className="healthCardsHeading mt-3 ms-3">
              Status (Prev Suite)
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {/* <div className="container"> */}
      {id ? (
        <div className="healthCheck">
          <div className="mx-3 pt-2">
            <ul
              className="nav nav-pills mb-3 mt-4 ms-3"
              id={"pills-tab-" + id}
              role="tablist"
            >
              <li
                className="nav-item health-itemOverview mr-1"
                role="presentation"
              >
                <div
                  className="fontSizeGeneral nav-link healthPill active healthPillOverview"
                  id={"pills-overview-health-tab-" + id}
                  data-toggle="pill"
                  data-target={"#pills-overview-health-" + id}
                  type="button"
                  role="tab"
                  aria-selected="true"
                >
                  Overview
                </div>
              </li>
              <li className="nav-item health-itemTimeline" role="presentation">
                <div
                  className="fontSizeGeneral nav-link healthPill healthPillTimeline"
                  id={"pills-timeline-tab-" + id}
                  data-toggle="pill"
                  data-target={"#pills-timeline-" + id}
                  type="button"
                  role="tab"
                  aria-selected="false"
                >
                  Timeline
                </div>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id={"pills-overview-health-" + id}
                role="tabpanel"
                aria-labelledby={"pills-overview-health-tab-" + id}
              >
                <div className="mx-4 pt-2">
                  {" "}
                  <div className="health-data">{createHealthCards()}</div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id={"pills-timeline-" + id}
                role="tabpanel"
                aria-labelledby={"pills-timeline-tab-" + id}
              >
                <div>Timeline</div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* </div> */}
    </>
  );
};

export default FullHealthReport;
