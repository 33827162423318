import React from "react";

const ExecutionInfo = ({
  projectTitle,
  projectName,
  env,
  status,
  className,
  isSecure,
}) => {
  return (
    <div className="extentCard mt-4">
      <div className="row d-flex align-items-center fontSizeGeneral">
        <div className="col-md-4 col-sm-12 col-12 my-1">
          <span className={`execution-headers ${isSecure && className}`}>
            {projectTitle}
          </span>
          <div className={`project-name mt-2 ${isSecure && className}`}>
            {projectName}
          </div>
        </div>
        {env ? (
          <div className="col-md-4 col-sm-12 col-12 my-1">
            <span className="execution-headers">Environment: </span>
            {isSecure && <div className="my-2" />}
            <span className="report-env">{env}</span>
          </div>
        ) : null}
        {status ? (
          <div
            className={`col-md-4 col-sm-12 col-12 my-1 ${
              !isSecure && `d-flex`
            }`}
          >
            <div className="execution-headers align-items-center mb-2 ms-2">Status:</div>
            <span
              className={`${status.toLowerCase()}-btn-health report-status align-items-center`}
            >
              {status}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ExecutionInfo;