import React from "react";
import "./Profile.css";
import UserAvatar from "./UserAvatar";

const UserAbstract = ({ userData }) => {
  return (
    <div className="container-fluid">
      <div className="d-flex abstractCard mx-2 mx-lg-4 my-1 my-lg-3 rounded-3 px-lg-5 px-3 py-lg-4 py-2 align-items-center">
        <div className="mx-lg-5 mx-3 my-lg-2 my-1">
          <UserAvatar userData={userData} />
        </div>
        <div className="mx-lg-4 mx-1">
          <p className="fs-2 m-0">{`${userData?.firstname} ${userData?.lastname}`}</p>
          <div className="nameLine mt-1 mb-3"></div>
          <p className="my-3">{userData?.email}</p>
          <p className="">{userData?.company}</p>
        </div>
      </div>
    </div>
  );
};

export default UserAbstract;
