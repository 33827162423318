import { DataTable } from "primereact/datatable";
import "./Timeline.css";
import {
  convertToMMDDYYYY,
  createColumns,
  createFilters,
  getErrorMessage,
} from "../../Helper/Helper";
import { useEffect } from "react";
import httpService from "../../../services/http.service";
import { toast } from "react-toastify";
import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { useRef } from "react";
import SkeletonUtility from "../../UI/Skeleton/SkeletonUtility";
import NoAccess from "../../UI/NoAccess/NoAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import { InputText } from "primereact/inputtext";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import NoData from "../../UI/NoData/NoData";

const Timeline = ({ s_run_id, p_id, setS_Id }) => {
  const [timeLineData, setTimelineData] = useState(null);
  const [timelineFilters, setTimelineFilters] = useState({});
  const [selectedDateRange, setSelectedDateRange] = useState("last7days");
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTokenValue, setSearchTokenValue] = useState(null);
  const [debouncedSearchTokenValue, setDebouncedSearchTokenValue] =
    useState(null);
  const [getDataApiIsLoading, setGetDataAPiIsLoading] = useState(false);
  const startDate = useRef(
    convertToMMDDYYYY(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000))
  );
  const endDate = useRef(convertToMMDDYYYY(new Date()));
  const [checked, setChecked] = useState(false);
  const firstUpdate = useRef(true);
  const firstUpdateSearch = useRef(true);
  const options = [
    { label: "Last 24 Hours", value: "last24hours" },
    { label: "Last 7 Days", value: "last7days" },
    { label: "Last 30 Days", value: "last30days" },
  ];

  // get data if table header values are changed
  useEffect(() => {
    getData();
  }, [debouncedSearchTokenValue, checked, selectedDateRange]);

  useEffect(() => {
    const filterData = timeLineData?.headers
      ? createFilters(timeLineData["headers"])
      : {};

    setTimelineFilters(filterData);
  }, [timeLineData]);

  // debounce
  useEffect(() => {
    var timeout = setTimeout(() => {
      setDebouncedSearchTokenValue(searchTokenValue);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchTokenValue]);

  const getData = async () => {
    let payload = {
      s_run_id: s_run_id,
      start_time: startDate.current,
      end_time: endDate.current,
    };

    setTimelineData(null);
    setGetDataAPiIsLoading(true);
    await httpService
      .getSuiteTimeline(payload, checked, searchTokenValue)
      .then((data) => {
        getErrorMessage(data);
        setTimelineData(data?.["data"]);
        setS_Id(data?.data?.s_id);
      })
      .catch((err) => {
        if (
          err?.["response"]?.["data"]?.["operation"]?.toLowerCase() ===
          "failure"
        ) {
          setErrorLoading(true);
          setErrorMessage(
            err?.["response"]?.["data"]?.["message"]
              ? err?.["response"]?.["data"]?.["message"]
              : "Something went wrong!"
          );
          return;
        }
        handleToastErrorCatch(err, () => setGetDataAPiIsLoading(false));
      });
  };

  const setDatePayload = (val) => {
    switch (val.toLowerCase()) {
      case "last24hours":
        startDate.current = convertToMMDDYYYY(
          new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
        );

        endDate.current = convertToMMDDYYYY(new Date());
        break;

      case "last7days":
        startDate.current = convertToMMDDYYYY(
          new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
        );
        endDate.current = convertToMMDDYYYY(new Date());
        break;

      case "last30days":
        startDate.current = convertToMMDDYYYY(
          new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
        );
        endDate.current = convertToMMDDYYYY(new Date());
        break;

      default:
        return;
    }
    setSelectedDateRange(val);
  };

  const timelineHeader = () => {
    return (
      <div className="timelineHeaderDiv">
        <div className="d-flex">
          <div className="ms-2 d-flex align-items-center">
            <div className="d-flex justify-content-start w-100">
              <div className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  value={searchTokenValue}
                  onChange={(e) => {
                    e?.preventDefault();
                    setSearchTokenValue(e.target.value);
                  }}
                  placeholder="Search by Token User"
                  className="reqFormInputs"
                />
              </div>
            </div>
          </div>
          <div className="d-flex ms-auto">
            <div className="input-switch-execution me-4">
              All{" "}
              <InputSwitch
                checked={checked}
                onChange={(e) => setChecked(e.value)}
                style={{ transform: "scale(0.7)" }}
              />
              Criteria{" "}
            </div>
            <Dropdown
              className="timelineDialog"
              style={{ minWidth: "18vw", maxWidth: "18vw" }}
              options={options}
              value={selectedDateRange}
              onChange={(e) => setDatePayload(e.value)}
              placeholder="Select Date Range"
            />
          </div>
        </div>
      </div>
    );
  };

  const openReport = (val) => {
    return (
      <div>
        <label title="Open in New Window">
          <div
            className="btn-sm btn-success me-2 reportIcons"
            onClick={() => {
              val
                ? window.open(
                    window.location.origin +
                      "/#/autolytics/execution-report?s_run_id=" +
                      val +
                      "&p_id=" +
                      p_id,
                    "_blank"
                  )
                : toast.error("No Report found !", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: "mt-5",
                  });
            }}
          >
            <FontAwesomeIcon
              className="mx-2 tabIcon"
              icon={faArrowUpRightFromSquare}
            />
          </div>
        </label>
      </div>
    );
  };
  const createTimelineTC = (val) => {
    let value = val?.props?.val ? val.props.val : null;
    if (!value) {
      return "-";
    }

    const data = Object.keys(value).map((key) => (
      <div>
        {(value[key] !== 0 && key.toLowerCase() !== "total") ||
        key.toLowerCase() === "pass" ||
        key.toLowerCase() === "fail" ? (
          <>
            <div className="row d-flex my-1">
              <div className="col-8 badgeStatusTimelineCol pe-0">
                <span
                  className={`me-2 badgeStatusTimeline ${key.toLowerCase()}-btn`}
                ></span>
                <span className="tcKey">
                  {key.at(0).toUpperCase() +
                    key.substring(1, key.length).toLowerCase()}
                </span>
              </div>
              <div className="col-4 badgeStatusTimelineCol tcValue">
                {value?.[key]}
              </div>
            </div>
          </>
        ) : null}
      </div>
    ));

    return data;
  };
  return (
    <>
      {errorLoading ? (
        <NoAccess errorMessage={errorMessage} />
      ) : (
        <div className="my-3 p-2">
          <DataTable
            header={timelineHeader}
            reorderableColumns
            onFilter={(e) => {
              setTimelineFilters(e.filters);
            }}
            globalFilterFields={"Token User"}
            resizableColumns
            stripedRows
            columnResizeMode="expand"
            value={timeLineData ? timeLineData["data"] : null}
            paginator
            rows={5}
            dataKey="id"
            filters={timelineFilters}
            rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            scrollHeight={"65vh"}
            filterDisplay="menu"
            responsiveLayout="scroll"
            emptyMessage={<NoData />}
            currentPageReportTemplate="Total {totalRecords} Record(s) Found"
            removableSort
            className="test-stepTable borderedRows"
          >
            {timeLineData &&
              createColumns(
                timeLineData?.["headers"],
                true,
                true,
                timeLineData?.["data"],
                true,
                false,
                { customAction: openReport },
                { timeline_tc: true },
                {
                  timeline_tc: createTimelineTC,
                }
              )}
          </DataTable>
        </div>
      )}
    </>
  );
};

export default Timeline;
