import React from "react";
import Header from "../../UI/Header/Header";
import Footer from "../../UI/Footer/Footer";
import ComingSoonMain from "../ComingSoon/ComingSoonMain";
import NewFooter from "../../UI/NewFooter/NewFooter";

const NewComingSoonPage = () => {
  return (
    <>
      <Header />
      <section className="heroSoon">
        <ComingSoonMain />
      </section>
      <NewFooter />
    </>
  );
};

export default NewComingSoonPage;
