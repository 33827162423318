import React from "react";
import { workMinutesUserCapabilitiesCardData } from "../../../Helper/MockData/WorkMinutesPageMockData";
import WorkMinutesUserCapabilitiesCard from "../../../UI/UserCapabilitiesCard/UserCapabilitiesCard";
import "./WorkMinutesUserCapabilities.css";

const WorkMinutesUserCapabilities = () => {
  return (
    <div className="work-minutes-user-capabilities-background">
      <div className="max-w-1440 component-padding">
        <p className="component-heading">User Capabilities</p>
        <div className="work-minutes-user-capabilities-cards-wrapper">
          {workMinutesUserCapabilitiesCardData.map((cardData) => {
            return (
              <React.Fragment key={cardData.heading}>
                <WorkMinutesUserCapabilitiesCard
                  heading={cardData.heading}
                  icon={cardData.icon}
                  description={cardData.description}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WorkMinutesUserCapabilities;
