import React, { useRef, useState } from "react";
import ExecutionInfo from "./ExecutionInfo";
import { ConvertTexttoChart, createColumns } from "../../../Helper/Helper";
import { DataTable } from "primereact/datatable";
import NoData from "../../../UI/NoData/NoData";
import { Skeleton } from "primereact/skeleton";
import OverviewSkeleton from "./OveriewSkeleton";
import "../Extent.css";
import { Splitter, SplitterPanel } from "primereact/splitter";
import SastInstance from "./SastInstance";

const Sast = ({
  SecureData,
  sastData,
  mergedSastData,
  filters,
  setFilters,
  tableref,
  props,
  sastDataLoading
}) => {
  const [openSastSpiltter, setOpenSastSplitter] = useState(false);
  const [sidePanelData, setSidePanelData] = useState(null);

  const reportRef = useRef();

  const customAction = (val, rowData) => {
    return <div>{val.length}</div>;
  };

  const crudInstance = ({ props }) => {
    return (
      <>
        <div
          className="instanceText"
          onClick={() => {
            setOpenSastSplitter(true);
            setSidePanelData(props?.rowData);
          }}
        >
          {props?.val}
        </div>
      </>
    );
  };

  const mainPage = () => {
    const final = (
      <>
        {!openSastSpiltter && (
          <>
            <div className="mt-2">
              <ExecutionInfo
                projectTitle="Project"
                projectName={SecureData?.data?.projectName}
                env={SecureData?.data?.env}
                status={SecureData?.data?.status}
                isSecure={true}
                className="fw-bold"
              />
            </div>
            <div className="p-3 mb-4">
              <div className="d-flex row justify-content-between">
                <div className="extentCard justify-content-start  me-2 card my-2 col-lg-6 col-md-6 col-sm-12 col-12 ">
                  <div className="extentHeading">
                    Overall Vulnerability Distribution
                  </div>
                  <div className="row d-flex">
                    <div className="col-md-6 my-auto">
                      {ConvertTexttoChart(
                        sastData?.data?.vulnerabilityDistribution?.data,
                        "doughnut_chart",
                        false,
                        null
                      )}
                    </div>
                    <div className="col-md-6">
                      <table className="doughnut-legend d-flex flex-column align-items-center justify-content-center">
                        {sastData?.data?.vulnerabilityDistribution?.data?.labels?.map(
                          (label, index) => {
                            return (
                              <tr
                                key={index}
                                className="w-100 py-2 px-3 legend"
                              >
                                <td>
                                  <span
                                    className={`secure-${label.toLowerCase()}-btn doughnut-legend-span`}
                                  />
                                </td>
                                <td className="w-50 legend-label">
                                  {label}
                                </td>
                                <td className="w-50 text-end doughnut-legend-val">
                                  {
                                    sastData?.data
                                      ?.vulnerabilityDistribution?.data
                                      ?.datasets?.[0]?.data?.[index]
                                  }
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                {sastData ? (
                  <div className="extentCard  mx-2 card my-2 col-lg-5 col-md-6 col-sm-12 col-12 ">
                    {ConvertTexttoChart(
                      sastData?.data?.timelineGraph?.value,
                      "stacked_bar_chart",
                      false,
                      sastData?.data?.timelineGraph?.heading,
                      "",
                      "",
                      "",
                      "",
                      true
                    )}
                  </div>
                ) : (
                  <div className="extentCard  mx-2 card my-2 col-lg-5 col-md-6 col-sm-12 col-12">
                    <div className="text-left extentHeading d-flex justify-content-between align-items-center fontSizeGeneral lexendFont extentHeading mb-3">
                      Timeline Graph
                    </div>
                    <Skeleton width="100%" height="10rem"></Skeleton>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <DataTable
          reorderableColumns
          resizableColumns
          header={
            <>
              <div className="headingFont fs-6">Security SAST Data</div>
            </>
          }
          ref={tableref}
          scrollHeight={props.shared ? "67vh" : "60vh"}
          cellClassName="dataTable"
          // rowClassName={"dataTable-row dataTable"}
          currentPageReportTemplate="Total {totalRecords} Records Found"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
          rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
          columnResizeMode="expand"
          removableSort
          value={mergedSastData}
          paginator
          rows={25}
          // dataKey="id"
          filterDisplay="menu"
          loading={sastData ? false : true}
          responsiveLayout="scroll"
          emptyMessage={<NoData />}
          filters={filters}
          onFilter={(e) => {
            setFilters(e.filters);
          }} // showGridlines
          className="test-stepTable"
        >
          {sastData?.data && sastData?.data?.headers ? (
            createColumns(
              sastData?.data?.headers,
              true,
              true,
              mergedSastData,
              true,
              null,
              {
                customAction: customAction,
              },
              {
                vulnerability: true,
              },
              {
                vulnerability: crudInstance,
              },
            )
          ) : (
            <></>
          )}
        </DataTable>
      </>
    );
    return final;
  };

  return (
    <>
      {sastData && mergedSastData ? (
        <>
          <Splitter
            style={{
              height: "100% !important",
              // width: "100vh - calc(10vh + 10vw)",
              border: "none",
              background: "transparent",
            }}
            gutterSize={openSastSpiltter ? 4 : 0}
          >
            <SplitterPanel className="parent" size={50}>
              <div className="child px-4">{mainPage()}</div>
            </SplitterPanel>

            <SplitterPanel
              style={{ display: openSastSpiltter ? "inherit" : "none" }}
              className="secondparent"
            >
              <div className="secondchild px-2" ref={reportRef}>
                <SastInstance
                  setOpenSastSplitter={setOpenSastSplitter}
                  data={sidePanelData}
                />
              </div>
            </SplitterPanel>
          </Splitter>
        </>
      ) : sastDataLoading ? (
        <OverviewSkeleton />
      ) : (
        <NoData />
      )}
    </>
  );
};

export default Sast;
