import { Button } from "primereact/button";
const CustomDialogFooter = ({
  onSubmit,
  onClose,
  isBtnLoading,
  action,
  isSubmitDisabled,
  onlyCloseBtn,
}) => {
  return (
    <div className="custom-dialog-footer">
      <Button
        label="Cancel"
        className="mx-1 py-2"
        style={{ backgroundColor: "#FFF", color: "#404040" }}
        onClick={onClose}
        loadingIcon={"pi pi-spin pi-spinner me-2"}
        iconPos="right"
      />
      {!onlyCloseBtn && (
        <Button
          label={
            action === "edit"
              ? "Update"
              : action === "create"
              ? "Submit"
              : "Save Changes"
          }
          className="btn-success themeBtn mx-1 py-2"
          onClick={onSubmit}
          loading={isBtnLoading}
          disabled={isSubmitDisabled}
          loadingIcon={"pi pi-spin pi-spinner me-2"}
          iconPos="right"
        />
      )}
    </div>
  );
};

export default CustomDialogFooter;
