import { useSearchParams } from "react-router-dom";
import ManualTestcasesExe from "../../Layouts/ManualTestcasesExe/ManualTestcasesExe";
import { useEffect } from "react";
import { logPageTitleForGA } from "../../Helper/Helper";

const ManualTestcasesExePage = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let title = "Jewel | Manual Execution : " + searchParams.get("s_run_id");
    document.title = title;
    logPageTitleForGA(title);
  }, [])
  return (
    <>

      <div className="container-fluid">
        <ManualTestcasesExe
          s_run_id={searchParams.get("s_run_id")}
          tc_run_id={searchParams.get("tc_run_id")}
          s_id={searchParams.get("s_id")}
          p_id={searchParams.get("p_id")}
        />
      </div>

    </>
  );
};

export default ManualTestcasesExePage;
