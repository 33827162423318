import { convertTexttoStatus, getToastMessage } from "../../Helper/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import VarianceActive from "../../../assets/VarianceActive.svg";
import FalsePositiveActive from "../../../assets/FalsePositiveActive.svg";
import VarianceInactive from "../../../assets/VarianceInactive.svg";
import FalsePositiveInactive from "../../../assets/FalsePositiveInactive.svg";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import httpService from "../../../services/http.service";
import { toast } from "react-toastify";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { Button } from "primereact/button";

const FailedSuiteEdit = ({ props }) => {
  const [showTable, setShowTable] = useState(false);
  const [classification, setClassification] = useState("false+");
  const [reasonFalsePositive, setReasonFalsePositive] = useState("");
  const [reasonVariance, setReasonVariance] = useState("");
  const [name, setName] = useState(
    props.level === "testcase"
      ? props?.rowData["NAME"]?.value
      : props?.rowData["STEP NAME"]?.value
  );
  const [matchType, setMatchType] = useState("Exact");
  const [endDate, setEndDate] = useState(null);
  const [saveChanges, setSaveChanges] = useState(false);
  const varianceId = useRef(null);
  const [falsePositiveDisabled, setFalsePositiveDisabled] = useState(false);
  const [varianceDisabled, setVarianceDisabled] = useState(false);
  const [
    isVarianceFalsePositiveButtonLoading,
    setIsVarianceFalsePositiveButtonLoading,
  ] = useState(false);

  const checkFields = () => {
    if (
      (classification === "false+" && reasonFalsePositive.length > 0) ||
      (classification === "variance" &&
        reasonVariance.length > 0 &&
        name.length > 0 &&
        endDate !== null)
    ) {
      return true;
    }

    return false;
  };

  const handleReset = (e) => {
    if (classification === "false+") {
      let payload = {};
      switch (props.level) {
        case "testcase":
          payload["tc_run_id"] = props?.rowData["TC_RUN_ID"]?.value;
          payload["category"] = "testcase";
          payload["reason"] =
            classification.toLowerCase() === "false+"
              ? reasonFalsePositive
              : reasonVariance;
          payload["classification_at"] = new Date().getTime();
          break;
        case "teststep":
          payload["classification_at"] = new Date().getTime();
          payload["tc_run_id"] = props?.tc_run_id;
          payload["category"] = "step";
          payload["name"] = props?.rowData["STEP NAME"]?.value;
          payload["stepId"] = props?.rowData["ID"]?.value;
          break;
        default:
          break;
      }
      setIsVarianceFalsePositiveButtonLoading(true);
      httpService
        .falsePositiveReset(payload)
        .then((data) => {
          getToastMessage(data);
          if (
            data["operation"] &&
            data["operation"].toLowerCase() === "success"
          ) {
            setShowTable(false);
            varianceId.current = null;
            setSaveChanges(false);
            setFalsePositiveDisabled(false);
            setVarianceDisabled(false);
            setClassification("false+");
            setName(
              props.level === "testcase"
                ? props?.rowData["NAME"]?.value
                : props?.rowData["STEP NAME"]?.value
            );
            setReasonFalsePositive("");
            setReasonVariance("");
            setEndDate(null);
            setMatchType("Exact");
          }
          setIsVarianceFalsePositiveButtonLoading(false);
        })
        .catch((err) => {
          handleToastErrorCatch(err, () =>
            setIsVarianceFalsePositiveButtonLoading(false)
          );
        });
      return;
    }
    setIsVarianceFalsePositiveButtonLoading(true);
    httpService
      .varianceReset(varianceId.current)
      .then((data) => {
        getToastMessage(data);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          setShowTable(false);
          setVarianceDisabled(false);
          setFalsePositiveDisabled(false);
          varianceId.current = null;
          setSaveChanges(false);
          setClassification("false+");
          setName(
            props.level === "testcase"
              ? props?.rowData["NAME"]?.value
              : props?.rowData["STEP NAME"]?.value
          );
          setReasonFalsePositive("");
          setReasonVariance("");
          setEndDate(null);
          setMatchType("Exact");
        }
        setIsVarianceFalsePositiveButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () =>
          setIsVarianceFalsePositiveButtonLoading(false)
        );
      });
  };

  const handleSubmit = (e) => {
    if (checkFields()) {
      if (classification === "false+") {
        let payload = {
          reason: reasonFalsePositive,
          classification_at: new Date().getTime(),
        };
        switch (props.level) {
          case "testcase":
            payload["tc_run_id"] = props?.rowData?.["TC_RUN_ID"]?.value;
            payload["category"] = "testcase";
            break;
          case "teststep":
            payload["tc_run_id"] = props?.tc_run_id;
            payload["category"] = "step";
            payload["name"] = props?.rowData["STEP NAME"]?.value;
            payload["stepId"] = props?.rowData["ID"]?.value;
            break;
          default:
            break;
        }
        setIsVarianceFalsePositiveButtonLoading(true);
        httpService
          .falsePositiveUpdate(payload)
          .then((data) => {
            getToastMessage(data);
            if (
              data["operation"] &&
              data["operation"].toLowerCase() === "success"
            ) {
              setShowTable(false);
              setVarianceDisabled(false);
              setFalsePositiveDisabled(false);
              varianceId.current = null;
              setSaveChanges(false);
              setClassification("false+");
              setName(
                props.level === "testcase"
                  ? props?.rowData["NAME"]?.value
                  : props?.rowData["STEP NAME"]?.value
              );
              setReasonFalsePositive("");
              setReasonVariance("");
              setEndDate(null);
              setMatchType("Exact");
            }
            setIsVarianceFalsePositiveButtonLoading(false);
          })
          .catch((err) => {
            handleToastErrorCatch(err, () =>
              setIsVarianceFalsePositiveButtonLoading(false)
            );
          });
      } else {
        let payload = {
          reason: reasonVariance,
          startTime: new Date().getTime(),
          name: name,
          match: matchType,
          tc_run_id:
            props?.level === "testcase"
              ? props?.rowData?.["TC_RUN_ID"]?.value
              : props?.tc_run_id,
          endDate: endDate.setHours(23, 59, 59, 999),
          category: props.level === "testcase" ? "test" : "step",
        };
        setIsVarianceFalsePositiveButtonLoading(true);
        httpService
          .varianceUpdate(payload)
          .then((data) => {
            getToastMessage(data);
            if (
              data["operation"] &&
              data["operation"].toLowerCase() === "success"
            ) {
              setShowTable(false);
              setVarianceDisabled(false);
              setFalsePositiveDisabled(false);
              setSaveChanges(false);
              varianceId.current = null;
              setClassification("false+");
              setName(
                props?.level === "testcase"
                  ? props?.rowData?.["NAME"]?.value
                  : props?.rowData?.["STEP NAME"]?.value
              );
              setReasonFalsePositive("");
              setReasonVariance("");
              setEndDate(null);
              setMatchType("Exact");
            }
            setIsVarianceFalsePositiveButtonLoading(false);
          })
          .catch((err) => {
            handleToastErrorCatch(err, () =>
              setIsVarianceFalsePositiveButtonLoading(false)
            );
          });
      }
    } else {
      toast.error("Please fill all mandatory fields!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "mt-5",
      });
    }
  };

  const getVarianceActiveDetails = (e, id) => {
    httpService
      .getVarianceInfo(id)
      .then((data) => {
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          setFalsePositiveDisabled(true);
          setShowTable(true);
          setSaveChanges(true);
          varianceId.current = id;
          setClassification("variance");
          setName(data?.["data"]?.["name"]);
          setReasonFalsePositive("");
          setReasonVariance(data?.["data"]?.["reason"]);
          setEndDate(new Date(data?.["data"]?.["endDate"]));
          setMatchType(data?.["data"]?.["match"]);
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const showIcon = (value) => {
    switch (value) {
      case "false_positive_active":
        return (
          <span
            className="fontSizeGeneral ms-1"
            title={
              props.level !== "suite" && props?.rowData?.["ISCLICKABLE"]?.value
                ? "Click to reset False+ "
                : ""
            }
          >
            <img
              alt="falsePositiveActive"
              src={FalsePositiveActive}
              onClick={(e) =>
                props.level !== "suite" &&
                props?.rowData?.["ISCLICKABLE"]?.value
                  ? (e?.preventDefault(),
                    setClassification("false+"),
                    setName(
                      props.level === "testcase"
                        ? props?.rowData?.["NAME"]?.value
                        : props?.rowData?.["STEP NAME"]?.value
                    ),
                    (varianceId.current =
                      props?.rowData?.["VARIANCEID"]?.value),
                    setReasonFalsePositive(
                      props?.rowData?.["REASON"]?.value
                        ? props?.rowData?.["REASON"]?.value
                        : ""
                    ),
                    setReasonVariance(""),
                    setEndDate(null),
                    setMatchType("Exact"),
                    setVarianceDisabled(true),
                    setShowTable(true),
                    setSaveChanges(true))
                  : null
              }
            />
          </span>
        );

      case "false_positive_inactive":
        return (
          <span className="fontSizeGeneral ms-1" title={"False+ Inactivated"}>
            <img alt="falsePositiveInactive" src={FalsePositiveInactive} />
          </span>
        );

      case "variance_active":
        return (
          <span
            className="fontSizeGeneral ms-1"
            title={
              props.level !== "suite" && props?.rowData?.["ISCLICKABLE"]?.value
                ? "Click to reset Variance"
                : ""
            }
          >
            <img
              alt="varianceActive"
              src={VarianceActive}
              onClick={(e) =>
                props.level !== "suite" &&
                props?.rowData?.["ISCLICKABLE"]?.value
                  ? (e?.preventDefault(),
                    (varianceId.current =
                      props?.rowData?.["VARIANCEID"]?.value),
                    getVarianceActiveDetails(e, varianceId.current))
                  : null
              }
            />
          </span>
        );

      case "variance_inactive":
        return (
          <span
            className="fontSizeGeneral ms-1"
            title={props.level !== "suite" ? "Variance Inactivated" : ""}
          >
            <img alt="varianceInactive" src={VarianceInactive} />
          </span>
        );

      default:
        break;
    }
  };
  return (
    <div
      className={`d-flex align-items-center ${
        props.level === "teststep" ? "justify-content-end" : ""
      }`}
    >
      {convertTexttoStatus(props.val)}
      <span className="">
        {props?.level !== "suite" &&
        props?.rowData?.["EDIT_ICON"]?.value.toLowerCase() === "active" ? (
          <div
            className={`ms-2`}
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              setName(
                props?.level === "testcase"
                  ? props?.rowData?.["NAME"]?.value
                  : props?.rowData?.["STEP NAME"]?.value
              );
              setFalsePositiveDisabled(false);
              setVarianceDisabled(false);
              setShowTable(true);
            }}
          >
            <FontAwesomeIcon
              className="actionBtn"
              icon={faPencil}
              title="Update (False+/Variance)"
            />
          </div>
        ) : null}
      </span>
      {props?.rowData?.["ICON"] && (
        <div>{showIcon(props?.rowData?.["ICON"]?.["value"].toLowerCase())}</div>
      )}
      <Dialog
        header={"Update Status"}
        visible={showTable}
        onHide={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
          setShowTable(false);
          setSaveChanges(false);
          varianceId.current = null;
          setFalsePositiveDisabled(false);
          setVarianceDisabled(false);
          setClassification("false+");
          setName(
            props.level === "testcase"
              ? props?.rowData?.["NAME"]?.value
              : props?.rowData?.["STEP NAME"]?.value
          );
          setReasonFalsePositive("");
          setReasonVariance("");
          setEndDate(null);
          setMatchType("Exact");
        }}
        blockScroll={true}
        draggable={false}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "25vw" }}
      >
        <div>
          <div className="mb-1 " onClick={(e) => e?.stopPropagation()}>
            {classification === "variance" ? (
              <>
                <div>
                  <label
                    className="text-secondary"
                    style={{ marginRight: "10px", fontWeight: "500" }}
                  >
                    Name
                  </label>
                </div>
                <div className="reqFormInputs">
                  <InputText
                    name="nameofCase"
                    value={
                      matchType === "Exact"
                        ? props.level === "testcase"
                          ? props?.rowData["NAME"]?.value
                          : props?.rowData["STEP NAME"]?.value
                        : name
                    }
                    disabled={
                      matchType === "Exact"
                        ? true
                        : false || isVarianceFalsePositiveButtonLoading
                    }
                    className="p-inputtext-sm w-100"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </>
            ) : null}
            <label
              className="mt-3 text-secondary "
              style={{ fontWeight: "500" }}
            >
              Classification<sup className="text-danger"> *</sup>
            </label>
            <div className="d-flex mt-2">
              <div className="mr-4" style={{ marginRight: "30px" }}>
                <input
                  type="radio"
                  className="radio-input"
                  name="name"
                  disabled={
                    falsePositiveDisabled ||
                    isVarianceFalsePositiveButtonLoading
                  }
                  value="false+"
                  checked={classification === "false+"}
                  onChange={(e) => {
                    setClassification("false+");
                  }}
                  style={{
                    accentColor: "#fc6060",
                    marginRight: "10px",
                  }}
                ></input>
                <label htmlFor="false+" className="ml-2">
                  False+
                </label>
              </div>
              {!(props?.rowData?.["PRODUCT TYPE"]?.value === "MANUAL") ? (
                <div>
                  <input
                    type="radio"
                    name="name"
                    disabled={
                      varianceDisabled || isVarianceFalsePositiveButtonLoading
                    }
                    checked={classification === "variance"}
                    value="variance"
                    onChange={(e) => {
                      setClassification("variance");
                    }}
                    style={{ accentColor: "#fc6060", marginRight: "5px" }}
                  ></input>
                  <label htmlFor="variance" className="ml-2">
                    Variance
                  </label>
                </div>
              ) : null}
            </div>
            <div className="mt-3">
              <label className="text-secondary" style={{ fontWeight: "500" }}>
                {classification === "false+"
                  ? "Reason for False + "
                  : "Reason for Variance"}
                <sup className="text-danger">*</sup>
              </label>
              <div>
                <InputTextarea
                  className="reqFormInputs mt-1"
                  rows={3}
                  cols={40}
                  name="reason"
                  value={
                    classification === "false+"
                      ? reasonFalsePositive
                      : reasonVariance
                  }
                  placeholder="Enter a reason"
                  onChange={(e) =>
                    classification === "false+"
                      ? setReasonFalsePositive(e.target.value)
                      : setReasonVariance(e.target.value)
                  }
                  disabled={isVarianceFalsePositiveButtonLoading}
                />
              </div>
            </div>
            {classification === "variance" ? (
              <>
                <label
                  className="mt-3 text-secondary"
                  style={{ fontWeight: "500" }}
                >
                  Variance Match Type
                  <sup className="text-danger"> *</sup>
                </label>
                <div className="d-flex mt-2 ">
                  <div className="mr-4" style={{ marginRight: "30px" }}>
                    <input
                      type="radio"
                      className="radio-input"
                      name="match"
                      value="Like"
                      checked={matchType === "Like"}
                      onChange={(e) => {
                        setMatchType("Like");
                      }}
                      style={{
                        accentColor: "#fc6060",
                        marginRight: "10px",
                      }}
                      disabled={isVarianceFalsePositiveButtonLoading}
                    ></input>
                    <label htmlFor="Like" className="ml-2">
                      Like
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="match"
                      checked={matchType === "Exact"}
                      value="Exact"
                      onChange={(e) => {
                        setMatchType("Exact");
                      }}
                      style={{
                        accentColor: "#fc6060",
                        marginRight: "5px",
                      }}
                      disabled={isVarianceFalsePositiveButtonLoading}
                    ></input>
                    <label htmlFor="Exact" className="ml-2">
                      Exact
                    </label>
                  </div>
                </div>
                <div className="mt-3">
                  <label
                    className="text-secondary"
                    style={{ fontWeight: "500" }}
                  >
                    End Date <sup className="text-danger">*</sup>
                  </label>
                  <span className="w-100 mt-1">
                    <Calendar
                      showButtonBar
                      name="submitDate"
                      showIcon
                      dateFormat="dd/mm/yy"
                      minDate={new Date()}
                      className="w-100 reqFormInputs"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.value);
                      }}
                      placeholder="Select End Date"
                      disabled={isVarianceFalsePositiveButtonLoading}
                    />
                  </span>
                </div>
              </>
            ) : null}
            <div className="d-flex mt-2">
              {!saveChanges ? (
                <Button
                  label="Submit"
                  className="btn-success btn themeBtn mx-1 py-2"
                  onClick={(e) => {
                    e?.preventDefault();
                    handleSubmit(e);
                  }}
                  loadingIcon={"pi pi-spin pi-spinner me-2"}
                  loading={isVarianceFalsePositiveButtonLoading}
                  iconPos="right"
                />
              ) : (
                <Button
                  label="Reset"
                  className="btn-success btn themeBtn mx-1 py-2"
                  onClick={(e) => {
                    e?.preventDefault();
                    handleReset(e);
                  }}
                  loadingIcon={"pi pi-spin pi-spinner me-2"}
                  loading={isVarianceFalsePositiveButtonLoading}
                  iconPos="right"
                />
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FailedSuiteEdit;
