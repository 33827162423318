import "./Footer.css";
import footerlogo from "../../../assets/FooterLogo.svg";
import facebook from "../../../assets/facebook.svg";
import twitter from "../../../assets/twitter.svg";
import instagram from "../../../assets/instagram.svg";
import linkedin from "../../../assets/linkedin.svg";
import { Link } from "react-router-dom";
import httpService from "../../../services/http.service";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="heading-text">
                <img
                  src={footerlogo}
                  alt="LOGO"
                  style={{ marginLeft: "27%" }}
                />
                {/* <span style={{marginLeft:'3%'}}>GemEcosystem</span> */}
              </div>

              {/* <div className="sub-text mb-2" style={{ marginLeft: '22%' }}>Made with ❤️ in India.</div> */}
              <div className="row">
                <div className="d-flex justify-content-center text-center">
                  <a
                    href="https://www.facebook.com/gemecosystem"
                    className="icon_links"
                    target="_blank"
                  >
                    <img src={facebook} />
                  </a>
                  <a
                    href="https://twitter.com/gemecosystem"
                    className="icon_links"
                    target="_blank"
                  >
                    <img src={twitter} />
                  </a>
                  <a
                    href="https://www.instagram.com/gemecosystem/"
                    className="icon_links"
                    target="_blank"
                  >
                    <img src={instagram} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/gemecosystem/"
                    className="icon_links"
                    target="_blank"
                  >
                    <img src={linkedin} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="heading-text2 mt-3">Feature</div>
              <ul className="sub-text list">
                <li>
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Jewel dashboard
                  </Link>
                </li>
                <li>
                  <a
                    href={`${httpService.getGempypBaseUrl()}`}
                    target="_blank"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {" "}
                    GemPYP
                  </a>
                </li>
                {/* <li><a href="https://gemprf.gemecosystem.com" target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>GemPRF</a></li> */}
                <li>
                  <a
                    href={`${httpService.getGemjarBaseUrl()}`}
                    target="_blank"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    GemJAR
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-sm-6 col-12">
              <div className="heading-text2 mt-3">Company</div>
              <ul className="sub-text list">
                {/* <li>
                                <Link to="/howitworks" style={{ textDecoration: 'none', color: 'inherit' }}>How it Works </Link>
                            </li> */}
                <li>
                  <Link
                    to="/pricing"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Pricing{" "}
                  </Link>
                </li>
                {/* <li>
                                <Link to="/whatsnew" style={{ textDecoration: 'none', color: 'inherit' }}>What's New </Link>
                            </li> */}
              </ul>
            </div>

            <div className="col-lg-4 col-sm-6 col-12">
              <div className="heading-text2 mt-3">Send Updates</div>
              <div className="input-group mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your email address"
                  aria-label="Enter your email address"
                  aria-describedby="button-addon2"
                />
                <button
                  className="btn btn-primary"
                  style={{ backgroundColor: "#ff7e7c", border: "#ff7e7c" }}
                  type="button"
                  id="button-addon2"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
