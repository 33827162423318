import axios from "axios";
import authHeader from "../auth-header";
import { MICRO_API_URL_TESTEXECUTION, MICRO_API_URL_TESTPAD } from "../../config/API_URLS";


class MlabService {
    getTestcaseAssignees=async(id)=>{
        return await axios
        .get(
            MICRO_API_URL_TESTPAD.baseURL +
            "folderManagement/v1/test/assignment?folderId="+id,
            
            {
                headers: authHeader(),
            }
        )
        .then((data) => {
            return Promise.resolve(data.data);
        })
        .catch((err) => Promise.reject(err));
    }

    setTestcaseAssignees=async(username,company,pid,payload)=>{
        return await axios
          .patch(MICRO_API_URL_TESTEXECUTION.baseURL + "v1/test/assignment/update",payload, {
            headers: authHeader(),
            params:{username,company,pid}
          })
          .then((data) => {
            return Promise.resolve(data.data ? data.data : []);
          })
          .catch((err) => Promise.reject(err));
    }
}

export default new MlabService();