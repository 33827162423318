import "./Tickets.css";
import { useEffect } from "react";
import { useState } from "react";
import httpService from "../../../services/http.service";
import { getErrorMessage } from "../../Helper/Helper";
import { toast } from "react-toastify";
import NoAccess from "../../UI/NoAccess/NoAccess";
import jiraIcon from "../../../assets/jiraIcon.svg";
import azureIcon from "../../../assets/azureIcon.svg";
import { Skeleton } from "primereact/skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

const Tickets = ({ s_run_id }) => {
  const [ticketData, setTicketData] = useState(null);
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setTicketData(null);

    // let temp = "TEST_PY_BETA_A9DF746EB1AF4A769786FAD5863B4D97";

    httpService
      .getSuiteTickets(s_run_id)
      .then(async (data) => {
        getErrorMessage(data);
        setTicketData(data?.["data"]);
        if (!data?.["data"]) {
          setInfoMessage(data?.["message"]);
        }
      })
      .catch((err) => {
        if (
          err?.["response"]?.["data"]?.["operation"]?.toLowerCase() ===
          "failure"
        ) {
          setErrorLoading(true);
          setErrorMessage(
            err?.["response"]?.["data"]?.["message"]
              ? err["response"]["data"]["message"]
              : "Something went wrong!"
          );
          return;
        }
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
        Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  const getSkeleton = () => {
    const skelCards = Array.from(Array(9).keys()).map((card) => {
      return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-items-center my-2">
          <Skeleton
            width="23rem"
            height="7.5rem"
            className="card propertiesCardSkel"
          ></Skeleton>
        </div>
      );
    });
    return skelCards;
  };

  const createTicketCards = (data) => {
    if (data?.["Azure"].length === 0 && data?.["Jira"].length === 0) {
      return <div>No Tickets Found !</div>;
    }

    let combinedData = [];
    for (const key in data) {
      const values = data[key];

      values.forEach((value) => {
        value["key"] = key;
        combinedData.push(value);
      });
    }

    const cards = combinedData.map((cardData) => (
      <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-items-center my-2">
        <div className="card ticketsCard pb-4 pt-3">
          <div className="d-flex">
            <span className="ms-auto">
              {/* <FontAwesomeIcon
                                className="deleteReq me-3"
                                icon={faArrowUpRightFromSquare}
                                onClick={(e) => {
                                    window.open(
                                        window.location.origin +
                                        '/#/testpad/MLAB?mtc_id=' +
                                        attachmentData["Testcase Id"]["value"],
                                        '_blank'
                                    );
                                }}
                                title="Show Attachment"
                            /> */}
            </span>
          </div>
          <div className="ms-auto d-flex align-items-center">
            <div className="ticket-cardData ticket-key px-3 py-1 d-flex">
              <div className="me-2 d-flex align-items-center">
                <img
                  src={`${
                    cardData?.["key"].toUpperCase() === "JIRA"
                      ? jiraIcon
                      : azureIcon
                  }`}
                  alt="icon"
                />
              </div>
              <div className="d-flex align-items-center">
                {cardData?.["key"].toUpperCase()}
              </div>
            </div>
          </div>
          <div className="mb-2 px-4">
            <span className="boldHeading">Ticket ID : </span>
            <span className="ticket-cardData">{cardData?.["ID"]}</span>
            <span className="ms-3">
              <FontAwesomeIcon
                className="ticket-open"
                icon={faArrowUpRightFromSquare}
                onClick={(e) => {
                  window.open(cardData?.["Link"]);
                }}
                title="Open ticket in new tab"
              />
            </span>
          </div>
          <div className="my-2 px-4">
            <span className="boldHeading">Created For : </span>
            <span className="ticket-cardData">{cardData?.["Type"]}</span>
          </div>
        </div>
      </div>
    ));

    return cards;
  };

  return (
    <>
      {errorLoading ? (
        <NoAccess errorMessage={errorMessage} />
      ) : (
        <>
          {ticketData ? (
            <div className="row d-flex mt-2">
              {createTicketCards(ticketData)}
            </div>
          ) : infoMessage?.length > 0 ? (
            <NoAccess errorMessage={infoMessage} />
          ) : (
            <div className="row d-flex">{getSkeleton()}</div>
          )}
        </>
      )}
    </>
  );
};

export default Tickets;
