import React from "react";
import "./HighlightsAndBenefits.css";
import LandingPageHighlightsVector from "../../../../assets/LandingPageHighlightsVector.svg";

const HighlightsAndBenefits = () => {
  return (
    <div className="highlights-wrapper">
      <div className="max-w-1440 component-padding">
        <p className="component-heading">Highlights and Benefits</p>
        <div className="highlights-text-and-image-wrapper">
          <div className="highlights-text-wrapper">
            <p className="highlights-heading">AI Powered</p>
            <p className="highlights-description">
              Our AI powered analytics engine brings in analytics about test
              execution which helps you to improve quality.
            </p>
            <p className="highlights-heading">Scalability and Flexibility</p>
            <p className="highlights-description">
              Our Centralized test automation tool, scale your testcases to run
              with multiple test data and with dependency and maximum
              parallelization in on demand and scheduled manner and within your
              datacenter or on cloud-hosted resources.
            </p>
            <p className="highlights-heading">Codeless</p>
            <p className="highlights-description">
              Generate multiple test cases without the need for code, providing
              support for business users within the Agile cycle.
            </p>
          </div>
          <img
            className="highlights-image"
            src={LandingPageHighlightsVector}
            alt="2 people looking at charts"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default HighlightsAndBenefits;
