import React, { useEffect, useState } from "react";
import "./TestpadPageScrollAnimationDesktop.css";
import { Link } from "react-router-dom";
import TestpadPageRightArrowIcon from "../../../assets/TestpadPageRightArrowIcon.svg";
import { set } from "react-ga";
import { Dialog } from "primereact/dialog"; // Import the Dialog component from PrimeReact

const TestpadPageScrollAnimationDesktop = ({ cardData }) => {
  const arrayLength = cardData.contentArray.length;
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageDailogIndex, setImageDailogIndex] = useState(0);
  const [isTimerPaused, setIsTimerPaused] = useState(false);
  const [visible, setVisible] = useState(false);

  // used to toggle content every 10 seconds
  useEffect(() => {
    let indexInterval;
    if (!isTimerPaused) {
      indexInterval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % arrayLength);
      }, 10000);
    }
    return () => {
      clearInterval(indexInterval);
    };
  }, [isTimerPaused, arrayLength]);

  const handleImageClick = () => {
    setImageDailogIndex(activeIndex);
    setVisible(true); // Open the dialogue
  };
  return (
    <div className="testpad-scroll-animation-desktop-wrapper">
      <div>
        {cardData.contentArray.map((item, index) => {
          return (
            <div
              key={index}
              style={{ marginBottom: "1.5rem" }}
              onMouseEnter={() =>
                index === activeIndex && setIsTimerPaused(true)
              }
              onMouseLeave={() =>
                index === activeIndex && setIsTimerPaused(false)
              }
            >
              <p
                className={`testpad-card-heading ${
                  index === activeIndex && "testpad-card-heading-active"
                } `}
                onClick={() => setActiveIndex(index)}
              >
                {item.heading}
              </p>
              <div
                className={`description-and-link-wrapper ${
                  index === activeIndex && "description-and-link-wrapper-active"
                }`}
              >
                <p className="testpad-card-description">{item.description}</p>
                {/* <Link to="/try-it-now" className="testpad-card-link">
                  <span>Try it now</span>
                  <img
                    src={TestpadPageRightArrowIcon}
                    alt="right arrow icon"
                    loading="lazy"
                  />
                </Link> */}
              </div>
            </div>
          );
        })}
      </div>
      <div className="image-wrapper">
        {cardData.imagesArray.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <img
                src={item.image}
                alt={cardData.contentArray[index].heading}
                loading="lazy"
                className={`image  ${index === activeIndex && "active-image"}`}
                onClick={handleImageClick}
              />
            </React.Fragment>
          );
        })}
      </div>
      <Dialog
        header=""
        visible={visible}
        maximizable
        style={{ width: "60vw" }}
        onHide={() => setVisible(false)}
      >
        <div>
          <img
            src={cardData.imagesArray[imageDailogIndex].image}
            alt={cardData.contentArray[imageDailogIndex].heading}
            loading="lazy"
            style={{ width: "100%" }}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default TestpadPageScrollAnimationDesktop;
