import React from 'react'

import ELab from "../../Layouts/TestTool/ELab/ELab";
import "./ELabPage.css";
const ELabPage = () => {
    return (
        <>
            <div className="container-fluid">
                <ELab />
            </div>
        </>
    );
};


export default ELabPage