import "./Auth.css";
import signupLogo from "../../../assets/signupLogo.svg";
import cross from "../../../assets/cross.svg";
import { Link } from "react-router-dom";

const Auth = (props) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row signup">
          <div className={`col-xl-6 col-lg-6 col-md-12 col-sm-12 chainback `}>
            <div className="">
              <img src={signupLogo} alt="logo" className="imageLogo  " />
            </div>
          </div>
          <div className=" d-flex justify-content-center align-items-center col-xl-6 col-lg-6 col-md-12 col-sm-12 authback ">
            <div className="">
              <img
                src={signupLogo}
                alt="logo"
                className="imageLogo d-lg-none"
              />
            </div>
            <Link to="/">
              <img src={cross} className="crossicon" />
            </Link>
            <div className="mx-auto">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
