export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_USER_AVATAR = 'SET_USER_AVATAR';
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const IS_OPEN = "IS_OPEN";
export const IS_CLOSE = "IS_CLOSE";
export const REPORT_DATA = "REPORT_DATA";
export const CLEAR_REPORT_DATA = "CLEAR_REPORT_DATA";
export const PROJECT_DATA = "PROJECT_DATA";
export const CLEAR_PROJECT_DATA = "CLEAR_PROJECT_DATA";
export const OPEN_PROMPT = "OPEN_PROMPT";
export const CLOSE_PROMPT = "CLOSE_PROMPT";
export const AVAILABLE_SUITE = "AVAILABLE_SUITE";
export const CLEAR_AVAILABLE_SUITE = "CLEAR_AVAILABLE_SUITE";
export const PROJECT_DATA_TESTTOOL = "PROJECT_DATA_TESTTOOL";
export const CLEAR_PROJECT_DATA_TESTTOOL = "CLEAR_PROJECT_DATA_TESTTOOL";
export const PROJECT_DATA_REQUIREMENTS = "PROJECT_DATA_REQUIREMENTS";
export const CLEAR_PROJECT_DATA_REQUIREMENTS = "CLEAR_PROJECT_DATA_REQUIREMENTS";
export const PROJECT_DATA_MANUALTC = "PROJECT_DATA_MANUALTC";
export const CLEAR_PROJECT_DATA_MANUALTC = "CLEAR_PROJECT_DATA_MANUALTC";
export const AVAILABLE_TESTCASE = "AVAILABLE_TESTCASE";
export const CLEAR_AVAILABLE_TESTCASE = "CLEAR_AVAILABLE_TESTCASE";
export const ADMIN_AVAILABLE_PROJECTS = "AVAILABLE_PROJECTS";
export const CLEAR_ADMIN_AVAILABLE_PROJECTS = "CLEAR_ADMIN_AVAILABLE_PROJECTS";
export const BREADCRUMB_DATA = "BREADCRUMB_DATA";
export const CLEAR_BREADCRUMB_DATA = "CLEAR_BREADCRUMB_DATA";
export const EDIT_JOB_DETAILS = "EDIT_JOB_DETAILS";
export const CLEAR_EDIT_JOB_DETAILS = "CLEAR_EDIT_JOB_DETAILS";
export const MARK_STATUS_WHILE_MANUAL_EXECUCTION =
  "MARK_STATUS_WHILE_MANUAL_EXECUCTION";
export const CLEAR_MARK_STATUS_WHILE_MANUAL_EXECUCTION =
  "CLEAR_MARK_STATUS_WHILE_MANUAL_EXECUCTION";
