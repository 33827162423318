import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Extent.css";
import KillExecution from "../KillExecution";
import {
  faArrowUpRightFromSquare,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";

const ReportHeader = ({
  title,
  showIcon,
  iconsData,
  extentData,
  s_run_id,
  p_id,
  className,
  isSecurity,
  manualReportLink,
  s_id,
  showManualReportLink,
}) => {
  return (
    <>
      <div className={`mt-1 d-flex ${className ? className : ``}`}>
        <span className="exeReportHeading">{title}</span>
        {showIcon ? (
          <span className="mt-2 ms-2">
            {iconsData.map((iconData) => (
              <label title={iconData.title} key={iconData.title}>
                <div
                  className="btn-sm btn-success me-2 reportIcons"
                  onClick={() => {
                    iconData.onClick();
                  }}
                >
                  {iconData.icon}
                </div>
              </label>
            ))}
          </span>
        ) : null}
        {extentData?.data?.["Execution Info"]?.Status?.toLowerCase() ===
          "exe" && (
          <span className="ms-auto">
            <KillExecution button={true} s_run_id={s_run_id} pid={p_id} />
          </span>
        )}
      </div>
      {manualReportLink && s_id && showManualReportLink && (
        <span
          className="breadLink"
          onClick={() => window.open(manualReportLink)}
        >
          <FontAwesomeIcon
            className="tabIcon me-2"
            icon={faArrowUpRightFromSquare}
            title={"Open Suite Execution Report"}
          />
          <span className="mt-1">
            Suite ID: <i>{s_id}</i> | Execute Manual Testcase(s) (Click to
            continue
            <FontAwesomeIcon className="tabIcon mx-1" icon={faClipboardList} />)
          </span>
        </span>
      )}
    </>
  );
};

export default ReportHeader;