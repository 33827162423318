import { useEffect, useState } from "react";
import httpService from "../../../services/http.service";
import { getInfoMessage, getToastMessage } from "../../Helper/Helper";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
const ProjectAccessAdmin = ({props}) => {
    const [isApproveButtonLoading, setIsApproveButtonLoading] = useState(false);
    const [isRejectButtonLoading, setIsRejectButtonLoading] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    useEffect(() => { 
      const id = props.rowData?.["S No"]?.value;
      props?.selectedUser?.length > 0
        ? setDisableButtons(
            props?.selectedUser?.some((item) => item?.["S No"]?.value === id)
          )
        : setDisableButtons(false);
    }, [props?.selectedUser,props?.key,props?.bulkActionTrigger]);
    const requestAction = (type) => {
        httpService.postPendingProjectRequest({pids: props?.val,action: type})
          .then((data) => {
            setDisableButtons(false);
            getInfoMessage(data);
          })
          .catch((err) => {
            handleToastErrorCatch(err);
          })
          .finally(()=>setDisableButtons(false))
      };
      useEffect(() => {
        setDisableButtons(false);
      }, [props?.bulkActionTrigger]);
    return (
        <div className="accessBtn">
        <>
          <FontAwesomeIcon
            className={`mx-2 ${
              disableButtons ? "disabledReqIcon" : "acceptIcon"
            }`}
            icon={faCircleCheck}
            disabled={disableButtons}
            onClick={() => {
              if (!disableButtons) {
                requestAction("approve");
              }
            }}
          />
          <FontAwesomeIcon
            className={`mx-2 ${
              disableButtons ? "disabledReqIcon" : "rejectIcon"
            }`}
            icon={faCircleXmark}
            onClick={() => {
              if (!disableButtons) {
                requestAction("reject");
              }
            }}
          />
        </>
      </div>
    )
  };
  
  export default ProjectAccessAdmin;