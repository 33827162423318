// CodeDisplay.js
import React, { useState, useEffect } from "react";

import "primereact/resources/themes/saga-blue/theme.css"; // Choose the theme you prefer
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./index.css"; // Import the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowUpRightFromSquare,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";

const CodeDisplay = ({ type, dialog, setDialog, data, setDialogData }) => {
  const [code, setCode] = useState(null);

  useEffect(() => {
    // const updatedData = data?.map((item, index) => {   return `${index+1} ${item}`; });

    let editorCode = data?.join(" \n");

    setCode(editorCode);
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    alert("Code copied to clipboard!");
  };

  return (
    <div className="code-container">
      <pre
        className={
          dialog && type === "dialog" ? "code-block-dialog" : "code-block"
        }
      >
        <code className="codeText">{code}</code>
        <div className="code-actions d-flex align-items-center">
          <FontAwesomeIcon
            icon={faCopy}
            className="copyCodeIcon pointer"
            onClick={handleCopy}
          />
          {(!dialog || type === "splitter") && (
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              onClick={() => {
                setDialogData(data);
                setDialog(true);
              }}
              className="expandCodeIcon pointer mx-2"
            />
          )}
        </div>
      </pre>
    </div>
  );
};

export default CodeDisplay;
