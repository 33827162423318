import React from "react";
import "./HeroSection.css";
import { Button } from "primereact/button";
import LandingPageHeroSectionVector from "../../../../assets/LandingPageHeroSectionVector.svg";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <div className="hero-section-background">
      <div className="hero-section-wrapper component-padding max-w-1440">
        <div>
          <p className="hero-section-heading">
            Illuminating the path to project excellence
          </p>
          <p className="hero-section-description">
            Jewel is your one stop Dashboard to analyze and visualize data in a
            unique and efficient way.
          </p>
          <Link
  to={
    process.env.REACT_APP_ENV === "prod"
      ? "https://gemecosystem.com/#/gem-consultancy#contact-for-resources"
      : "https://beta.gemecosystem.com/#/gem-consultancy#contact-for-resources"
  }
  target="_blank"
>
            <Button
              className="base-button primary-button hover-right-arrow landing-page-hero-section-button-margin-top"
              label="Request Demo"
            />
          </Link>
        </div>
        <div className="hero-section-image-wrapper">
          <img
            className="hero-section-image"
            src={LandingPageHeroSectionVector}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
