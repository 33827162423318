import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import sideBar from "./sideBar";
import autolytics from "./autolytics";
import TestTool from "./TestTool";
import admin from "./admin";
import breadcrumb from "./breadcrumb";
import ManualExecution from "./ManualExecution";
import requirements from "./requirements";
import manualTC from "./manualTC";

export default combineReducers({
  auth,
  message,
  sideBar,
  autolytics,
  TestTool,
  requirements,
  manualTC,
  admin,
  breadcrumb,
  ManualExecution,
});
