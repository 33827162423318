import { statementError } from "../../../Helper/ToastHelper";

export const createTestcase = async (action, framework, { ...paramsObject }) => {
    const {
        keyHeaders,
        testcaseName,
        category,
        type,
        runFlag,
        tags,
        selectedTestingCategory,
        contextVal,
        scenarioSteps,
        scenarioExample,
        setDisable,
        createUpdateTc,
        actionFrom,
        sid,
        manualRef,
        editUpdateTc
    } = paramsObject

    switch (framework) {
        case "GEMPYP":
            let frameworkData = {};
            keyHeaders?.forEach((data) => {
                if (data?.key && data?.key?.length > 0) {
                    frameworkData[data.key] = data?.value;
                }
            });

            if (
                testcaseName?.length > 0 &&
                category?.length > 0 &&
                type?.length > 0 &&
                runFlag?.length > 0
            ) {
                let payload = {
                    type: type,
                    name: testcaseName?.trim(),
                    automationType:selectedTestingCategory,
                    category: category?.trim(),
                    framework: framework.toUpperCase(),
                    frameworkDefinedData: frameworkData,
                    tags: tags.length > 0 ? tags : null,
                    run_flag: runFlag,
                };
                if (contextVal) {
                    payload["folderId"] = contextVal.currentFolderID.current;
                    payload["pid"] = contextVal.currentP_ID.current;
                }
                if (actionFrom === "elab") {
                    payload["s_id"] = [sid];
                }
                if (Object.keys(frameworkData)?.length <= 0) {
                    delete payload["frameworkDefinedData"];
                }
                switch (action) {
                    case "create":
                        setDisable(true);
                        createUpdateTc(payload);
                        break;

                    case "edit":
                        setDisable(true);
                        editUpdateTc(payload);
                        break;
                    default:
                        return null;
                }
            } else {
                statementError("All Fields are required !");;
                setDisable(false);
            }
            break;

        case "GEMJAR":
            let scenarioList = null;
            let scenarioExampleList = null;
            let payload = {};
            switch (action) {
                case "create":
                    scenarioList = scenarioSteps?.split("\n");
                    scenarioExampleList = null;
                    setDisable(true);

                    if (
                        !(
                            testcaseName?.trim() &&
                            type?.trim() &&
                            scenarioSteps?.trim() &&
                            category?.trim() &&
                            runFlag?.trim()
                        )
                    ) {
                        statementError("All Fields are required !");;
                        setDisable(false);
                    } else {
                        if (type === "Scenario Outline" && !scenarioExample) {
                            statementError("Example is required with Scenario Outline Type");
                            setDisable(false);
                        } else if (type === "Scenario Outline" && scenarioExample) {
                            scenarioExampleList = scenarioExample?.split("\n");
                            payload =
                            {
                                category: category?.trim(),
                                name: testcaseName?.trim(),
                                type: type,
                                automationType:selectedTestingCategory,
                                scenario_steps: scenarioList,
                                examples: scenarioExampleList,
                                run_flag: runFlag,
                                tags: tags?.length > 0 ? tags : null,
                                framework: framework?.toUpperCase(),
                            }
                            if (contextVal) {
                                payload["folderId"] = contextVal.currentFolderID.current;
                                payload["pid"] = contextVal.currentP_ID.current;
                            }
                            createUpdateTc(payload);
                        } else {
                            payload =
                            {
                                category: category?.trim(),
                                name: testcaseName?.trim(),
                                type: type,
                                automationType:selectedTestingCategory,
                                scenario_steps: scenarioList,
                                run_flag: runFlag,
                                tags: tags?.length > 0 ? tags : null,
                                framework: framework?.toUpperCase(),
                            }
                            if (contextVal) {
                                payload["folderId"] = contextVal.currentFolderID.current;
                                payload["pid"] = contextVal.currentP_ID.current;
                            }
                            createUpdateTc(payload);
                        }
                    }
                    break;
                case "edit":
                    scenarioList = scenarioSteps?.split("\n");
                    scenarioExampleList = null;
                    if (
                        !(
                            testcaseName?.trim() &&
                            type?.trim() &&
                            scenarioSteps?.trim() &&
                            category?.trim() &&
                            runFlag?.trim()
                        )
                    ) {
                        statementError("All fields are Required");
                    } else {
                        if (type === "Scenario Outline" && !scenarioExample) {
                            statementError("Example is required with Scenario Outline Type");
                        } else if (type === "Scenario Outline" && scenarioExample) {
                            scenarioExampleList = scenarioExample?.split("\n");
                            payload = {
                                category: category?.trim(),
                                name: testcaseName?.trim(),
                                type: type,
                                automationType:selectedTestingCategory,
                                scenario_steps: scenarioList,
                                examples: scenarioExampleList,
                                run_flag: runFlag,
                                framework: framework?.toUpperCase(),
                                tags: tags?.length > 0 ? tags : null,
                            };
                            if (contextVal) {
                                payload["folderId"] = contextVal.currentFolderID.current;
                                payload["pid"] = contextVal.currentP_ID.current;
                            }
                            if (actionFrom === "elab") {
                                payload["s_id"] = [sid];
                            }
                            setDisable(true);
                            editUpdateTc(payload);
                        } else {
                            payload = {
                                category: category?.trim(),
                                name: testcaseName?.trim(),
                                type: type,
                                automationType:selectedTestingCategory,
                                scenario_steps: scenarioList,
                                run_flag: runFlag,
                                framework: framework?.toUpperCase(),
                                tags: tags?.length > 0 ? tags : null,
                            };
                            if (contextVal) {
                                payload["folderId"] = contextVal.currentFolderID.current;
                                payload["pid"] = contextVal.currentP_ID.current;
                            }
                            if (actionFrom === "elab") {
                                payload["s_id"] = [sid];
                            }
                            setDisable(true);
                            editUpdateTc(payload);
                        }
                    }
                    break;
                default:
                    break;
            }
            break;

        case "MANUAL":
            setDisable(true);
            editUpdateTc(null, "manualEdit");
            break;
        default:
            break;
    }
};
