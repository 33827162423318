import BreadCrumb from "../../UI/BreadCrumb/BreadCrumb";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Requirements.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFolderOpen,
  faCopy,
  faFolderTree,
} from "@fortawesome/free-solid-svg-icons";
import httpService from "../../../services/http.service";
import {
  ConvertTextToSeeMore,
  copyToClipboard,
  createColumns,
  createFilters,
  decodeParam,
  encodeParam,
  getErrorMessage,
  getToastMessage,
  logPageTitleForGA,
} from "../../Helper/Helper";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import RequirementFile from "./RequirementFile";
import ReqForm from "./ReqForm";
import { over } from "stompjs";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import NoAccess from "./../../UI/NoAccess/NoAccess";
import {
  CLEAR_PROJECT_DATA_REQUIREMENTS,
  PROJECT_DATA_REQUIREMENTS,
} from "../../../actions/type";
import { useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { connectSocket } from "../../Helper/SocketHelper";
import {
  handleToastErrorCatch,
  statementError,
} from "../../Helper/ToastHelper";
import NoData from "../../UI/NoData/NoData";

const Requirements = () => {
  const [requirementFilters, setRequirementFilters] = useState({});
  const currentFolderID = useRef(null);
  const currentFolderName = useRef(null);
  const [currentReqId, setCurrentReqId] = useState(null);
  const [currentReqName, setCurrentReqName] = useState(null);
  const [allRequirements, setAllRequirements] = useState(null);
  const [newReqDialog, setNewReqDialog] = useState(false);
  const [newFolderDialog, setNewFolderDialog] = useState(false);
  const [projectsWithAccess, setProjectsWithAccess] = useState([]);
  const [newFolderName, setNewFolderName] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [sameCompanyUsers, setSameCompanyUsers] = useState([]);
  const currentP_ID = useRef(null);
  const currentP_Name = useRef(null);
  const [reqFormData, setReqFormData] = useState(null);
  const company = useSelector((state) => state.auth.userProfile.socket);
  const [breadList, setBreadList] = useState([
    { name: "TaskBoard", f_id: null, p_id: null, p_name: null },
  ]);
  const [noAccess, setNoAccess] = useState(false);
  const projectData = useSelector(
    (state) => state.requirements.project_data_requirements
  );
  const dispatch = useDispatch();
  const ref = useRef();
  const [searchParams] = useSearchParams();
  let stompClient = null;
  const connection = useRef(null);
  const pidArray = useRef(null);
  const [requirementButtonLoading, setRequirementButtonLoading] =
    useState(false);
  const [createNewFolderButtonLoading, setCreateNewFolderButtonLoading] =
    useState(false);
  const [createButtonLoading, setCreateButtonLoading] = useState(false);
  const role = useSelector((state) => state.auth.userProfile.role);
  const notifyClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (pidArray.current && pidArray.current.includes(wsBody["pid"])) {
        getSuiteRequirements();
      }
    }
  };

  useEffect(() => {
    const filterData =
      allRequirements && allRequirements["headers"]
        ? createFilters(allRequirements["headers"])
        : {};

    setRequirementFilters(filterData);
  }, [allRequirements]);

  useEffect(() => {
    //connecting the websocket
    connectSocket(
      "testpad",
      company.toString().toUpperCase() + "_REQUIREMENT_FOLDER/private",
      connection,
      notifyClient
    );
    // connect();

    setNoAccess(false);

    let title = "Jewel | TaskBoard";
    document.title = title;
    logPageTitleForGA(title);

    httpService
      .getProjectRole()
      .then((data) => {
        let pids = [];
        data.data.map((roles) => pids.push(roles["pid"]));
        getErrorMessage(data);
        return Promise.resolve(pids);
      })
      .then((data) => {
        if (data && data.length > 0) {
          httpService.getProjectData(data).then((res) => {
            dispatch({
              type: PROJECT_DATA_REQUIREMENTS,
              payload: res,
            });
            return Promise.resolve(res);
          });
        }
      })
      .catch((err) => {
        if (
          err["response"]["data"]["operation"].toLowerCase() === "info" &&
          err["response"]["data"]["suboperation"]
        ) {
          dispatch({
            type: PROJECT_DATA_REQUIREMENTS,
            payload: err["response"]["data"],
          });
        }
      });

    return () => {
      //unsubscribe from websocket
      connection?.current?.unsubscribe();

      dispatch({
        type: CLEAR_PROJECT_DATA_REQUIREMENTS,
      });
    };
  }, []);

  useEffect(() => {
    setNoAccess(false);
    if (searchParams.get("f_id")) {
      let tempList = [...JSON.parse(decodeParam(searchParams.get("list")))];
      setBreadList(tempList);
      setCurrentReqId(null);
      currentFolderID.current = searchParams.get("f_id");
      currentP_ID.current = searchParams.get("p_id");
      currentP_Name.current = tempList?.[tempList.length - 1]?.["p_name"];
      getSuiteRequirements();
    } else if (searchParams.get("task_id")) {
      let tempList = searchParams.get("list")
        ? [...JSON.parse(decodeParam(searchParams.get("list")))]
        : [];
      setBreadList(tempList);
      setCurrentReqId(searchParams.get("task_id"));
      currentP_ID.current = searchParams.get("p_id");
      currentP_Name.current = tempList?.[tempList.length - 1]?.["p_name"];
    } else {
      setBreadList([
        { name: "Taskboard", f_id: null, p_id: null, p_name: null },
      ]);
      setCurrentReqId(null);
      currentFolderID.current = null;
      currentP_ID.current = null;
      currentP_Name.current = null;
      getSuiteRequirements();
    }
  }, [searchParams]);

  useEffect(() => {
    if (projectData.length > 0) {
      let pids = projectData.map((data) => data["pid"]);
      pidArray.current = pids;
    } else if (
      projectData["operation"] &&
      projectData["operation"].toLowerCase() === "info" &&
      projectData["suboperation"]
    ) {
      setNoAccess(true);
    }
  }, [projectData]);

  const skeletonBridgeRows = Array.from({ length: 8 });
  const skeletonBridgeTemplate = () => {
    return <Skeleton></Skeleton>;
  };
  const tableSkeleton = () => {
    return (
      <DataTable
        responsiveLayout="scroll"
        value={skeletonBridgeRows}
        className="p-datatable-striped mt-3 test-stepTable"
      >
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "20%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "15%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "13%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "13%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "20%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "18%" }}
          body={skeletonBridgeTemplate}
        ></Column>
      </DataTable>
    );
  };

  const getUsers = (pid) => {
    httpService
      .getSameCompanyUsers(pid)
      .then(async (data) => {
        (await data["data"]["data"])
          ? setSameCompanyUsers(data["data"]["data"])
          : setSameCompanyUsers([]);
        getErrorMessage(data);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
  Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };
  const onRequirementClick = (data, type, list) => {
    if (type === "requirement") {
      if (data["f_id"]) {
        setAllRequirements(null);
        setCurrentReqId(null);
        currentFolderID.current = data["f_id"];
        currentP_ID.current = data["p_id"];
        currentP_Name.current = data["p_name"];
        getSuiteRequirements();
        window.history.replaceState(
          null,
          "",
          "/#/testpad/taskboard?f_id=" +
            data["f_id"] +
            "&p_id=" +
            data["p_id"] +
            "&list=" +
            encodeParam(JSON.stringify(list))
          // + "&filters=" +
          // encodeParam(JSON.stringify(manualFilters))
        );
      } else if (data["req_id"]) {
        currentFolderID.current = null;
        currentP_ID.current = data["p_id"];
        currentP_Name.current = data["p_name"];
        setCurrentReqId(data["req_id"]);
      } else {
        if (data["name"].toLowerCase() === "taskboard") {
          setAllRequirements(null);
          currentFolderID.current = null;
          setCurrentReqId(null);
          currentP_ID.current = null;
          currentP_Name.current = null;
          getSuiteRequirements();

          window.history.replaceState(null, "", "/#/testpad/taskboard");
        }
      }
    }
  };

  const openFolder = (f_id, f_name, p_id, p_name) => {
    setAllRequirements(null);
    currentFolderID.current = f_id;
    currentFolderName.current = f_name;
    currentP_ID.current = p_id;
    currentP_Name.current = p_name;
    let newLink = {
      name: f_name,
      f_id: f_id,
      p_id: p_id,
      p_name: p_name,
    };
    setBreadList([...breadList, newLink]);
    getSuiteRequirements();

    let list = [...breadList, newLink];
    window.history.replaceState(
      null,
      "",
      "/#/testpad/taskboard?f_id=" +
        f_id +
        "&p_id=" +
        p_id +
        "&list=" +
        encodeParam(JSON.stringify(list))
      // + "&filters=" +
      // encodeParam(JSON.stringify(manualFilters))
    );
  };

  const openFile = (req_id, req_name, p_id, p_name) => {
    setCurrentReqId(req_id);
    setCurrentReqName(req_name);
    currentP_ID.current = p_id;
    currentP_Name.current = p_name;

    let newLink = {
      name: req_name,
      req_id: req_id,
      p_id: p_id,
      p_name: p_name,
    };
    setBreadList([...breadList, newLink]);

    let list = [...breadList, newLink];
    window.history.replaceState(
      null,
      "",
      "/#/testpad/taskboard?task_id=" +
        req_id +
        "&p_id=" +
        p_id +
        "&list=" +
        encodeParam(JSON.stringify(list))
      // + "&filters=" +
      // encodeParam(JSON.stringify(manualFilters))
    );
    // breadcrumbArr.push(newLink);
  };

  const getProjects = () => {
    setCreateNewFolderButtonLoading(true);
    httpService
      .getAllProjectsWithAccess()
      .then((data) => {
        setProjectsWithAccess(data?.data);
        getErrorMessage(data);
        setNewFolderDialog(true);
        setCreateNewFolderButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () =>
          setCreateNewFolderButtonLoading(false)
        );
      });
  };
  const createFolder = () => {
    return (
      <>
        <div className="row mt-3 mx-2">
          <div className="col-md-6 col-12">
            <InputText
              type={"text"}
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              className="w-100"
              placeholder={"Folder Name"}
              disabled={createButtonLoading}
            />
          </div>
          {!currentFolderID.current ? (
            <div className="col-md-6 col-12">
              <Dropdown
                className="dialog-dropdown px-3 w-100"
                options={projectsWithAccess}
                optionValue="P ID"
                optionLabel="Project Name"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.value)}
                filter
                placeholder="Select Project"
                maxSelectedLabels={1}
                disabled={createButtonLoading}
              />
            </div>
          ) : (
            <div className="col-md-6 col-12">
              <InputText
                type={"text"}
                value={currentP_Name.current}
                className="w-100"
                disabled={true}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  const createNewFolderAPI = (payload) => {
    setCreateButtonLoading(true);
    httpService
      .createNewFolderTestpad(payload, "REQUIREMENT")
      .then((data) => {
        getToastMessage(data);
        setNewFolderDialog(false);
        setNewFolderName("");
        setSelectedProject(null);
        // getSuiteRequirements();
        setCreateButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setNewFolderDialog(false);
          setNewFolderName("");
          setSelectedProject(null);
          setCreateButtonLoading(false);
        });
      });
  };

  const createNewFolder = () => {
    if (!currentFolderID.current) {
      if (selectedProject && newFolderName.length > 0) {
        let payload = {
          name: newFolderName,
          pid: selectedProject,
          folderId: currentFolderID.current,
        };
        createNewFolderAPI(payload);
      } else {
        statementError("All Fields are required !");
      }
    } else {
      if (newFolderName.length > 0) {
        let payload = {
          name: newFolderName,
          pid: currentP_ID.current,
          folderId: currentFolderID.current,
        };
        createNewFolderAPI(payload);
      } else {
        statementError("All Fields are required !");
      }
    }
  };

  const FolderAction = (val, data) => {
    const [showSeeMoreFolder, setShowSeeMoreFolder] = useState(false);
    const [showSeeMoreFile, setShowSeeMoreFile] = useState(false);
    return (
      <div>
        {data["Folder/Requirement"] &&
        data["Folder/Requirement"]["subType"].toLowerCase() === "folder" ? (
          <div
            className="d-inline-flex reqLinks boldText ms-2"
            onClick={(e) =>
              openFolder(
                data["Id"]["value"],
                data["Folder/Requirement"]["value"],
                data["P ID"]["value"],
                data["Project Name"]["value"]
              )
            }
          >
            <FontAwesomeIcon icon={faFolderOpen} className="reqIcons me-2" />
            <span>
              {ConvertTextToSeeMore(
                "Folder Name",
                val,
                showSeeMoreFolder,
                setShowSeeMoreFolder,
                20
              )}
            </span>
          </div>
        ) : (
          <div
            className=" d-inline-flex reqLinks boldText ms-2"
            onClick={(e) =>
              openFile(
                data["Id"]["value"],
                data["Folder/Requirement"]["value"],
                data["P ID"]["value"],
                data["Project Name"]["value"]
              )
            }
          >
            <FontAwesomeIcon icon={faFile} className="reqIcons me-2" />
            <span>
              {ConvertTextToSeeMore(
                "Testcase Name",
                val,
                showSeeMoreFile,
                setShowSeeMoreFile,
                20
              )}
            </span>
          </div>
        )}
      </div>
    );
  };

  const editRequirementData = async () => {
    setRequirementButtonLoading(true);
    await httpService.getRequirementData(currentReqId).then((data) => {
      getErrorMessage(data);
      setReqFormData(data["data"]);
      if (data["operation"] && data["operation"].toLowerCase() === "success") {
        getUsers(data["data"]["pid"]);
        setNewReqDialog(true);
      }
    });
    setRequirementButtonLoading(false);
  };

  const newRequirement = async () => {
    setRequirementButtonLoading(true);
    await httpService
      .getAllProjectsWithAccess()
      .then(async (data) => {
        setProjectsWithAccess(data["data"]);
        getErrorMessage(data);
        setNewReqDialog(true);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
    setRequirementButtonLoading(false);
  };
  const getSuiteRequirements = () => {
    let title = "Jewel | TaskBoard";
    document.title = title;
    logPageTitleForGA(title);

    let payload = {
      folderId: currentFolderID.current,
      companyName: company,
    };
    httpService
      .getAllRequirements(payload)
      .then(async (data) => {
        getErrorMessage(data);
        setAllRequirements(await data["data"]);
      })
      .catch((err) => {
        if (
          err["response"]["data"]["operation"].toLowerCase() === "info" &&
          err["response"]["data"]["suboperation"]
        ) {
          setNoAccess(true);
          return;
        }
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
      Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  const copyLink = (e) => {
    copyToClipboard(window.location.origin + "/" + window.location.hash);
  };

  return !noAccess ? (
    <div className="container-fluid reqSection mx-2">
      <div className="d-flex flex-wrap align-items-center pt-2 mb-2 mx-2">
        <div className="align-items-center   pb-1">
          <div className="headerTest">
            TaskBoard
            <span className="ms-3">
              {breadList.length > 0 ? (
                <FontAwesomeIcon
                  className="actionBtn"
                  icon={faCopy}
                  title="Copy Link"
                  onClick={(e) => copyLink(e)}
                />
              ) : null}
            </span>
          </div>
        </div>
        <div className="ms-sm-auto ">
          {!currentReqId && role.toString().toUpperCase() !== "SUPER_ADMIN" && (
            <>
              <Button
                label="Create New Folder"
                className="btn-success btn themeBtn mx-2 p-2 mt-1"
                onClick={(e) => getProjects()}
                icon={
                  createNewFolderButtonLoading
                    ? "pi pi-spin pi-spinner me-2"
                    : "pi pi-plus me-2"
                }
                iconPos="right"
                loading={createNewFolderButtonLoading}
              />
            </>
          )}
          {role.toString().toUpperCase() !== "SUPER_ADMIN" && (
            <Button
              label={
                currentReqId ? "Edit Requirement" : "Create New Requirement"
              }
              className="btn-success btn themeBtn mx-2 p-2 mt-1"
              onClick={(e) => {
                currentReqId ? editRequirementData() : newRequirement();
              }}
              icon={
                requirementButtonLoading
                  ? "pi pi-spin pi-spinner me-2"
                  : "pi pi-plus me-2"
              }
              iconPos="right"
              loading={requirementButtonLoading}
            />
          )}
        </div>
      </div>
      <div className="mx-2">
        {breadList.length > 0 ? (
          <BreadCrumb
            breadList={breadList}
            setBreadList={setBreadList}
            component={"Requirement"}
            onClickEvent={onRequirementClick}
          />
        ) : (
          <div>
            <span>
              <FontAwesomeIcon
                className="me-2 staticIcon"
                icon={faFolderTree}
              />
            </span>
            TaskBoard
          </div>
        )}
      </div>
      {currentReqId ? (
        <RequirementFile
          req_id={currentReqId}
          req_name={currentReqName}
          noAccess={noAccess}
          setNoAccess={setNoAccess}
        />
      ) : (
        <div className="mt-3 mx-2">
          {allRequirements ? (
            <DataTable
              reorderableColumns
              onFilter={(e) => {
                setRequirementFilters(e.filters);
              }}
              resizableColumns
              stripedRows
              columnResizeMode="expand"
              value={allRequirements ? allRequirements["data"] : null}
              paginator
              rows={25}
              dataKey="id"
              filters={requirementFilters}
              rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              scrollHeight={"65vh"}
              filterDisplay="menu"
              responsiveLayout="scroll"
              emptyMessage={<NoData />}
              currentPageReportTemplate="Total {totalRecords} Record(s) Found"
              removableSort
              className="test-stepTable"
            >
              {createColumns(
                allRequirements["headers"],
                true,
                true,
                allRequirements["data"],
                false,
                null,
                { customAction: FolderAction }
              )}
            </DataTable>
          ) : (
            tableSkeleton()
          )}
        </div>
      )}
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Create New Folder"
        visible={newFolderDialog}
        onHide={() => {
          setNewFolderDialog(false);
          setNewFolderName("");
          setSelectedProject(null);
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
        footer={
          <div className="text-center">
            <Button
              label="Create"
              className="btn-success btn themeBtn"
              onClick={(e) => createNewFolder()}
              loadingIcon="pi pi-spin pi-spinner me-2"
              iconPos="right"
              loading={createButtonLoading}
            />
          </div>
        }
      >
        {createFolder()}
      </Dialog>
      <Dialog
        blockScroll={true}
        draggable={false}
        header={currentReqId ? "Edit Requirement" : "Create New Requirement"}
        visible={newReqDialog}
        maximizable
        onHide={() => {
          setNewReqDialog(false);
          setSameCompanyUsers([]);
          ref.current.clearFormData();
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "65vw" }}
      >
        <ReqForm
          type={currentReqId ? "edit" : "create"}
          f_id={currentFolderID.current}
          req_id={currentReqId}
          users={sameCompanyUsers}
          projectsWithAccess={projectsWithAccess}
          ref={ref}
          editData={reqFormData}
          setEditData={setReqFormData}
          setNewReqDialog={setNewReqDialog}
          p_id={currentP_ID.current}
          p_name={currentP_Name.current}
          getSuiteRequirements={getSuiteRequirements}
          breadList={breadList}
          setBreadList={setBreadList}
          getUsers={getUsers}
        />
      </Dialog>
    </div>
  ) : (
    <NoAccess />
  );
};

export default Requirements;
