import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Chips } from "primereact/chips";
import "./ShareVia.css";
import { toast } from "react-toastify";
import httpService from "../../../services/http.service";
import { getErrorMessage, getToastMessage } from "../../Helper/Helper";
import { useDispatch } from "react-redux";
import { AVAILABLE_SUITE } from "../../../actions/type";
import {
  handleToastErrorCatch,
  statementError,
} from "../../Helper/ToastHelper";
import { Button } from "primereact/button";

const ShareVia = ({ props }) => {
  const { isActive, val, s_id, setDataMain } = props;
  const dispatch = useDispatch();
  const [isShareViaEmail, setIsShareViaEmail] = useState(false);
  const [toMail, setToMail] = useState(null);
  const [ccMail, setCcMail] = useState(null);
  const [bccMail, setBccMail] = useState(null);
  const [isUnlinkEmailLoading, setIsUnlinkEmailLoading] = useState(false);
  const [isUpdateEmailButtonLoading, setIsUpdateEmailButtonLoading] =
    useState(false);

  const clearData = () => {
    setIsShareViaEmail(false);
    setBccMail(null);
    setCcMail(null);
    setToMail(null);
  };

  const validateEmail = (event) => {
    let value = event?.value;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      return true;
    }
    toast.warn("You have entered an invalid email address!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    return false;
  };

  const integrateMail = () => {
    if (!toMail || toMail?.length === 0) {
      statementError("To details are mandatory !");
      return;
    } else {
      let payload = {
        s_id: s_id,
        to: toMail,
        cc: ccMail,
        bcc: bccMail,
      };
      setIsUpdateEmailButtonLoading(true);
      httpService
        .sendMail(payload)
        .then((data) => {
          clearData();
          getToastMessage(data);
          if (data?.operation?.toLowerCase() === "success") {
            httpService
              .getAvailableTestToolSuites()
              .then((data) => {
                dispatch({
                  type: AVAILABLE_SUITE,
                  payload: data.data,
                });
                setDataMain(data?.data);
                getErrorMessage(data);
                setIsUpdateEmailButtonLoading(false);
              })
              .catch((err) => {
                handleToastErrorCatch(err, () =>
                  setIsUpdateEmailButtonLoading(false)
                );
              });
          }
        })
        .catch((err) => {
          clearData();
          handleToastErrorCatch(err, () =>
            setIsUpdateEmailButtonLoading(false)
          );
        });
    }
  };

  const deleteMail = () => {
    setIsUnlinkEmailLoading(true);
    httpService
      .unlinkMail(s_id)
      .then((data) => {
        clearData();
        getToastMessage(data);
        if (data?.operation?.toLowerCase() === "success") {
          httpService
            .getAvailableTestToolSuites()
            .then((data) => {
              dispatch({
                type: AVAILABLE_SUITE,
                payload: data["data"],
              });
              setDataMain(data["data"]);
              getErrorMessage(data);
              setIsUnlinkEmailLoading(false);
            })
            .catch((err) => {
              handleToastErrorCatch(err, () => setIsUnlinkEmailLoading(false));
            });
        }
      })
      .catch((err) => {
        clearData();
        handleToastErrorCatch(err, () => setIsUnlinkEmailLoading(false));
      });
  };

  const getChipsInput = (value, setValue) => {
    return (
      <>
        <Chips
          onAdd={(e) => {
            return validateEmail(e);
          }}
          allowDuplicate={false}
          className="mailChip"
          style={{ minWidth: "100%" }}
          value={value}
          onChange={(e) => setValue(e.value)}
          disabled={isUpdateEmailButtonLoading || isUnlinkEmailLoading}
        ></Chips>
        <div className="ms-1 mb-1 mt-1 pDetails-smheading">
          Note: Press Enter to Add Email(s).
        </div>
      </>
    );
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        <FontAwesomeIcon
          icon={faEnvelope}
          onClick={() => {
            if (val) {
              setToMail(val?.to);
              setBccMail(val?.bcc);
              setCcMail(val?.cc);
            }
            setIsShareViaEmail(true);
          }}
          className={`iconclick actionBtn ${isActive ? "colorTheme" : ""}`}
        />
      </div>

      <Dialog
        blockScroll={true}
        draggable={false}
        header={`${isActive ? "Update" : "Save"} Details`}
        visible={isShareViaEmail}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "65vw" }}
        footer={() => (
          <>
            {isActive && (
              <Button
                label="Unlink Email"
                className="btn-success btn themeBtn mx-2 p-2 mt-1"
                onClick={(e) => {
                  deleteMail();
                }}
                loadingIcon={"pi pi-spin pi-spinner me-2"}
                loading={isUnlinkEmailLoading}
                iconPos="right"
              />
            )}

            <Button
              label={`${isActive ? "Update" : "Save"} `}
              className="btn-success btn themeBtn mx-2 p-2 mt-1"
              onClick={(e) => {
                integrateMail();
              }}
              loadingIcon={"pi pi-spin pi-spinner me-2"}
              loading={isUpdateEmailButtonLoading}
              iconPos="right"
            />
          </>
        )}
        onHide={() => {
          clearData();
        }}
      >
        <div className="mx-4">
          <div className="pDetails-heading pt-2">
            To: <sup>*</sup>
          </div>
          {getChipsInput(toMail, setToMail)}
          <div className="pDetails-heading mt-2">CC:</div>
          {getChipsInput(ccMail, setCcMail)}
          <div className="pDetails-heading mt-2">Bcc:</div>
          {getChipsInput(bccMail, setBccMail)}
        </div>
      </Dialog>
    </>
  );
};

export default ShareVia;
