import RecycleBin from "../../Layouts/RecycleBin/RecycleModules";

const RecycleBinPage = () => {
    return (
        <div className='container-fluid'>
            <RecycleBin />
        </div>
    )
}

export default RecycleBinPage