import React from "react";
import "./AdvantageCard.css";

const AdvantageCard = ({ heading, icon, description }) => {
  return (
    <div className="advantage-card-wrapper">
      <img
        className="advantage-card-icon"
        src={icon}
        alt={heading}
        loading="lazy"
      />

      <p className="advantage-card-heading">{heading}</p>
      <p className="advantage-card-description">{description}</p>
    </div>
  );
};

export default AdvantageCard;
