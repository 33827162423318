import { faArrowUpFromBracket, faCircleInfo, faCirclePlus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { addFormField, createHeadersKeyValueInputs, getErrorMessage, loadingData } from '../../../Helper/Helper';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { handleToastErrorCatch, statementError } from '../../../Helper/ToastHelper';
import * as XLSX from "xlsx";
import httpService from '../../../../services/http.service';
import excelLoading from '../../../../assets/loadingExcelTCGif.gif';

import { toast } from 'react-toastify';
import { ProgressBar } from 'primereact/progressbar';
import { Badge } from 'primereact/badge';

const ImportExcel = ({
    currentP_Name,
    currentP_ID,
    currentFolderID
}) => {

    const [excelDialog, setExcelDialog] = useState(false);
    const [checked, setChecked] = useState(false);
    const [keyHeaders, setKeyHeaders] = useState([{ key: "", value: "" }]);
    const [activeTab, setActiveTab] = useState('import');
    const [fileData, setFileData] = useState([]);
    const [fixedCol, setFixedCol] = useState(null);
    const [load, setLoad] = useState(false);
    const [totalRowsDone, setTotalRowsDone] = useState();
    const [errList, setErrList] = useState([]);
    const fieldOptions = [
        "Testcase Name",
        "Testcase Description",
        "Test Data",
        "Test Steps",
        "Step Description",
        "Expected Behaviour",
    ]

    const mandatoryFields = [
        "Testcase Name",
        "Testcase Description",
        "Test Steps",
        "Step Description",
        "Expected Behaviour",
    ]

    const uploadOptions = {
        label: "Upload",
        icon: "pi pi-upload",
        className: "p-button-success uploadButton",
    };
    const cancelOptions = {
        label: "Cancel",
        icon: "pi pi-times",
        className: "p-button-danger cancelButton",
    };

    const switchTab = (tab) => {
        setActiveTab(tab);
    };

    const checkValidity = (obj, i, sheet) => {
        let testStepsLength = obj["testSteps"]?.length;
        let expectedBehaviourLength = obj["expectedBehaviour"]?.length;
        let stepsDescriptionLength = obj["stepsDescription"]?.length;
        const undefinedKeys = [];
        Object.keys(obj).forEach(key => {
            if (obj[key] === undefined) {
                undefinedKeys.push(key);
            }
        });

        if (undefinedKeys.length > 0) {
            setErrList(prevErr => [...prevErr, `Row: ${i} in sheet ${sheet} has undefined values for key(s): ${undefinedKeys.join(', ')}`]);
            return false;
        }

        else if (!(testStepsLength === stepsDescriptionLength &&
            stepsDescriptionLength === expectedBehaviourLength)) {
            if (testStepsLength < stepsDescriptionLength && testStepsLength < expectedBehaviourLength) {
                setErrList(prevErr => [...prevErr, `Testcase created but steps not added for Row: ${i} in sheet ${sheet}. ${Math.min(stepsDescriptionLength - testStepsLength, expectedBehaviourLength - testStepsLength)} entry(s) missing in Test Steps column`])
            } else if (stepsDescriptionLength < testStepsLength && stepsDescriptionLength < expectedBehaviourLength) {
                setErrList(prevErr => [...prevErr, `Testcase created but steps not added for Row: ${i} in sheet ${sheet}. ${Math.min(testStepsLength - stepsDescriptionLength, expectedBehaviourLength - stepsDescriptionLength)} entry(s) missing in Step Description column`])
            } else if (expectedBehaviourLength < testStepsLength && expectedBehaviourLength < stepsDescriptionLength) {
                setErrList(prevErr => [...prevErr, `Testcase created but steps not added for Row: ${i} in sheet ${sheet}. ${Math.min(testStepsLength - expectedBehaviourLength, stepsDescriptionLength - expectedBehaviourLength)} entry(s) missing in Expected Behaviour column`])
            }
            return true;
        }
        else {
            return true;

        }

    }

    const onFileSelect = (e) => {
        const file = e?.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            //binary data
            const data = e.target.result;

            const workbook = XLSX.read(data, { type: "binary" });
            let jsonData = {};
            workbook?.SheetNames?.forEach((sheetName) => {
                const sheet = workbook.Sheets[sheetName];
                jsonData[sheetName] = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
                setFileData(jsonData);
            });
        }
        reader.readAsBinaryString(file);
    }

    const uploadHandler = async (e) => {
        const uploadedFile = e?.files[0];
        if (!uploadedFile) {
            statementError("No File uploaded. Please upload one to continue further !");
            return;
        }

        const validTypes = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
            "application/vnd.ms-excel", // .xls
            "text/csv" //.csv
        ];

        if (!(validTypes.includes(uploadedFile?.type))) {
            statementError("Please upload a valid Excel file (XLSX or XLS format). !");
            return;
        }

        setLoad(true);
        // let fixed = [empCode, empName, designation, email];
        let fixedJson = {
            "Testcase Name": "testCaseName",
            "Testcase Description": "testCaseDescription",
            "Test Steps": "testSteps",
            "Step Description": "stepsDescription",
            "Expected Behaviour": "expectedBehaviour",

        };
        // setFixedCol(mandatoryFields);
        const keys = Object.keys(fileData);
        let notFixed = [];
        let totalRowsProcessed = 0;
        setTotalRowsDone(totalRowsProcessed);
        for (const sheet of keys) {
            const firstRow = fileData[sheet][0];
            let final = mandatoryFields?.map((data) => {
                return firstRow?.includes(data) ? true : false;
            });
            if (final.includes(false)) {
                setLoad(false);
                setErrList(prevErr => [...prevErr, `Provided mandatory fields columns in sheet ${sheet} are not correct.`]);
                statementError(`Import failed! Please ensure that provided mandatory fields columns in sheet ${sheet} are correct.`);
                return;
            }
        }

        for (const sheet of keys) {
            setLoad(true)
            let errorOccured = false;
            const firstRow = fileData[sheet][0];
            const mapping = {};
            firstRow.forEach((element, index) => {
                mapping[element] = index;
            });

            // Prepare payload for createSheetApi
            const payload = {
                name: sheet,
                pid: currentP_ID.current,
                folderId: currentFolderID.current,
                // columns: notFixed,
            };
            for (const col of fileData[sheet][0]) {
                if (!mandatoryFields.includes(col)) {
                    if (col && !notFixed.includes(col)) {
                        notFixed.push(col);
                    }
                }
            }

            let newFolderId;
            await httpService
                .createNewFolderTestpad(payload, "TESTCASE")
                .then((data) => {
                    newFolderId = data?.data?.ID;
                })
                .catch((err) => {
                    handleToastErrorCatch(err
                        , () => {
                            setLoad(false);
                            setErrList(prevErr => [...prevErr, err?.response?.data?.message]);
                            errorOccured = true;
                        }
                    );
                });

            if (errorOccured) {
                continue;
            }
            const totalRows = fileData[sheet].length; // Excludes header row for now
            const totalChunks = Math.ceil(totalRows / 10); // Calculate total chunks 

            for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                const startIndex = chunkIndex * 10 + 1; // Calculate start index of current chunk
                const endIndex = Math.min((chunkIndex + 1) * 10, totalRows + 1); // Calculate end index of current chunk

                const maina = [];
                const tempData = {};

                for (let i = startIndex; i <= endIndex; i++) {
                    const row = fileData[sheet][i];
                    const obj = {};

                    if (row?.length > 0) {
                        mandatoryFields.forEach((col) => {
                            obj[fixedJson[col]] = row[mapping[col]];
                        });

                        obj["folderId"] = newFolderId;
                        obj["pid"] = currentP_ID.current;
                        obj["testSteps"] = obj["testSteps"]?.split('\n');
                        obj['stepsDescription'] = obj["stepsDescription"]?.split('\n');
                        obj["expectedBehaviour"] = obj["expectedBehaviour"]?.split('\n');
                        //extra data fields
                        // for (const col of fileData[sheet][0]) {
                        //     if (!fieldOptions.includes(col)) {
                        //         if (col && !notFixed.includes(col)) {
                        //             notFixed.push(col);
                        //         }
                        //         tempData[col] = row[mapping[col]];
                        //             // ? await encryptData(secret, salt, row[mapping[col]] + "")
                        //             // : "";
                        //     }
                        // }

                        if (checkValidity(obj, i, sheet)) {
                            maina.push({ ...obj })
                            // maina.push({ ...obj, sheetData: { ...tempData } });
                        }
                    }
                }


                if (maina.length > 0) {

                    await httpService
                        .importExcel(maina)
                        .then((data) => {
                            getErrorMessage(data);
                            data?.data.forEach((tc) => {
                                setErrList(prevErr => [...prevErr, `Duplicate test cases with the name ${tc} have been identified. Only the first unique test case is created.`]);
                            })
                        })
                        .catch((err) => {
                            handleToastErrorCatch(err
                                //     , () => {
                                //     setLoad(false);
                                //     return;
                                // }
                            );
                        });

                    totalRowsProcessed += maina.length;

                    setTotalRowsDone((totalRowsProcessed / totalRows).toFixed(2) * 100);
                    if (totalChunks === chunkIndex + 1) {
                        // setUpload(false);
                        toast.success(
                            `Data for Sheet :${sheet} Uploaded Successfully`
                        );
                    }
                }
            }
        }

        setLoad(false);

    }

    useEffect(() => {
    }, [errList])
    const getErrMessages = (err) => {
        const errMap = err?.map(item => <li>{item}</li>);
        return errMap;
    }

    const createExcelDialogContent = () => {
        return load ? (
            <div>
                <img src={excelLoading} alt='loading excel' className='excelLoadingGif' />
                <div className='px-4'>{loadingData("Importing testcases from excel...")}</div>
                <div className='mt-2 px-5 mx-5 custom-progress-bar'>
                    <ProgressBar
                        className="custom-progressbar shiny-animation"
                        color="#ff7e7c"
                        value={totalRowsDone}>
                    </ProgressBar>
                </div>
            </div>
        ) :
            (
                <>
                    <div className="d-inline-block wrapperPillsMlab align-items-center">
                        <ul
                            className="nav nav-pills mb-3 mt-2"
                            id={"pills-tab"}
                            role="tablist"
                        >
                            <li className="nav-item mr-1" role="presentation">
                                <div
                                    className={`nav-link tcDetailPillMlab px-3 ${activeTab === 'import' ? 'active' : ''}`}
                                    id={"pills-import-tab-"}
                                    onClick={() => switchTab('import')}
                                    data-toggle="pill"
                                    role="tab"
                                    type="button"
                                    aria-selected={activeTab === 'import'}>
                                    Import
                                </div>
                            </li>
                            <li className="nav-item" role="presentation">
                                <div
                                    className={`nav-link tcDetailPillMlab px-3 d-flex align-items-center ${activeTab === 'errors' ? 'active' : ''}`}
                                    id="pills-errors-tab-"
                                    data-toggle="pill"
                                    onClick={() => switchTab('errors')}
                                    role="tab"
                                    type="button"
                                    aria-selected={activeTab === 'errors'}
                                >
                                    Errors
                                    {errList?.length > 0 && (
                                        <Badge
                                            className="ms-2 err-btn-health errorBadge"
                                            value={errList.length}
                                        ></Badge>
                                    )}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className={`tab-pane fade show ${activeTab === 'import' ? 'active' : ''}`}
                            id="pills-import"
                            role="tabpanel"
                            aria-labelledby="pills-import-tab-"
                        >
                            <div className="mt-2 ms-1">
                                Basic Info
                                <span className="ms-2">
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        className="infoicon fa-sm custom-target-icon-username"
                                    />
                                </span>
                                <ul className="mt-1 mb-1">
                                    <li>
                                        Download the Excel template for uploading Manual Testcases by
                                        clicking
                                        <strong>
                                            <a
                                                className="ms-1"
                                                href={`${process.env.PUBLIC_URL}/sample_excel.xlsx`}
                                                download
                                            >
                                                here
                                            </a>
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        To ensure that your testcases are uploaded correctly, please
                                        follow the specified format.
                                    </li>
                                    <li>
                                        Upload the appropriate excel file, ensuring it's in either
                                        .xlsx/.xls/.csv format.
                                    </li>
                                    <li>
                                        Once uploaded, a folder with the same name as the 'Sheet_Name'
                                        will be created, containing all the testcases from that sheet.
                                    </li>
                                    <li>
                                        If your excel file has multiple sheets, multiple folders with
                                        their respective testcases will be created accordingly.
                                    </li>
                                </ul>
                                {/* <div>
                                    <span className='d-flex align-items-center'>
                                        <FontAwesomeIcon
                                            className="me-2"
                                            icon={faCirclePlus}
                                        />
                                        Customize Fields
                                        <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} style={{ transform: "scale(0.7)" }} />
                                    </span>
                                </div> */}
                                <div className="mt-4 mx-2">
                                    <div className="ms-1 mb-1 mt-1 pDetails-heading">
                                        Project Name<sup> *</sup>
                                    </div>
                                    <div className="mb-2">
                                        <InputText
                                            type={"text"}
                                            value={currentP_Name.current}
                                            className="w-100"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="ms-1 mb-1 mt-1 pDetails-heading">
                                        Upload File<sup> *</sup>
                                    </div>
                                    <FileUpload
                                        name="demo[]"
                                        accept=".xlsx,.xls,.csv"
                                        uploadOptions={uploadOptions}
                                        cancelOptions={cancelOptions}
                                        maxFileSize={1000000}
                                        onSelect={onFileSelect}
                                        uploadHandler={uploadHandler}
                                        customUpload={true}
                                        className=""
                                        chooseLabel="Browse"
                                    />
                                    {checked && <div>
                                        <div className="ms-1 mt-2 pDetails-heading">
                                            Field Mapper<sup> *</sup>
                                        </div>
                                        <div>
                                            {createHeadersKeyValueInputs(
                                                keyHeaders,
                                                setKeyHeaders,
                                                fieldOptions
                                            )}
                                            <button
                                                className="btn themeBtn mt-3"
                                                onClick={(e) => addFormField("headers", keyHeaders, setKeyHeaders)}
                                            >
                                                Add Data
                                            </button>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div
                            className={`tab-pane fade ${activeTab === 'errors' ? 'show active' : ''}`}
                            id="pills-errors-"
                            role="tabpanel"
                            aria-labelledby="pills-errors-tab-"
                        >
                            <>
                                <div className="mt-2 ms-1 errMsgDiv p-3">
                                    <strong>
                                        <span className="me-2">
                                            <FontAwesomeIcon
                                                className="warningBtn"
                                                icon={faTriangleExclamation}
                                            />
                                        </span>
                                        Following errors have been encountered while importing
                                        testcases from excel:

                                    </strong>
                                    <div className="mt-2">
                                        {errList?.length > 0 ? (
                                            getErrMessages(errList)
                                        ) : (
                                            <div>No Errors Found in uploading yet !</div>
                                        )}
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </>
            );
    };

    return (
        <>
            <button
                className='btn-success btn mx-2 py-2 px-3 mt-1 themeBtnOutline'
                onClick={() => { setExcelDialog(true) }}
            >
                <FontAwesomeIcon icon={faArrowUpFromBracket} className='me-2' />
                Import from Excel
            </button>
            <Dialog
                blockScroll={true}
                draggable={false}
                header="Import Manuals from Excel"
                visible={excelDialog}
                onHide={() => {
                    !load && setExcelDialog(false);
                    !load && setErrList([]);
                }}
                className="excel-dialog"
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "60vw" }}
            >
                {createExcelDialogContent()}
            </Dialog>
        </>
    )
}

export default ImportExcel