import {
    PROJECT_DATA_REQUIREMENTS,
    CLEAR_PROJECT_DATA_REQUIREMENTS
} from "../actions/type";

const initialState = {
    project_data_requirements: []
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PROJECT_DATA_REQUIREMENTS:
            return {
                ...state,
                project_data_requirements: payload,
            };
        case CLEAR_PROJECT_DATA_REQUIREMENTS:
            return {
                ...state,
                project_data_requirements: [],
            };
        default:
            return state;
    }
}
