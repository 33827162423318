import React, { useState } from "react";
import "./StandoutFeaturesCarouselCard.css";
import { Dialog } from "primereact/dialog";

const StandoutFeaturesCarouselCard = ({ heading, description, image }) => {
  const [visible, setVisible] = useState(false);
  const handleImageClick = () => {
    setVisible(true);
  };
  return (
    <>
    <div className="standout-feature-card-wrapper">
      <p className="standout-feature-card-heading">{heading}</p>
      <div className="standout-feature-image-wrapper">
        <img
          className="standout-feature-card-image clickable"
          src={image}
          alt={heading}
          loading="lazy"
          onClick={handleImageClick} // Attach onClick event handler directly to the image
        />
      </div>

      <p className="standout-feature-card-description">{description}</p>
    </div>
    <Dialog
        header=""
        visible={visible}
        maximizable
        className="dailog-wrapper"
        style={{ width: '60vw'}} // Set padding to 0 to reduce padding around the dialog content
        onHide={() => setVisible(false)}
      >
        <img
          src={image}
          alt={heading}
          loading="lazy"
          style={{ maxWidth: '100%'}} // Set margin to 0 to reduce space around the image
        />
      </Dialog>

    </>
  );
};

export default StandoutFeaturesCarouselCard;
