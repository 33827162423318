import {
  faAnglesLeft,
  faAnglesRight,
  faArrowUpRightFromSquare,
  faCheck,
  faUndoAlt,
  faSignOut,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  CLEAR_MARK_STATUS_WHILE_MANUAL_EXECUCTION,
  MARK_STATUS_WHILE_MANUAL_EXECUCTION,
} from "../../../actions/type";
import httpService from "../../../services/http.service";
import {
  convertTexttoStatus,
  createColumns,
  getErrorMessage,
  loadingData,
} from "../../Helper/Helper";
import NoAccess from "../../UI/NoAccess/NoAccess";
import UploadScreenshot from "../../UI/UploadScreenshot/UploadScreenshot";
import "./ManualTestcasesExe.css";
import MarkStatus from "./MarkStatus";
import NoData from "../../UI/NoData/NoData";
import { Checkbox } from "primereact/checkbox";
import { ProgressBar } from "primereact/progressbar";
import { Tooltip } from "primereact/tooltip";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";

const ManualTestcasesExe = ({ s_id, s_run_id, tc_run_id, p_id }) => {
  const [allManuals, setAllManuals] = useState(null);
  const [index, setIndex] = useState(0);
  const [activeTc, setActiveTc] = useState(0);
  const [stepData, setStepData] = useState(null);
  const [filters, setFilters] = useState({});
  const manualTable = useRef(null);
  const [disable, setDisable] = useState(false);
  const [referenceData, setReferenceData] = useState(null);
  const [confirmPrompt, setConfirmPrompt] = useState(false);
  const [leaveTc, setLeaveTc] = useState("next");
  const [indexRef, setIndexRef] = useState(null);
  const [selectedTcs, setSelectedTcs] = useState(null);
  const [status, setStatus] = useState(null);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [testStepData, setTestStepData] = useState(null);
  const executionReportLink = useRef(null);
  const [noAccess, setNoAccess] = useState(false);
  const [checked, setChecked] = useState(false);
  const [allTcId, setAllTcId] = useState(null);
  const [TcId, setTcId] = useState(null);
  const [testStatus, setTestStatus] = useState(null);
  const [saveOption, setSaveOption] = useState("");
  const [totalRowsDone, setTotalRowsDone] = useState();
  const [testcaseExecutionLoading, setTestcaseExecutionLoading] =
    useState(false);
  const [selectedTc, setSelectedTc] = useState(null);

  const dispatch = useDispatch();
  const username = useSelector((state)=>state.auth.userProfile.username)
  useEffect(() => {
    if (allManuals?.[index] && !stepData) {
      setDisable(true);

      httpService
        .getManualTestcaseData(allManuals?.[index]?.tc_run_id)
        .then((data) => {
          getErrorMessage(data);
          setStepData(data?.data);
          setTestStepData(data?.data?.testSteps?.["data"]);
          let tcData = [];
          data?.data?.testSteps?.data?.forEach((arr, i) => {
            let insideObj = {};
            Object.keys(arr)?.map((key) => {
              if (!["id", "expected result"].includes(key.toLowerCase())) {
                insideObj[key.toLowerCase()] = arr?.[key]?.["value"];
              }
            });
            tcData[i] = insideObj;
          });
          sessionStorage.setItem("tcData", JSON.stringify(tcData));
          setReferenceData([...tcData]);
          setDisable(false);
        })
        .catch((err) => {
          handleToastErrorCatch(err, () => setDisable(false));
        });
    }
  }, [index, allManuals?.[index]?.tc_run_id]);

 

  useEffect(() => {
    setSelectedTc(allManuals?.[0]?.tc_run_id);
    getManualData();
    executionReportLink.current =
      window.location.origin +
      "/#/autolytics/execution-report?s_run_id=" +
      s_run_id +
      "&p_id=" +
      p_id;
    return () => {
      dispatch({
        type: CLEAR_MARK_STATUS_WHILE_MANUAL_EXECUCTION,
      });
    };
  }, []);

  const getManualData = async () => {
    setDisable(true);
    await httpService
      .getAllManualTestcase(s_id, s_run_id)
      .then((data) => {
        getErrorMessage(data);
        if (data?.operation?.toLowerCase() === "success") {
          setAllManuals(data?.data);
        }
        setDisable(false)
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          setNoAccess(true);
        }
        handleToastErrorCatch(err,()=>setDisable(false));
      });
    if (saveOption.length > 0) {
      httpService
        .getManualTestcaseData(allManuals?.[index]?.tc_run_id)
        .then((data) => {
          getErrorMessage(data);
          setStepData(data?.data);
          setTestStepData(data?.data?.testSteps?.["data"]);
          let tcData = [];
          data?.data?.testSteps?.data?.forEach((arr, i) => {
            let insideObj = {};
            Object.keys(arr)?.map((key) => {
              if (!["id", "expected result"].includes(key.toLowerCase())) {
                insideObj[key.toLowerCase()] = arr?.[key]?.["value"];
              }
            });
            tcData[i] = insideObj;
          });
          sessionStorage.setItem("tcData", JSON.stringify(tcData));
          setReferenceData([...tcData]);
          setDisable(false);
        })
        .catch((err) => {
          handleToastErrorCatch(err, () => setDisable(false));
        });
    }
  };
  const skeletonBridgeRows = Array.from({ length: 5 });
  const skeletonBridgeTemplate = () => {
    return <Skeleton></Skeleton>;
  };
  const tableSkeleton = (heading) => {
    return (
      <DataTable
        responsiveLayout="scroll"
        header={heading}
        value={skeletonBridgeRows}
        className="p-datatable-striped mt-3 test-stepTable"
      >
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "5%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "30%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "15%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "25%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "25%" }}
          body={skeletonBridgeTemplate}
        ></Column>
      </DataTable>
    );
  };

  const prevIndex = () => {
    setStepData(null);
    setSelectedTcs(null);
    setStatus(null);
    dispatch({
      type: CLEAR_MARK_STATUS_WHILE_MANUAL_EXECUCTION,
    });
    setTestStepData(null);

    setReferenceData(null);
    if (index > 0) {
      setIndex(index - 1);
      setActiveTc(index - 1);
    } else {
      setIndex(allManuals?.length - 1);
      setActiveTc(allManuals?.length - 1);
    }

    setConfirmPrompt(false);
  };
  const nextIndex = () => {
    setStepData(null);
    setSelectedTcs(null);
    setStatus(null);

    dispatch({
      type: CLEAR_MARK_STATUS_WHILE_MANUAL_EXECUCTION,
    });
    setTestStepData(null);
    setReferenceData(null);
    if (index < allManuals?.length - 1) {
      setIndex(index + 1);
      setActiveTc(index + 1);
    } else {
      setIndex(0);
      setActiveTc(0);
    }
    setConfirmPrompt(false);
  };

  const saveAndNext = async (fn) => {
    if (saveOption === "all") {
      setTestcaseExecutionLoading(true);
      setDisable(true);

      let totalRowsProcessed = 0;
      setTotalRowsDone(totalRowsProcessed);
      const totalRows = allTcId?.length; // Excludes header row for now
      const totalChunks = Math.ceil(totalRows / 4); // Calculate total chunks

      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const startIndex = chunkIndex * 4; // Calculate start index of current chunk
        const endIndex = Math.min((chunkIndex + 1) * 4, totalRows + 1); // Calculate end index of current chunk

        const chunkTestcase = [];

        for (let i = startIndex; i < endIndex; i++) {
          const row = allTcId[i];
          if (row) {
            chunkTestcase?.push(row);
          }
        }

        if (chunkTestcase?.length > 0) {
          await httpService
            .putManualTcs(chunkTestcase)
            .then((data) => {
              if (
               
                data?.operation?.toLowerCase() === "success"
              ) {
              } else {
                getErrorMessage(data);
              }
            })
            .catch((err) => {
              handleToastErrorCatch(err);
            });

          totalRowsProcessed += chunkTestcase.length;

          setTotalRowsDone((totalRowsProcessed / totalRows).toFixed(2) * 100);
          if (totalChunks === chunkIndex + 1) {
            toast.success(`Testcases executed Successfully`);
          }
        }
      }
      setStepData(null);
      setTestStepData(null);
      getManualData();
      setAllTcId(null);
      setTcId(null)
      setSaveOption("");
      setDisable(false);
      setIsStatusSelected(false);
      setTestcaseExecutionLoading(false);
    } else if (saveOption === "multiple") {
      setDisable(true);
      await httpService
        .putManualTcs(TcId)
        .then((data) => {
          if (data?.operation?.toLowerCase() === "success") {
            getManualData();

            if (allManuals?.length > 1) {
              fn();
            }
          } else {
            getErrorMessage(data);
          }
        })
        .catch((err) => {
          handleToastErrorCatch(err);
        });
      setStepData(null);
      setTestStepData(null);
      getManualData();
      setTcId(null);
      setSaveOption("");
      setDisable(false);
      setIsStatusSelected(false);
    } else {
      let arr = [];
      referenceData?.forEach((data) => {
        if (data?.["status"]) {
          arr.push(data?.["status"]);
        }
      });
      if (arr?.length === stepData?.testSteps?.data?.length || !stepData) {
        if (!stepData) {
          if (allManuals?.length > 1) {
            fn();
          }
        } else {
          let payload = [
            {
              tc_run_id: allManuals?.[index]?.tc_run_id,
              steps: referenceData,
            },
          ];
          setDisable(true);
          await httpService
            .putManualTcs(payload)
            .then((data) => {
              if (
               
                data?.operation?.toLowerCase() === "success"
              ) {
                getManualData();
                // nextIndex();
                if (allManuals?.length > 1) {
                  fn();
                }
              } else {
                getErrorMessage(data);
              }
              setDisable(false);
            })
            .catch((err) => {
              handleToastErrorCatch(err, () => setDisable(false));
            });
        }
      } else {
        toast.error("All Status should be marked !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const selectAll = () => {
    if (allTcId) {
      setAllTcId(null);
      setSaveOption("");
      setTcId(null);
      setIsStatusSelected(false);
    } else {
      let allTestcase = [];
      allManuals.forEach((item) => {
       if(item?.assignedTo===username){
        let testcaseObj = { tc_run_id: item?.tc_run_id, status: "" };
        allTestcase.push(testcaseObj);
       }
      });
      setAllTcId(allTestcase);
      if(allTestcase?.length>0){
        setSaveOption("all");
      }
    }
  };

  const selectTcId = (id) => {
    if (TcId && TcId?.length > 0) {
      if (TcId?.some((dat) => dat?.tc_run_id === id)) {
        if (TcId?.length === 1) {
          TcId.pop();
          setSaveOption("");
        } else {
          TcId.forEach((item) => {
            if (item?.tc_run_id === id) {
              setTcId(
                TcId?.filter(
                  (testcase) => testcase?.tc_run_id !== item?.tc_run_id
                )
              );

              setIsStatusSelected(false);
            }
          });
        }
      } else {
        setTcId([...TcId, { tc_run_id: id, status: "" }]);
      }
    } else {
      let newTcId = [{ tc_run_id: id, status: "" }];
      setTcId(newTcId);
    }
  };

  const setTestcaseStatus = (status) => {
    setIsStatusSelected(true);
    setTestStatus(status);
    if (saveOption === "all") {
      const updatedAllTestcase = allTcId?.map((item) => ({
        ...item,
        status: status,
      }));
      setAllTcId(updatedAllTestcase);
    } else if (saveOption === "multiple") {
      const updatedAllTestcase = TcId?.map((item) => ({
        ...item,
        status: status,
      }));
      setTcId(updatedAllTestcase);
    }
  };

  const indexNav = () => {
    if (index !== indexRef) {
      setStepData(null);
      setSelectedTcs(null);
      setStatus(null);

      dispatch({
        type: CLEAR_MARK_STATUS_WHILE_MANUAL_EXECUCTION,
      });
      setTestStepData(null);

      setReferenceData(null);
      setIndex(indexRef);
    }
    setActiveTc(indexRef);
    setConfirmPrompt(false);
  };
  const navigateTc = (fn) => {
    if (
      JSON.stringify(referenceData) === sessionStorage.getItem("tcData") &&
      fn
    ) {
      fn();
    } else {
      setConfirmPrompt(true);
    }
  };
  const markStatus = (value) => {
    setStatus(value);

    let tempStepData = [];
    selectedTcs?.forEach((data, index) => {
      let val = data?.Id?.value - 1;
      tempStepData[index] = val;
    });
    dispatch({
      type: MARK_STATUS_WHILE_MANUAL_EXECUCTION,
      payload: {
        markedTcSteps: tempStepData,
        markedStatus: value,
      },
    });
  };

  return !noAccess ? (
    <>
      <div className="container-fluid reqSection mx-2">
        <div className="d-flex flex-wrap align-items-center pt-2 mx-2">
          <div className="align-items-center ms-3 mt-4 mb-3 pb-1">
            <div className="headerTest">Execute Manual Testcase(s)</div>

            <div
              className="breadLink"
              onClick={() => window.open(executionReportLink.current)}
            >
              <FontAwesomeIcon
                className="tabIcon me-2"
                icon={faArrowUpRightFromSquare}
                title={"Open Suite Execution Report"}
              />
              <span className="mt-1">
                Suite ID: <i>{s_id}</i> | Suite Execution Report
              </span>
            </div>
          </div>
        </div>
        <Dialog
          blockScroll={true}
          className="createTestcaseDialog"
          visible={testcaseExecutionLoading}
          draggable={false}
          breakpoints={{ "960px": "75vw" }}
          style={{
            width: "40vw",
          }}
        >
          <div>
            <div className="px-4">
              {loadingData("Executing Testcases ....")}
            </div>
            <div className="mt-2 px-5 mx-5 custom-progress-bar">
              <ProgressBar
                className="custom-progressbar shiny-animation"
                color="#ff7e7c"
                value={Math.ceil(totalRowsDone)}
              ></ProgressBar>
            </div>
          </div>
        </Dialog>
        <div className="container row ms-3 mt-2">
          <p className="headerTest my-0 col-12">
            Testcase Name : {stepData?.name}
          </p>
        </div>

        <div className="container row">
          <div className="col-8">
            {stepData?.testSteps ? (
              <>
              {stepData?.assignedTo!==username && (
                 <Tooltip
                 position="top"
                 target={`.stepDataTable-${stepData?.isolatedVersionId}`}
                 className="stepDataTooltip"
               />
              )}
              <div className={`mt-2 ${saveOption!=="" && "datatable-disabled"} stepDataTable-${stepData?.isolatedVersionId}`} data-pr-tooltip="This testcase is not assigned to you">
                <DataTable
                  id={"manualTable"}
                  key={"manualexe"}
                  ref={manualTable}
                  reorderableColumns
                  filters={filters}
                  onFilter={(e) => {
                    setFilters(e.filters);
                  }}
                  alwaysShowPaginator={true}
                  resizableColumns
                  stripedRows
                  columnResizeMode="expand"
                  value={testStepData}
                  paginator={saveOption.length===0}
                  rows={50}
                  responsiveLayout="scroll"
                  emptyMessage={<NoData />}
                  removableSort
                  currentPageReportTemplate="Total {totalRecords} Test Step(s) Found"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
                  rowsPerPageOptions={[25, 50, 80, 100]}
                  scrollHeight={"50vh"}
                  selectionMode={saveOption.length===0 && "checkbox"}
                  selection={selectedTcs}
                  onRowClick={(e) => e.preventDefault()}
                  onSelectionChange={(e) => {
                    Object.keys(e.value)?.length <= 0 && setStatus(null);
                    setSelectedTcs(e?.value);
                  }}
                  header={
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        Select and Mark Step(s) :
                      </div>
                      {saveOption.length===0 && stepData?.assignedTo===username &&
                      (
                        <div className="d-flex flex-wrap align-items-center">
                        {["PASS", "FAIL", "INFO"].map((data) => (
                          <div
                            className="ms-2"
                            onClick={() => {
                              selectedTcs && Object.keys(selectedTcs).length > 0
                                ? markStatus(data)
                                : toast.info("No Testcase(s) Selected.", {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "mt-3",
                                  });
                            }}
                          >
                            {convertTexttoStatus(
                              data,
                              `clickable ${
                                status?.toLowerCase() === data?.toLowerCase()
                                  ? "highligtBorder"
                                  : ""
                              }`
                            )}
                          </div>
                        ))}
                      </div>
                      )}
                      <button
                        className="btn btn-success mx-2 my-auto ms-auto themeBtn"
                        disabled={
                          selectedTcs === null || selectedTcs?.length < 1
                        }
                        onClick={() => {
                          setSelectedTcs(null);
                        }}
                      >
                        <FontAwesomeIcon
                          className="mx-1 tabIcon"
                          icon={faUndoAlt}
                        />
                        Clear Selection
                      </button>
                    </div>
                  }
                  className="test-stepTable"
                >
                  <Column
                    selectionMode="multiple"
                    headerClassName={`headercolumnsPrime`}
                    className={"columnsPrime"}
                    headerStyle={{ width: "3em" }}
                  ></Column>

                  {stepData?.testSteps?.["headers"]
                    ? createColumns(
                        stepData?.testSteps?.["headers"],
                        true,
                        false,
                        stepData?.testSteps?.["data"],
                        true,
                        null,
                        null,
                        {
                          upload_screenshot: true,
                          mark_status: true,
                        },
                        {
                          upload_screenshot: UploadScreenshot,
                          mark_status: MarkStatus,
                        },
                        {
                          upload_screenshot_props: {
                            allManuals: stepData?.testSteps,
                          },
                          mark_status_props: {
                            tc_data: referenceData,
                            setTc_Data: setReferenceData,
                            main_status: status,
                            assignedTo:stepData?.assignedTo
                          },
                        }
                      )
                    : null}
                </DataTable>
              </div>
              </>
            ) : (
              tableSkeleton()
            )}
          </div>
          <div className="col-4 d-flex flex-column justify-content-around">
            <div className="card shadow-sm allTcCard mt-2 test-stepTable">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <Checkbox
                    className="mx-2"
                    disabled={isStatusSelected}
                    onChange={(e) => {
                      
                      selectAll();
                    }}
                    checked={allTcId?.length > 0}
                  ></Checkbox>

                  <strong className="headerTest my-2">
                    Total Testcase(s) : {allManuals?.length}
                  </strong>
                </div>
                {allManuals
                  ? allManuals?.map((data, indexing) => (
                    <>
                    {data?.assignedTo!==username && (
                      <Tooltip
                      position="top"
                      target={`.allManual-${indexing}`}
                    />
                    )}
                      <div className={`d-flex align-items-center allManual-${indexing}`}
                       data-pr-tooltip="This testcase is not assigned to you"
                       >
                        
                        <Checkbox
                          className="mx-2"
                          disabled={isStatusSelected || saveOption === "all" || data?.assignedTo!==username}
                          onChange={(e) => {
                            setSaveOption("multiple");

                            selectTcId(data?.tc_run_id);
                          }}
                          checked={
                            TcId?.some(
                              (item) => item?.tc_run_id === data?.tc_run_id
                            ) || allTcId?.some(
                              (item) => item?.tc_run_id === data?.tc_run_id
                            )
                          }
                        ></Checkbox>

                        <button
                          disabled={
                            disable || 
                            saveOption === "all" ||
                            saveOption === "multiple"
                          }
                          key={data?.name + data?.tc_id}
                          className={`btn btn-secondary my-2 py-2 w-100 ${
                            activeTc === indexing
                              ? `activeBtn ${
                                  data?.status
                                    ? data?.status?.toLowerCase() + "-btn"
                                    : ""
                                }`
                              : ` ${
                                  data?.status
                                    ? data?.status?.toLowerCase() + "-btn"
                                    : ""
                                }`
                          }`}
                          onClick={() => {
                            if (activeTc !== indexing) {
                              setSelectedTc(data);
                              setLeaveTc("btnNav");
                              setIndexRef(indexing);
                              navigateTc(() => {
                                if (index !== indexing) {
                                  setStepData(null);
                                  setSelectedTcs(null);
                                  setStatus(null);

                                  dispatch({
                                    type: CLEAR_MARK_STATUS_WHILE_MANUAL_EXECUCTION,
                                  });
                                  setTestStepData(null);
                                  setTestStepData(null);
                                  setReferenceData(null);
                                  setIndex(indexing);
                                }
                                setActiveTc(indexing);
                                setConfirmPrompt(false);
                              });
                            }
                          }}
                        >
                          {data?.tc_name}
                        </button>
                      </div>
                      </>
                    ))
                  : [1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5]?.map(
                      (data, indexing) => (
                        <>
                          <button
                            disabled={disable}
                            key={data?.name + data?.tc_id}
                            className={`btn btn-secondary my-2 py-3 px-3 w-100`}
                          ></button>
                        </>
                      )
                    )}
                    
              </div>
            </div>
            {(saveOption === "all" ||
              (saveOption === "multiple" && TcId.length > 0)) && (
              <div
                className={`d-flex flex-wrap align-items-center justify-content-center mt-3 `}
              >
                {["PASS", "FAIL", "INFO"].map((data) => (
                  <div
                    className={`ms-2 ${
                      testStatus !== data && isStatusSelected && "notSelected"
                    }`}
                    onClick={() => {
                      setTestcaseStatus(data);
                    }}
                  >
                    {convertTexttoStatus(
                      data,
                      `clickable ${
                        status?.toLowerCase() === data?.toLowerCase()
                          ? "highligtBorder"
                          : ""
                      }`
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className="d-flex align-items-center my-4 mx-auto ">
              {saveOption === "" && (
                <button
                  className="btn btn-success themeBtn"
                  disabled={disable || allManuals?.length <= 1}
                  onClick={() => {
                    setLeaveTc("prev");
                    navigateTc(prevIndex);
                  }}
                >
                  <FontAwesomeIcon
                    className="mx-1 tabIcon"
                    icon={faAnglesLeft}
                  />{" "}
                  Previous{" "}
                </button>
              )}
              <button
                className="btn btn-success mx-2 themeBtn"
                onClick={() => {
                  saveAndNext(nextIndex);
                }}
                disabled={
                  disable || (saveOption.length > 0 && !isStatusSelected) || (stepData?.assignedTo!==username && saveOption.length===0)
                }
              >
                <FontAwesomeIcon className="mx-1 tabIcon" icon={faCheck} />{" "}
                {`Save ${saveOption.length > 0 ? "Testcases" : ""}`}
              </button>

              {saveOption !== "" && (
                <button
                  className="btn btn-success  themeBtn"
                  onClick={() => {
                    setIsStatusSelected(false);
                    setSaveOption("");
                    setTcId(null);
                    setAllTcId(null);
                  }}
                >
                  <FontAwesomeIcon className="mx-1 tabIcon" icon={faTimes} />
                  Cancel
                </button>
              )}

              {saveOption === "" && (
                <button
                  className="btn btn-success  themeBtn"
                  onClick={() => {
                    setLeaveTc("next");
                    navigateTc(nextIndex);
                  }}
                  disabled={disable || allManuals?.length <= 1}
                >
                  Next{" "}
                  <FontAwesomeIcon
                    className="mx-1 tabIcon"
                    icon={faAnglesRight}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        blockScroll={true}
        className="createTestcaseDialog"
        header={`Warning`}
        visible={confirmPrompt}
        onHide={() => {
          setConfirmPrompt(false);
        }}
        draggable={false}
        breakpoints={{ "960px": "75vw" }}
        style={{
          width: "40vw",
        }}
      >
        <div className="text-center mt-3 py-2 mx-3 deletePrompt">
          <h5>
            Changes Not Saved Yet! <br></br>
            <div className="mt-2">
              Please Save them or Your Progress will be lost.{" "}
            </div>
          </h5>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <div
            onClick={() => {
              if (leaveTc === "prev") {
                saveAndNext(prevIndex);
              } else if (leaveTc === "next") {
                saveAndNext(nextIndex);
              } else {
                saveAndNext(() => {
                  indexNav();
                });
              }
            }}
            className="btn btn-success"
          >
            <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faCheck} />
            Save
          </div>
          <div
            onClick={() => {
              if (leaveTc === "prev") {
                prevIndex();
              } else if (leaveTc === "next") {
                nextIndex();
              } else {
                indexNav();
              }
            }}
            className="btn btn-danger mx-2"
          >
            <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faSignOut} />
            Leave
          </div>
        </div>
      </Dialog>
    </>
  ) : (
    <NoAccess />
  );
};

export default ManualTestcasesExe;
