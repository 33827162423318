import React,{ useState } from "react";
import "./FeaturesOfAutolyticsDashboard.css";
import { featureOfAutolyticsDashboardCardsData } from "../../../Helper/MockData/AutolyticsInfoPageMockData";
import { Dialog } from 'primereact/dialog'; // Import the Dialog component from PrimeReact


export const FeaturesOfAutolyticsDashboard = () => {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0); // Assuming activeIndex is managed somewhere

  const handleImageClick = (index) => {
    setActiveIndex(index); // Update the active index
    setVisible(true); // Open the dialog
  };
  return (
    <div className="feature-autolytics-dashboard-background">
      <div className="component-padding mx-auto max-w-1440">
        <p className="component-heading">Features of Autolytics</p>
        <div className="feature-autolytics-cards-wrapper">
          {featureOfAutolyticsDashboardCardsData.map((cardData, index) => {
            return (
              <div
                key={index}
                className="autolytics-info-inner-cards-wrapper"
                onClick={() => handleImageClick(index)} // Attach onClick event handler to each card
                style={{ cursor: 'pointer' }} // Add cursor pointer style
              >
                <img
                  className="autolytics-info-card-image"
                  src={cardData.icon}
                  alt={cardData.heading}
                  loading="lazy"
                />
                <div>
                  <p className="autolytics-info-card-heading">
                    {cardData.heading}
                  </p>
                  <p className="autolytics-info-card-description">
                    {cardData.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Dialog
        header=""
        visible={visible}
        maximizable
        style={{ width: '60vw' }} // Add cursor style to make it pointer
        onHide={() => setVisible(false)}
      >
        <div>
        <img
          src={featureOfAutolyticsDashboardCardsData[activeIndex].icon}
          alt={featureOfAutolyticsDashboardCardsData[activeIndex].heading}
          loading="lazy"
          style={{ width: '100%'}} // Set margin to 0 to reduce space around the image
        />
        </div>
      </Dialog>
    </div>
  );
};

export default FeaturesOfAutolyticsDashboard;
