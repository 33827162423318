import StandoutFeaturesLiveReportingImage from "../../../assets/StandoutFeaturesLiveReportingImage.png";
import StandoutFeaturesReportAnalyticsImage from "../../../assets/StandoutFeaturesReportAnalyticsImage.png";
import StandoutFeaturesManageManualTestingWithRegressionImage from "../../../assets/StandoutFeaturesManageManualTestingWithRegressionImage.png";
import StandoutFeaturesQualityScoreImage from "../../../assets/StandoutFeaturesQualityScoreImage.png";
import StandoutFeaturesTestSuitesFromUIImage from "../../../assets/StandoutFeaturesTestSuitesFromUIImage.png";
import StandoutFeaturesSchedulingJobsImage from "../../../assets/StandoutFeaturesSchedulingJobsImage.png";
import StandoutFeaturesTicketCreationImage from "../../../assets/StandoutFeaturesTicketCreationImage.png";
import LandingPageALabIcon from "../../../assets/LandingPageALabIcon.svg";
import LandingPageAutolyticsIcon from "../../../assets/LandingPageAutolyticsIcon.svg";
import LandingPageMLabIcon from "../../../assets/LandingPageMLabIcon.svg";

import footerFacebookIcon from "../../../assets/footerFacebookIcon.svg";
import footerInstagramIcon from "../../../assets/footerInstagramIcon.svg";
import footerLinkedInIcon from "../../../assets/footerLinkedInIcon.svg";
import footerTwitterIcon from "../../../assets/footerTwitterIcon.svg";
import footerYouTubeIcon from "../../../assets/footerYouTubeIcon.svg";

import gemPypIcon from "../../../assets/gemPypIcon.svg";
import gemJarIcon from "../../../assets/gemJarIcon.svg";
import gemPrfIcon from "../../../assets/gemPrfIcon.svg";
import jewelIcon from "../../../assets/newJewelIcon.svg";

import headerProductsMenuIcon from "../../../assets/headerProductsMenuIcon.svg";
import headerSolutionsMenuIcon from "../../../assets/headerSolutionsMenuIcon.svg";
import headerBuildYourFrameworkMenuIcon from "../../../assets/headerBuildYourFrameworkMenuIcon.svg";

export const headerLinksData = [
  {
    id: "01-autolytics",
    name: "Autolytics",
    link: "/info/autolytics",
    hash: "#/info/autolytics",
    headerLinkMenu: {
      icon: headerProductsMenuIcon,
      heading: "Products",
      description:
        "With our various products test and manage your modern applications",
      menuChildren: [
        {
          id: "menu-child-a-01",
          headingIcon: jewelIcon,
          heading: "Jewel",
          headingLink: "https://jewel.gemecosystem.com/",
          headingBetaLink: "https://jewel-beta.gemecosystem.com/",
          links: [
            {
              name: "Jewel Autolytics",
              betaLink: "https://jewel-beta.gemecosystem.com/#/w/autolytics",
              link: "https://jewel.gemecosystem.com/#/autolytics",
              openInNewTab: true,
            },
            {
              name: "Jewel ALab",
              betaLink: "https://jewel-beta.gemecosystem.com/#/w/ALab",
              link: "https://jewel.gemecosystem.com/#/w/ALab",
              openInNewTab: true,
            },
            {
              name: "Jewel MLab",
              betaLink: "https://jewel-beta.gemecosystem.com/#/w/MLab",
              link: "https://jewel.gemecosystem.com/#/w/MLab",
              openInNewTab: true,
            },
            {
              name: "Jewel Taskboard",
              betaLink: "https://jewel-beta.gemecosystem.com/#/w/taskboard",
              link: "https://jewel.gemecosystem.com/#/w/taskboard",
              openInNewTab: true,
            },
          ],
        },
        {
          id: "menu-child-a-02",
          headingIcon: gemJarIcon,
          heading: "GemJar",
          headingLink: "https://gemjar.gemecosystem.com/",
          headingBetaLink: "https://gemjar-beta.gemecosystem.com/",
          links: [
            {
              name: "JAR RAC",
              betaLink: "https://gemjar-beta.gemecosystem.com/#/jar-rac",
              link: "https://gemjar.gemecosystem.com/#/jar-rac",
              openInNewTab: true,
            },
            {
              name: "JAR Heal",
              betaLink: "https://gemjar-beta.gemecosystem.com/#/jar-heal",
              link: "https://gemjar.gemecosystem.com/#/jar-heal",
              openInNewTab: true,
            },
          ],
        },
        {
          id: "menu-child-a-03",
          headingIcon: gemPypIcon,
          heading: "GemPYP",
          headingLink: "https://gempyp.gemecosystem.com/",
          headingBetaLink: "https://gempyp-beta.gemecosystem.com/",
          links: [
            {
              name: "PYP Rest",
              betaLink: "https://gempyp-beta.gemecosystem.com/#/pyp-rest",
              link: "https://gempyp.gemecosystem.com/#/pyp-rest",
              openInNewTab: true,
            },
            {
              name: "PYP DV",
              betaLink: "https://gempyp-beta.gemecosystem.com/#/pyp-dv",
              link: "https://gempyp.gemecosystem.com/#/pyp-dv",
              openInNewTab: true,
            },
          ],
        },
        {
          id: "menu-child-a-04",
          headingIcon: gemPrfIcon,
          heading: "GemPRF",
          headingLink: "https://gemprf.gemecosystem.com/",
          headingBetaLink: "https://gemprf-beta.gemecosystem.com/",
          links: [
            {
              name: "PRF Autolytics",
              betaLink: "https://gemprf-beta.gemecosystem.com/#/prf-autolytics",
              link: "https://gemprf.gemecosystem.com/#/prf-autolytics",
              openInNewTab: true,
            },
            {
              name: "PRF Lab",
              betaLink: "https://gemprf-beta.gemecosystem.com/#/prf-lab",
              link: "https://gemprf.gemecosystem.com/#/prf-lab",
              openInNewTab: true,
            },
          ],
        },
      ],
    },
  },
  {
    id: "02-testpad",
    name: "Testpad",
    link: "/info/testpad",
    hash: "#/info/testpad",
    headerLinkMenu: {
      icon: headerSolutionsMenuIcon,
      heading: "Solutions",
      description:
        "With our various products test and manage your modern applications",
      menuChildren: [
        {
          id: "menu-child-b-01",
          // headingIcon: jewelIcon,
          heading: "Use Cases",
          headingLink: "",
          links: [
            {
              name: "Codeless API Automation",
              link: "/codeless-api-automation",
            },
            { name: "Data Comparison", link: "/data-comparison" },
            { name: "Web UI Automation", link: "/web-ui-automation" },
            { name: "CI CD CT", link: "/ci-cd-ct" },
            {
              name: "More Usecases",
              link: "https://blustone.gemecosystem.com/category/usecases/",
              isBlueColor: true,
              openInNewTab: true,
            },
          ],
        },
        {
          id: "menu-child-b-02",
          // headingIcon: jewelIcon,
          heading: "For ",
          headingLink: "",
          links: [
            { name: "Large Organizations", link: "/large-organizations" },
            { name: "Startups", link: "/startups" },
            { name: "GemEcosystem Consultancy", link: "/gem-consultancy" },
          ],
        },
        {
          id: "menu-child-b-03",
          // headingIcon: jewelIcon,
          heading: "For Education",
          headingLink: "",
          links: [{ name: "GemEcosystem Academy", link: "/gem-academy" }],
        },
      ],
    },
  },
  {
    id: "03-taskboard",
    name: "Taskboard",
    link: "/info/taskboard",
    hash: "#/info/taskboard",
    headerLinkMenu: {
      icon: headerBuildYourFrameworkMenuIcon,
      heading: "Build Your Framework",
      description: "Build your framework to manage your modern applications",
      menuChildren: [
        {
          id: "menu-child-c-01",
          // headingIcon: jewelIcon,
          heading: "",
          headingLink: "",
          links: [
            {
              name: "Getting Started",
              link: "/getting-started",
            },
            { name: "Open API", link: "/open-api" },
          ],
        },
      ],
    },
  },
  {
    id: "04-work-minutes",
    name: "Work Minutes",
    link: "/info/work-minutes",
    hash: "#/info/work-minutes",
  },
  {
    id: "05-open api",
    name: "Open API",
    link: "https://blustone.gemecosystem.com/2024/02/15/open-api/",
    openInNewTab: true,
  },
  {
    id: "06-release-notes",
    name: "Release Notes",
    link: "https://blustone.gemecosystem.com/2024/03/14/jewel-release-notes/",
    openInNewTab: true,
    hash: "#/release-notes",
  },
];

export const standoutFeaturesCarouselCardsData = [
  {
    heading: "Live Reporting",
    image: StandoutFeaturesLiveReportingImage,
    description:
      "Immediate updates on test case pass/fail, accelerating issue identification by revealing failures instantly.",
  },
  {
    heading: "Report Analytics",
    image: StandoutFeaturesReportAnalyticsImage,
    description:
      "Analyze reports for insights, metrics, and informed decision-making in testing processes.",
  },
  {
    heading: "Manage Manual Testing with Regression",
    image: StandoutFeaturesManageManualTestingWithRegressionImage,
    description:
      "Effortlessly integrate manuals with efficient regression for seamless management.",
  },
  {
    heading: "Quality Score",
    image: StandoutFeaturesQualityScoreImage,
    description:
      "Measure and evaluate testing quality with a dedicated quality score metric.",
  },
  {
    heading: "Test Suites from UI",
    image: StandoutFeaturesTestSuitesFromUIImage,
    description:
      "Effortlessly create, manage test suites through a streamlined user interface experience.",
  },
  {
    heading: "Scheduling Jobs",
    image: StandoutFeaturesSchedulingJobsImage,
    description:
      "Effortlessly schedule tests to optimize resources, meet project timelines seamlessly.",
  },
  {
    heading: "Ticket Creation",
    image: StandoutFeaturesTicketCreationImage,
    description:
      "Streamline issue tracking, resolution by creating tickets directly within testing platform.",
  },
];

export const youMayAlsoLikeCardsData = [
  {
    icon: LandingPageALabIcon,
    heading: "ALab",
    description:
      "Suite creation, on-cloud execution, UI monitoring, GitHub integration, email, and test management.",
    buttonLabel: "Try it now",
    buttonLink: "/try-it-now",
  },
  {
    icon: LandingPageAutolyticsIcon,
    heading: "Autolytics",
    description:
      "Generate detailed reports, fostering extensive testcase and suite analysis with seamless report sharing.",
    buttonLabel: "Try it now",
    buttonLink: "/try-it-now",
  },
  {
    icon: LandingPageMLabIcon,
    heading: "MLab",
    description:
      "Create repository for manual testcases with an enhanced folder structure hierarchy.",
    buttonLabel: "Try it now",
    buttonLink: "/try-it-now",
  },
];

export const footerIcons = [
  {
    id: "facebook",
    icon: footerFacebookIcon,
    link: "https://www.facebook.com/gemecosys",
  },
  {
    id: "Twitter",
    icon: footerTwitterIcon,
    link: "https://twitter.com/gemecosystem",
  },
  {
    id: "Instagram",
    icon: footerInstagramIcon,
    link: "https://instagram.com/gemecosystem",
  },
  {
    id: "LinkedIn",
    icon: footerLinkedInIcon,
    link: "https://www.linkedin.com/company/gemecosystem",
  },
  {
    id: "youtube",
    icon: footerYouTubeIcon,
    link: "https://youtube.com/@Gemecosystem",
  },
];
export const footerLinksData = [
  {
    id: "01-getting-started",
    heading: "Getting Started",
    children: [
      {
        id: "01-autolytics",
        heading: "Autolytics",
        link: "/info/autolytics",
      },
      {
        id: "02-testpad",
        heading: "Testpad",
        link: "/info/testpad",
      },
      {
        id: "03-taskboard",
        heading: "Taskboard",
        link: "/info/taskboard",
      },
      {
        id: "04-work-minutes",
        heading: "Work Minutes",
        link: "/info/work-minutes",
      },
      {
        id: "03-ope-api",
        heading: "Open API",
        link: "https://blustone.gemecosystem.com/2024/02/15/open-api/",
        openLinkInNewTab: true,
      },
    ],
  },
  {
    id: "02-our",
    heading: "Our Offerings",
    children: [
      {
        id: "01-working-at",
        heading: "Jewel",
        betaLink: "https://jewel-beta.gemecosystem.com/",
        link: "https://jewel.gemecosystem.com/",
      },
      {
        id: "02-gemjar",
        heading: "GemJar",
        openLinkInNewTab: true,
        betaLink: "https://gemjar-beta.gemecosystem.com/",
        link: "https://gemjar.gemecosystem.com/",
      },
      {
        id: "03-gempyp",
        heading: "GemPyp",
        openLinkInNewTab: true,
        betaLink: "https://gempyp-beta.gemecosystem.com/",
        link: "https://gempyp.gemecosystem.com/",
      },

      {
        id: "04-gemprf",
        heading: "GemPrf",
        openLinkInNewTab: true,
        betaLink: "https://beta.gemecosystem.com/#/gem-prf",
        link: "https://gemecosystem.com/#/gem-prf",
      },
    ],
  },
  {
    id: "03-company",
    heading: "Company",
    children: [
      {
        id: "01-about-us",
        heading: "About Us",
        betaLink: "https://beta.gemecosystem.com/#/about-us",
        link: "https://gemecosystem.com/#/about-us",
        openLinkInNewTab: true,
      },
      {
        id: "02-blogs",
        heading: "Blogs",
        link: "https://blustone.gemecosystem.com/",
        openLinkInNewTab: true,
      },
      {
        id: "03-contact-us",
        heading: "Contact Us",
        link: "contact-us",
        openLinkInNewTab: true,
        betaLink:
          "https://beta.gemecosystem.com/#/gem-consultancy#contact-for-resources",
        link: "https://gemecosystem.com/#/gem-consultancy#contact-for-resources",
      },
      {
        id: "02-working-at",
        heading: "Working at Gemini",
        openLinkInNewTab: true,
        link: "https://www.geminisolutions.com/life-at-gemini",
      },
    ],
  },
];
