import React, { useEffect } from "react";
import TestpadPageHeroSection from "../../Layouts/TestpadPage/TestpadPageHeroSection/TestpadPageHeroSection";
import SpecializedModules from "../../Layouts/TestpadPage/SpecializedModules/SpecializedModules";
import Header from "../../UI/Header/Header";
import NewFooter from "../../UI/NewFooter/NewFooter";
import ALab from "../../Layouts/TestpadPage/ALab/ALab";
import ELab from "../../Layouts/TestpadPage/ELab/ELab";
import MLab from "../../Layouts/TestpadPage/MLab/MLab";

const TestpadPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  });
  return (
    <>
      <Header />
      <TestpadPageHeroSection />
      <SpecializedModules />
      <ALab />
      <ELab />
      <MLab />
      <NewFooter />
    </>
  );
};

export default TestpadPage;
