import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import httpService from "../../../services/http.service";
import {
  createColumns,
  getErrorMessage,
  getToastMessage,
  loadingData,
} from "../../Helper/Helper";
import "./ManualTCFile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowUpRightFromSquare,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { over } from "stompjs";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { connectSocket } from "../../Helper/SocketHelper";
import NoData from "../../UI/NoData/NoData";

const AttachedRequirements = (props) => {
  const mtc_id = useRef(props.id);
  const [attachedRequirements, setAttachedRequirements] = useState(null);
  const [attachNewRequirementLoading, setAttachNewRequirementLoading] =
    useState(false);
  const [availableRequirements, setAvailableRequirements] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [selectedRequirements, setSelectedRequirements] = useState(null);
  const tempHeaders = useRef([
    "Requirement Id",
    "Requirement Name",
    "Description",
  ]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const toDeleteReqName = useRef(null);
  const toDeleteReqId = useRef(null);
  const company = useSelector((state) => state.auth.userProfile.socket);
  const firstUpdate = useRef(false);
  const pidArray = useRef(null);
  const projectData = useSelector(
    (state) => state.manualTC.project_data_manualtc
  );
  const role = useSelector((state) => state.auth.userProfile.role);
  let stompClient = null;
  const tcConnection = useRef(null);

  const getAttachedRequirementsInTC = () => {
    httpService
      .getAttachedReqInTC(mtc_id.current)
      .then(async (data) => {
        setAttachedRequirements(data["data"]);
        if (!firstUpdate.current) {
          firstUpdate.current = true;
          connectSocket(
            "execution",
            company.toString().toUpperCase() + "_MANUAL/private",
            tcConnection,
            notifyTcClient
          );
        }
      })
      .catch((err) => {
        if (
          err["response"]["data"]["operation"].toLowerCase() === "info" &&
          err["response"]["data"]["suboperation"]
        ) {
          props.setNoAccess(true);
          tcConnection?.current?.unsubscribe();
          return;
        }
        handleToastErrorCatch(err);
      });
  };
  useEffect(() => {
    getAttachedRequirementsInTC();
    return () => {
      tcConnection?.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (projectData.length > 0) {
      let pids = projectData.map((data) => data["pid"]);
      pidArray.current = pids;
    }
  }, [projectData]);

  const notifyTcClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (pidArray.current && pidArray.current.includes(wsBody["pid"])) {
        getAttachedRequirementsInTC();
      }
    }
  };

  const getRequirementsToAttach = (e) => {
    setAttachNewRequirementLoading(true);
    httpService
      .getRequirementsToAttachInTC(mtc_id.current)
      .then((data) => {
        getErrorMessage(data);
        setAvailableRequirements(data["data"]);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          setDialog(true);
        }
        setAttachNewRequirementLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setAttachNewRequirementLoading(false));
      });
  };
  const addRemoveReqInManual = (payload, type) => {
    setAttachNewRequirementLoading(true);
    httpService
      .addRemoveInManual(payload, type)
      .then(async (data) => {
        getToastMessage(data);
        const action =
          type === "add"
            ? (setDialog(false),
              setAvailableRequirements(null),
              setSelectedRequirements(null))
            : (setDeleteLoading(false),
              setDeleteDialog(false),
              (toDeleteReqId.current = null),
              (toDeleteReqName.current = null));

        setAttachNewRequirementLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setAttachNewRequirementLoading(false));
      });
  };

  const addNewReqInManual = (e) => {
    let payload = {
      id: mtc_id.current,
    };
    const ids = selectedRequirements
      ? Object.keys(selectedRequirements).map((data) => {
          return selectedRequirements[data]["Requirement Id"]["value"];
        })
      : null;

    payload["ids"] = ids;
    addRemoveReqInManual(payload, "add");
  };

  const deleteReqInTC = (e, id) => {
    setDeleteLoading(true);
    let payload = {
      id: mtc_id.current,
    };

    let ids = [];
    ids.push(id);

    payload["ids"] = ids;
    addRemoveReqInManual(payload, "remove");
  };

  const getSkeleton = () => {
    const skelCards = Array.from(Array(9).keys()).map((card) => {
      return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-items-center my-2">
          <Skeleton
            width="23rem"
            height="7.5rem"
            className="card propertiesCardSkel"
          ></Skeleton>
        </div>
      );
    });
    return skelCards;
  };

  const createAttachmentCards = (data) => {
    const cards =
      data.length > 0 ? (
        data.map((attachmentData) => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-items-center my-2">
              {/* {attachmentData["Requirement Name"]["value"]} */}
              <div className="card propertiesCard pb-4 px-4 pt-3">
                <div className="d-flex">
                  <span className="ms-auto">
                    <FontAwesomeIcon
                      className="deleteReq me-3"
                      icon={faArrowUpRightFromSquare}
                      onClick={(e) => {
                        window.open(
                          window.location.origin +
                            "/#/testpad/taskboard?task_id=" +
                            attachmentData["Requirement Id"]["value"],
                          "_blank"
                        );
                      }}
                      title="Show Attachment"
                    />
                    <FontAwesomeIcon
                      className="deleteReq"
                      icon={faTrashCan}
                      onClick={(e) => (
                        (toDeleteReqId.current =
                          attachmentData["Requirement Id"]["value"]),
                        (toDeleteReqName.current =
                          attachmentData["Requirement Name"]["value"]),
                        setDeleteDialog(true)
                      )}
                      title="Unlink Attachment"
                    />
                    {/* <FontAwesomeIcon
                                    className="ms-2"
                                    icon={faArrowUpRightFromSquare}
                                    title="Show More"
                                /> */}
                  </span>
                </div>
                <div className="my-2">
                  <span className="boldText">Requirement ID : </span>
                  <span className="attach-cardData">
                    {attachmentData["Requirement Id"]["value"]}
                  </span>
                </div>
                <div className="my-2">
                  <span className="boldText">Requirement Name : </span>
                  <span className="attach-cardData">
                    {attachmentData["Requirement Name"]["value"]}
                  </span>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="mt-3 px-5 text-center">
          <div className="card d-flex align-items-center py-3">
            <div className="boldText">
              No Requirements Found ! Click on Attach New Requirements to add.{" "}
            </div>
          </div>
        </div>
      );

    return cards;
  };

  return (
    <div>
      <div className="d-flex">
        {role.toString().toUpperCase() !== "SUPER_ADMIN" && (
          <Button
            label={"Attach New Requirements"}
            className="btn-success btn themeBtn ms-auto p-2"
            onClick={(e) => getRequirementsToAttach()}
            icon={
              attachNewRequirementLoading
                ? "pi pi-spin pi-spinner me-2"
                : "pi pi-plus me-2"
            }
            iconPos="right"
            loading={attachNewRequirementLoading}
          />
        )}
      </div>
      {attachedRequirements ? (
        <div className="row d-flex mt-2">
          {attachedRequirements["data"]
            ? createAttachmentCards(attachedRequirements["data"])
            : null}
        </div>
      ) : (
        <div className="row d-flex">{getSkeleton()}</div>
      )}

      <Dialog
        blockScroll={true}
        header={`Attach New Requirement`}
        visible={dialog}
        onHide={() => {
          setDialog(false);
          setAvailableRequirements(null);
          setSelectedRequirements(null);
        }}
        draggable={false}
        breakpoints={{ "960px": "75vw" }}
        style={{
          width: "60vw",
        }}
        footer={
          <div>
            <Button
              label={"Attach"}
              className="btn-success btn themeBtn ms-auto p-2"
              disabled={!selectedRequirements?.length}
              onClick={(e) => addNewReqInManual(e)}
              icon={
                attachNewRequirementLoading
                  ? "pi pi-spin pi-spinner me-2"
                  : "pi pi-plus me-2"
              }
              iconPos="right"
              loading={attachNewRequirementLoading}
            />
          </div>
        }
      >
        <DataTable
          reorderableColumns
          collapsedRowIcon="pi pi-arrow-circle-right"
          expandedRowIcon="pi pi-arrow-circle-down"
          selection={selectedRequirements}
          onSelectionChange={(e) => setSelectedRequirements(e.value)}
          removableSort
          resizableColumns
          stripedRows
          columnResizeMode="expand"
          value={
            availableRequirements && availableRequirements["data"]
              ? availableRequirements["data"]
              : null
          }
          dragSelection
          paginator
          rows={25}
          filterDisplay="menu"
          responsiveLayout="scroll"
          emptyMessage={<NoData />}
          currentPageReportTemplate="Total {totalRecords} Records Found"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
          rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
          scrollHeight={"55vh"}
          className="test-stepTable"
        >
          <Column
            selectionMode="multiple"
            headerClassName={`headercolumnsPrime`}
            className={"columnsPrime"}
            headerStyle={{ width: "3em" }}
          ></Column>

          {availableRequirements && availableRequirements["data"] ? (
            createColumns(
              tempHeaders.current,
              true,
              false,
              availableRequirements["data"]
            )
          ) : (
            <></>
          )}
        </DataTable>
      </Dialog>
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Unlink Attachment"
        visible={deleteDialog}
        onHide={() => {
          setDeleteDialog(false);
          setDeleteLoading(false);
          toDeleteReqId.current = null;
          toDeleteReqName.current = null;
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        {deleteLoading ? (
          <div className="py-5">
            {loadingData("Unlinking " + toDeleteReqName.current)}
          </div>
        ) : (
          <>
            <div className="text-center mt-3 py-2 mx-3">
              <h5>
                Are you Sure you want to unlink : {toDeleteReqName.current}
              </h5>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div
                onClick={(e) => {
                  deleteReqInTC(e, toDeleteReqId.current);
                }}
                className="btn btn-success"
              >
                <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faCheck} />
                Yes
              </div>
              <div
                onClick={() => {
                  setDeleteDialog(false);
                }}
                className="btn btn-danger mx-2"
              >
                <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faXmark} />
                No
              </div>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default AttachedRequirements;
