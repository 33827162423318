import React from "react";
import "./SpecializedModulesCard.css";

const SpecializedModulesCard = ({ key, icon, heading, description }) => {
  return (
    <div key={key} className="specialized-modules-card-wrapper">
      <img
        className="specialized-modules-card-icon"
        src={icon}
        alt={heading}
        loading="lazy"
      />

      <p className="specialized-modules-card-heading">{heading}</p>
      <p className="specialized-modules-card-description">{description}</p>
    </div>
  );
};

export default SpecializedModulesCard;
