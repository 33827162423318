import {
    PROJECT_DATA_MANUALTC,
    CLEAR_PROJECT_DATA_MANUALTC
} from "../actions/type";

const initialState = {
    project_data_manualtc: []
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PROJECT_DATA_MANUALTC:
            return {
                ...state,
                project_data_manualtc: payload,
            };
        case CLEAR_PROJECT_DATA_MANUALTC:
            return {
                ...state,
                project_data_manualtc: [],
            };
        default:
            return state;
    }
}
