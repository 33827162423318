import { copyToClipboard } from '../../../Helper/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const ReportLinkCard = ({ link, header, footer }) => {
    const final = (
        <>
            {" "}
            <div className="card  shadow-sm mt-3">
                <div className="card-body">
                    <div className="card-title text-center boldText">
                        {header}
                        <span className="ms-1">
                            <label title="Copy Manual Execution Link">
                                <div
                                    className="btn-sm btn-success me-2 reportIcons"
                                    onClick={() => {
                                        copyToClipboard(link);
                                    }}
                                >
                                    <FontAwesomeIcon className="me-2 tabIcon" icon={faCopy} />
                                </div>
                            </label>
                        </span>
                    </div>
                    <div
                        className="w-100 btn btn-outline-secondary text-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            window.open(link);
                        }}
                    >
                        {footer}{" "}
                    </div>
                </div>
            </div>
        </>
    );
    return final;
};

export default ReportLinkCard
