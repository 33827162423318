import { useEffect, useRef, useState } from "react";
import httpService from "../../../services/http.service";
import "./TestTool.css";
import { DataTable } from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import X2JS from "x2js";
import * as Environment from "../../../services/Environment";
import NoAccess from "../../UI/NoAccess/NoAccess";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import {
  addFormField,
  copyToClipboard,
  createColumns,
  createFilters,
  createHeadersKeyValueInputs,
  loadingData,
  OBJtoXML,
  getErrorMessage,
  getToastMessage,
  logPageTitleForGA,
} from "../../Helper/Helper";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { InputTextarea } from "primereact/inputtextarea";

import {
  faCopy,
  faSquarePlus,
  faUpload,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import TestCasePill from "./TestCasePill";
import ReactDOM from "react-dom/client";
import { useDispatch, useSelector } from "react-redux";
import {
  AVAILABLE_SUITE,
  CLEAR_AVAILABLE_SUITE,
  CLEAR_PROJECT_DATA_TESTTOOL,
  PROJECT_DATA_TESTTOOL,
} from "../../../actions/type";
import { clearProjectData, getProjectData } from "../../../actions/autolytics";
import VersionControl from "./VersionControl";
import ShareVia from "./ShareVia";
import Scheduler from "./Scheduler/Scheduler";
import { over } from "stompjs";
import { Button } from "primereact/button";
import { connectSocket } from "../../Helper/SocketHelper";
import NoData from "../../UI/NoData/NoData";
let stompClient = null;

const TestTool = (props) => {
  // const [enableHealthMode, setEnableHealthMode] = useState(false);
  const [healthReportLink, setHealthReportLink] = useState(null);
  const userName = useSelector((state) => state.auth.userProfile.username);
  const role = useSelector((state) => state.auth.userProfile.role);
  const [exeDisable, setExeDisable] = useState(true);
  const [p_id, setP_id] = useState(null);
  const [keyHeaders, setKeyHeaders] = useState([{ key: "", value: "" }]);
  const [uploadSection, setUploadSection] = useState(false);
  const [xmlText, setxmlText] = useState(null);
  const [gitIntegratedDialog, setGitIntegratedDialog] = useState(false);
  const [gitLink, setGitLink] = useState("");
  const [activeGitChange, setActiveGitChange] = useState(true);
  const [isGitActive, setIsGitActive] = useState(false);
  const [privateGit, setPrivateGit] = useState(false);
  const [gitaccesstoken, setGitaccesstoken] = useState("");
  const [noAccessFile, setNoAccessFile] = useState(false);
  const [unlinkGit, setUnlinkGit] = useState(false);
  const [testcaseOptions, setTestcaseOptions] = useState([
    "Category",
    "Base Url",
    "Tags",
    "Jira Email",
    "Jira Access Token",
    "Jira Title",
    "Jira Project Id",
    "Jira Workflow",
    "Testcase List",
  ]);

  const dispatch = useDispatch();
  const getSuite = useRef(false);
  const [filters, setFilters] = useState({});
  const [dataMain, setDataMain] = useState(null);
  const [showCreateSuite, setShowCreateSuite] = useState(false);
  const [projectNames, setProjectName] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [reportName, setReportName] = useState("");

  const [showTestcase, setShowTestcase] = useState(false);
  const testcaseref = useRef();
  const [executeTestcaseLength, setExecuteTestcaseLength] = useState(null);
  const [executableTCCount, setExecutableTCCount] = useState(null);
  const [executionEnv, setExecutionEnv] = useState([]);
  const firstUpdate = useRef(true);
  const firstUpdate2 = useRef(true);
  const firstUpdate3 = useRef(true);
  const testcasePill = useRef();
  const [lambdaXML, setLambdaXML] = useState(null);
  const [openExecution, setOpenExecution] = useState(false);
  const [mode, setMode] = useState(null);
  const [s_id, setS_Id] = useState(null);
  const executable_json = useRef(null);
  const [env, setEnv] = useState(null);
  const [other, setOther] = useState(false);
  const [otherEnvData, setOtherEnvData] = useState(null);
  const availableMode = useRef(["Sequence", "Optimize"]);
  const availableEnv = useRef(["Dev", "Beta", "Prod", "Other"]);
  const [reportLink, setReportLink] = useState(null);
  const [manualLink, setManualLink] = useState(null);
  const [manualCount, setManualCount] = useState(0);
  const [suiteLoading, setSuiteLoading] = useState(true);
  const projectData = useSelector(
    (state) => state.TestTool.project_data_testtool
  );
  const pidArray = useRef(null);
  const [gitRowData, setGitRowData] = useState(null);
  const [testcaseLoader, setTestcaseLoader] = useState(false);
  const [loadingJar, setLoadingJar] = useState(false);
  const [suiteId, setSuiteId] = useState(null);
  const [wsSuiteId, setWSSuiteId] = useState(null);
  const [selectedSuite, setSelectedSuite] = useState(null);
  const gemjarexejson = useRef(null);
  const connection = useRef(null);
  const suiteConnection = useRef(null);
  const [pids, setPids] = useState(null);
  const [s_run_id, setS_run_id] = useState("");
  const [createSuiteButtonLoading, setCreateSuiteButtonLoading] =
    useState(false);
  const [executeSuiteButtonLoading, setExecuteSuiteButtonLoading] =
    useState(false);
  const [areAllTestcasesManual, setAreAllTestcasesManual] = useState(true);

  useEffect(() => {
    let title = "Jewel | ALab";
    document.title = title;
    logPageTitleForGA(title);
    connectSocket(
      "execution",
      "SUITE/private",
      suiteConnection,
      notifySuiteClient
    );

    httpService
      .getProjectRole()
      .then((data) => {
        let pids = [];
        data.data.map((roles) => pids.push(roles["pid"]));
        getErrorMessage(data);
        return Promise.resolve(pids);
      })
      .then((data) => {
        if (data && data.length > 0) {
          httpService.getProjectData(data).then((res) => {
            setProjectName(res);

            dispatch({
              type: PROJECT_DATA_TESTTOOL,
              payload: res,
            });
            getSuite.current = true;
            return Promise.resolve(res);
          });
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          setNoAccessFile(true);
          dispatch({
            type: PROJECT_DATA_TESTTOOL,
            payload: err["response"]["data"],
          });
        }
        handleToastErrorCatch(err);
      })
      .finally(() => {
        getSuiteData();
      });

    return () => {
      dispatch({
        type: CLEAR_PROJECT_DATA_TESTTOOL,
      });
      dispatch({
        type: CLEAR_AVAILABLE_SUITE,
      });
      suiteConnection?.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (firstUpdate3.current) {
      firstUpdate3.current = false;
      return;
    }
    if (env === "Other") {
      setOther(true);
    } else {
      setOther(false);
      setOtherEnvData(null);
    }
  }, [env]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (testcaseref.current) {
      testcaseref.current.click();
    }
  }, [showTestcase]);

  useEffect(() => {
    if (firstUpdate2.current) {
      firstUpdate2.current = false;
    }
  }, [lambdaXML]);

  const notifySuiteClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (wsBody["pid"]) {
        if (pidArray.current && pidArray.current.includes(wsBody["pid"])) {
          getSuiteData();
        }
      }
    }
  };

  const notifyClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (
        wsBody["message"]?.toLowerCase() === "testcase(s) updated successfully"
      ) {
        reloadjar();
        setGitIntegratedDialog(false);
        addtestcase(null, gitRowData["Suite ID"]["value"], null, gitRowData);

        return;
      }
      if (
        wsBody["message"]?.toLowerCase() === "testcase(s) created successfully"
      ) {
        reloadjar();
        setGitIntegratedDialog(false);
        addtestcase(null, gitRowData["Suite ID"]["value"], null, gitRowData);

        return;
      }
      if (wsBody["message"]?.toLowerCase() === "jar created successfully!!") {
        setLoadingJar(false);
        setTestcaseLoader(false);
        setGitIntegratedDialog(false);
        setPrivateGit(false);
        setGitaccesstoken("");
        connection?.current?.unsubscribe();
        return;
      } else {
        toast.error(wsBody["message"], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        connection?.current?.unsubscribe();
        setLoadingJar(false);
        setTestcaseLoader(false);
        setGitIntegratedDialog(false);
        setPrivateGit(false);
        setGitaccesstoken("");
      }
    }
  };

  const getSuiteData = (e) => {
    httpService
      .getAvailableTestToolSuites()
      .then((data) => {
        dispatch({
          type: AVAILABLE_SUITE,
          payload: data.data,
        });
        setDataMain(data["data"]);
        getErrorMessage(data);
        setSuiteLoading(false);
      })
      .catch((err) => {
        if (getSuite.current) {
          handleToastErrorCatch(err, () => setSuiteLoading(false));
        }
      });
  };

  useEffect(() => {
    if (projectData.length > 0) {
      let pids = projectData.map((data) => data["pid"]);
      pidArray.current = pids;
    }
  }, [projectData]);

  const integrateGit = (e, isGitIntegrated) => {
    setIsGitActive(isGitIntegrated);
    setGitIntegratedDialog(true);
  };

  const reloadjarWithAsync = () => {
    setTestcaseLoader(true);
    setLoadingJar(true);
    let s_id = gitRowData?.["Suite ID"]?.value;
    httpService
      .createJar(s_id)
      .then((gitData) => {
        connectSocket(
          "testpad",
          "S_" + gitRowData["Suite ID"]["value"] + "/private",
          connection,
          notifyClient
        );
        getToastMessage(gitData);
        setLoadingJar(false);
        setTestcaseLoader(false);
        setGitIntegratedDialog(false);
        setPrivateGit(false);
        setGitaccesstoken("");
        getSuiteData();
      })
      .catch((err) => {
        toast.error(
          "Something Went Wrong while Reloading the JAR. Please Try Again!",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setLoadingJar(false);
        setTestcaseLoader(false);
        setGitIntegratedDialog(false);
        setPrivateGit(false);
        setGitaccesstoken("");
        connection?.current?.unsubscribe();
        // getSuiteData();
      });
  };
  const reloadjar = () => {
    setTestcaseLoader(false);
    setLoadingJar(false);
    let s_id = gitRowData?.["Suite ID"]?.value;
    httpService
      .createJar(s_id)
      .then((gitData) => {
        getToastMessage(gitData);
      })
      .catch((err) => {
        toast.error(
          "Something Went Wrong while Reloading the JAR. Please Try Again!",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setLoadingJar(false);
        setTestcaseLoader(false);
        setGitIntegratedDialog(false);
        setPrivateGit(false);
        setGitaccesstoken("");
        // getSuiteData();
      });
  };
  const removeGit = () => {
    setTestcaseLoader(true);
    setUnlinkGit(true);
    setLoadingJar(true);
    httpService
      .deleteGit(gitRowData["Suite ID"]["value"])
      .then((data) => {
        const isSuccess =
          data["operation"] && data["operation"].toLowerCase() === "success"
            ? toast.success(
                `Git Unlinked from Suite Id: ${gitRowData["Suite ID"]["value"]} `,
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              )
            : null;

        getErrorMessage(data);
        // getSuiteData();
        setShowTestcase(false);
        setGitIntegratedDialog(false);
        setPrivateGit(false);
        setGitaccesstoken("");
        setUnlinkGit(false);
        setLoadingJar(false);
        setTestcaseLoader(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
        setUnlinkGit(false);
        setLoadingJar(false);
        setTestcaseLoader(false);
      });
  };
  const gitControl = (action) => {
    connection?.current?.unsubscribe();
    let payload = {};
    if (privateGit) {
      if (gitaccesstoken) {
        payload = {
          s_id: gitRowData["Suite ID"]["value"],

          source: gitLink,

          accessToken: gitaccesstoken,
        };
      } else {
        toast.error(
          "Personal Access Token is Required for Private Repositories.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }
    } else {
      payload = {
        s_id: gitRowData["Suite ID"]["value"],

        source: gitLink,

        accessToken: gitaccesstoken,
      };
    }
    switch (action) {
      case "integrate":
        setTestcaseLoader(true);
        connectSocket(
          "testpad",
          "S_" + gitRowData["Suite ID"]["value"] + "/private",
          connection,
          notifyClient
        );
        httpService
          .integrateGit(payload)
          .then((data) => {
            setLoadingJar(true);
            let jarpayload = {
              s_id: gitRowData["Suite ID"]["value"],
            };
            if (data?.operation?.toLowerCase() === "success") {
              getToastMessage(data);
              // setTimeout(() => {
              //   reloadjar();
              //   setGitIntegratedDialog(false);
              //   addtestcase(
              //     null,
              //     gitRowData["Suite ID"]["value"],
              //     null,
              //     gitRowData
              //   );
              //   getSuiteData();
              // }, 10000);
            } else {
              getErrorMessage(data);
              setLoadingJar(false);
              setTestcaseLoader(false);
              setGitIntegratedDialog(false);
              setPrivateGit(false);
              setGitaccesstoken("");
              connection?.current?.unsubscribe();
              // getSuiteData();
            }
          })
          .catch((err) => {
            toast.error(
              err["response"]
                ? err["response"]["data"]["message"]
                : "Something Went Wrong. Please Try Again",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
            setTestcaseLoader(false);
            setGitIntegratedDialog(false);
            setPrivateGit(false);
            setGitaccesstoken("");
            connection?.current?.unsubscribe();
          });
        break;
      case "update":
        setTestcaseLoader(true);
        connectSocket(
          "testpad",
          "S_" + gitRowData["Suite ID"]["value"] + "/private",
          connection,
          notifyClient
        );
        httpService
          .updateGit(payload)
          .then((data) => {
            if (
              data["operation"] &&
              data["operation"].toLowerCase() === "success"
            ) {
              getToastMessage(data);
              // setTimeout(() => {
              //   reloadjar();
              //   setGitIntegratedDialog(false);
              //   addtestcase(
              //     null,
              //     gitRowData["Suite ID"]["value"],
              //     null,
              //     gitRowData
              //   );
              //   getSuiteData();
              // }, 10000);
            } else {
              getErrorMessage(data);
              setLoadingJar(false);
              setTestcaseLoader(false);
              setGitIntegratedDialog(false);
              setPrivateGit(false);
              setGitaccesstoken("");
              connection?.current?.unsubscribe();
              // getSuiteData();
            }
          })
          .catch((err) => {
            toast.error(
              err["response"]
                ? err["response"]["data"]["message"]
                : "Something Went Wrong. Please Try Again",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
            setTestcaseLoader(false);
            setGitIntegratedDialog(false);
            setPrivateGit(false);
            setGitaccesstoken("");
            connection?.current?.unsubscribe();
          });
        break;
      default:
        break;
    }
  };
  const uploadXML = (event) => {
    event.preventDefault();
    document
      .getElementById("filevalue")
      .setAttribute("value", event.target.files[0].name);

    let readXml = null;

    let selectedFile = document.getElementById("fetching").files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      readXml = e.target.result;
      let parser = new DOMParser();
      let doc = parser.parseFromString(readXml, "application/xml");

      setxmlText(
        doc.getElementsByTagName("parsererror").length ? "error" : readXml
      );
    };
    reader.readAsText(selectedFile);
  };
  const executeSuite = (e, val, p_id, rowData) => {
    // setEnableHealthMode(false);

    executable_json.current = null;
    setP_id(p_id);
    setS_Id(val);
    setExecuteTestcaseLength(null);
    setExecutableTCCount(null);

    let check =
      dataMain && dataMain["data"]
        ? dataMain["data"].filter((rowDataIndex) =>
            rowDataIndex["Suite ID"]["value"] === val ? rowDataIndex : null
          )
        : null;
    executable_json.current = {
      id: val,
      name: check[0]["Suite Name"]["value"],
    };

    executable_json.current["Project Name"] = check[0]["Project Name"]["value"];
    executable_json.current["report_name"] = check[0]["Suite Name"]["value"];

    httpService
      .getAvailableSuiteTestCases(val)
      .then(async (data) => {
        setAreAllTestcasesManual(data?.data?.allManual);
        getErrorMessage(data);
        setExecutionEnv(data["data"]["env"]);
        let count = data["data"]["data"].length;
        data["data"]["data"].map((ele) => {
          if (ele?.["RUN FLAG"]?.["value"] === "N") {
            count--;
          }
        });
        setExecuteTestcaseLength(
          data && data["data"] && data["data"]["data"]
            ? data["data"]["data"].length
            : null
        );
        setExecutableTCCount(count);
        httpService.getBridgeToken().then(async (data) => {
          executable_json.current["bridge_token"] = await data["data"][
            "bridgeToken"
          ];
        });
        if (count > 0) {
          setExeDisable(false);
        } else {
          setExeDisable(true);
          toast.error(
            "No Executable Testcase(s) Found. Create/Add or Review Existing Testcase(s) to execute Suite.",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : "Something Went Wrong. Please Try Again",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      });

    setOpenExecution(true);
  };
  const addtestcase = (e, val, p_id, rowData) => {
    setShowTestcase(true);
    setSuiteId(val);
    const check =
      dataMain && dataMain["data"]
        ? dataMain["data"].filter((rowDataIndex) =>
            rowDataIndex["Suite ID"]["value"] === val
              ? setSelectedSuite(rowDataIndex)
              : null
          )
        : null;

    if (testcaseref.current) {
      testcaseref.current.click();
    }
  };

  const executeModeErrorMessage = () => {
    return toast.error("Other Environment field can't be empty!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const ReportLinkCard = ({ link, header, footer }) => {
    const final = (
      <>
        {" "}
        <div className="card  shadow-sm mt-3">
          <div className="card-body">
            <div className="card-title text-center boldText">
              {header}
              <span className="ms-1">
                <label title="Copy Manual Execution Link">
                  <div
                    className="btn-sm btn-success me-2 reportIcons"
                    onClick={() => {
                      copyToClipboard(link);
                    }}
                  >
                    <FontAwesomeIcon className="me-2 tabIcon" icon={faCopy} />
                  </div>
                </label>
              </span>
            </div>
            <div
              className="w-100 btn btn-outline-secondary text-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(link);
              }}
            >
              {footer}{" "}
            </div>
          </div>
        </div>
      </>
    );
    return final;
  };
  // const startHealthCheck = (framework) => {
  //   switch (framework) {
  //     case "GEMPYP":
  //       if (mode && env) {
  //         if (other && !otherEnvData) {
  //           executeModeErrorMessage();
  //         }
  //         else {

  //             let mainjson = lambdaXML;

  //             mainjson["data"]["suite"]["mode"] = mode;
  //             mainjson["data"]["suite"]["mail"] = mail;
  //             if (env === "Other") {
  //               mainjson["data"]["suite"]["env"] = otherEnvData;
  //             } else {
  //               mainjson["data"]["suite"]["env"] = env;
  //             }
  //             keyHeaders.map((key) => {
  //               if (key["key"].length > 0) {
  //                 mainjson["data"]["suite"][key["key"]] = key["value"];
  //               }
  //             });

  //             mainjson["data"]["suite"]["run_id"] =
  //               otherEnvData ? mainjson["data"]["suite"]["project"] + "_" + otherEnvData + "_" + uuidv4() : mainjson["data"]["suite"]["project"] + "_" + env + "_" + uuidv4()
  //             mainjson["data"]["suite"]["run_mode"] = "JEWEL";
  //             mainjson["data"]["suite"]["run_type"] = "ON-DEMAND";
  //             mainjson["data"]["suite"]["s_id"] = s_id;
  //             //generating h_run_id
  //             const h_run_id = otherEnvData ? mainjson["data"]["suite"]["project"] + "_health-run_" + otherEnvData + "_" + uuidv4() : mainjson["data"]["suite"]["project"] + "_health-run_" + env + "_" + uuidv4();

  //             setLambdaXML(mainjson);
  //             let link =
  //               window.location.origin +
  //               "/#/autolytics/health-report?h_run_id=" +
  //               h_run_id +
  //               "&p_id=" +
  //               p_id;
  //             setHealthReportLink(link);
  //             // httpService
  //             //   .GempyplambdaExecution(OBJtoXML(mainjson))
  //             //   .then((data) => {
  //             //     let link =
  //             //       window.location.origin +
  //             //       "/#/autolytics/health-report?h_run_id=" +
  //             //       mainjson["data"]["suite"]["run_id"] +
  //             //       "&p_id=" +
  //             //       p_id;
  //             //     setHealthReportLink(link);
  //             //   })
  //             //   .catch((err) => {
  //             //     toast.error("Something Went Wrong!", {
  //             //       position: "top-right",
  //             //       autoClose: 3000,
  //             //       hideProgressBar: true,
  //             //       closeOnClick: true,
  //             //       pauseOnHover: true,
  //             //       draggable: true,
  //             //       progress: undefined,
  //             //       theme: "colored",
  //             //
  //             //     });
  //             //   });
  //         }
  //       } else {
  //         toast.error("Mode and Env Cannot be Empty", {
  //           position: "top-right",
  //           autoClose: 3000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //
  //         });
  //       }

  //       break;
  //     case "GEMJAR":
  //       let payload = {};
  //       if (mode && env) {

  //         if (other && !otherEnvData) {
  //           executeModeErrorMessage();
  //         }
  //         else {

  //             const srunid =
  //               otherEnvData ?
  //                 (executable_json.current["Project Name"] +
  //                   "_" +
  //                   otherEnvData +
  //                   "_" +
  //                   uuidv4())
  //                 :
  //                 (executable_json.current["Project Name"] +
  //                   "_" +
  //                   env +
  //                   "_" +
  //                   uuidv4());

  //             let API_URL;

  //             if (Environment.checkEnv() === "prod") {
  //               API_URL = "https://apis.gemecosystem.com/";
  //             } else {
  //               API_URL = "https://apis-beta.gemecosystem.com/";
  //             }

  //             payload = {
  //               run_mode: mode,
  //               s_run_id: srunid,
  //               env: otherEnvData ? otherEnvData : env,
  //               user: userName,
  //               bridge_token: executable_json.current["bridge_token"],
  //               s_id: s_id,
  //               jewel_entry_url: API_URL + "enter-point",
  //               report_name: executable_json.current["report_name"],
  //             };
  //             keyHeaders.map((key) => {
  //               if (key["key"].length > 0) {
  //                 payload[key["key"]] = key["value"];
  //               }
  //             });

  //             // httpService
  //             //   .GemjarlambdaExecution(payload)
  //             //   .then((data) => {
  //             //     let link =
  //             //       window.location.origin +
  //             //       "/#/autolytics/execution-report?s_run_id=" +
  //             //       srunid +
  //             //       "&p_id=" +
  //             //       p_id;
  //             //     setReportLink(link);
  //             //   })
  //             //   .catch((err) => {
  //             //     toast.error("Something Went Wrong!", {
  //             //       position: "top-right",
  //             //       autoClose: 3000,
  //             //       hideProgressBar: true,
  //             //       closeOnClick: true,
  //             //       pauseOnHover: true,
  //             //       draggable: true,
  //             //       progress: undefined,
  //             //       theme: "colored",
  //             //
  //             //     });
  //             //   });
  //         }
  //       } else {
  //         toast.error("Mode and Env Cannot be Empty", {
  //           position: "top-right",
  //           autoClose: 3000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //
  //         });
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // }

  const startexecution = () => {
    if (manualLink) {
      setManualLink(null);
      setManualCount(0);
    }
    if (reportLink) {
      setReportLink(null);
    }
    let payload = {};
    let srunid = otherEnvData
      ? executable_json.current["Project Name"] +
        "_" +
        otherEnvData +
        "_" +
        uuidv4()
      : executable_json.current["Project Name"] + "_" + env + "_" + uuidv4();
    setS_run_id(srunid);

    async function execute() {
      if (other && !otherEnvData) {
        executeModeErrorMessage();
      } else {
        let API_URL;
        if (Environment.checkEnv() === "prod") {
          API_URL = "https://apis.gemecosystem.com/";
        } else {
          API_URL = "https://apis-beta.gemecosystem.com/";
          // API_URL =
          //   "http://ec2-13-127-221-50.ap-south-1.compute.amazonaws.com:8080/";
        }
        payload = {
          mode: mode,
          s_run_id: srunid,
          environment: otherEnvData ? otherEnvData : env,
          s_id: s_id,
          allManual: areAllTestcasesManual,
        };
        keyHeaders.map((key) => {
          if (key["key"].length > 0) {
            payload[key["key"]?.toLowerCase().split(" ").join("_")] =
              key["value"];
          }
        });

        setExecuteSuiteButtonLoading(true);
        await httpService
          .GemjarlambdaExecution(payload)
          .then((data) => {
            if (
              data["operation"] &&
              data["operation"].toLowerCase() !== "failure"
            ) {
              httpService.getManualTestcaseCount(s_id).then((data) => {
                setManualCount(data?.data);
                if (data?.data) {
                  let manualtempLink =
                    window.location.origin +
                    "/#/testpad/manual-testcase-execution?s_run_id=" +
                    srunid +
                    "&s_id=" +
                    s_id +
                    "&p_id=" +
                    p_id;
                  setManualLink(manualtempLink);
                }
              });
              let link =
                window.location.origin +
                "/#/autolytics/execution-report?s_run_id=" +
                srunid +
                "&p_id=" +
                p_id;
              setReportLink(link);
            } else {
              getErrorMessage(data);
            }
          })
          .catch((err) => {
            toast.error("Something Went Wrong!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
        setExecuteSuiteButtonLoading(false);
      }
    }

    if (areAllTestcasesManual) {
      if (env) {
        execute();
      } else {
        toast.error("Environment Cannot be Empty", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      if (mode && env) {
        execute();
      } else {
        toast.error("Mode and Environment Cannot be Empty", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const createSuite = async () => {
    if (selectedProject && reportName && reportName.length > 0) {
      setCreateSuiteButtonLoading(true);
      let payload = {
        p_id: selectedProject["pid"],
        project_name: selectedProject["projectName"],
        report_name: reportName,
      };
      await httpService
        .createSuiteTestTool(payload)
        .then((data) => {
          getToastMessage(data);
          setShowCreateSuite(false);
        })
        .catch((err) => {
          toast.error(
            err["response"]
              ? err["response"]["data"]["message"]
              : "Something Went Wrong. Please Try Again",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        });
      setCreateSuiteButtonLoading(false);
    } else {
      toast.error("All fields are required !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const skeletonBridgeRows = Array.from({ length: 5 });
  const skeletonBridgeTemplate = () => {
    return <Skeleton></Skeleton>;
  };

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      const filterData = dataMain?.headers
        ? createFilters(dataMain?.headers)
        : {};

      setFilters(filterData);
    }
  }, [dataMain]);
  const onUpload = () => {
    let x2js = new X2JS();
    const result = x2js.xml2js(xmlText);
    const json = JSON.stringify(result, null, 4);

    if (result?.data?.suite) {
      let pid = projectData.filter(
        (data) => data?.projectName === result?.data?.suite?.project
      );
      if (pid && pid.length > 0) {
        let payload = {
          p_id: pid?.["0"]?.pid,
          project_name: result?.data?.suite?.project,
          report_name: result?.data?.suite?.report_name,
          framework: result?.data?.suite?.framework,
        };
        httpService
          .createSuiteTestTool(payload)
          .then((data) => {
            getToastMessage(data);

            if (
              result?.data?.testcases?.testcase &&
              result?.data?.testcases?.testcase?.length > 0
            ) {
              let testData = result?.data?.testcases?.testcase;
              let testcasePayload = [];
              testData.map((testcase, i) => {
                testcasePayload[i] = {};
                var json = {
                  s_id: data["data"]["s_id"],
                  type: testcase["TYPE"]
                    ? testcase["TYPE"]
                    : data && data["data"] && data["data"]["framework"]
                    ? data["data"]["framework"].toLowerCase() === "gempyp"
                      ? "Gempyp"
                      : null
                    : null,
                  name: testcase["name"],
                  category: testcase["name"],
                  frameworkDefinedData: {},
                };
                //TEST
                Object.keys(testcase)?.forEach((keys) => {
                  if (
                    !["type", "category", "name"].includes(keys.toLowerCase())
                  ) {
                    json["frameworkDefinedData"][keys] = testcase[keys];
                  }
                });
                json["source"] = "JEWEL";
                testcasePayload[i] = json;
              });

              let frameworkPayload = data?.data?.framework
                ? data?.data?.framework
                : "GEMPYP";
              httpService
                .createTestcaseTestTool(testcasePayload, frameworkPayload)
                .then((test) => {
                  getErrorMessage(test);
                  // getSuiteData();
                  setUploadSection(false);
                  setxmlText(null);
                })
                .catch((err) => {
                  // getSuiteData();
                  setUploadSection(false);
                  setxmlText(null);

                  toast.error(
                    "Something Went Wrong while uploading Testcases. Please Try to upload Again directly from suite",
                    {
                      position: "top-right",

                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    }
                  );
                });
            } else {
              // getSuiteData();
              setUploadSection(false);
              setxmlText(null);
            }
          })
          .catch((err) => {
            const missingKeys =
              err["response"] && err["response"]["data"]["data"]
                ? Object.keys(err["response"]["data"]["data"])
                : null;
            const ifKeys = missingKeys
              ? missingKeys.map((key) =>
                  toast.error(key + " key is missing in XML!", {
                    position: "top-right",

                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  })
                )
              : null;
          });
      } else {
        toast.error(
          "You don't have access to this project. Please ask Admin to provide access or Try again with other Project.",
          {
            position: "top-right",

            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    }
  };
  const createOptionalData = () => {
    const returnData = (
      <>
        {createHeadersKeyValueInputs(
          keyHeaders,
          setKeyHeaders,
          testcaseOptions
        )}
        <button
          className="btn themeBtn mt-3"
          onClick={(e) => addFormField("headers", keyHeaders, setKeyHeaders)}
        >
          Add Data
        </button>
      </>
    );
    return returnData;
  };
  return !noAccessFile ? (
    <>
      <ul
        className="nav nav-pills ms-2 pt-3 mb-3"
        id={"pills-tab"}
        role="tablist"
      >
        <li className="nav-item mr-1" role="presentation">
          <div
            className="fontSizeGeneral nav-link extentPill active"
            id={"pills-suite-tab"}
            data-toggle="pill"
            data-target={"#pills-suite"}
            type="button"
            role="tab"
            aria-selected="true"
          >
            Suite
          </div>
        </li>
        {showTestcase && (
          <>
            <li className="nav-item ms-1" role="presentation">
              <div
                ref={testcaseref}
                className={`fontSizeGeneral nav-link extentPill`}
                id={"pills-testcase-tab"}
                data-toggle="pill"
                data-target={"#pills-testcase"}
                type="button"
                role="tab"
                aria-selected="false"
              >
                Testcases
              </div>
            </li>
          </>
        )}
      </ul>
      <div className="tab-content ms-2 me-4 pb-3" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id={"pills-suite"}
          role="tabpanel"
          aria-labelledby={"pills-suite-tab"}
        >
          <div className="mt-3">
            <div className="mb-1 mt-4">
              <div
                className="btn-success btn themeBtn"
                onClick={() => {
                  setSelectedProject(null);
                  setReportName(null);
                  setShowCreateSuite(true);
                }}
              >
                Create Suite
              </div>
              {/* <div
                className="btn-success btn ms-1 themeBtn"
                onClick={() => {
                  setUploadSection(true);
                }}
              >
                <FontAwesomeIcon className="mx-1 tabIcon" icon={faUpload} />
                Upload
              </div> */}
            </div>
          </div>

          {/* {uploadSection ? (
            <FileUpload
              chooseOptions={chooseOptions}
              uploadOptions={uploadOptions}
              cancelOptions={cancelOptions}
              name="demo[]"
              url="https://primefaces.org/primereact/showcase/upload.php"
              uploadHandler	={onUpload}
              multiple
              accept="image/*"
              maxFileSize={1000000}
              emptyTemplate={
                <p className="m-0">Drag and drop files to here to upload.</p>
              }
            />
          ) : null} */}
          {suiteLoading ? (
            <DataTable
              responsiveLayout="scroll"
              header={"Suite"}
              value={skeletonBridgeRows}
              className="p-datatable-striped mt-3 test-stepTable"
            >
              <Column
                header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                style={{ width: "5%" }}
                body={skeletonBridgeTemplate}
              ></Column>
              <Column
                header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                style={{ width: "30%" }}
                body={skeletonBridgeTemplate}
              ></Column>
              <Column
                header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                style={{ width: "15%" }}
                body={skeletonBridgeTemplate}
              ></Column>
              <Column
                header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                style={{ width: "25%" }}
                body={skeletonBridgeTemplate}
              ></Column>
              <Column
                header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                style={{ width: "25%" }}
                body={skeletonBridgeTemplate}
              ></Column>
            </DataTable>
          ) : (
            <div className="mt-2">
              <DataTable
                reorderableColumns
                onFilter={(e) => {
                  setFilters(e.filters);
                }}
                resizableColumns
                stripedRows
                columnResizeMode="expand"
                value={dataMain ? dataMain["data"] : null}
                paginator
                rows={25}
                dataKey="suiteTable"
                filters={filters}
                filterDisplay="menu"
                responsiveLayout="scroll"
                className="test-stepTable"
                emptyMessage={<NoData />}
                currentPageReportTemplate="Total {totalRecords} Suite(s) Found"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
                rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                scrollHeight={"65vh"}
                removableSort
              >
                {dataMain && dataMain["headers"]
                  ? createColumns(
                      dataMain["headers"],
                      true,
                      true,
                      dataMain["data"],
                      false,
                      null,
                      {
                        edit_suite: addtestcase,
                        edit_suite_icon: faSquarePlus,
                        execute_suite: executeSuite,
                        execute_suite_icon: faPlayCircle,
                      },
                      {
                        git_add: true,
                        git_update: true,
                        email_add: true,
                        email_update: true,
                        scheduler: true,
                      },
                      {
                        git_add: VersionControl,
                        git_update: VersionControl,
                        email_add: ShareVia,
                        email_update: ShareVia,
                        scheduler: Scheduler,
                      },
                      {
                        git_add_props: {
                          isActive: false,
                          integrateGit: integrateGit,
                          setGitLink: setGitLink,
                          setGitRowData: setGitRowData,
                        },
                        git_update_props: {
                          isActive: true,
                          integrateGit: integrateGit,
                          setGitLink: setGitLink,
                          setGitRowData: setGitRowData,
                        },
                        email_add_props: {
                          isActive: false,
                          setDataMain: setDataMain,
                        },
                        email_update_props: {
                          isActive: true,
                          setDataMain: setDataMain,
                        },
                        scheduler_props: {
                          setDataMain: setDataMain,
                        },
                      }
                    )
                  : null}
              </DataTable>

              <Dialog
                blockScroll={true}
                draggable={false}
                header={`Create Suite`}
                visible={showCreateSuite}
                onHide={() => {
                  setShowCreateSuite(false);
                }}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "60vw" }}
              >
                <>
                  <div className="d-flex justify-content-around mt-3 mx-4">
                    <div className="">
                      <div className="main-dropdown">
                        <div className="mb-1 dropdown-heading mt-2">
                          Project Name <sup> *</sup>
                        </div>

                        <div className="row">
                          <Dropdown
                            className="dialog-dropdown"
                            filter
                            filterBy="projectName"
                            style={{ minWidth: "20vw", maxWidth: "20vw" }}
                            value={selectedProject ? selectedProject : null}
                            options={projectNames}
                            onChange={(e) => {
                              setSelectedProject(e.value);
                            }}
                            placeholder="Select Project Name"
                            optionLabel="projectName"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ms-5">
                      <div className="mb-1 dropdown-heading mt-2">
                        Suite Name<sup> *</sup>
                      </div>

                      <div className="">
                        <InputText
                          style={{ minWidth: "20vw", maxWidth: "20vw" }}
                          type="text"
                          value={reportName ? reportName : ""}
                          placeholder={"Suite Name"}
                          onChange={(e) => setReportName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 d-flex justify-content-end">
                    {role.toString().toUpperCase() !== "SUPER_ADMIN" && (
                      <Button
                        label="Create Suite"
                        onClick={() => {
                          createSuite();
                        }}
                        className="btn-success ms-2 py-2 btn themeBtn"
                        loadingIcon={"pi pi-spin pi-spinner me-2"}
                        loading={createSuiteButtonLoading}
                        iconPos="right"
                      />
                    )}
                  </div>
                </>
              </Dialog>
            </div>
          )}
        </div>
        <div
          ref={testcasePill}
          className="tab-pane fade"
          id={"pills-testcase"}
          role="tabpanel"
          aria-labelledby={"pills-testcase-tab"}
        >
          {suiteId && (
            <TestCasePill
              key={suiteId}
              sid={suiteId}
              availablePids={pidArray.current ? pidArray.current : null}
              selectedsuite={selectedSuite}
            />
          )}
        </div>
      </div>

      <Dialog
        blockScroll={true}
        draggable={false}
        header={"Upload XML"}
        visible={uploadSection}
        onHide={() => {
          setUploadSection(false);
          setxmlText(null);
        }}
        footer={() => (
          <>
            <button
              disabled={!xmlText || xmlText === "error" ? true : false}
              onClick={() => {
                onUpload();
              }}
              className="btn-success mt-3  btn themeBtn"
            >
              Upload
            </button>
          </>
        )}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "60vw" }}
      >
        <label className="custom-file-upload themeBtn">
          <input
            id="fetching"
            className="upload"
            type="file"
            onChange={(e) => {
              uploadXML(e);
            }}
          />
        </label>
        <div className="d-flex justify-content-center flex-wrap">
          <input
            className="mx-1"
            type={"text"}
            disabled={true}
            placeholder="Browse Files"
            id="filevalue"
          ></input>
          <div
            onClick={() => {
              document.getElementById("fetching").click();
            }}
            className="btn btn-primary themeBtn mx-1"
          >
            Browse
          </div>
        </div>

        {xmlText &&
          (xmlText === "error" ? (
            <InputTextarea
              id="error"
              value={"Invalid XML File. Please check and upload again"}
              className="w-100 mt-2"
              rows={3}
              cols={30}
            />
          ) : (
            <>
              <InputTextarea
                id="xmltxt"
                rows={5}
                cols={30}
                value={xmlText}
                onChange={(e) => setxmlText(e.target.value)}
                autoResize
                className="w-100 mt-2"
              />
            </>
          ))}
      </Dialog>

      <Dialog
        blockScroll={true}
        draggable={false}
        header={`${isGitActive ? "Update/Reload" : "Integrate"} Git`}
        visible={gitIntegratedDialog}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "65vw" }}
        onHide={() => {
          setTestcaseLoader(false);
          setLoadingJar(false);
          setGitIntegratedDialog(false);
          setPrivateGit(false);
          setGitaccesstoken("");
          connection?.current?.unsubscribe();
        }}
        footer={() => (
          <>
            <div>
              {isGitActive && (
                <>
                  <button
                    disabled={gitLink ? false : true}
                    onClick={() => {
                      removeGit();
                    }}
                    className="btn-success mt-3  btn themeBtn"
                  >
                    Remove Git
                  </button>
                  <button
                    disabled={gitLink ? false : true}
                    onClick={() => {
                      reloadjarWithAsync();
                    }}
                    className="btn-success mt-3  btn themeBtn"
                  >
                    Reload JAR
                  </button>
                </>
              )}
              <button
                disabled={gitLink ? false : true}
                onClick={() => {
                  isGitActive ? gitControl("update") : gitControl("integrate");
                }}
                className="btn-success mt-3  btn themeBtn"
              >
                {isGitActive ? "Update/Reload Git" : "Integrate Git"}
              </button>
            </div>
          </>
        )}
      >
        <>
          {!testcaseLoader ? (
            <div className="w-100">
              <div className="overHeadAnalytics ms-2 text-center ">
                <strong>URL</strong>
              </div>
              <div className="text-center mt-1">
                <InputText
                  // style={{ minWidth: "20vw", maxWidth: "20vw" }}
                  type="text"
                  className="inputHeight w-75"
                  value={gitLink ? gitLink : ""}
                  placeholder={"Git Link"}
                  onChange={(e) => {
                    setGitLink(e.target.value);
                  }}
                />
                <br />
                {gitLink ? (
                  <div className="mt-2">
                    <input
                      id="privatepublic"
                      type="checkbox"
                      checked={privateGit}
                      onChange={(e) => {
                        setPrivateGit(e.target.checked);
                      }}
                    />
                    <label for="privatepublic">
                      <span className="ms-2">
                        {" "}
                        Check if Repository is Private.
                      </span>
                    </label>
                  </div>
                ) : null}
                {privateGit ? (
                  <>
                    <div className="overHeadAnalytics ms-2 mt-2 text-center ">
                      <strong>Access Token</strong>
                    </div>
                    <div className="text-center mt-1">
                      <InputText
                        // style={{ minWidth: "20vw", maxWidth: "20vw" }}
                        type="text"
                        className="inputHeight w-75"
                        value={gitaccesstoken ? gitaccesstoken : ""}
                        placeholder={"Personal Access Token"}
                        onChange={(e) => {
                          setGitaccesstoken(e.target.value);
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </div>

              <div></div>
              <br />
            </div>
          ) : !unlinkGit ? (
            loadingData(
              loadingJar ? "Creating JAR..." : "Fetching TestCases..."
            )
          ) : (
            loadingData("Unlinking Git...")
          )}
        </>
      </Dialog>
    </>
  ) : (
    <NoAccess />
  );
};

export default TestTool;
