import { useState, useRef,useEffect } from "react";
import { convertEpochtoDate } from "../../../Helper/Helper";
import Reactions from "./Reactions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faPen,
  faSpinner,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { InputTextarea } from "primereact/inputtextarea";
import Emojis from "./Emojis";
import { useSelector } from "react-redux";
import commentsService from "../../../../services/Comments/comments.service";
import { handleToastErrorCatch } from "../../../Helper/ToastHelper";

const Reply = ({
  replyComment,
  props,
  socketId,
  id,
  socketMessage,
  fullname,
  deleteComment,
  removeEmoji,
}) => {
  const [commentReplyBody, setCommentReplyBody] = useState(null);
  const [commentReplyEdit, setCommentReplyEdit] = useState(false);
  const [commentReplyEditLoading, setCommentReplyEditLoading] = useState(false);
  const [isCommentReplyReacted, setIsCommentReplyReacted] = useState(false);
  const [showActions, setShowActions] = useState(true);
  const [currentReplyEmojiId, setCurrentReplyEmojiId] = useState(null);
  const currentCommentId = useRef(null);
  

  const editReplyComment = (id, text) => {
    setCommentReplyEditLoading(true);
    let payload = {
      commentBody: text,
      commentId: id,
      srunId: props.s_run_id,
      pid: props.p_id,
      createdByName: fullname,
    };

    commentsService
      .editComment(payload)
      .then((data) => {
        setCommentReplyBody("");
        setCommentReplyEdit(false);
        setCommentReplyEditLoading(false);
        currentCommentId.current = null;
      })
      .catch((err) =>
        handleToastErrorCatch(err, () => {
          setCommentReplyEdit(false);
          setCommentReplyEditLoading(false);
        })
      );
  }; 



  return (
    <div key={id}>
      {replyComment?.map((comment, i) => (
        <div className="ps-5" key={`reply-${comment?.commentId}-${i}`}>
          <div>
            <div className="d-flex align-items-center">
              <span>
            
                <img
                  src={comment?.profileUrl?`${comment?.profileUrl}&token=${localStorage.getItem("usertoken")}`:`https://api.dicebear.com/7.x/initials/svg?seed=${comment?.createdByName}`}
                  className="userCommentAvatar rounded-circle mx-2"
                />
              </span>
              <span className="userFullName">{comment?.createdByName}</span>
              <span className="mx-2 commentDate">
                {convertEpochtoDate(comment?.createdAt)}
              </span>
            </div>
            <div className="my-3 mx-5">
              <div className="comment-container">
                <div
                  className={`reaction-picker d-flex align-items-center ${
                    showActions ? "reactions-container" : ""
                  }`}
                >
                  <Reactions
                    parentId={comment?.commentParentId}
                    currentCommentId={currentCommentId}
                    socketId={socketId}
                    socketMessage={socketMessage}
                    setIsReacted={setIsCommentReplyReacted}
                    setCurrentEmojiId={setCurrentReplyEmojiId}
                    id={comment?.commentId}
                    fullname={fullname}
                    setShowActions={setShowActions}
                    props={props}
                  />
                  {comment?.createdBy === props.userDetail.username && showActions && (
                    <>
                      <p className="mx-2 my-0 fs-5">|</p>
                      <div className="d-flex ms-auto">
                        {!commentReplyEditLoading ? (
                          <FontAwesomeIcon
                            className="pointer mx-2"
                            onClick={() => {
                              if (
                                commentReplyEdit &&
                                currentCommentId.current === comment?.commentId
                              ) {
                                editReplyComment(
                                  comment?.commentId,
                                  commentReplyBody
                                );
                              } else {
                                setCommentReplyEdit(true);
                                currentCommentId.current = comment?.commentId;
                                setCommentReplyBody(comment?.commentBody);
                              }
                            }}
                            icon={
                              commentReplyEdit &&
                              currentCommentId.current === comment?.commentId
                                ? faCircleCheck
                                : faPen
                            }
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="fa-spin mx-2 "
                            icon={faSpinner}
                          />
                        )}
                        <FontAwesomeIcon
                          className="pointer"
                          onClick={() => deleteComment(comment?.commentId)}
                          icon={faTrashCan}
                        />
                      </div>
                    </>
                  )}
                </div>
                <InputTextarea
                  className="w-100 px-4 commentInput inputBorder"
                  readOnly={
                    !commentReplyEdit &&
                    currentCommentId.current !== comment?.commentId
                  }
                  value={
                    commentReplyEdit &&
                    currentCommentId.current === comment?.commentId
                      ? commentReplyBody
                      : comment?.commentBody
                  }
                  onChange={(e) => setCommentReplyBody(e.target.value)}
                  rows={2}
                  
                />
              </div>
              <div>
                <Emojis
                  parentId={comment?.commentParentId}
                  currentCommentId={currentCommentId}
                  key={comment?.commentId}
                  commentId={comment?.commentId}
                  removeEmoji={removeEmoji}
                  reactionData={comment?.reactions}
                  isReacted={isCommentReplyReacted}
                  setIsReacted={setIsCommentReplyReacted}
                  setCurrentEmojiId={setCurrentReplyEmojiId}
                  currentEmojiId={currentReplyEmojiId}
                  props={props}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Reply;
