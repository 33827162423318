import {
  CLEAR_MARK_STATUS_WHILE_MANUAL_EXECUCTION,
  MARK_STATUS_WHILE_MANUAL_EXECUCTION,
} from "../actions/type";

const initialState = { markDetails: {} };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MARK_STATUS_WHILE_MANUAL_EXECUCTION:
      return { ...state, markDetails: payload };
    case CLEAR_MARK_STATUS_WHILE_MANUAL_EXECUCTION:
      return { ...state, markDetails: {} };
    default:
      return state;
  }
}
