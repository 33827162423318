import React from 'react'
import { Skeleton } from 'primereact/skeleton';
import noComments from "../../../../assets/noComments.svg"
import "./index.css"

export const CommentSkeleton = () => {
    
      return (
        <>
        <div className="mt-2">
            <div className="pb-2 mt-4">
              <Skeleton width="100%" height="2rem"></Skeleton>
            </div>
          </div>
          <div className="mt-2">
            <div className="pb-2 mt-4">
              <Skeleton width="100%" height="8rem"></Skeleton>
            </div>
          </div>
          <div className="mt-2">
            <div className="pb-2 mt-4">
              <Skeleton width="100%" height="8rem"></Skeleton>
            </div>
          </div>
          
        </>
      );
    
}

export const CommentInputSkeleton = () => {
    
  return (
    <>
    <div className="mt-2">
        <div className="pb-2 mt-4">
          <Skeleton width="75%" height="6rem"></Skeleton>
        </div>
      </div>
    </>
  );

}

export const NoCommentsSkeleton = ()=>{
  return(
    <div className='noCommentSection w-75 my-auto d-flex flex-column justify-content-center align-items-center'>
    <img className='noCommentImage mb-4' width="15%" src={noComments} alt="no comments"/>
    <h1 className='fs-4'>No Comments Yet</h1>
  </div>
  )
}
