import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import {
  createColumns,
  createFilters,
  getToastMessage,
} from "../../../Helper/Helper";
import {
  handleToastErrorCatch,
  statementError,
} from "../../../Helper/ToastHelper";
import httpService from "../../../../services/http.service";
import NoData from "../../../UI/NoData/NoData";

const LinkSuite = ({
  suiteLinkDialog,
  setSuiteLinkDialog,
  currentTcId,
  suites,
  setSuites,
}) => {
  const [selectedSuites, setSelectedSuites] = useState(null);
  const [linkButtonLoading, setLinkButtonLoading] = useState(false);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (Object.keys(filters)?.length === 0) {
      const filterData = suites?.headers ? createFilters(suites.headers) : {};
      setFilters(filterData);
    }
  }, [suites]);
  const linkTCToSuite = () => {
    setLinkButtonLoading(true);
    let payload = {
      id: currentTcId,
    };

    const s_id = selectedSuites
      ? selectedSuites.length > 0 &&
        Object.keys(selectedSuites).map((data) => {
          return selectedSuites[data]?.["Suite ID"]?.value;
        })
      : null;
    payload["s_id"] = s_id;

    if (!s_id) {
      statementError("No suite selected !");
      return;
    }
    httpService
      .linkToSuiteAlab(payload)
      .then((data) => {
        getToastMessage(data);
        setSuiteLinkDialog(false);
        setSelectedSuites(null);
        setSuites(null);
        setLinkButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setLinkButtonLoading(false);
        });
      });
  };
  return (
    <Dialog
      blockScroll={true}
      header={`Link Suite`}
      visible={suiteLinkDialog}
      onHide={() => {
        setSuiteLinkDialog(false);
        setSuites(null);
        setSelectedSuites(null);
        setLinkButtonLoading(false);
      }}
      draggable={false}
      breakpoints={{ "960px": "75vw" }}
      style={{
        width: "80vw",
      }}
      footer={
        <div>
          <Button
            label="Link suite"
            className="btn-success btn themeBtn mx-2 p-2 mt-1"
            disabled={!selectedSuites}
            onClick={(e) => linkTCToSuite(e)}
            loadingIcon={"pi pi-spin pi-spinner me-2"}
            loading={linkButtonLoading}
            iconPos="right"
          />
        </div>
      }
    >
      <DataTable
        reorderableColumns
        collapsedRowIcon="pi pi-arrow-circle-right"
        expandedRowIcon="pi pi-arrow-circle-down"
        selection={selectedSuites}
        onSelectionChange={(e) => setSelectedSuites(e.value)}
        removableSort
        filters={filters}
        onFilter={(e) => {
          setFilters(e.filters);
        }}
        resizableColumns
        stripedRows
        columnResizeMode="expand"
        value={suites?.data}
        dragSelection
        paginator
        rows={25}
        filterDisplay="menu"
        responsiveLayout="scroll"
        emptyMessage={<NoData />}
        currentPageReportTemplate="Total {totalRecords} Records Found"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
        rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
        scrollHeight={"55vh"}
        className="test-stepTable"
      >
        <Column
          selectionMode="multiple"
          headerClassName={`headercolumnsPrime`}
          className={"columnsPrime"}
          headerStyle={{ width: "3em" }}
        ></Column>

        {suites?.["data"] &&
          createColumns(suites["headers"], true, true, suites["data"])}
      </DataTable>
    </Dialog>
  );
};

export default LinkSuite;
