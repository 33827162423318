import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import httpService from "../../../services/http.service";
import Auth from "../../Pages/AuthPage/Auth";
import "./SignUpOTP.css";
import { getSuccessToast, statementError } from "../../Helper/ToastHelper";
import { Link } from "react-router-dom";

const SignUpOTP = () => {
  const navigate = useNavigate();
  const username = useLocation().state?.username ?? "";
  const email = useLocation().state?.email ?? "";
  const INITIAL_SECONDS = 30;
  const [secondsLeft, setSecondsLeft] = useState(INITIAL_SECONDS);
  const formattedSeconds =
    secondsLeft < 10
      ? secondsLeft.toString().padStart(2, "0") //   0:08
      : secondsLeft.toString(); //   0:15
  const [isResendOtpButtonDisabled, setIsResendOtpButtonDisabled] =
    useState(true);
  const [isVerifyOtpButtonDisabled, setIsVerifyOtpButtonDisabled] =
    useState(false);
  const [otp, setOtp] = useState("");

  // Countdown timer
  useEffect(() => {
    let interval;

    if (isResendOtpButtonDisabled) {
      // Start the timer only if the "Resend OTP" button is disabled
      interval = setInterval(() => {
        setSecondsLeft((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(interval); // Stop the interval when seconds reach 0
            setIsResendOtpButtonDisabled(false); // Enable the "Resend OTP" button
            return 0;
          }
        });
      }, 1000);
    }

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [isResendOtpButtonDisabled]); // To restart timer when "Resend OTP" button is clicked

  function handleResendOtp() {
    setSecondsLeft(INITIAL_SECONDS); // Reset the timer to its initial value
    setIsResendOtpButtonDisabled(true); // Disable the "Resend OTP" button
    getSuccessToast("OTP sent again");

    httpService.sendSignUpOtp(username).catch(() => {
      statementError("Something went wrong with the OTP service", 5000);
    });
  }

  function handleOtpVerification() {
    setIsVerifyOtpButtonDisabled(true);
    httpService
      .verifySignUpOtp(username, otp)
      .then((data) => {
        // "Account Verification Successful !!" + " Please Login to continue."
        getSuccessToast(data.data.message + " Please Login to continue.", 5000);
        navigate("/login");
      })
      .catch((data) => {
        statementError(data.response.data.message, 5000);
      })
      .finally(() => {
        setIsVerifyOtpButtonDisabled(false);
      });
  }

  return (
    <Auth>
      <div className="d-flex card customSignup">
        <div className="d-flex flex-column align-items-center card-body py-5 px-lg-5 px-md-5 px-sm-4 px-xs-2 otp-verification-card">
          <h1>Account Verification</h1>
          <h2>Please enter verification code sent to</h2>
          <h3>{email}</h3>
          {/* <h4>0:{formattedSeconds}</h4> */}
          <Link to="/change-email" state={useLocation().state}>
            <h4>change email?</h4>
          </Link>

          <InputText
            id="signup-otp"
            keyfilter="pint"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="OTP"
            maxLength={6}
            className="my-3"
          />

          <div className="d-flex mt-3 justify-content-center">
            <button
              className="mx-3 btn btn-outline-primary px-3 otp-verification-card_outlined-button "
              onClick={handleResendOtp}
              disabled={isResendOtpButtonDisabled}
            >
              Resend OTP{" "}
              {secondsLeft > 0 && <span>{`in 0:${formattedSeconds}`}</span>}
            </button>
            <button
              className="mx-3 btn btn-primary px-3 "
              label="Verify OTP"
              onClick={handleOtpVerification}
              disabled={otp.length < 6 || isVerifyOtpButtonDisabled}
            >
              Verify OTP
            </button>
          </div>
        </div>
      </div>
    </Auth>
  );
};

export default SignUpOTP;
