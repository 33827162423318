import React, { useState } from "react";
import "./index.css";
import {
  ConvertTextToSeeMore,
  convertTexttoPills,
  convertTexttoStatus,
  convertToBadge,
} from "../../../Helper/Helper";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Timeline } from "primereact/timeline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesSquare } from "@fortawesome/free-solid-svg-icons";

const DastInstance = ({ data, setOpenDastSplitter }) => {
  const getPhase = (phase) => {
    if (phase?.toLowerCase() === "identified") {
      return [
        { status: "Identified", current: "curr" },
        { status: "Validated", current: "next" },
        { status: "Mitigated", current: "next" },
        { status: "Resolved", current: "next" },
      ];
    } else if (phase?.toLowerCase() === "validated") {
      return [
        { status: "Identified", current: "prev" },
        { status: "Validated", current: "curr" },
        { status: "Mitigated", current: "next" },
        { status: "Resolved", current: "next" },
      ];
    } else if (phase?.toLowerCase() === "mitigated") {
      return [
        { status: "Identified", current: "prev" },
        { status: "Validated", current: "prev" },
        { status: "Mitigated", current: "curr" },
        { status: "Resolved", current: "next" },
      ];
    } else if (phase?.toLowerCase() === "resolved") {
      return [
        { status: "Identified", current: "prev" },
        { status: "Validated", current: "prev" },
        { status: "Mitigated", current: "prev" },
        { status: "Resolved", current: "curr" },
      ];
    }
  };

  const customMarker = (item) => {
    return (
      <span
        style={{
          backgroundColor:
            item.current === "curr"
              ? "#ff737c"
              : item.current === "next"
              ? "gray"
              : "green",
          display: "inline-block",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
        }}
      ></span>
    );
  };

  const customContent = (item) => {
    return (
      <div
        style={{
          color:
            item.current === "curr"
              ? "#ff7e7c"
              : item.current === "next"
              ? "gray"
              : "green",
          fontWeight: item.current ? "bold" : "normal",
        }}
      >
        {item.status}
      </div>
    );
  };

  return (
    <div className="sidePanel px-4 py-4 mt-1">
      <div className="d-flex align-items-center">
        <h1 className="sidePanelHeader my-2">{data?.Vulnerability?.value}</h1>
        <FontAwesomeIcon
          icon={faTimesSquare}
          onClick={() => setOpenDastSplitter(false)}
          className="fs-4 closeSplitterIcon"
        />
      </div>
      <div className=" mt-4 mb-2 d-flex align-items-center">
        <p className="my-0 dastInstanceValue">
          Severity <b>:</b> {convertToBadge(data?.Severity?.value)}
        </p>
        <p className="my-0 mx-4 dastInstanceValue">
          Status <b>:</b> {convertTexttoStatus(data?.Status?.value)}
        </p>
        <p className="my-0 dastInstanceValue">
          Instances <b>:</b> {data?.Instances?.value?.length}
        </p>
      </div>
      <p className="mb-4 dastInstanceValue">
        References <b>:</b>{" "}
        <a href={data?.References?.value} target="_blank">
          {data?.References?.value}
        </a>
      </p>
      <div>
        <h1 className="sidePanelDescription my-2">Description</h1>
        <p className="my-2 text-justify sidePanelDescriptionText">
          {data?.Description?.value}
        </p>
      </div>

      <div className="mt-4 p-2 sidePanelInstances rounded">
        <h1 className="sidePanelDescription m-2">Instances</h1>
        <hr />
        <div>
          <Accordion activeIndex={0}>
            {data?.Instances?.value.map((item) => (
              <AccordionTab className="instanceAccordion" header={item?.url}>
                <div>
                  <h2 className="my-3 dastInstanceText">
                    Method : <span>{convertTexttoPills(item?.method)}</span>
                  </h2>
                  <h2 className="my-3 dastInstanceText">
                    Parameter :{" "}
                    <span>{ConvertTextToSeeMore(item?.parameter)}</span>
                  </h2>
                  <h2 className="my-3 dastInstanceText">
                    Attack : <span>{ConvertTextToSeeMore(item?.attack)}</span>
                  </h2>
                  <h2 className="my-3 dastInstanceText">
                    Evidence :{" "}
                    <span>{ConvertTextToSeeMore(item?.evidence)}</span>
                  </h2>
                </div>
                <h1 className="sidePanelDescription my-3">
                  Timeline Lifecycle :{" "}
                </h1>
                <Timeline
                  value={getPhase(item?.phase)}
                  layout="horizontal"
                  marker={customMarker}
                  content={customContent}
                />
              </AccordionTab>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default DastInstance;
