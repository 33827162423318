import React from "react";
import { Skeleton } from "primereact/skeleton";

const ProfileSkeleton = () => {
  return (
    <>
      <div className="mt-2">
        <div className="pb-2 mt-4">
          <Skeleton width="100%" height="8rem"></Skeleton>
        </div>
      </div>
      <div className="card-body text-center">
        <div className="mt-2 row justify-content-center">
          <div className="my-1 col-lg-6 col-md-6 col-sm-12 col-12">
            <Skeleton width="100%" height="12rem"></Skeleton>
          </div>
          <div className="my-1 col-lg-6 col-md-6 col-sm-12 col-12">
            <Skeleton width="100%" height="12rem"></Skeleton>
          </div>
          <div className="my-1 col-lg-6 col-md-6 col-sm-12 col-12">
            <Skeleton width="100%" height="12rem"></Skeleton>
          </div>
          <div className="my-1 col-lg-6 col-md-6 col-sm-12 col-12">
            <Skeleton width="100%" height="12rem"></Skeleton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSkeleton;
