import noTableData from "../../../assets/noTableData.svg";
const NoData = ({ height = "25rem", width = "auto" }) => {
  return (
    <div className="emptyTable">
      <img
        style={{ height: height, width: width }}
        src={noTableData}
        alt="No Notification(s)"
      />
    </div>
  );
};

export default NoData;
