import {faChartLine } from "@fortawesome/free-solid-svg-icons";
import { ConvertTextToKnob, ConvertTexttoChart, IncrementIndicator, formatDecimal } from "../../../Helper/Helper";
import { Skeleton } from "primereact/skeleton";
import { ProgressBar } from "primereact/progressbar";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import coverageVector from "../../../../assets/Vector.svg";
import NoData from "../../../UI/NoData/NoData";
export const TotalTestcaseCard = ({
  totalTestcaseDataLoading,
  totalTestcaseData,
  chartRefTotal,
  handleClickChart,
  handleClickIcon,
  duration
}) => {
  const infoText = "Displays the overall proportion of test cases covered by automation, including counts of automated and manual test cases."
  return (
    <>
      {!totalTestcaseDataLoading ? (
        <div className="filterCard col-3 col-md-4 mt-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
              <p className="m-0 ps-3 fs-7">
                Testcases
              </p>
              <CustomTooltip text={infoText} key="TotalTestcases" name="Total Testcases" />
            </div>
          </div>
          {totalTestcaseData && (
            <div className="ps-3 mt-1">
              <b className="fs-7">
                <div icon={faChartLine} className="d-flex fs-7" >
                  <p className="me-1 fs-5" style={{textDecoration:"underline", cursor:"pointer"}} onClick={()=>handleClickChart("BOTH",[0],"")}>{formatDecimal(totalTestcaseData?.total)}{""}</p>
                  <IncrementIndicator value={formatDecimal(totalTestcaseData?.["count increment"])} />
                </div>
              </b>
            </div>
          )}
          {totalTestcaseData?.["count increment"]>0&&
          <p className="ms-3 durationText">{formatDecimal(totalTestcaseData?.["count increment"])} new testcases added this month </p>
          }
        {totalTestcaseData?
          <div>  
            <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="row doughnut-container mb-1">
              {totalTestcaseData && ConvertTexttoChart(
                totalTestcaseData?.chart?.value,
                "doughnut_chart_metrics",
                false,
                "TotalTestcase",
                "",
                "testcase",
                "",
                "",
                chartRefTotal,
                handleClickChart
              )}
            </div>
            <div className="mt-2">
            <div className="d-flex align-items-center justify-content center">
              <span
                style={{
                  display: "inline-block",
                  width: "12px",
                  height: "12px",
                  backgroundColor: "rgba(255, 126, 124, 1)",
                }}
              ></span>
              <span className="ms-1">Automated</span>
            </div>
            <div className="d-flex align-items-center justify-content center mt-1">
              <span
                style={{
                  display: "inline-block",
                  width: "12px",
                  height: "12px",
                  backgroundColor: "rgba(224, 224, 224, 1)",
                }}
              ></span>
              <span className="ms-1">Manual</span>
            </div>
          </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
            <div style={{height:"2rem"}} className="d-flex align-items-center justify-content-center mt-2">
                <img src={coverageVector}></img>
                <p className="fs-8 ms-2 mt-3" style={{fontWeight:"600"}}>Automation Coverage: {formatDecimal( totalTestcaseData?.coverage)} %</p>
            </div>
            {totalTestcaseData?.["coverage increment"]!==0&&(
            <div>
                <p className="fs-10 ms-3 durationText">{totalTestcaseData?.["coverage increment"]>0?"increased":"decreased"} by {formatDecimal(totalTestcaseData?.["coverage increment"])}% from last month</p>
            </div>
              )}
            </div>
          </div>
               :(<NoData height="16rem"/>)}
        </div>
      ) : (
        <Skeleton className="filterCard mt-1" height="25rem"></Skeleton>
      )}
    </>
  );
};
export const SourceAutomatedTestRuns = ({
  automatedTestRunsLoading,
  automatedTestRunsData,
  chartRefSource,
  handleClickChart,
  handleClickIcon,
  duration
}) => {
  const infoText = "Source of automated test case execution displaying counts for each environment where tests have been executed."
  return (
    <>
      {!automatedTestRunsLoading ? (
        <div className="filterCard col-3 col-md-4 mt-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <p className="m-0 ps-3 fs-7 me-1">
                Source of automated test runs
              </p>
              <CustomTooltip text={infoText} key="TotalTestcases" name="Total Testcases" />
            </div>
          </div>
          {automatedTestRunsData && (
            <div className="ps-3">
              <b className="fs-7">
                <div icon={faChartLine} className="d-flex fs-7" >
                <p className="me-1 fs-5">{formatDecimal(automatedTestRunsData?.total)}{""}</p>
                  <IncrementIndicator value={formatDecimal(automatedTestRunsData?.increment)} />
                </div>
              </b>
            </div>
          )}

          <p className="ms-3 durationText mb-1">Based on past {duration} days</p>
          {automatedTestRunsData?
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="row doughnut-container mb-1">
              {automatedTestRunsData && ConvertTexttoChart(
                automatedTestRunsData?.chart?.value,
                "doughnut_chart_metrics",
                false,
                "SourceOfAutomatedTestRuns",
                "",
                "newDashboard",
                "",
                "",
                chartRefSource,
                handleClickChart
              )}
            </div>
            <div>
            <div className="d-flex align-items-center justify-content center mt-1">
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "rgb(75,192,192)",
                }}
              ></span>
              <span className="ms-1">Jenkins Scheduled</span>
            </div>
            <div className="d-flex align-items-center justify-content center mt-1">
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "rgba(255, 126, 124, 1)",
                }}
              ></span>
              <span className="ms-1">Jewel On-demand</span>
            </div>
            <div className="d-flex align-items-center justify-content center mt-1">
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "rgb(173, 66, 245)",
                }}
              ></span>
              <span className="ms-1">Jewel Scheduled</span>
            </div>
            <div className="d-flex align-items-center justify-content center mt-1">
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "rgba(255, 192, 124, 1)",
                }}
              ></span>
              <span className="ms-1">Jenkins On-demand</span>
            </div>
            <div className="d-flex align-items-center justify-content center mt-1">
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "rgba(0, 119, 182, 1)",
                }}
              ></span>
              <span className="ms-1">Local</span>
            </div>
          </div>
          </div>
          :(<NoData height="16rem"/>)}
        </div>
      ) : (
        <Skeleton className="filterCard mt-1" height="25rem"></Skeleton>
      )}
    </>
  );
};
export const ApplicationStabilityCard = ({
  stabilityData,
  stabilityDataLoading,
  duration,
}) => {

  const infoText = "This score reflects the overall health and reliability of your application. A higher score indicates a more stable application with fewer functionality issues."
  return (
    <>

      {!stabilityDataLoading ? (
        <div className="stabilityCard col-3 col-md-4 mt-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
              <p className="m-0 ps-3 fs-5">
                Application Stablity
              </p>
              <CustomTooltip text={infoText} key="ApplicationStability" name="Application Stablity" />
            </div>
            <p className="mt-auto mb-0 pe-2 durationText">based on past {duration} days</p>
          </div>
          <hr />
          {stabilityData ? (
            <div className="d-flex px-3 justify-content-between align-items-center">
              <div>
                <p className="my-1">Application Stability Score</p>
                <p>
                  <b className="fs-4 mx-1">
                    {stabilityData?.[0]?.["Application stability score"]}
                  </b>
                  of 1000
                </p>
              </div>
              <div className="pe-3">
                {ConvertTextToKnob(
                  stabilityData?.[0]?.["Application stability score"]
                )}
              </div>
            </div>
          ) : (
            <div className="fadeText">No Data Found</div>
          )}
        </div>
      ) : (
        <Skeleton className="filterCard mt-1" height="25rem"></Skeleton>
      )}
    </>
  );
};

export const AutomationCoverageCard = ({
  automationCoverageData,
  automationCoverageDataLoading,
  chartRefAutomation,
  handleClickChart,
  handleClickIcon,
  classificationType,
  setClassificationType,
  duration
}) => {
  const infoText = "Displays automation coverage and test case counts for either regression or smoke tests, including both automated and manual test cases."
  return (
    <>
      {!automationCoverageDataLoading ? (
        <div className="filterCard col-3 col-md-4 mt-2">
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center gap-2">
              <p className="m-0 ps-3 fs-7">
                Automation Coverage Type{" "}
              </p>
              <CustomTooltip text={infoText} key="AutomationCoverage" name="Automation Coverage" />
            </div>
            <div className="d-flex flex-row ms-3 mt-2" style={{ minWidth: "10vw", maxWidth: "10vw" }}>
              {["Regression", "Smoke"].map((option) => (
                <div key={option} className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    style={{cursor:"pointer"}}
                    type="radio"
                    name="classificationType"
                    value={option}
                    checked={classificationType === option}
                    onChange={(e) => setClassificationType(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor={option}>
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {automationCoverageData && (
            <div className="ps-3 mt-2">
              <b className="fs-7 d-flex">
              <p className="me-1 fs-5">{formatDecimal(automationCoverageData?.coverage)} %{" "}</p>
                <IncrementIndicator value={formatDecimal(automationCoverageData?.["coverage increment"])} className="mx-2 fs-5" />
                {automationCoverageData?.["coverage increment"] !== 0 && <span style={{ color: automationCoverageData?.["coverage increment"] > 0 ? "green" : "red",marginTop:"0.2rem" }}>%</span>}
              </b>
            </div>
          )}
         {automationCoverageData?     
          <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="row bar-container">
            {automationCoverageData?.chart && ConvertTexttoChart(
              automationCoverageData?.chart,
              "bar_chart",
              false,
              "AutomationCoverage",
              "",
              "newDashboard",
              "",
              "",
              chartRefAutomation,
              handleClickChart
            )}
          </div>
          </div>
          :(<NoData height="16rem"/>)}
        </div>

      ) : (
        <Skeleton className="filterCard mt-1" height="25rem"></Skeleton>
      )}
    </>
  );
};

export const AutomationStabilityCard = ({
  stabilityData,
  stabilityDataLoading,
  duration,
}) => {

  const infoText = "This score specifically focuses on the reliability and effectiveness of your automated testing suites. A high score indicates that your tests are consistently identifying and reporting issues without unexpected automation errors."
  return (
    <>
      {!stabilityDataLoading ? (
        <div className="stabilityCard col-3 col-md-4 mt-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
              <p className="m-0 ps-3 fs-5">
                Automation Stability
              </p>
              <CustomTooltip text={infoText} key="AutomationStability" name="Automation Stability" />
            </div>
            <p className="mt-auto mb-0 pe-2 durationText">based on past {duration} days</p>
          </div>
          <hr />
          {stabilityData ? (
            <div className="d-flex px-3 justify-content-between align-items-center">
              <div>
                <p className="my-1">Automation Stability Score</p>
                <p>
                  <b className="fs-4 mx-1">
                    {stabilityData?.[1]?.["Automation stability score"]}
                  </b>
                  of 1000
                </p>
              </div>
              <div className="pe-3">
                {ConvertTextToKnob(
                  stabilityData?.[1]?.["Automation stability score"]
                )}
              </div>
            </div>
          ) : (
            <div className="fadeText">No Data Found</div>
          )}
        </div>
      ) : (
        <Skeleton className="filterCard mt-1" height="25rem"></Skeleton>
      )}
    </>
  );
};

export const StabilityIndexCard = ({
  stabilityData,
  stabilityDataLoading,
  duration,
}) => {

  const infoText = "This value represents the historical stability of test suites based on their past execution results."
  return (
    <>
      {!stabilityDataLoading ? (
        <div className="stabilityCard col-3 col-md-4 mt-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
              <p className="m-0 ps-3 fs-5">
                Stability Index
              </p>
              <CustomTooltip text={infoText} key="StabilityIndex" name="Stability Index" />
            </div>
            <p className="mt-auto mb-0 pe-2 durationText">based on past {duration} days</p>
          </div>
          <hr />
          {stabilityData ? (
            <div className="ps-3">
              <p className="my-3 ps-2">Total Percentage</p>
              <div className="d-flex align-items-center">
                <ProgressBar
                  className="dashboard-progress-bar w-75 mx-1"
                  color="green"
                  value={stabilityData?.[2]?.["Stability index"]}
                  showValue={false}
                ></ProgressBar>
                <b className="fs-4 ms-1">{`${stabilityData?.[2]?.["Stability index"]} %`}</b>
              </div>
            </div>
          ) : (
            <div className="fadeText">No Data Found</div>
          )}
        </div>
      ) : (
        <Skeleton className="filterCard mt-1" height="25rem"></Skeleton>
      )}
    </>
  );
};

export const TestcaseExecutedCard = ({
  executionCount,
  duration,
  executionCountLoading,
}) => {

  const infoText = "Percentage of unique test cases executed over a specific period relative to the total executed till now."
  return (
    <>
      {!executionCountLoading ? (
        <div className="stabilityCard col-3 col-md-4 mt-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
              <p className="m-0 ps-3 fs-5">
                Testcase Executed
              </p>
              <CustomTooltip text={infoText} key="TestcaseExecuted" name="Testcase Executed" />
            </div>
            <p className="pe-2 durationText">based on past {duration} days</p>
          </div>
          <hr />
          {executionCount ? (
            <div className="ps-3">
              <p className="my-3 ps-2">Total Percentage</p>
              <div className="d-flex align-items-center">
                <ProgressBar
                  className="dashboard-progress-bar w-75 mx-1"
                  color={
                    executionCount < 60
                      ? "#C21010"
                      : executionCount >= 60 && executionCount < 75
                        ? "#E64848"
                        : executionCount >= 75 && executionCount < 85
                          ? "#FD841F"
                          : executionCount >= 85 && executionCount < 90
                            ? "#82CD47"
                            : executionCount >= 90
                              ? "#379237"
                              : null
                  }
                  value={executionCount}
                  showValue={false}
                ></ProgressBar>
                <b className="fs-4 ms-1">{`${executionCount} %`}</b>
              </div>
            </div>
          ) : (
            <div className="fadeText">No Data Found</div>
          )}
        </div>
      ) : (
        <Skeleton className="filterCard mt-1" height="25rem"></Skeleton>
      )}
    </>
  );
};

export const AverageTestExecutionCard = ({
  averageTestExecutionLoading,
  data,
  chartRef,
  handleClickChart,
  handleClickIcon,
  duration
}) => {
  const infoText = "Average number of times a test case executed per month over the last three months, reflecting testing activity and efficiency trends."
  return (
    <>
      {!averageTestExecutionLoading ? (
        <div className="filterCard col-3 col-md-4 mt-2">
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center gap-2">
              <p className="m-0 ps-3 fs-7">
                Average Test Execution Count
              </p>
              <CustomTooltip text={infoText} key="TotalTestcases" name="Total Testcases" />
            </div>
          </div>
          {data?.average && (
            <div className="ps-3 mt-2">
              <b className="fs-7">
                <div icon={faChartLine} className="d-flex fs-7" >
                <p className="me-1 fs-5">{formatDecimal(data?.average)}{""}</p>
                  <IncrementIndicator value={formatDecimal(data?.["average increment"])} />
                </div>
              </b>
            </div>
          )}
         <p className="ms-3 durationText">Based on past {duration} days</p>
         {data?.average?
          <div className="d-flex flex-column">
           <div className="row chart-container">
            {data && ConvertTexttoChart(
              data,
              "line_chart_metrics",
              false,
              "Average Test Execution Count",
              "",
              "newDashboard",
              "",
              "",
              chartRef,
              handleClickChart
            )}
            </div>
          </div>
          :(<NoData height="16rem"/>)}
        </div>
      ) : (
        <Skeleton className="filterCard mt-1" height="25rem"></Skeleton>
      )}
    </>
  );
};


const CustomTooltip = ({ text, name }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <>
      <Dialog
        blockScroll={true}
        draggable={false}
        header={name}
        visible={showInfo}
        onHide={() => {
          setShowInfo(false);
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "30vw" }}
      >
        <p>{text}</p>
      </Dialog>
      <i className="pi pi-info-circle fs-5 pointer" onClick={() => setShowInfo(true)}></i>
    </>
  );
};