import {
  faCheck,
  faPencil,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AVAILABLE_SUITE, EDIT_JOB_DETAILS } from "../../../../actions/type";
import httpService from "../../../../services/http.service";
import { getErrorMessage, loadingData } from "../../../Helper/Helper";

const ActionScheduler = ({ props }) => {
  const dispatch = useDispatch();
  const { val, rowData } = props;
  const [deleteload, setDeleteload] = useState(false);
  const [deletePrompt, setDeletePrompt] = useState(false);
  const toDeletejob = useRef(val);
  const toDeletejobname = useRef(rowData["Job Name"]["value"]);

  const deleteJob = (jobId) => {
    setDeleteload(true);
    httpService
      .deleteScheduledJobs(jobId)
      .then((data) => {
        getErrorMessage(data);
        setDeleteload(false);
        setDeletePrompt(false);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          props.getAllJobs();

          httpService
            .getAvailableTestToolSuites()
            .then(async (data) => {
              dispatch({
                type: AVAILABLE_SUITE,
                payload: await data["data"],
              });
              props.setSuiteData(data["data"]);
              getErrorMessage(data);
            })
            .catch((err) => {
              toast.error(
                `Something Went Wrong. 
              Please refresh the Page / Try Again`,
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
            });
        }
      })
      .catch((err) => {
        setDeleteload(false);
        setDeletePrompt(false);
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  return (
    <>
      <div className="d-flex">
        <div>
          {props.isEditButtonDisabled ? (
            <FontAwesomeIcon
              className="mx-2 disabledIcon"
              title="No Active testcase with run flag 'Y' found in the suite"
              icon={faPencil}
            />
          ) : (
            <FontAwesomeIcon
              className="mx-2 tabIcon actionBtn"
              icon={faPencil}
              onClick={(e) => {
                props.setJobAction("edit");
                props.setBreadList([
                  ...props.breadList,
                  { name: "Edit Job", type: "edit" },
                ]);
                dispatch({
                  type: EDIT_JOB_DETAILS,
                  payload: props,
                });
              }}
            />
          )}
        </div>
        <div>
          <FontAwesomeIcon
            className="mx-2 tabIcon actionBtn"
            icon={faTrash}
            onClick={(e) => setDeletePrompt(true)}
          />
        </div>
      </div>

      <Dialog
        blockScroll={true}
        className="createTestcaseDialog"
        header={`Delete Job`}
        visible={deletePrompt}
        onHide={() => {
          setDeletePrompt(false);
        }}
        draggable={false}
        breakpoints={{ "960px": "75vw" }}
        style={{
          width: "60vw",
        }}
      >
        {!deleteload ? (
          <>
            {" "}
            <div className="text-center mt-3 py-2 mx-3 deletePrompt">
              <h5>
                Are you Sure you want to delete Job : {toDeletejobname.current}
              </h5>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div
                onClick={() => {
                  deleteJob(toDeletejob.current);
                }}
                className="btn btn-success"
              >
                <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faCheck} />
                Yes
              </div>
              <div
                onClick={() => {
                  setDeletePrompt(false);
                }}
                className="btn btn-danger mx-2"
              >
                <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faXmark} />
                No
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="py-5">
              {loadingData("Deleting " + toDeletejobname.current)}
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ActionScheduler;
