import "./ManualTCFile.css";
import { useEffect, useState, useRef } from "react";
import httpService from "../../../services/http.service";
import {
  getErrorMessage,
  convertToBadge,
  toCapitalizeFirstChar,
} from "../../Helper/Helper";
import { toast } from "react-toastify";
import { Skeleton } from "primereact/skeleton";
import SeeMoreText from "../../UI/SeeMoreText/SeeMoreText";
import { over } from "stompjs";
import { useSelector } from "react-redux";
import { connectSocket } from "../../Helper/SocketHelper";

const ManualProperties = (props) => {
  const [testcaseData, setTestcaseData] = useState(null);
  const mtc_id = useRef(props.id);
  const company = useSelector((state) => state.auth.userProfile.socket);
  const firstUpdate = useRef(false);
  const pidArray = useRef(null);
  const projectData = useSelector(
    (state) => state.manualTC.project_data_manualtc
  );
  let stompClient = null;
  const tcConnection = useRef(null);

  const getData = () => {
    httpService
      .getManualData(mtc_id.current)
      .then(async (data) => {
        getErrorMessage(data);
        setTestcaseData(data["data"]);
        if (!firstUpdate.current) {
          firstUpdate.current = true;
          connectSocket(
            "execution",
            company.toString().toUpperCase() + "_MANUAL/private",
            tcConnection,
            notifyTcClient
          );
        }
      })
      .catch((err) => {
        if (
          err["response"]["data"]["operation"].toLowerCase() === "info" &&
          err["response"]["data"]["suboperation"]
        ) {
          props.setNoAccess(true);
          tcConnection?.current?.unsubscribe();
          return;
        }
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
    Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  useEffect(() => {
    getData();
    return () => {
      tcConnection?.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (projectData.length > 0) {
      let pids = projectData.map((data) => data["pid"]);
      pidArray.current = pids;
    }
  }, [projectData]);

  const notifyTcClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (pidArray.current && pidArray.current.includes(wsBody["pid"])) {
        getData();
      }
    }
  };

  return (
    <div>
      <div className="container">
        <div className="card propertiesCard p-3 mb-2">
          <div className="row mt-3 mx-2">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-md-4 col-12">
                  <div className="ms-1 my-1 reqDetails-heading">
                    Testcase ID
                  </div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["isolatedVersionId"] ? (
                        testcaseData["isolatedVersionId"]
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="2rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="ms-1 my-1 reqDetails-heading">
                    Testcase Name
                  </div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["name"] ? (
                        testcaseData["name"]
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="8rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="ms-1 my-1 reqDetails-heading">
                    Description
                  </div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["description"] ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: testcaseData["description"]
                              .map(String)
                              .join("<br />"),
                          }}
                        />
                      ) : (
                        // <SeeMoreText
                        //     text={testcaseData["description"].join("\n")}
                        //     maxLimit={150}
                        // />
                        "-"
                      )
                    ) : (
                      <Skeleton width="15rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <div className="ms-1 my-1 reqDetails-heading">Version</div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["version"] ? (
                        testcaseData["version"]
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="2rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ms-1 my-1 reqDetails-heading">Severity</div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["severity"] ? (
                        convertToBadge(
                          toCapitalizeFirstChar(
                            testcaseData["severity"]
                              .toLowerCase()
                              .split("_")
                              .join(" ")
                          )
                        )
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="3rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ms-1 my-1 reqDetails-heading">
                    Sprint Name
                  </div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["sprintName"] ? (
                        testcaseData["sprintName"]
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="8rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* new */}
          <div className="row mx-2 mt-3">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-md-4 col-12">
                  <div className="ms-1 my-1 reqDetails-heading">Status</div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["status"] ? (
                        toCapitalizeFirstChar(
                          testcaseData["status"]
                            .toLowerCase()
                            .split("_")
                            .join(" ")
                        )
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="5rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="ms-1 my-1 reqDetails-heading">Priority</div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["priority"] ? (
                        convertToBadge(
                          toCapitalizeFirstChar(
                            testcaseData["priority"]
                              .toLowerCase()
                              .split("_")
                              .join(" ")
                          )
                        )
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="3rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="ms-1 my-1 reqDetails-heading">Variables</div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["variables"] &&
                      Object.keys(testcaseData["variables"]).length > 0 ? (
                        "key-value"
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="5rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4 col-12">
                  <div className="ms-1 my-1 reqDetails-heading">
                    Assigned To
                  </div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["assignedTo"] ? (
                        testcaseData["assignedTo"]
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="5rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="ms-1 my-1 reqDetails-heading">Created By</div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData["created_by"] ? (
                        testcaseData["created_by"]
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="5rem" className=""></Skeleton>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="ms-1 my-1 reqDetails-heading">
                    Classification
                  </div>
                  <div className="ms-1 mt-2 fontSizeGeneral">
                    {testcaseData ? (
                      testcaseData?.automationType?.length > 0 ? (
                        <div>
                          {testcaseData?.automationType.map((item, index) => (
                            <span key={index}>{item}{ (index!==testcaseData?.automationType?.length-1) && ","}</span>
                          ))}
                        </div>
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton width="5rem" className="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Test Data</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {testcaseData ? (
                  testcaseData["testData"] ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: testcaseData["testData"]
                          .map(String)
                          .join("<br />"),
                      }}
                    />
                  ) : (
                    // <SeeMoreText
                    //     text={testcaseData["description"].join("\n")}
                    //     maxLimit={150}
                    // />
                    "-"
                  )
                ) : (
                  <Skeleton width="15rem" className=""></Skeleton>
                )}
              </div>
            </div>
          </div>

          <div className="row my-3 mx-2">
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Type</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {testcaseData ? (
                  testcaseData["manualTestcaseType"] ? (
                    toCapitalizeFirstChar(
                      testcaseData["manualTestcaseType"]
                        .toLowerCase()
                        .split("_")
                        .join(" ")
                    )
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Approved By</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {testcaseData ? (
                  testcaseData["approvedBy"] ? (
                    testcaseData["approvedBy"]
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualProperties;
