import { getErrorMessage } from "../components/Helper/Helper";
import httpService from "../services/http.service";
import {
  CLEAR_REPORT_DATA,
  REPORT_DATA,
  PROJECT_DATA,
  CLEAR_PROJECT_DATA,
} from "./type";

export const getReportData = () => (dispatch) => {
  return httpService.getReportDetails().then((data) => {
    dispatch({
      type: REPORT_DATA,
      payload: data.data,
    });
    return Promise.resolve(data.data);
  });
};
export const clearReportData = () => (dispatch) => {
  return dispatch({
    type: CLEAR_REPORT_DATA,
  });
};

export const getProjectData = () => (dispatch) => {

  return httpService
    .getProjectRole()
    .then((data) => {
      let pids = [];
      data.data.map((roles) =>
        pids.push(roles["pid"])
      );
      getErrorMessage(data);
      return Promise.resolve(pids);
    })
    .then((data) => {
      if (data && data.length > 0) {
        httpService.getProjectData(data).then((res) => {
          dispatch({
            type: PROJECT_DATA,
            payload: res,
          });
          return Promise.resolve(res);
        });
      }
    })
    .catch((err) => {
      if (err["response"]["data"]["operation"].toLowerCase() === "info" && err["response"]["data"]["suboperation"]) {
        dispatch({
          type: PROJECT_DATA,
          payload: err["response"]["data"],
        });
      }
    })
};

export const clearProjectData = () => (dispatch) => {
  return dispatch({
    type: CLEAR_PROJECT_DATA,
  });
};
