import React from "react";
import "./UserCapabilitiesCard.css";

const UserCapabiltiesCard = ({ heading, icon, description }) => {
  return (
    <div className="user-capabilities-inner-cards-wrapper">
      <img
        className="user-capabilities-card-icon"
        src={icon}
        alt={heading}
        loading="lazy"
      />

      <div>
        <p className="userCapabilities-card-heading">{heading}</p>
        <p className="userCapabilities-card-description">{description}</p>
      </div>
    </div>
  );
};

export default UserCapabiltiesCard;
