import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import './index.css';
import {  useState } from "react";
import ReportCards from "./ReportCards";


const NewReportTemplate = ({ type, clickFn }) => {
    
    const reportPills = [
        {
            "name": "All Reports",
            "type": "all"
        },
        {
            "name": "Saved Reports",
            "type": "saved"
        },
        {
            "name": "Shared With Me",
            "type": "shared"
        },
        {
            "name": "Shared By Me",
            "type": "sharedByMe"
        }
    ]

    

    const [activeReport, setActiveReport] = useState("all");
    const [isReportDataFetching,setIsReportDataFetching]=useState(false);
    return (
        <div>
            <button
                className='btn-success btn py-2 px-3 mt-1 themeBtnOutline'
                onClick={() => clickFn()}
            >
                <FontAwesomeIcon icon={faPlus} className='me-2' />
                {type === "noTab" ? "Open new tab" : "Create Report"}
            </button>
            <div className="mt-3">
                {reportPills.map((item) =>
                (<button
                    className={`me-2 reportPill ${activeReport === item.type ? 'activeReportPill' : ''} py-1 px-3`}
                    onClick={() => setActiveReport(item.type)}
                    disabled={isReportDataFetching}
                >
                    {item.name}
                </button>)
                )}
                <div className="mt-3">
                    <ReportCards type={activeReport} setIsReportDataFetching={setIsReportDataFetching} />
                </div>
            </div>
        </div>
    );

}

export default NewReportTemplate