import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const renderTooltip = (props, mod) => (
  <Tooltip id="info-tooltip" {...props}>
    {mod}
  </Tooltip>
);

const SideBarItem = (props) => {
  const [open, setOpen] = useState(false);
  const isOpen = useSelector((state) => state.sideBar.isOpen);

  return (
    <>
      {props?.item?.map((data, i) => {
        if (data.children) {
          return (
            <div key={`${i}+${Math.random()}`}>
              <div
                className={`row iconDropdown text-center my-2 py-2 d-flex justify-content-center ${isOpen ? "ms-2" : ""
                  }`}
                onClick={(e) => {
                  setOpen(!open);
                }}
              >
                <div className={`icon ${isOpen ? "col-3" : "col-12 ms-1"}`}>
                  {!isOpen ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={renderTooltip(props, data.module)}
                    >
                      <FontAwesomeIcon icon={data.icon} />
                    </OverlayTrigger>
                  ) : (
                    <FontAwesomeIcon icon={data.icon} />
                  )}
                </div>
                <div
                  className={`icon-text ${isOpen
                      ? "col-md-9 col-sm-12 text-md-start text-center"
                      : "col-9 collapseSideBar"
                    }`}
                >
                  <div className="d-md-flex justify-content-start">
                    <div className="">{data.module}</div>
                    <div className="ms-auto me-4 d-none d-sm-none d-md-block">
                      {!open ? (
                        <FontAwesomeIcon
                          flip={open ? "vertical" : ""}
                          icon={faChevronRight}
                        />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col:12 ms-1">
                {open && props.sideBar(data.children, "ms-3")}{" "}
              </div>{" "}
            </div>
          );
        } else {
          return (
            <div key={`${i}+${Math.random()}`} className="ms-2">
              {props.sideBarElements(data)}
            </div>
          );
        }
      })}
    </>
  );
};

export default SideBarItem;
