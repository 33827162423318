import { useEffect, useRef, useState, createContext } from "react";
import NoAccess from "../../../UI/NoAccess/NoAccess";
import { DataTable } from "primereact/datatable";
import {
  copyToClipboard,
  createColumns,
  createFilters,
  decodeParam,
  encodeParam,
  getErrorMessage,
  getToastMessage,
  logPageTitleForGA,
} from "../../../Helper/Helper";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import httpService from "../../../../services/http.service";
import {
  AVAILABLE_SUITE,
  CLEAR_AVAILABLE_SUITE,
  CLEAR_PROJECT_DATA_TESTTOOL,
  PROJECT_DATA_TESTTOOL,
} from "../../../../actions/type";
import { handleToastErrorCatch } from "../../../Helper/ToastHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import BreadCrumb from "../../../UI/BreadCrumb/BreadCrumb";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import SkeletonUtility from "../../../UI/Skeleton/SkeletonUtility";
import { EditDeleteAction, FileAction, FolderAction } from "./FolderActions";
import CreateFolderDialog from "../../../UI/FolderUtils/CreateFolderDialog";
import NewTestcaseDialog from "./NewTestcaseDialog";
import TestcaseFile from "./TestcaseFile";
import { useSearchParams } from "react-router-dom";
import DeleteDialog from "./DeleteDialog";
import LinkSuite from "./LinkSuite";
import { connectSocket } from "../../../Helper/SocketHelper";
import { MultiSelect } from "primereact/multiselect";
import noTableData from "../../../../assets/noTableData.svg";
import NoData from "../../../UI/NoData/NoData";
export const AlabContext = createContext();

const ALab = () => {
  const dispatch = useDispatch();
  const [noAccessFile, setNoAccessFile] = useState(false);
  const [alabData, setAlabData] = useState(null);
  const [breadList, setBreadList] = useState([
    { name: "Automation Lab", f_id: null, p_id: null, p_name: null },
  ]);
  const [createNewFolderButtonLoading, setCreateNewFolderButtonLoading] =
    useState(false);
  const [createNewTestcaseButtonLoading, setCreateNewTestcaseButtonLoading] =
    useState(false);
  const [newFolderDialog, setNewFolderDialog] = useState(false);
  const [newTestcaseDialog, setNewTestcaseDialog] = useState(false);
  const [projectsWithAccess, setProjectsWithAccess] = useState(null);
  const [action, setAction] = useState(null);
  const company = useSelector((state) => state.auth.userProfile.socket);
  const currentP_Name = useRef(null);
  const currentFolderID = useRef(null);
  const currentFolderName = useRef(null);
  const currentP_ID = useRef(null);
  const pidArray = useRef([]);
  const [currentTcId, setCurrentTcId] = useState(null);
  const [currentTcName, setCurrentTcName] = useState(null);
  const [searchParams] = useSearchParams();
  const [editTcId, setEditTcId] = useState(null);
  const [editFolderData, setEditFolderData] = useState(null);
  const toDeleteName = useRef(null);
  const toDeleteId = useRef(null);
  const toDeleteType = useRef(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [suiteLinkDialog, setSuiteLinkDialog] = useState(false);
  const [linkSuiteButtonLoading, setLinkSuiteButtonLoading] = useState(false);
  const [linkingSuites, setLinkingSuites] = useState(null);
  const connectionTestpad = useRef(null);
  const connectionExecution = useRef(null);
  const [alabFilters, setAlabFilters] = useState({});
  const [selectedColumns, setSelectedColumns] = useState([]);
  const role = useSelector((state) => state.auth.userProfile.role);

  useEffect(() => {
    let title = "Jewel | ALab";
    document.title = title;
    logPageTitleForGA(title);

    connectSocket(
      "testpad",
      company.toString().toUpperCase() + "_AUTOMATION_FOLDER/private",
      connectionTestpad,
      notifyClient
    );
    connectSocket(
      "execution",
      company.toString().toUpperCase() + "_AUTOMATION_FOLDER/private",
      connectionExecution,
      notifyClient
    );

    httpService
      .getProjectRole()
      .then((data) => {
        let pids = [];
        data?.data?.map((roles) => pids.push(roles["pid"]));
        getErrorMessage(data);
        return Promise.resolve(pids);
      })
      .then((data) => {
        if (data && data.length > 0) {
          httpService.getProjectData(data).then((res) => {
            dispatch({
              type: PROJECT_DATA_TESTTOOL,
              payload: res,
            });
            if (res && res.length > 0) {
              let pids = res.map((data) => data["pid"]);
              pidArray.current = pids;
            }
            return Promise.resolve(res);
          });
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          setNoAccessFile(true);
          dispatch({
            type: PROJECT_DATA_TESTTOOL,
            payload: err?.response?.data,
          });
        }
        handleToastErrorCatch(err);
      });

    return () => {
      dispatch({ type: CLEAR_AVAILABLE_SUITE });
      dispatch({ type: CLEAR_PROJECT_DATA_TESTTOOL });
      connectionExecution?.current?.unsubscribe();
      connectionTestpad?.current?.unsubscribe();
    };
  }, []);

  const notifyClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (pidArray.current && pidArray.current.includes(wsBody["pid"])) {
        getAlabFolders();
      }
    }
  };

  useEffect(() => {
    setNoAccessFile(false);
    if (searchParams.get("f_id")) {
      let tempList = [...JSON.parse(decodeParam(searchParams.get("list")))];
      setBreadList(tempList);
      setCurrentTcId(null);
      currentFolderID.current = searchParams.get("f_id");
      currentP_ID.current = searchParams.get("p_id");
      currentP_Name.current = tempList?.[tempList.length - 1]?.["p_name"];
      getAlabFolders();
    } else if (searchParams.get("tc_id")) {
      let tempList = searchParams.get("list")
        ? [...JSON.parse(decodeParam(searchParams.get("list")))]
        : [];
      setBreadList(tempList);
      setCurrentTcId(searchParams.get("tc_id"));
      currentP_ID.current = searchParams.get("p_id");
      currentP_Name.current = tempList?.[tempList.length - 1]?.["p_name"];
    } else {
      setBreadList([
        { name: "Automation Lab", f_id: null, p_id: null, p_name: null },
      ]);
      setCurrentTcId(null);
      currentFolderID.current = null;
      currentP_ID.current = null;
      currentP_Name.current = null;
      getAlabFolders();
    }
  }, [searchParams]);

  useEffect(() => {
    if (alabData) {
      let finalHeaders = [];
      finalHeaders = [...alabData?.headers, ...alabData?.optionalHeaders];
      if (Object.keys(alabFilters)?.length === 0) {
        const filterData = alabData?.headers ? createFilters(finalHeaders) : {};
        setAlabFilters(filterData);
      }
    }
  }, [alabData]);

  const onHideDeleteDialog = () => {
    setDeleteDialog(false);
    toDeleteId.current = null;
    toDeleteName.current = null;
    toDeleteType.current = null;
  };

  const getAlabFolders = () => {
    let payload = {
      folderId: currentFolderID.current,
      companyName: company,
      folderType: "AUTOMATION_TC",
    };
    httpService
      .getAllFolderTC(payload)
      .then((data) => {
        dispatch({
          type: AVAILABLE_SUITE,
          payload: data?.data,
        });
        setAlabData(data?.data);
        getErrorMessage(data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const getProjects = () => {
    setCreateNewFolderButtonLoading(true);
    httpService
      .getAllProjectsWithAccess()
      .then((data) => {
        setProjectsWithAccess(data["data"]);
        getErrorMessage(data);
        setCreateNewFolderButtonLoading(false);
        setNewFolderDialog(true);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () =>
          setCreateNewFolderButtonLoading(false)
        );
      });
  };

  const onTestcaseClick = (data, type, list) => {
    if (type === "alab") {
      if (data["f_id"]) {
        setAlabData(null);
        setCurrentTcId(null);
        currentFolderID.current = data["f_id"];
        currentP_ID.current = data["p_id"];
        currentP_Name.current = data["p_name"];
        getAlabFolders();
        window.history.replaceState(
          null,
          "",
          "/#/testpad/ALab?f_id=" +
            data["f_id"] +
            "&p_id=" +
            data["p_id"] +
            "&list=" +
            encodeParam(JSON.stringify(list))
          // + "&filters=" +
          // encodeParam(JSON.stringify(manualFilters))
        );
      } else if (data["tc_id"]) {
        currentFolderID.current = null;
        currentP_ID.current = data["p_id"];
        currentP_Name.current = data["p_name"];
        setCurrentTcId(data["tc_id"]);
      } else {
        if (data["name"].toLowerCase() === "automation lab") {
          setAlabData(null);
          currentFolderID.current = null;
          setCurrentTcId(null);
          currentP_ID.current = null;
          currentP_Name.current = null;
          getAlabFolders();
          window.history.replaceState(null, "", "/#/testpad/ALab");
        }
      }
    }
  };

  const openFile = (tc_id, tc_name, p_id, p_name) => {
    setCurrentTcId(tc_id);
    setCurrentTcName(tc_name);
    currentP_ID.current = p_id;
    currentP_Name.current = p_name;

    let newLink = {
      name: tc_name,
      tc_id: tc_id,
      p_id: p_id,
      p_name: p_name,
    };
    setBreadList([...breadList, newLink]);

    let list = [...breadList, newLink];
    window.history.replaceState(
      null,
      "",
      "/#/testpad/ALab?tc_id=" +
        tc_id +
        "&p_id=" +
        p_id +
        "&list=" +
        encodeParam(JSON.stringify(list))
    );
  };

  const openFolder = (f_id, f_name, p_id, p_name) => {
    setAlabData(null);
    setAlabFilters({});
    currentFolderID.current = f_id;
    currentFolderName.current = f_name;
    currentP_ID.current = p_id;
    currentP_Name.current = p_name;
    let newLink = {
      name: f_name,
      f_id: f_id,
      p_id: p_id,
      p_name: p_name,
    };
    setBreadList([...breadList, newLink]);
    getAlabFolders();

    let list = [...breadList, newLink];
    window.history.replaceState(
      null,
      "",
      "/#/testpad/ALab?f_id=" +
        f_id +
        "&p_id=" +
        p_id +
        "&list=" +
        encodeParam(JSON.stringify(list))
    );
  };

  const deleteFolder = (id, setLoading) => {
    setLoading(true);
    httpService
      .deleteFolderTestpad(id)
      .then((data) => {
        getToastMessage(data);
        setLoading(false);
        onHideDeleteDialog();
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => onHideDeleteDialog());
        setLoading(false);
      });
  };

  const deleteTestcase = (id, setLoading) => {
    setLoading(true);
    httpService
      .deleteAlabTestcase(id)
      .then((data) => {
        getToastMessage(data);
        setLoading(false);
        onHideDeleteDialog();
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => onHideDeleteDialog());
        setLoading(false);
      });
  };

  const editFileAction = (val, rowData) => {
    const type = rowData?.[currentFolderID.current?"Folder/Testcase":"Folder"]?.subType?.toLowerCase();
    setAction("edit");

    if (type === "crud_automationtc") {
      setEditTcId(val);
      return;
    }

    setEditFolderData(rowData);
  };

  const deleteAction = (val, rowData) => {
    setDeleteDialog(true);
    toDeleteId.current = val;
    toDeleteName.current = rowData?.[currentFolderID.current?"Folder/Testcase":"Folder"]?.value;
    toDeleteType.current =
      rowData?.[currentFolderID.current?"Folder/Testcase":"Folder"]?.subType?.toLowerCase() ===
      "crud_automationfolder"
        ? "folder"
        : "file";
  };

  //common
  const newTestcase = () => {
    setCreateNewTestcaseButtonLoading(true);
    httpService
      .getAllProjectsWithAccess()
      .then((data) => {
        setProjectsWithAccess(data["data"]);
        getErrorMessage(data);
        setAction("create");
        setNewTestcaseDialog(true);
        setCreateNewTestcaseButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () =>
          setCreateNewTestcaseButtonLoading(false)
        );
      });
  };

  const contextValue = {
    currentFolderID,
    currentP_ID,
    currentP_Name,
    currentFolderName,
  };

  const getSuitesToLink = () => {
    setLinkSuiteButtonLoading(true);
    httpService
      .getSuitesToLink(currentTcId)
      .then(async (data) => {
        setLinkingSuites(data["data"]);
        getErrorMessage(data);
        setSuiteLinkDialog(true);
        setLinkSuiteButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setLinkSuiteButtonLoading(false));
      });
  };

  const onColumnToggle = (e) => {
    setSelectedColumns(e.value);
  };
  const header = alabData?.optionalHeaders && (
    <div style={{ textAlign: "left", minHeight: "2vh" }}>
      <MultiSelect
        placeholder="More Detail(s)"
        value={selectedColumns}
        options={alabData?.optionalHeaders}
        onChange={(e) => onColumnToggle(e)}
        maxSelectedLabels={1}
        style={{ width: "15vw" }}
      />
    </div>
  );

  return !noAccessFile ? (
    <AlabContext.Provider value={contextValue}>
      <div className="container-fluid reqSection mx-2">
        <div className="d-flex flex-wrap align-items-center pt-2 mx-2 mb-2 ">
          <div className=" pb-1">
            <div className="headerTest">
              ALab
              <span className="ms-3">
                {breadList && breadList.length > 0 && (
                  <FontAwesomeIcon
                    className="actionBtn"
                    icon={faCopy}
                    title="Copy Link"
                    onClick={(e) =>
                      copyToClipboard(
                        window.location.origin + "/" + window.location.hash
                      )
                    }
                  />
                )}
              </span>
            </div>
          </div>
          <div className="ms-sm-auto">
            {!currentTcId &&
              role.toString().toUpperCase() !== "SUPER_ADMIN" && (
                <Button
                  label="Create New Folder"
                  className="btn-success btn themeBtn mx-2 p-2 mt-1"
                  onClick={(e) => getProjects()}
                  icon={
                    createNewFolderButtonLoading
                      ? "pi pi-spin pi-spinner me-2"
                      : "pi pi-plus me-2"
                  }
                  iconPos="right"
                  loading={createNewFolderButtonLoading}
                />
              )}
            {(currentFolderID.current || currentTcId) &&
              role.toString().toUpperCase() !== "SUPER_ADMIN" && (
                <Button
                  label={currentTcId ? "Edit Testcase" : "Create New Testcase"}
                  className="btn-success btn themeBtn mx-2 p-2 mt-1"
                  onClick={(e) => {
                    if (currentTcId) {
                      setAction("edit");
                      setEditTcId(currentTcId);
                      return;
                    }
                    newTestcase();
                  }}
                  icon={
                    createNewTestcaseButtonLoading
                      ? "pi pi-spin pi-spinner me-2"
                      : "pi pi-plus me-2"
                  }
                  iconPos="right"
                  loading={createNewTestcaseButtonLoading}
                />
              )}
            {currentTcId && role.toString().toUpperCase() !== "SUPER_ADMIN" && (
              <Button
                label={"Link Suite"}
                className="btn-success btn themeBtn mx-2 p-2 mt-1"
                onClick={(e) => {
                  getSuitesToLink();
                }}
                icon={
                  linkSuiteButtonLoading
                    ? "pi pi-spin pi-spinner me-2"
                    : "pi pi-plus me-2"
                }
                iconPos="right"
                loading={linkSuiteButtonLoading}
              />
            )}
          </div>
        </div>
        <div className="mx-2">
          {breadList && breadList.length > 0 ? (
            <BreadCrumb
              breadList={breadList}
              setBreadList={setBreadList}
              component={"Alab"}
              onClickEvent={onTestcaseClick}
            />
          ) : (
            <div>
              <span>
                <FontAwesomeIcon
                  className="me-2 staticIcon"
                  icon={faClipboardList}
                />
              </span>
              ALab
            </div>
          )}
        </div>
        {currentTcId ? (
          <TestcaseFile
            tc_id={currentTcId}
            tc_name={currentTcName}
            noAccessFile={noAccessFile}
            setAction={setAction}
            setNoAccessFile={setNoAccessFile}
          />
        ) : (
          <div className="mt-3 mx-2">
            {alabData ? (
              <div>
                <DataTable
                  header={header}
                  reorderableColumns
                  onFilter={(e) => {
                    setAlabFilters(e.filters);
                  }}
                  resizableColumns
                  stripedRows
                  columnResizeMode="expand"
                  value={alabData?.data}
                  paginator
                  rows={25}
                  dataKey="id"
                  filters={alabFilters}
                  rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  scrollHeight={"60vh"}
                  filterDisplay="menu"
                  responsiveLayout="scroll"
                  emptyMessage={<NoData />}
                  currentPageReportTemplate="Total {totalRecords} Record(s) Found"
                  removableSort
                  className="test-stepTable"
                >
                  {createColumns(
                    alabData?.headers,
                    true,
                    true,
                    alabData?.data,
                    false,
                    null,
                    null,
                    {
                      crud_automationTc: true,
                      crud_automationFolder: true,
                      crud_delete: true,
                    },
                    {
                      crud_automationTc: FileAction,
                      crud_automationFolder: FolderAction,
                      crud_delete: EditDeleteAction,
                    },
                    {
                      crud_automationFolder_props: {
                        openFolder: openFolder,
                        fid:currentFolderID
                      },
                      crud_automationTc_props: {
                        openFile: openFile,
                        fid:currentFolderID
                      },
                      crud_delete_props: {
                        editFileAction: editFileAction,
                        setAction: setAction,
                        setEditTcId: setEditTcId,
                        setEditFolderData: setEditFolderData,
                        deleteAction: deleteAction,
                      },
                    }
                  )}
                  {alabData?.optionalHeaders &&
                    createColumns(selectedColumns, true, true, alabData?.data)}
                </DataTable>
              </div>
            ) : (
              <SkeletonUtility paginator={false} />
            )}
          </div>
        )}
        <CreateFolderDialog
          projectOptions={projectsWithAccess}
          action={action}
          setAction={setAction}
          setEditFolderData={setEditFolderData}
          editFolderData={editFolderData}
          newFolderDialog={newFolderDialog}
          setNewFolderDialog={setNewFolderDialog}
        />
        <NewTestcaseDialog
          newTestcaseDialog={newTestcaseDialog}
          setTestcaseDialog={setNewTestcaseDialog}
          action={action}
          setAction={setAction}
          createNewTestcaseButtonLoading={createNewTestcaseButtonLoading}
          setCreateNewTestcaseButtonLoading={setCreateNewTestcaseButtonLoading}
          editTcId={editTcId}
          setEditTcId={setEditTcId}
        />
        <DeleteDialog
          header="Delete Folder/Testcase"
          deleteDialog={deleteDialog}
          onHideDeleteDialog={onHideDeleteDialog}
          toDeleteName={toDeleteName}
          toDeleteId={toDeleteId}
          toDeleteType={toDeleteType}
          deleteFolder={deleteFolder}
          deleteFile={deleteTestcase}
        />
        <LinkSuite
          suites={linkingSuites}
          setSuites={setLinkingSuites}
          currentTcId={currentTcId}
          suiteLinkDialog={suiteLinkDialog}
          setSuiteLinkDialog={setSuiteLinkDialog}
        />
      </div>
    </AlabContext.Provider>
  ) : (
    <NoAccess />
  );
};

export default ALab;
