import { useEffect, useState } from "react";
import {handleToastErrorCatch } from "../../Helper/ToastHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import httpService from "../../../services/http.service";
import { getInfoMessage } from "../../Helper/Helper";
const ProjectRemovalApproval = ({ props }) => {
    const [requestActionLoading, setRequestActionLoading] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    useEffect(() => {
        const id = props.rowData?.["S No"]?.value;
        props?.selectedUser?.length > 0
            ? setDisableButtons(
                props?.selectedUser?.some((item) => item?.["S No"]?.value === id)
            )
            : setDisableButtons(false);
    }, [props?.selectedUser, props?.key, props?.bulkActionTrigger]);
    const requestAction = (type) => {
        setRequestActionLoading(true);
        let payload = [{
            pid: props?.val,
            username: props?.rowData?.Name?.value
        }]
        httpService.postProjectRemovalRequest(payload, type === "approve" ? "accept" : "decline")
            .then((data) => {
                setDisableButtons(false);
                getInfoMessage(data);
            })
            .catch((err) => {
                handleToastErrorCatch(err);
            })
            .finally(() => { setDisableButtons(false); setRequestActionLoading(false); })
    };
    useEffect(() => {
        setDisableButtons(false);
    }, [props?.bulkActionTrigger]);
    return (
        <div className="accessBtn">
            <>
                {requestActionLoading
                    ? <FontAwesomeIcon icon={faSpinner} className="fa fa-spin ms-2" />
                    : (<>
                        <FontAwesomeIcon
                            className={`mx-2 ${disableButtons ? "disabledReqIcon" : "acceptIcon"
                                }`}
                            icon={faCircleCheck}
                            disabled={disableButtons}
                            onClick={() => {
                                if (!disableButtons) {
                                    requestAction("approve");
                                }
                            }}
                        />
                        <FontAwesomeIcon
                            className={`mx-2 ${disableButtons ? "disabledReqIcon" : "rejectIcon"
                                }`}
                            icon={faCircleXmark}
                            onClick={() => {
                                if (!disableButtons) {
                                    requestAction("reject");
                                }
                            }}
                        />
                    </>
                    )}
            </>
        </div>
    )
};

export default ProjectRemovalApproval;