import React from "react";
import OverviewImage from "../../../../assets/OverviewImage.png";
import "./Overview.css";

const Overview = () => {
  return (
    <div className="component-padding max-w-1440">
      <p className="component-heading">Overview</p>
      <div className="overview-text-and-image-wrapper">
        <p className="overview-text">
        Taskboard is a dynamic project management tool designed for seamless collaboration and efficient task handling. Its hierarchical folder system enables structured organization of tasks, fostering clarity and systematic workflow management. With user-friendly features for task assignment, deadline setting, and real-time progress tracking, Taskboard empowers teams to streamline project requirements and achieve goals effectively.Taskboard's customizable features adapt to diverse team needs, offering flexibility and scalability for efficient project management and collaboration.
        </p>
        <img
          className="overview-image"
          src={OverviewImage}
          alt="people looking at charts"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Overview;
