import {
  CLEAR_PROJECT_DATA,
  CLEAR_REPORT_DATA,
  OPEN_PROMPT,
  CLOSE_PROMPT,
  PROJECT_DATA,
  REPORT_DATA,
} from "../actions/type";

const initialState = { reportData: [], projectData: [] , reportOpen: false};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REPORT_DATA:
      return { ...state, reportData: payload };
    case CLEAR_REPORT_DATA:
      return { ...state, reportData: [] };
    case PROJECT_DATA:
      return { ...state, projectData: payload };
    case CLEAR_PROJECT_DATA:
      return { ...state, projectData: [] };
    case OPEN_PROMPT:
      return {...state, reportOpen: true};
    case CLOSE_PROMPT:
      return {...state, reportOpen: false};  
    default:
      return state;
  }
}


