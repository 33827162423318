import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import httpService from "../../../services/http.service";
import { getErrorMessage, getInfoMessage } from "../../Helper/Helper";
import "./Admin.css";
import { Button } from "primereact/button";
import { handleToastErrorCatch, statementError } from "../../Helper/ToastHelper";

const AddUser = (props) => {
  const [user, setUser] = useState([]);
  const [selectedAccess, setSelectedAccess] = useState("");
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [addUserButtonLoading, setAddUserButtonLoading] = useState(false);

  const userOptionTemplate = (option) => {
    return (
      <>
        <div>
          <span>{`${option.Name} ( ${option.Username} )`}</span>
        </div>
      </>
    );
  };
  const addNewUser = async () => {
    let finalPayload = [];
    if (selectedAccess.length > 0 && user.length > 0) {
      user.map((data) => {
        let payload = {
          pid: props.p_id,
          username: data,
          role: selectedAccess,
        };
        finalPayload.push(payload);
      });

      setAddUserButtonLoading(true);
      await httpService
        .addUserRequest(finalPayload)
        .then(async (data) => {
          if (
            data?.["operation"]?.toLowerCase() === "success" &&
            data?.["data"] &&
            typeof data?.["data"] === "object"
          ) {
            const errMsg = data["data"] ? Object.keys(data["data"]) : null;
            errMsg &&
              errMsg.map((key) =>
                statementError(key + " : " + data?.["data"]?.[key] + "!")
              );
          }
         else if (
            data["operation"] &&
            data["operation"].toLowerCase() === "success"
          ) {
            getInfoMessage(data);
            const updateProjectUsers = new CustomEvent("onAddUser", {
              detail: {
                pid: props.p_id,
              },
              cancelable: true,
            });
            document.dispatchEvent(updateProjectUsers);
            setUser([]);
            setSelectedAccess("");
          } else {
            getErrorMessage(data);
          }
        })
        .catch((err) => {
handleToastErrorCatch(err);
        });
      setAddUserButtonLoading(false);
    } else {
      toast.error(`All Fields are required!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const userAdd = () => {
    return (
      <>
        {props.users.length > 0 ? (
          <MultiSelect
            value={user}
            style={{ minWidth: "22vw", maxWidth: "22vw" }}
            options={props.users}
            onChange={(e) => {
              setUser(e.value);
              setSelectAllUsers(e.value.length === props.users.length);
            }}
            selectAll={selectAllUsers}
            onSelectAll={(e) => {
              setUser(
                e.checked ? [] : props.users.map((user) => user.Username)
              );
              setSelectAllUsers(!e.checked);
            }}
            virtualScrollerOptions={{ itemSize: 50 }}
            optionLabel="Name"
            optionValue="Username"
            placeholder="Select User(s) to Add"
            filter
            maxSelectedLabels={1}
            className="multiselect-custom w-100 addUserDropdown"
            itemTemplate={userOptionTemplate}
            disabled={addUserButtonLoading}
          />
        ) : (
          <Dropdown
            className="dialog-dropdown"
            style={{ minWidth: "22vw", maxWidth: "22vw" }}
            options={[]}
            emptyMessage="No User(s) Found"
            placeholder="Select User(s) to Add"
            disabled={addUserButtonLoading}
          />
        )}
        <Dropdown
          className="dialog-dropdown ms-3"
          style={{ minWidth: "12vw", maxWidth: "12vw" }}
          options={["Admin", "Dev", "QA", "Viewer"]}
          value={selectedAccess}
          onChange={(e) => setSelectedAccess(e.value)}
          placeholder="Select Access Role(s)"
          maxSelectedLabels={1}
          disabled={addUserButtonLoading}
        />
      </>
    );
  };
  return (
    <>
      <div className="text-center mt-5">{userAdd()}</div>
      <div className="text-center mt-5">
        <Button
          label="Add User"
          onClick={(e) => {
            addNewUser();
          }}
          className="btn-success ms-2 py-2 btn themeBtn"
          loadingIcon={"pi pi-spin pi-spinner me-2"}
          loading={addUserButtonLoading}
          iconPos="right"
        />
      </div>
    </>
  );
};

export default AddUser;
