import './index.css';
import { convertEpochtoDate } from '../Helper'

const ConvertTextToTimeDetails = (value) => {
    return (
        <div>
            <div className='d-flex justify-content-start userSection'>
                {value?.user}
            </div>
            <div className='d-flex justify-content-start timeSection'>{convertEpochtoDate(value?.time)}</div>
        </div>
    )
}

export default ConvertTextToTimeDetails