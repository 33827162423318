import SuperAdmin from "../../Layouts/SuperAdmin/SuperAdmin";
const SuperAdminPage = () => {
  return (
    <>
      <div className="container-fluid">
        <SuperAdmin />
      </div>
    </>
  );
};

export default SuperAdminPage;
