import { useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import httpService from '../../../../services/http.service';
import { handleToastErrorCatch } from '../../../Helper/ToastHelper';
import { ConvertTextToSeeMore, convertTextToFramework, convertTexttoPills, logPageTitleForGA } from '../../../Helper/Helper';
import { useEffect } from 'react';
import { Skeleton } from 'primereact/skeleton';
import NoAccess from '../../../UI/NoAccess/NoAccess';
import { connectSocket } from '../../../Helper/SocketHelper';

const TestcaseFile = ({
    tc_id,
    tc_name,
    noAccessFile,
    setNoAccessFile
}) => {
    const [testcaseData, setTestcaseData] = useState(null);
    const company = useSelector((state) => state.auth.userProfile.socket);
    const pidArray = useRef(null);
    const connectionExecution = useRef(null);
    const projectData = useSelector(
        (state) => state.TestTool.project_data_testtool
    );

    const getData = () => {
        httpService
            .getEditTestCasesDetails(tc_id)
            .then((res) => {
                let data = res?.data;
                setTestcaseData(data);
                // setShowEditTestcase(true);
                // setTestcaseName(data?.["name"]);
                // setScenarioSteps(data?.["scenario_steps"].join("\n"));
                // setCategory(data?.["category"]);
                // setTags(data?.["tags"] ? data?.["tags"] : "");
                // setRunflag(data?.["run_flag"]);
                // setType(data?.["type"]);

                // if (data?.["examples"]) {
                //     setScenarioExample(data["examples"].join("\n"));
                // }
                // setShowEditTestcase(true);
                // setTestcaseName(data?.["name"]);
                // setCategory(data?.["category"]);
                // setType(data?.["type"]);
                // setTags(data?.["tags"] ? data["tags"] : "");
                // setRunflag(data?.["run_flag"]);
                // data?.type.toLowerCase() !== "gempyp"
                //     ? showTcpills(true)
                //     : showTcpills(false);
                // setTestcaseOptions(
                //     availableTestcaseKeys.current[data["type"].toLowerCase()]
                // );

                // if (data?.["frameworkDefinedData"]) {
                //     const frameworkDefinedDataKeys = Object.keys(
                //         data?.["frameworkDefinedData"]
                //     );
                //     let tempKeyHeaders = [...keyHeaders];
                //     frameworkDefinedDataKeys?.forEach((keys, i) => {
                //         tempKeyHeaders[i] = {
                //             key: keys,
                //             value: data["frameworkDefinedData"][keys],
                //         };
                //     });
                //     setKeyHeaders(tempKeyHeaders);
                // }
            })
            .catch((err) => {
                if (
                    err?.response?.data?.operation?.toLowerCase() === "info" &&
                    err?.response?.data?.suboperation
                ) {
                    setNoAccessFile(true);
                    return;
                }
                handleToastErrorCatch(err);
            });
    };

    useEffect(() => {
        let title = "Jewel | ALab: " + tc_id;
        document.title = title;
        logPageTitleForGA(title);

        getData();
        connectSocket(
            "execution",
            company.toString().toUpperCase() + "_AUTOMATION/private",
            connectionExecution,
            notifyClient
        );

        return () => {
            connectionExecution?.current?.unsubscribe();
        }
    }, []);


    useEffect(() => {
        if (projectData?.length > 0) {
            let pids = projectData.map((data) => data["pid"]);
            pidArray.current = pids;
        }
    }, [projectData]);

    const notifyClient = (e) => {
        if (e["body"]) {
            let wsBody = JSON.parse(e["body"]);
            if (pidArray.current && pidArray.current.includes(wsBody["pid"])) {
                getData();
            }
        }
    };
    const getValue = (key) => {
        if (!key) {
            return "-";
        }

        switch (key) {
            case "framework":
                return convertTextToFramework(testcaseData[key], "table");
            case "tags":
                return convertTexttoPills(testcaseData[key]);
            default:
                return testcaseData[key];
        }
    }
    const getTestcaseInfo = (value, key, convertFn) => {
        const info =
            <>
                <div className="ms-1 my-1 reqDetails-heading">{value}</div>
                <div className="ms-1 mt-2 fontSizeGeneral">
                    {
                        testcaseData
                            ? <span>{getValue(key)}</span>
                            : <Skeleton width="2rem" className="" />
                    }
                </div>

            </>
        return info;
    }

    const getTestcaseInfoAutomation = (value) => {
    
        const info =
            <>
                <div className="ms-1 my-1 reqDetails-heading">{value}</div>
                <div className="ms-1 mt-2 fontSizeGeneral">
                    {
                        testcaseData
                            ? testcaseData?.automationType?(<span>{testcaseData?.automationType?.map((item, index) => (
                                <span key={index}>{item}{ (index!==testcaseData?.automationType?.length-1) && ", "}</span>
                              ))}</span>):"-"
                            : <Skeleton width="2rem" className="" />
                    }
                </div>

            </>
        return info;
    }


    return !noAccessFile ? (
        <div>
            <div className="container my-3">
                <div className="card propertiesCard p-3">
                    <div className="row my-3 mx-2">
                        <div className="col-md-4 col-12">
                            {getTestcaseInfo("Testcase ID", "isolatedVersionId")}
                        </div>
                        <div className="col-md-4 col-12">
                            {getTestcaseInfo("Testcase Name", "name")}
                        </div>
                        <div className="col-md-4 col-12">
                            {getTestcaseInfo("Framework", "framework")}
                        </div>
                    </div>
                    <div className="row my-3 mx-2">
                        <div className="col-md-4 col-12">
                            {getTestcaseInfo("Category", "category")}
                        </div>
                        <div className="col-md-4 col-12">
                            {getTestcaseInfo("Type", "type")}
                        </div>
                        <div className="col-md-4 col-12">
                            {getTestcaseInfo("Run Flag", "run_flag")}
                        </div>
                    </div>
                    <div className="row my-3 mx-2">
                        <div className="col-md-4 col-12">
                            {getTestcaseInfo("Tags", "tags", convertTexttoPills)}
                        </div>
                        <div className="col-md-4 col-12">
                            {getTestcaseInfoAutomation("Classification")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : <NoAccess />

}

export default TestcaseFile