export const testcaseOptions = [
    "Category",
    "Base Url",
    "Tags",
    "Jira Email",
    "Jira Access Token",
    "Jira Title",
    "Jira Project Id",
    "Jira Workflow",
    "Testcase List",
]