import React, { useState, useEffect } from "react";
import data from "@emoji-mart/data";
import "./index.css";
import { useSelector } from "react-redux";

const Emojis = ({
  removeEmoji,

  parentId,
  currentCommentId,
props,
  isReacted,
  setIsReacted,
  reactionData,
  commentId,
}) => {
  const [hoveredEmoji, setHoveredEmoji] = useState(false);
  const [totalEmoji, setTotalEmoji] = useState(null);
  const [currentEmoji, setCurrentEmoji] = useState(null);

 

  const getEmojiNative = () => {
    let userObj = reactionData?.filter((item) => item.username === props.userDetail.username);

    if (userObj?.length > 0) {
      const emoji = data.emojis[userObj[0]?.reaction];

      return emoji.skins[0].native;
    }
    return null;
  };

  const getTotalEmojiNative = (emojiUsername) => {
    let userObj=reactionData?.filter(item=>item.username===emojiUsername);
    if(userObj?.length>0){
      const emoji = data.emojis[userObj[0]?.reaction];
      return emoji ? emoji.skins[0].native : '';
    }
    return null
  };

  const getTotalEmojiNativeById = (id) => {
   
 
      const emoji = data.emojis[id];
      return emoji ? emoji.skins[0].native : '';
    
  };


  const getCurrentEmoji = () => {
    let userObj = reactionData?.filter((item) => item.username === props.userDetail.username);

    if (userObj?.length > 0) {
      return userObj[0]?.reaction;
    }
    return null;
  };

  const getTopReactions = (reactions) => {
    const reactionCounts = reactions?.reduce((acc, { reaction }) => {
      acc[reaction] = (acc[reaction] || 0) + 1;
      return acc;
    }, {});

    if (
      reactionCounts &&
      !(reactions?.length === 1 && reactions[0]?.username === props.userDetail.username)
    ) {
      const sortedReactions = Object?.entries(reactionCounts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 1)
        .map(([reaction]) => reaction);
      return sortedReactions;
    }

    return null;
  };

  useEffect(() => {
    setTotalEmoji(getTopReactions(reactionData));

    let userObj = reactionData?.filter((item) => item.username === props.userDetail.username);

    if (parentId) {
      if (userObj[0] && currentCommentId.current == commentId) {
        setCurrentEmoji(userObj[0]?.reaction);
        setIsReacted(true);
      }
    } else {
      if (userObj[0]) {
        setCurrentEmoji(userObj[0]?.reaction);
        setIsReacted(true);
      }
    }
  }, []);



  
  return (
    <div
      key={commentId}
      className="mx-2 d-flex position-relative"
      onMouseEnter={() => setHoveredEmoji(true)}
      onMouseLeave={() => setHoveredEmoji(false)}
    >
      {(isReacted ||
        reactionData?.some((item) => item.username === props.userDetail.username)) && (
        <span className="d-flex">
          <p
            onClick={() => removeEmoji(getCurrentEmoji(), commentId)}
            className="pointer"
          >
            {getEmojiNative()}
          </p>
          <p className="mx-2">|</p>
        </span>
      )}
      {totalEmoji?.map((emoji, index) => (
        <p>{getTotalEmojiNativeById(emoji)}</p>
      ))}
      {reactionData?.length > 0? reactionData?.length===1?(
        <>
        { !(reactionData?.some((item) => item.username === props.userDetail.username)) && <p className="mx-2">|</p>}
      </>
      ):(
        <>
          <p>+{reactionData?.length - 1}</p>
          <p className="mx-2">|</p>
        </>
      ):null}
      {hoveredEmoji && reactionData?.length > 0 && (
        <div className="tooltipComment">
          {reactionData?.map((user, index) => (
            <div className="tooltip-item row">
              <p className="col-9">{user.fullname}</p>
              <p className="col-2 me-2">{getTotalEmojiNative(user.username)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Emojis;
