import { Button } from "primereact/button";
import React from "react";
import "./YouMayAlsoLikeCard.css";
import { Link } from "react-router-dom";

const YouMayAlsoLikeCard = ({
  icon,
  heading,
  description,
  buttonLabel,
  buttonLink,
}) => {
  return (
    <div className="you-may-card-wrapper">
      <img
        className="you-may-card-icon"
        src={icon}
        alt={heading}
        loading="lazy"
      />
      <p className="you-may-card-heading">{heading}</p>
      <p className="you-may-card-description">{description}</p>
      {/* <Link to={buttonLink}>
        <Button
          className="base-button secondary-button secondary-button-transition"
          label={buttonLabel}
        />
      </Link> */}
    </div>
  );
};

export default YouMayAlsoLikeCard;
