import { over } from "stompjs";
import socketService from "../../services/socket.service";

/**
 *
 * @param {String} connectionManager Microservice manager to build connection for
 * @param {String} connectionString subscription string
 * @param {Const} connectionRef reference to connection created
 * @param {Function} notifyClientCallbackFn function to define the actions for messages received
 */

export const connectSocket = (
  connectionManager,
  connectionString,
  connectionRef,
  notifyClientCallbackFn
) => {
  let stompClient = null;
  let Sock = socketService.createSocketConnection(connectionManager);
  const stompConfig = {
    heartbeat: {
      outgoing: 0,
      incoming: 0,
    },
  };

  stompClient = over(Sock, stompConfig);
  stompClient.debug = function () {};
  stompClient?.connect(
    { Authorization: `Bearer ${localStorage.getItem("usertoken")}` },
    () =>
      onConnected(
        stompClient,
        connectionString,
        connectionRef,
        notifyClientCallbackFn
      ),
    onError
  );
};

const onConnected = (
  stompClient,
  connectionString,
  connectionRef,
  notifyClientCallbackFn
) => {
  connectionRef.current = stompClient?.subscribe(
    "/user/" + connectionString,
    notifyClientCallbackFn,
    {
      Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
    }
  );
};

const onError = () => {};
