import {
  AVAILABLE_SUITE,
  AVAILABLE_TESTCASE,
  CLEAR_AVAILABLE_SUITE,
  CLEAR_AVAILABLE_TESTCASE,
  CLEAR_EDIT_JOB_DETAILS,
  EDIT_JOB_DETAILS,
  PROJECT_DATA_TESTTOOL,
  CLEAR_PROJECT_DATA_TESTTOOL
} from "../actions/type";

const initialState = {
  available_suite: null,
  available_testcase: null,
  edit_job_details: null,
  project_data_testtool: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case AVAILABLE_SUITE:
      return {
        ...state,
        available_suite: payload,
      };
    case CLEAR_AVAILABLE_SUITE:
      return {
        ...state,
        available_suite: null,
      };
    case AVAILABLE_TESTCASE:
      return {
        ...state,
        available_testcase: payload,
      };
    case CLEAR_AVAILABLE_TESTCASE:
      return {
        ...state,
        available_testcase: null,
      };
    case EDIT_JOB_DETAILS:
      return {
        ...state,
        edit_job_details: payload,
      };
    case CLEAR_EDIT_JOB_DETAILS:
      return {
        ...state,
        edit_job_details: null,
      };
    case PROJECT_DATA_TESTTOOL:
      return {
        ...state,
        project_data_testtool: payload,
      };
    case CLEAR_PROJECT_DATA_TESTTOOL:
      return {
        ...state,
        project_data_testtool: [],
      };
    default:
      return state;
  }
}
