import axios from "axios";
import { MICRO_API_URL_USER } from "../../config/API_URLS";
import authHeader from "../auth-header";

class UserService {
    getUsersForGivenProjectAndCompanyName(pids, companyName, accessType) {
        let accessTypeUrl = accessType ? "&accessType=" + accessType : "";

        return axios
            .get(MICRO_API_URL_USER.baseURL + "userManagement/v1/role/username?pids=" + pids + "&companyName=" + companyName + accessTypeUrl, {
                headers: authHeader(),
            })
            .then((data) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => Promise.reject(err));
    }
}

export default new UserService();