import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  handleToastErrorCatch,
  statementError,
} from "../../../Helper/ToastHelper";
import httpService from "../../../../services/http.service";
import {
  addFormField,
  createHeadersKeyValueInputs,
  getErrorMessage,
  getSuccessMessage,
  mapKeyHeaders,
} from "../../../Helper/Helper";
import { testcaseOptions } from "./ElabData";
import ReportLinkCard from "./ReportLinkCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCirclePlay, faL, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { InputSwitch } from "primereact/inputswitch";
import eLabService from "../../../../services/Elab/eLab.service";

const ExecuteSuite = ({ props }) => {
  const executable_json = useRef(null);
  const [executeTestcaseLength, setExecuteTestcaseLength] = useState(null);
  const [executableTCCount, setExecutableTCCount] = useState(null);
  const [openExecution, setOpenExecution] = useState(false);
  const [LoadExecution, setLoadExecution] = useState(false);
  const [mode, setMode] = useState(null);
  const [env, setEnv] = useState(null);
  const [other, setOther] = useState(false);
  const [otherEnvData, setOtherEnvData] = useState(null);
  const availableMode = useRef(["Sequence", "Optimize"]);
  const [reportLink, setReportLink] = useState(null);
  const [manualLink, setManualLink] = useState(null);
  const [manualCount, setManualCount] = useState(0);
  const [exeDisable, setExeDisable] = useState(true);
  const [executionEnv, setExecutionEnv] = useState([]);
  const [keyHeaders, setKeyHeaders] = useState([{ key: "", value: "" }]);
  const [areAllTestcasesManual, setAreAllTestcasesManual] = useState(true);
  const [executeSuiteButtonLoading, setExecuteSuiteButtonLoading] =
    useState(false);
    const [SaveExecuteSuiteButtonLoading, setSaveExecuteSuiteButtonLoading] =
    useState(false);
  const [s_run_id, setS_run_id] = useState("");
  const [s_id, setS_Id] = useState(null);
  const [p_id, setP_id] = useState(null);
  const [checked, setChecked] = useState(true);

  const getTcPills = (heading, count) => {
    return (
      <>
        <div className="pb-1">{heading}</div>
        <div className="mx-4">
          {count !== null && count >= 0 ? (
            <div className="text-center">
              <span className="pink-btn-health py-1 px-2">{count}</span>
            </div>
          ) : (
            <>
              <div
                className="text-center spinner-border spinner-border-sm loaderBoot"
                role="status"
              >
                <span className="sr-only pt-2">Loading...</span>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const createDropdown = (heading, value, setValue, placeholder, options) => {
    return (
      <div>
        <div className="mb-1 dropdown-heading mt-2">
          {heading}
          <sup> *</sup>
        </div>

        <div className="">
          <Dropdown
            filter
            className="inputHeight w-100"
            value={value}
            name="key"
            options={options}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder={placeholder}
          />
        </div>
      </div>
    );
  };

  const execute = (srunid) => {
    if (other && !otherEnvData) {
      statementError("Other Environment field can't be empty!");
    } else {
      let payload = {
        mode: mode,
        s_run_id: srunid,
        environment: otherEnvData ? otherEnvData : env,
        s_id: s_id,
        allManual: areAllTestcasesManual,
        autoKill: checked,
      };
      keyHeaders.forEach((key) => {
        if (key["key"].length > 0) {
          payload[key["key"]?.toLowerCase().split(" ").join("_")] =
            key["value"];
        }
      });

      setExecuteSuiteButtonLoading(true);
      httpService
        .GemjarlambdaExecution(payload)
        .then((data) => {
          if (data?.operation?.toLowerCase() !== "failure") {
            httpService.getManualTestcaseCount(s_id).then((data) => {
              setManualCount(data?.data);
              if (data?.data) {
                let manualtempLink =
                  window.location.origin +
                  "/#/testpad/manual-testcase-execution?s_run_id=" +
                  srunid +
                  "&s_id=" +
                  s_id +
                  "&p_id=" +
                  p_id;
                setManualLink(manualtempLink);
              }
            });
            let link =
              window.location.origin +
              "/#/autolytics/execution-report?s_run_id=" +
              srunid +
              "&p_id=" +
              p_id;
            setReportLink(link);
            setExecuteSuiteButtonLoading(false);
          } else {
            getErrorMessage(data);
          }
        })
        .catch((err) => {
          handleToastErrorCatch(err, () => setExecuteSuiteButtonLoading(false));
        });
    }
  };

  const saveAndExecute = (srunid) => {
    if (other && !otherEnvData) {
      statementError("Other Environment field can't be empty!");
    } else {
      let payload = {
        mode: mode,
       
        environment: otherEnvData ? otherEnvData : env,
       
        
      };

      let Expayload = {
        mode: mode,
        s_run_id: srunid,
        environment: otherEnvData ? otherEnvData : env,
        s_id: s_id,
        allManual: areAllTestcasesManual,
        autoKill: checked,
      };

      let savePayload={
        s_id:s_id,
        configuration:payload
      }
      keyHeaders.forEach((key) => {
        if (key["key"].length > 0) {
          payload[key["key"]?.toLowerCase().split(" ").join("_")] =
            key["value"];
        }
      });

      setSaveExecuteSuiteButtonLoading(true);

      eLabService
      .setSuiteConfig(savePayload)
      .then((data)=>{
        getSuccessMessage(data)
      })
      .catch((err)=>{
        handleToastErrorCatch(err);
      })
      httpService
        .GemjarlambdaExecution(Expayload)
        .then((data) => {
          if (data?.operation?.toLowerCase() !== "failure") {
            httpService.getManualTestcaseCount(s_id).then((data) => {
              setManualCount(data?.data);
              if (data?.data) {
                let manualtempLink =
                  window.location.origin +
                  "/#/testpad/manual-testcase-execution?s_run_id=" +
                  srunid +
                  "&s_id=" +
                  s_id +
                  "&p_id=" +
                  p_id;
                setManualLink(manualtempLink);
              }
            });
            let link =
              window.location.origin +
              "/#/autolytics/execution-report?s_run_id=" +
              srunid +
              "&p_id=" +
              p_id;
            setReportLink(link);
            setSaveExecuteSuiteButtonLoading(false);
          } else {
            getErrorMessage(data);
          }
        })
        .catch((err) => {
          handleToastErrorCatch(err, () => setSaveExecuteSuiteButtonLoading(false));
        });
    }
  };

  const startexecution = (method) => {
  
    if (manualLink) {
      setManualLink(null);
      setManualCount(0);
    }
    if (reportLink) {
      setReportLink(null);
    }
   
    let srunid = otherEnvData
      ? executable_json?.current?.["Project Name"] +
      "_" +
      otherEnvData +
      "_" +
      uuidv4()
      : executable_json?.current?.["Project Name"] + "_" + env + "_" + uuidv4();
    setS_run_id(srunid);
   

    if (areAllTestcasesManual) {
      if (env && method==="save") {
        saveAndExecute(srunid);
      }
      else if(env && method==="notSave"){
        execute(srunid);
      }
      else {
        statementError("Environment Cannot be Empty");
      }
    }
     else {
      if(mode && env && method==="save"){
        saveAndExecute(srunid)
      }else if(mode && env && method==="notSave") {
     
        execute(srunid);
      } else {
        statementError("Mode and Environment Cannot be Empty");
      }
    }
  };

  const createOptionalData = () => {
    const returnData = (
      <>
        {createHeadersKeyValueInputs(
          keyHeaders,
          setKeyHeaders,
          testcaseOptions
        )}
        <button
          className="btn themeBtn mt-3"
          onClick={(e) => addFormField("headers", keyHeaders, setKeyHeaders)}
        >
          Add Data
        </button>
      </>
    );
    return returnData;
  };

  

  const executeSuite = () => {
    setOpenExecution(false);
    setLoadExecution(true);
    setKeyHeaders([{key:"",value:""}])
    setEnv(null);
    setMode(null)
    executable_json.current = null;
    //change them to ref
    setP_id(props?.p_id);
    setS_Id(props?.val);
    setExecuteTestcaseLength(null);
    setExecutableTCCount(null);

    const check = props?.dataMain?.data
      ? props.dataMain.data.filter((rowDataIndex) =>
        rowDataIndex?.["Suite ID"]?.value === props?.val ? rowDataIndex : null
      )
      : null;

    executable_json.current = {
      id: props?.val,
      name: check[0]?.["Suite Name"]?.value,
      config:props?.rowData?.Configuration
    };

    if(executable_json?.current?.config){
      setEnv(executable_json?.current?.config?.environment);
    setMode(executable_json?.current?.config?.mode);
    
    let newKeyHeaders=mapKeyHeaders(executable_json?.current?.config);
    setKeyHeaders(newKeyHeaders);
    
    }
  

    executable_json.current["Project Name"] =
      check?.[0]?.["Project Name"]?.value;
    executable_json.current["report_name"] = check?.[0]?.["Suite Name"]?.value;

   

    httpService
      .getAvailableSuiteTestCases(props?.val)
      .then((data) => {
        setAreAllTestcasesManual(data?.data?.allManual);
        getErrorMessage(data);
        setExecutionEnv(data?.data?.env);
        let count = data?.data?.data?.length;
        data?.data?.data?.forEach((ele) => {
          if (ele?.["RUN FLAG"]?.value === "N") {
            count--;
          }
        });
        setExecuteTestcaseLength(data?.data?.data?.length);
        setExecutableTCCount(count);
        httpService.getBridgeToken().then(async (data) => {
          executable_json.current["bridge_token"] = await data?.data
            ?.bridgeToken;
        });
        if (count > 0) {
          setExeDisable(false);
        } else {
          setExeDisable(true);
          statementError(
            "No Executable Testcase(s) Found. Create/Add or Review Existing Testcase(s) to execute Suite."
          );
        }
        setOpenExecution(true);
        setLoadExecution(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
        setLoadExecution(false);
      });
    
  };

  return (
    <>
      <div>
        {props?.rowData?.Executable?.value ? (
          <FontAwesomeIcon
            className={LoadExecution?"mx-2 tabIcon actionBtn fa-spin":"mx-2 tabIcon actionBtn"}
            id={props?.s_id}
            icon={LoadExecution?faSpinner:faCirclePlay}
            onClick={(e) => executeSuite()}
          />
        ) : (
          <FontAwesomeIcon
            className="mx-2 tabIcon disabledIcon"
            title="Jar Creation in Progress"
            icon={faCirclePlay}
          />
        )}
      </div>
      <Dialog
        blockScroll={true}
        draggable={false}
        header={() => (
          <>
            <div className="dialogExecuteSuite">
              <div>
                Execute Suite :{" "}
                {executable_json.current &&
                  executable_json.current.id +
                  "-" +
                  executable_json.current.name}
                <div></div>
                <div className="d-flex mt-2">
                  <div className="pe-3">
                    {getTcPills("Total Testcase(s)", executeTestcaseLength)}
                  </div>
                  <div className="ps-3">
                    {getTcPills("Active Testcase(s)", executableTCCount)}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        visible={openExecution}
        maximizable
        className="createTestcaseDialog"
        onHide={() => {
          setOpenExecution(false);
          setReportLink(null);
          setManualCount(null);
          setManualLink(null);
          setExeDisable(true);
          setMode(null);
          setEnv(null);
          setExecutionEnv([]);
          setKeyHeaders([{ key: "", value: "" }]);
          setExecuteSuiteButtonLoading(false)
          setAreAllTestcasesManual(true); // update default state -> to hide the mode input on opening of dialog
        }}
        footer={() => (
          <>
            <Button
              label="Execute"
              disabled={exeDisable}
              onClick={() => {
                startexecution("notSave");
              }}
              className="btn-success mt-3 py-2  btn themeBtn"
              loadingIcon={"pi pi-spin pi-spinner me-2"}
              loading={executeSuiteButtonLoading}
              iconPos="right"
            />
            <Button
              label="Save and Execute"
              disabled={exeDisable}
              onClick={() => {
                startexecution("save");
              }}
              className="btn-success mt-3 py-2  btn themeBtn"
              loadingIcon={"pi pi-spin pi-spinner me-2"}
              loading={SaveExecuteSuiteButtonLoading}
              iconPos="right"
            />
          </>
        )}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "60vw", minHeight: "93vh" }}
      >
        <div className="row">
          <div className="col-md-4">
            {createDropdown(
              "Environment",
              env,
              setEnv,
              "Select Environment",
              executionEnv
            )}
          </div>
          {!areAllTestcasesManual && (
            <div className="col-md-4">
              {createDropdown(
                "Mode",
                mode,
                setMode,
                "Select Mode",
                availableMode.current
              )}
            </div>
          )}
          <div className="col-md-4 d-flex flex-column">
            <div className="mb-1 mt-2">
              <span className="dropdown-heading">AutoKill Execution</span>
            </div>
            <div className="flex-grow-1 d-flex align-items-center">
              <div className="input-switch-execution">
                <InputSwitch
                  checked={checked}
                  onChange={(e) => setChecked(e.value)}
                  style={{ transform: "scale(0.9)" }}
                />
              </div>
            </div>
          </div>
          {other && (
            <div className="col d-flex justify-content-center">
              <div>
                <div className="mb-1 dropdown-heading mt-2">
                  Other Environment<sup> *</sup>
                </div>

                <div className="">
                  <InputText
                    className="inputHeight"
                    type="text"
                    value={otherEnvData ? otherEnvData : ""}
                    placeholder={"Other"}
                    onChange={(e) => setOtherEnvData(e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-2 pDetails-smheading me-5">
          Note: When Autokill is active, up to three identical suites can run
          simultaneously for same user, project, suite, and environment. Before
          starting any new execution with the same combination, the previous
          ones will be aborted and marked as "ERR."
        </div>
        {!checked && (
          <div className="text-danger fw-bold mt-1">
            {" "}
            Disabling autokill will imply maximum 3 executions with same user,
            project, and environment combinations.
            <br /> <br />
          </div>
        )}
        <>
          <div className="overHeadAnalytics mt-4">Other Keys</div>
          <div className="container  mt-3">{createOptionalData()}</div>
        </>
        <div className="row mt-2">
          {manualLink && manualCount && (
            <>
              <div className="col ">
                <ReportLinkCard
                  key="manual"
                  link={manualLink}
                  header={manualCount + " Manual Testcase(s) Found"}
                  footer="Execute Manual(s)"
                />
              </div>
            </>
          )}
          {reportLink && (
            <>
              <div className="col">
                <ReportLinkCard
                  key="reportLink"
                  link={reportLink}
                  header="Report Link"
                  footer="View Report"
                />
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default ExecuteSuite;
