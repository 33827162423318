import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SeeMoreText from '../../../UI/SeeMoreText/SeeMoreText';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';

const FolderAction = (val, rowData) => {

    const icon = rowData?.Folder?.subType?.toLowerCase() === "folder" ? faFolderOpen : faFile;
    return (
        <div
            className="boldText ms-2 d-flex align-items-center"
        >
            <FontAwesomeIcon icon={icon} className="reqIcons me-2" />{" "}
            <span>
                <SeeMoreText
                    text={val}
                    maxLimit={20}
                />
            </span>
        </div>
    )
}

export default FolderAction