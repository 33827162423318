import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useState } from "react";
import "./index.css";
import commentsService from "../../../../services/Comments/comments.service";
import { handleToastErrorCatch } from "../../../Helper/ToastHelper";

const Reactions = ({
  id,
  fullname,
  setIsReacted,
  setCurrentEmojiId,
  currentCommentId,
  parentId,
  setShowActions,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [emojiIds, setEmojiIds] = useState(["+1", "joy", "-1"]);

  const addEmoji = (emoji) => {
    let payload = {
      commentId: id,
      fullName: fullname,
      reaction: emoji?.id,
    };

    commentsService
      .reactComment(payload)
      .then((data) => {
        setCurrentEmojiId(emoji?.id);
        setShowPicker(false);
        setShowActions(true);
        if (parentId) {
          currentCommentId.current = id;
        }

        setIsReacted(true);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setShowActions(true);
          setShowPicker(false);
        });
      });
  };

  const getEmojiNative = (id) => {
    const emoji = data.emojis[id];
    return emoji ? emoji.skins[0].native : "";
  };

  return (
    <div>
      <div className="emojis">
        {!showPicker &&
          emojiIds.map((id, index) => (
            <span
              key={index}
              className="emoji"
              onClick={() => addEmoji({ id: id })}
            >
              {getEmojiNative(id)}
            </span>
          ))}
        {!showPicker && (
          <span
            className="emoji-picker-icon"
            onClick={() => {
              setShowActions(false);
              setShowPicker(!showPicker);
            }}
          >
            ➕
          </span>
        )}
      </div>
      {showPicker && (
        <div
          className="emoji-picker"
          onMouseLeave={() => {
            setShowActions(true);
            setShowPicker(false);
          }}
        >
          <Picker
            className="emojiPickTable"
            data={data}
            previewPosition="none"
            onEmojiSelect={addEmoji}
          />
        </div>
      )}
    </div>
  );
};

export default Reactions;
