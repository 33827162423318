import React from "react";
import "./SpecializedModules.css";
// import { specializedModulesCard } from "../../../Helper/MockData/SpecializedModulesMockData";
import SpecializedModulesCard from "./SpecializedModulesCard/SpecializedModulesCard";
import { SpecializedModulesCardData } from "../../../Helper/MockData/TestpadPageMockData";

const SpecializedModules = () => {
  return (
    <div className="component-padding max-w-1440">
      <p className="component-heading ">Specialized modules</p>
      <div className="specailized-modules-cards-wrapper">
        {SpecializedModulesCardData.map((item) => {
          return (
            <SpecializedModulesCard
              key={item.heading}
              icon={item.icon}
              heading={item.heading}
              description={item.description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SpecializedModules;
