import "./BridgeToken.css";
import httpService from "../../../services/http.service";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { convertEpochtoDate, copyToClipboard, createColumns, createFilters, logPageTitleForGA } from "../../Helper/Helper";
import { DataTable } from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { Button } from "primereact/button";
import NoData from "../../UI/NoData/NoData";

const BridgeToken = (props) => {
  const [token, setToken] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [createdOn, setCreatedOn] = useState("");
  const [tokenData, setTokenData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [tokenTableLoading, setTokenTableLoading] = useState(false);
  const [filters,setFilters]=useState({});
  const tableref=useRef();

  useEffect(() => {
    

    setTokenTableLoading(true);
    httpService.getBridgeToken().then((data) => {
      setToken(data.data.bridgeToken);
      const setCreated = data.data["created on"]
        ? setCreatedOn(convertEpochtoDate(data.data["created on"], "datetime"))
        : null;
    }, []);

    httpService.getLastToken(5).then((data) => {
      setTokenTableLoading(false);
      
      setTokenData(data.data);
      tableref.current=undefined
      setRowData(data.data.rowData);
      setTableHeaders(data.data.headers);
    });
  }, []);

  useEffect(()=>{
    const filterData =
      tokenData &&
      tokenData?.headers
        ? createFilters(tokenData.headers)
        : {};
        setFilters(filterData);
  },[tokenData])

  const skeletonBridgeRows = Array.from({ length: 5 });
  const skeletonBridgeTemplate = () => {
    return <Skeleton></Skeleton>;
  };

  const createHeaders = () => {
    if (!tableHeaders.includes("S.No")) {
      tableHeaders.unshift("S.No");
    }

    const headers = tableHeaders.map((header) => (
      <th className="fontSizeGeneral">{header}</th>
    ));
    return headers;
  };
  const createRows = () => {
    if (rowData.length > 0) {
      rowData.forEach((data, i) => {
        data["S.No"] = {
          type: "text",
          value: i + 1, //i is index
        };
      });

      const rows = rowData.map((data) => (
        <tr>
          {tableHeaders.map((header) => (
            <td className="fontSizeGeneral">
              {data[header]
                ? data[header]["type"] === "text"
                  ? data[header]["value"]
                  : data[header]["type"] === "date"
                    ? convertEpochtoDate(
                      data[header]["value"],
                      data[header]["subType"]
                    )
                    : null
                : null}
            </td>
          ))}
        </tr>
      ));
      return rows;
    } else {
      return (
        <td colSpan={tableHeaders.length}>
          <div className="py-5">
            <h3>No Expired Token(s) Found</h3>
          </div>
        </td>
      );
    }
  };

  const changeToken = () => {
    setIsButtonLoading(true);
    httpService.updateToken().then((data) => {
      setToken(data.data.bridgeToken);
      setCreatedOn(convertEpochtoDate(data.data["created on"], "datetime"));

      httpService.getLastToken(5).then((tokenData) => {
        setTokenData(tokenData.data);
        setRowData(tokenData.data.rowData);
        setTableHeaders(tokenData.data.headers);
      });

      toast.success("Bridge Token Changed Successfully.", {
        position: "top-right",

        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsButtonLoading(false);
    })
      .catch((err) => {
        handleToastErrorCatch(err, () => setIsButtonLoading(false))
      })
  };



  return (
    <>
      <div className="main-data">
        <div className="pt-5 text-center">
          <div className="row d-flex justify-content-center">
            <div
              value={token}
              className="tokenDiv col-2 d-flex justify-content-center align-items-center"
            >
              <strong> {token.slice(0, 6).toUpperCase() + "XXXXXXXXXX"}</strong>
            </div>
            <div
              onClick={() => copyToClipboard(token)}
              className="copyIcon col-2 d-flex justify-content-center align-items-center"
            >
              <FontAwesomeIcon icon={faCopy} />
            </div>
          </div>
          {createdOn ? (
            <div className="createdOn mt-3">Created on {createdOn}</div>
          ) : null}
          <Button
            label="Change Token"
            className="btn-success btn themeBtn text-center mx-2 p-2 mt-4"
            onClick={(e) => changeToken()}
            icon={
              isButtonLoading
                ? "pi pi-spin pi-spinner me-2"
                : null
            }
            iconPos="right"
            loading={isButtonLoading}
          />
          {/* <div
            onClick={() => changeToken()}
            className="changeToken mt-4 btn btn-success"
          >
            Change Token
          </div> */}
        </div>
        <div className="container-fluid mt-4">
          {tokenTableLoading ? (
            <DataTable
              responsiveLayout="scroll"
              value={skeletonBridgeRows}
              className="p-datatable-striped mt-3"
            >
              <Column
                header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                style={{ width: "5%" }}
                body={skeletonBridgeTemplate}
              ></Column>
              <Column
                header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                style={{ width: "30%" }}
                body={skeletonBridgeTemplate}
              ></Column>
              <Column
                header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                style={{ width: "15%" }}
                body={skeletonBridgeTemplate}
              ></Column>
              <Column
                header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                style={{ width: "25%" }}
                body={skeletonBridgeTemplate}
              ></Column>
              <Column
                header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
                style={{ width: "25%" }}
                body={skeletonBridgeTemplate}
              ></Column>
            </DataTable>
          ) : (
            <>
              {/* <div className="table-responsive mt-3">
                <table className="table table-borderless tokenTable">
                  <thead className="headerTable text-center">
                    <tr>{createHeaders()}</tr>
                  </thead>
                  <tbody className="tableBody text-center">
                    {createRows()}
                  </tbody>
                </table>
              </div> */}
              <DataTable
                  reorderableColumns
                  resizableColumns
                  header={
                    <>
                      <div className="headingFont fs-6">
                        Last 5 Expired Tokens
                      </div>
                    </>
                  }
                  ref={tableref}
                  scrollHeight={props.shared ? "67vh" : "60vh"}
                  cellClassName="dataTable"
                  // rowClassName={"dataTable-row dataTable"}
                  currentPageReportTemplate="Total {totalRecords} Records Found"
                  paginatorTemplate=" CurrentPageReport"
                  // rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                  columnResizeMode="expand"
                  removableSort
                  value={tokenData?.rowData}
                  paginator
                  rows={5}
                  // dataKey="id"
                  filterDisplay="menu"
                  loading={tokenData?.rowData ? false : true}
                  responsiveLayout="scroll"
                  emptyMessage={<NoData />}
                  filters={filters}
                  onFilter={(e) => {
                    setFilters(e.filters);
                  }} // showGridlines
                  className="test-stepTable my-4"
                  >
                    
                {tokenData?.rowData &&
                  tokenData?.headers ? (
                    createColumns(
                      tokenData?.headers,
                      true,
                      true,
                      tokenData?.rowData,
                      true,
                      null,
                    )
                  ) : (
                    <></>
                  )}
                </DataTable>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default BridgeToken;
