import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertTexttoStatus } from "../../Helper/Helper";
const MarkStatus = ({ props }) => {
  const statusVal = useSelector((state) => state?.ManualExecution?.markDetails);
  const username=useSelector((state)=>state?.auth?.userProfile?.username);
  const [status, setStatus] = useState(props?.val);

  useEffect(() => {
    if (statusVal?.markedTcSteps?.includes(props?.rowData?.Id?.value - 1)) {
      statusChange(statusVal?.markedStatus);
    }
  }, [statusVal]);

 

  const statusChange = (value) => {
    setStatus(value);
    let arr = [...props.tc_data];
    arr[props.rowData?.Id?.value - 1]["status"] = value;

    props.setTc_Data(arr);
  };
  const statusTemplate = (status) => {
    return <div className="text-center"> {convertTexttoStatus(status)} </div>;
  };
  const selectedStatusTemplate = (status) => {
    if (status) {
      return <div className="text-center"> {convertTexttoStatus(status)} </div>;
    }
    return "Select Status";
  };
  return (
    <>
      <Dropdown
        filter
        className="h-50 w-100"
        value={status}
        name="key"
        disabled={props?.assignedTo!==username}
        options={["PASS", "FAIL", "INFO"]}
        onChange={(e) => statusChange(e.value)}
        placeholder="Select Status"
        valueTemplate={selectedStatusTemplate}
        itemTemplate={statusTemplate}
        // editable
      />
    </>
  );
};

export default MarkStatus;
