import { useState, useEffect, useRef } from "react";
import {
  createColumns,
  createFilters,
  getErrorMessage,
} from "../../Helper/Helper";
import { DataTable } from "primereact/datatable";
import "./ManualTCFile.css";
import httpService from "../../../services/http.service";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { over } from "stompjs";
import { connectSocket } from "../../Helper/SocketHelper";
import NoData from "../../UI/NoData/NoData";

const TestStepsManual = (props) => {
  const [testStepsData, setTestStepsData] = useState(null);
  const mtc_id = useRef(props.id);
  const [filters, setFilters] = useState({});
  const company = useSelector((state) => state.auth.userProfile.socket);
  const firstUpdate = useRef(false);
  const pidArray = useRef(null);
  const projectData = useSelector(
    (state) => state.manualTC.project_data_manualtc
  );
  const role = useSelector((state) => state.auth.userProfile.role);
  let stompClient = null;
  const tcConnection = useRef(null);

  useEffect(() => {
    getSteps();
    return () => {
      tcConnection?.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (projectData.length > 0) {
      let pids = projectData.map((data) => data["pid"]);
      pidArray.current = pids;
    }
  }, [projectData]);

  const notifyTcClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (
        pidArray.current &&
        pidArray.current.includes(wsBody["pid"]) &&
        wsBody["message"]?.toLowerCase() === "draft saved"
      ) {
        getSteps();
      }
    }
  };

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      const filterData =
        testStepsData && testStepsData["headers"]
          ? createFilters(testStepsData["headers"])
          : {};

      setFilters(filterData);
    }
  }, [testStepsData]);

  const skeletonBridgeRows = Array.from({ length: 5 });
  const skeletonBridgeTemplate = () => {
    return <Skeleton></Skeleton>;
  };
  const tableSkeleton = () => {
    return (
      <DataTable
        responsiveLayout="scroll"
        value={skeletonBridgeRows}
        header={
          <>
            <div className="headingFont">
              <Skeleton width="15rem" className="my-3 ms-2"></Skeleton>
            </div>
          </>
        }
        className="p-datatable-striped mt-3 test-stepTable"
      >
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "20%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "15%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "13%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "13%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "20%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "18%" }}
          body={skeletonBridgeTemplate}
        ></Column>
      </DataTable>
    );
  };

  const getSteps = () => {
    httpService
      .getManualTestSteps(mtc_id.current)
      .then(async (data) => {
        getErrorMessage(data);
        setTestStepsData(data["data"]);
        if (!firstUpdate.current) {
          firstUpdate.current = true;
          connectSocket(
            "execution",
            company.toString().toUpperCase() + "_MANUAL/private",
            tcConnection,
            notifyTcClient
          );
        }
      })
      .catch((err) => {
        if (
          err["response"]["data"]["operation"].toLowerCase() === "info" &&
          err["response"]["data"]["suboperation"]
        ) {
          props.setNoAccess(true);
          tcConnection?.current?.unsubscribe();
          return;
        }
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
    Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  const stepScreenshot = (val) => {
    return (
      <div className={`${val.props.val ? "pass-color" : "fail-color"}`}>
        {val.props.val ? "Required" : "Not Required"}
      </div>
    );
  };

  const linkTCName = (val) => {
    return (
      <div>
        {val ? (
          <div>
            <FontAwesomeIcon
              icon={faLink}
              className="pass-color"
              size="lg"
              title={"Linked Testcase"}
            />
            <span className="ms-1">{val}</span>
          </div>
        ) : (
          "-"
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="mt-3">
        {testStepsData ? (
          <DataTable
            reorderableColumns
            header={
              <>
                <div className="d-flex align-items-center">
                  <>
                    <div className="">Test Step</div>
                    <div className="d-flex ms-auto">
                      {role.toString().toUpperCase() !== "SUPER_ADMIN" && (
                        <button
                          className="btn-success btn themeBtn p-2"
                          onClick={(e) => {
                            props.setShowDraftTab(true);
                            props.setIsShowDraftTabOpen(true);
                          }}
                        >
                          Edit/Add Step(s)
                        </button>
                      )}
                    </div>
                  </>
                </div>
              </>
            }
            onFilter={(e) => {
              setFilters(e.filters);
            }}
            resizableColumns
            stripedRows
            columnResizeMode="expand"
            value={testStepsData ? testStepsData["data"] : null}
            paginator
            rows={25}
            dataKey="id"
            filters={filters}
            rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            scrollHeight={"55vh"}
            filterDisplay="menu"
            responsiveLayout="scroll"
            emptyMessage={<NoData />}
            currentPageReportTemplate="Total {totalRecords} Step(s) Found"
            removableSort
            className="test-stepTable"
          >
            {createColumns(
              testStepsData["headers"],
              true,
              true,
              testStepsData["data"],
              false,
              null,
              { customAction: linkTCName },
              {
                stepScreenshot: true,
              },
              {
                stepScreenshot: stepScreenshot,
              }
            )}
          </DataTable>
        ) : (
          tableSkeleton()
        )}
      </div>
    </div>
  );
};

export default TestStepsManual;
