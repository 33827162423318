import React, { useRef, useState, useEffect } from "react";
import "./newTabRevamp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faFileInvoice,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import Reporting from "../Autolytics/Reporting";
import Extent from "../Autolytics/Extent";
import SecurityMetricsReport from "../Autolytics/SecurityMetricsReport";
import TestStepRevamp from "../Autolytics/TestStepRevamp";
import { useLocation, useNavigate } from "react-router-dom";

const Tab = ({ tab, closeTabFn, selectTabFn, isActive, onDoubleClick }) => {
  return (
    <div
      className={`newTab ${isActive ? "active" : ""}`}
      id={"flex-tab" + tab.id}
      onClick={() => selectTabFn(tab.id)}
      onDoubleClick={() => onDoubleClick(tab.id)}
    >
      <div id={"flex-icon" + tab.id} className="d-flex align-items-center">
        <FontAwesomeIcon
          className={`newTabIcon ${isActive ? "activeCol" : ""}`}
          icon={faFileInvoice}
        />
      </div>
      <Tooltip className="newTabTooltip" position="bottom" target=".content" />
      <div
        id={"flex-cross" + tab.id}
        className="crossDiv btn-sm ms-auto d-flex align-items-center"
      >
        <div className={`newTabText ${isActive ? "activeCol" : ""}`}>
          {tab.name}
        </div>
        <FontAwesomeIcon
          id={"icon-flex" + tab.id}
          onClick={(e) => {
            e.stopPropagation();
            closeTabFn(tab.id);
          }}
          className={`cross ${isActive ? "activeCol" : ""}`}
          icon={faXmark}
        />
      </div>
    </div>
  );
};

const NewTabRevamp = (props) => {
  const [allTabs, setAllTabs] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState(null);
  const [nextTabId, setNextTabId] = useState(2);
  const allTabsRef = useRef(allTabs);
  const previousLengthRef = useRef(allTabs?.length);
  const tabsWrapperRef = useRef(null);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [currentTabId, setCurrentTabId] = useState(null);
  const [tabName, setTabName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const createNewTab = (type, run_id, p_id) => {
    setNextTabId((prevId) => {
      const newTab = { id: prevId };
      
      if (type === "execution_report") {
        newTab.name = "Execution Report";
        newTab.content = (
          <Extent
            showIcon
            id={newTab.id}
            s_run_id={run_id}
            p_id={p_id}
            role={props.role}
            userDetail={props.userDetail}
          />
        );
      } else if (type === "teststep_report") {
        newTab.name = "Teststep Report";
        newTab.content = (
          <TestStepRevamp tc_run_id={run_id} newTab={true} p_id={p_id} />
        );
      } else if (type === "security_report") {
        newTab.name = "Security Report";
        newTab.content = (
          <SecurityMetricsReport
            showIcon
            s_run_id={run_id}
            p_id={p_id}
            id={newTab.id}
            role={props.role}
          />
        );
      } else {
        console.error("Unknown tab type:", type);
        return prevId;  // Return the previous ID without incrementing
      }
  
      setAllTabs((prevTabs) => [...prevTabs, newTab]);
      setCurrentActiveTab(newTab.id);
  
      return prevId + 1; // Increment nextTabId and return the new value
    });
  };

  const createNewReport = () => {
    const newTab = {
      id: nextTabId,
      name: "New Report",
      content: (
        <Reporting
          id={nextTabId}
          key={nextTabId}
          name="New Report"
          shared={false}
          onAction={{ createNewTab }}
          editName={updateTabEditName}
        />
      ),
    };

    setAllTabs((prevTabs) => [...prevTabs, newTab]);
    setCurrentActiveTab(newTab.id);
    setNextTabId((prevId) => prevId + 1);
  };


  useEffect(() => {
    // Initial tab setup
    if (allTabs?.length === 0) {
      const initialTab = {
        id: 1,
        name: `${props.shared ? "Shared Report" : "New Report"}`,
        content: (
          <Reporting
            id={1}
            key={1}
            name={`${props.shared ? "Shared Report" : "New Report"}`}
            shared={props.shared}
            onAction={{ createNewTab }}
            editName={updateTabEditName}
          />
        ),
      };
      setAllTabs([initialTab]);
      setCurrentActiveTab(initialTab.id);
    }
  }, []);

  useEffect(() => {
    // Add a new tab when location.state changes
    if (location.state?.payload) {
      const newTab = {
        id: nextTabId,
        name: location.state?.reportName,
        content: (
          <Reporting
            id={nextTabId}
            name={location.state?.reportName}
            key={nextTabId}
            payload={location.state?.payload}
            projectsDetails={location.state?.selectedProjectsDetails}
            shared={props.shared}
            onAction={{ createNewTab }}
            editName={updateTabEditName}
          />
        ),
      };

      setAllTabs((prevTabs) => [...prevTabs, newTab]);
      setCurrentActiveTab(newTab.id);
      setNextTabId((prevId) => prevId + 1);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state]);

  const closeTabFn = (id) => {
    if (allTabs?.length > 1) {
      const newTabs = allTabs.filter((tab) => tab.id !== id);
      setAllTabs(newTabs);

      if (newTabs.length === 0) {
        setCurrentActiveTab(null);
        setNextTabId(1); // Reset nextTabId to 1 when all tabs are closed
      } else {
        if (id === currentActiveTab) {
          const currentIndex = allTabs.findIndex((tab) => tab.id === id);
          const newActiveIndex = currentIndex > 0 ? currentIndex - 1 : 0;
          setCurrentActiveTab(newTabs[newActiveIndex]?.id || newTabs[0]?.id);
        }
      }
    }
  };

  const selectTabFn = (id) => {
    setCurrentActiveTab(id);
  };

  const handleDoubleClick = (id) => {
    const tab = allTabs.find((tab) => tab.id === id);
    // setTabName(tab.name);
    setCurrentTabId(id);
    setIsDialogVisible(true);
  };

  const updateTabName = () => {
    setAllTabs((prevTabs) =>
      prevTabs.map((tab) =>
        tab.id === currentTabId ? { ...tab, name: tabName } : tab
      )
    );
    setIsDialogVisible(false);
    setTabName("")
  };

  const updateTabEditName = (name,id) => {
    setAllTabs((prevTabs) =>
      prevTabs.map((tab) =>
        tab.id === id ? { ...tab, name: name } : tab
      )
    );
  };

  const scrollLeft = () => {
    if (tabsWrapperRef.current) {
      const currentIndex = allTabs.findIndex(
        (tab) => tab.id === currentActiveTab
      );
      const newActiveTab = Math.max(0, currentIndex - 1);
      setCurrentActiveTab(allTabs[newActiveTab].id);
      document
        .getElementById("flex-tab" + allTabs[newActiveTab].id)
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (tabsWrapperRef.current) {
      const currentIndex = allTabs.findIndex(
        (tab) => tab.id === currentActiveTab
      );
      const newActiveTab = Math.min(allTabs.length - 1, currentIndex + 1);
      setCurrentActiveTab(allTabs[newActiveTab].id);
      document
        .getElementById("flex-tab" + allTabs[newActiveTab].id)
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToStart = () => {
    if (tabsWrapperRef.current) {
      setCurrentActiveTab(allTabs[0]?.id);
      tabsWrapperRef.current.scrollLeft = 0;
    }
  };

  const scrollToEnd = () => {
    if (tabsWrapperRef.current) {
      setCurrentActiveTab(allTabs[allTabs.length - 1]?.id);
      tabsWrapperRef.current.scrollLeft = tabsWrapperRef.current.scrollWidth;
    }
  };

  useEffect(() => {
    allTabsRef.current = allTabs;
    if (allTabs.length !== previousLengthRef.current) {
      scrollToEnd();
      previousLengthRef.current = allTabs.length;
    }
   
  }, [allTabs]);

  return (
    <div>
      <div className="newTabs-container mt-3">
        <button
          className="btn btn-success themeBtn sliderBtn btn-left"
          id="newSlideLeftLeft"
          type="button"
          onClick={scrollToStart}
        >
          <div className="d-flex">
            <FontAwesomeIcon icon={faChevronLeft} />
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </button>
        <button
          className="btn btn-success themeBtn sliderBtn btn-left"
          id="newSlideLeft"
          type="button"
          onClick={scrollLeft}
        >
          <div className="d-flex">
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </button>
        <div className="newTabs-wrapper" ref={tabsWrapperRef}>
          {allTabs.map((tab) => (
            <Tab
              key={tab.id}
              tab={tab}
              closeTabFn={closeTabFn}
              selectTabFn={selectTabFn}
              isActive={tab.id === currentActiveTab}
              onDoubleClick={handleDoubleClick}
            />
          ))}
        </div>
        <div className="btn newTabAdd d-flex align-items-center">
          <i
            className="pi pi-plus-circle pointer"
            onClick={createNewReport}
          ></i>
        </div>
        <button
          className="btn btn-success themeBtn sliderBtn btn-right"
          id="newSlideRight"
          type="button"
          onClick={scrollRight}
        >
          <div className="d-flex">
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </button>
        <button
          className="btn btn-success themeBtn sliderBtn btn-right"
          id="newSlideRightRight"
          type="button"
          onClick={scrollToEnd}
        >
          <div className="d-flex">
            <FontAwesomeIcon icon={faChevronRight} />
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </button>
      </div>
      <div className="tabContent">
        {allTabs.map((tab) => (
          <div
            key={tab.id}
            style={{
              display: tab.id === currentActiveTab ? "block" : "none",
            }}
          >
            {tab.content}
          </div>
        ))}
      </div>

      <Dialog
        header="Rename Tab"
        visible={isDialogVisible}
        style={{ width: "20vw" }}
        onHide={() => setIsDialogVisible(false)}
        footer={
          <div>
            <button onClick={updateTabName} className="btn btn-primary">
              Save
            </button>
          </div>
        }
      >
        <InputText
          value={tabName}
          onChange={(e) => setTabName(e.target.value)}
          placeholder="Enter new tab name"
          className="w-100"
        />
      </Dialog>
    </div>
  );
};

export default NewTabRevamp;
