import ALab from "../../Layouts/TestTool/ALab/ALab";
import TestTool from "../../Layouts/TestTool/TestTool";
import "./TestToolPage.css";
const TestToolPage = () => {
  return (
    <>
      <div className="container-fluid">
        <ALab />
      </div>
    </>
  );
};

export default TestToolPage;
