import "./RequirementFile.css";
import { useEffect, useState, useRef } from "react";
import httpService from "../../../services/http.service";
import {
  convertEpochtoDate,
  getErrorMessage,
  convertToBadge,
  toCapitalizeFirstChar,
  decodeParam,
  encodeParam,
} from "../../Helper/Helper";
import { toast } from "react-toastify";
import { Skeleton } from "primereact/skeleton";
import { over } from "stompjs";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { connectSocket } from "../../Helper/SocketHelper";

const Properties = (props) => {
  const [requirementData, setRequirementData] = useState(null);
  const req_id = useRef(props.id);
  const company = useSelector((state) => state.auth.userProfile.socket);
  const firstUpdate = useRef(false);
  const pidArray = useRef(null);
  const projectData = useSelector(
    (state) => state.requirements.project_data_requirements
  );
  let stompClient = null;
  const tcConnection = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const getData = () => {
    httpService
      .getRequirementData(req_id.current)
      .then(async (data) => {
        getErrorMessage(data);
        setRequirementData(data["data"]);
        if (!firstUpdate.current) {
          firstUpdate.current = true;
          connectSocket("testpad", company.toString().toUpperCase() + "_REQUIREMENT/private", tcConnection, notifyTcClient);
        }
      })
      .catch((err) => {
        if (
          err["response"]["data"]["operation"].toLowerCase() === "info" &&
          err["response"]["data"]["suboperation"]
        ) {
          props.setNoAccess(true);
          tcConnection?.current?.unsubscribe();
          return;
        }
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
    Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  useEffect(() => {
    getData();
    return () => {
      tcConnection?.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (projectData.length > 0) {
      let pids = projectData.map((data) => data["pid"]);
      pidArray.current = pids;
    }
  }, [projectData]);


  const notifyTcClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (pidArray.current && pidArray.current.includes(wsBody["pid"])) {
        getData();
      }
    }
  };

  return (
    <div>
      <div className="container">
        <div className="card propertiesCard p-3 mb-2">
          <div className="row my-3 mx-2">
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Requirement ID</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["isolatedVersionId"] ? (
                    requirementData["isolatedVersionId"]
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="2rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">
                Requirement Name
              </div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["name"] ? (
                    requirementData["name"]
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="8rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Description</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["description"] ? (
                    requirementData["description"]
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="15rem" className=""></Skeleton>
                )}
              </div>
            </div>
          </div>
          <div className="row my-3 mx-2">
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Version</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["version"] ? (
                    requirementData["version"]
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="2rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Severity</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["severity"] ? (
                    convertToBadge(
                      toCapitalizeFirstChar(
                        requirementData["severity"]
                          .toLowerCase()
                          .split("_")
                          .join(" ")
                      )
                    )
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="3rem" className=""></Skeleton>
                )}
              </div>
            </div>
          </div>
          <div className="row my-3 mx-2">
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Type</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["requirementType"] ? (
                    toCapitalizeFirstChar(
                      requirementData["requirementType"]
                        .toLowerCase()
                        .split("_")
                        .join(" ")
                    )
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Priority</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["priority"] ? (
                    convertToBadge(
                      toCapitalizeFirstChar(
                        requirementData["priority"]
                          .toLowerCase()
                          .split("_")
                          .join(" ")
                      )
                    )
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="3rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Status</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["status"] ? (
                    toCapitalizeFirstChar(
                      requirementData["status"]
                        .toLowerCase()
                        .split("_")
                        .join(" ")
                    )
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
          </div>

          <div className="row my-3 mx-2">
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Assigned To</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["assignedTo"] ? (
                    requirementData["assignedTo"]
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Created By</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["createdBy"] ? (
                    requirementData["createdBy"]
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Variables</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["variables"] &&
                    Object.keys(requirementData["variables"]).length > 0 ? (
                    "key-value"
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
          </div>

          <div className="row my-3 mx-2">
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">
                Target Build Date
              </div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["targetBuildDate"] ? (
                    new Date(requirementData["targetBuildDate"]).toDateString()
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">
                Target Dev Date
              </div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["targetDevDate"] ? (
                    new Date(requirementData["targetDevDate"]).toDateString()
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Approved By</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["approvedBy"] ? (
                    requirementData["approvedBy"]
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
          </div>

          <div className="row my-3 mx-2">
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">
                Target Prod Date
              </div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["targetProdDate"] ? (
                    new Date(requirementData["targetProdDate"]).toDateString()
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">
                Target UAT Date
              </div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["targetUATDate"] ? (
                    new Date(requirementData["targetUATDate"]).toDateString()
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="ms-1 my-1 reqDetails-heading">Target QA Date</div>
              <div className="ms-1 mt-2 fontSizeGeneral">
                {requirementData ? (
                  requirementData["targetQADate"] ? (
                    new Date(requirementData["targetQADate"]).toDateString()
                  ) : (
                    "-"
                  )
                ) : (
                  <Skeleton width="5rem" className=""></Skeleton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Properties;
