import "./App.css";
import "react-calendar-heatmap/dist/styles.css";
import GuardedRoute from "./GuardedRoute";
import Login from "./components/Layouts/Login/Login";
import { Routes, Route, Navigate } from "react-router-dom";
import MainScreen from "./components/Pages/MainScreenPage/MainScreen";
import ComingSoon from "./components/Pages/ComingSoon/ComingSoon";
import Autolytics from "./components/Pages/Autolytics/Autolytics";
import Error404 from "./components/Pages/Error 404/Error404";
import Signup from "./components/Layouts/Signup/Signup";
import { connect } from "react-redux";
import HomePage from "./components/Pages/HomePage/HomePage";
import BridgeTokenPage from "./components/Pages/BridgeTokenPage/BridgeTokenPage";
import { ToastContainer, toast } from "react-toastify";
import FullExtent from "./components/Layouts/Autolytics/FullExtent";
import ScrolltoTop from "./components/UI/ScrolltoTop/ScrolltoTop";
import SharedReport from "./components/Layouts/Autolytics/SharedReport";
import FullTestStep from "./components/Layouts/Autolytics/FullTestStep";
import AdminPage from "./components/Pages/Admin/AdminPage";
import { useEffect } from "react";
import { useDispatch } from "react-redux/es/exports";
import { useNavigate, useLocation } from "react-router-dom";
import { isJwtExpired } from "jwt-check-expiration";
import { logout } from "./actions/auth";
import TestToolPage from "./components/Pages/TestTool/TestToolPage";
import FileViewer from "./components/Pages/FileViewer/FileViewer";
import FullHealthReport from "./components/Layouts/Autolytics/FullHealthReport";
import ForgotPass from "./components/Layouts/ForgotPass/ForgotPass";
import SuperAdminPage from "./components/Pages/SuperAdmin/SuperAdminPage";
import RequirementsPage from "./components/Pages/Requirements/RequirementsPage";
import ManualTCPage from "./components/Pages/ManualTC/ManualTCPage";
import ManualTestcasesExePage from "./components/Pages/ManualTestcaseExePage/ManualTestcasesExePage";
import SignUpOTP from "./components/Layouts/SignUpOTP/SignUpOTP";
import ELabPage from "./components/Pages/ELab/ELabPage";
import LandingPage from "./components/Pages/LandingPage/LandingPage";
import TaskboardPage from "./components/Pages/TaskboardPage/TaskboardPage";
import TestpadPage from "./components/Pages/TestpadPage/TestpadPage";
import WorkMinutes from "./components/Pages/WorkMinutes/WorkMinutes";
import NewComingSoonPage from "./components/Pages/newComingSoonPage/NewComingSoonPage";
import { AutolyticsInfo } from "./components/Pages/AutolyticsInfo/AutolyticsInfo";
import ChangeEmail from "./components/Layouts/changeEmail/ChangeEmail";
import FullSecurityMetricsReport from "./components/Layouts/Autolytics/FullSecurityMetricsReport";
import RecycleBinPage from "./components/Pages/RecycleBin/RecycleBin";
import Profile from "./components/Pages/Profile/Profile";

const App = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  useEffect(() => {


    // console.log =
    //   console.warn =
    //   console.error =
    //   console.debug =
    //   console.group =
    //   console.info =
    //   console.trace =
    //     () => {};

    const handleLogout = (e) => {
      if (!e.newValue && e.oldValue) {
        dispatch(logout());
      } else if (!e.oldValue && e.newValue) {
        if (sessionStorage.getItem("redirect")) {
          window.location.hash = "#" + sessionStorage.getItem("redirect");
          window.location.reload(false);
        } else {
          navigate("/autolytics/insights");
        }
      }
    };
    if (props.isLoggedIn) {
      if (isJwtExpired(localStorage.getItem("usertoken"))) {
        sessionStorage.setItem("redirect", pathname + search);
        if (
          !(
            sessionStorage.getItem("redirect") === "/" ||
            sessionStorage.getItem("redirect") === "/signup"
          )
        ) {
          toast.info("Session Expired ! Please Log In to continue . ", {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(logout());
      }
    }

    window.addEventListener("storage", handleLogout);

    return () => {
      window.removeEventListener("storage", handleLogout);
    };
  }, [props.isLoggedIn]);
  return (
    <>
      <ScrolltoTop />

      <Routes>
        <Route exact path="/" element={<LandingPage />} />

        <Route path="/pricing" element={<ComingSoon />} />
        <Route path="/howitworks" element={<ComingSoon />} />
        <Route path="/open-api" element={<NewComingSoonPage />} />
        <Route path="/release-notes" element={<NewComingSoonPage />} />
        <Route path="/info/taskboard" element={<TaskboardPage />} />
        <Route path="/info/testpad" element={<TestpadPage />} />
        <Route path="/info/autolytics" element={<AutolyticsInfo />} />
        <Route path="/info/work-minutes" element={<WorkMinutes />} />
        <Route path="/w/ALab" element={<NewComingSoonPage />} />
        <Route path="/w/MLab" element={<NewComingSoonPage />} />
        <Route path="/how-it-works" element={<NewComingSoonPage />} />
        <Route path="/request-demo" element={<NewComingSoonPage />} />
        <Route path="/build-your-framework" element={<NewComingSoonPage />} />
        <Route path="/try-it-now" element={<NewComingSoonPage />} />
        <Route path="/why-gemecosystem" element={<NewComingSoonPage />} />
        <Route path="/installation" element={<NewComingSoonPage />} />
        <Route path="/write-your-first-test" element={<NewComingSoonPage />} />
        <Route path="/blog" element={<NewComingSoonPage />} />
        <Route path="/newsletter" element={<NewComingSoonPage />} />
        <Route path="/tutorial" element={<NewComingSoonPage />} />
        <Route path="/support" element={<NewComingSoonPage />} />
        <Route path="/resources" element={<NewComingSoonPage />} />
        <Route path="/changelog" element={<NewComingSoonPage />} />
        <Route path="/status-site" element={<NewComingSoonPage />} />
        <Route path="/about-us" element={<NewComingSoonPage />} />
        <Route path="/working-at-gemini" element={<NewComingSoonPage />} />
        <Route path="/contact-us" element={<NewComingSoonPage />} />

        <Route path="/jewel" element={<MainScreen />} />

        <Route path="/login" element={<Login />} />

        <Route path="/signup" element={<Signup />} />
        <Route path="/account-verification" element={<SignUpOTP />} />
        <Route path="/change-email" element={<ChangeEmail />} />
        <Route path="/login/password/forgot" element={<ForgotPass />} />

        {/* <Route
          path="/home"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <HomePage />
            </GuardedRoute>
          }
        /> */}

        <Route
          path="/profile"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <Profile />
            </GuardedRoute>
          }
        />

        <Route
          path="/file-viewer"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <FileViewer />
            </GuardedRoute>
          }
        />
        <Route
          path="/autolytics/execution-report"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <FullExtent />
            </GuardedRoute>
          }
        />
        <Route
          path="/autolytics/security-report"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <FullSecurityMetricsReport />
            </GuardedRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <AdminPage />
            </GuardedRoute>
          }
        />

        <Route
          path="/super-admin"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <SuperAdminPage />
            </GuardedRoute>
          }
        />

        <Route
          path="/testpad/taskboard"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <RequirementsPage />
            </GuardedRoute>
          }
        />

        <Route
          path="/testpad/MLab"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <ManualTCPage />
            </GuardedRoute>
          }
        />

        <Route
          path="/autolytics/health-report"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <FullHealthReport />
            </GuardedRoute>
          }
        />
        <Route
          path="/autolytics/shared-report"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <SharedReport />
            </GuardedRoute>
          }
        />
        <Route
          path="/autolytics/teststep-report"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <FullTestStep />
            </GuardedRoute>
          }
        />
        <Route
          path="/autolytics/:module"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <Autolytics />
            </GuardedRoute>
          }
        />
        <Route
          path="/autolytics"
          element={<Navigate to={"/autolytics/insights"} />}
        />
        <Route
          path="/bridge-token"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <BridgeTokenPage />
            </GuardedRoute>
          }
        />
        <Route
          path="/recycle-bin"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <RecycleBinPage />
            </GuardedRoute>
          }
        />
        <Route
          path="/testpad/ALab"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <TestToolPage />
            </GuardedRoute>
          }
        />
        <Route
          path="/testpad/ELab"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <ELabPage />
            </GuardedRoute>
          }
        />
        <Route
          path="/testpad/manual-testcase-execution"
          element={
            <GuardedRoute user={props.isLoggedIn}>
              <ManualTestcasesExePage />
            </GuardedRoute>
          }
        ></Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
      <ToastContainer />
    </>
  );
};
function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}
export default connect(mapStateToProps)(App);
