import {
  convertEpochtoDate,
  copyToClipboard,
  createColumns,
  createTestCaseInfoCards,
  createFilters,
  getErrorMessage,
} from "../../Helper/Helper";
import { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSquareArrowUpRight,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import cross from "../../../assets/cross.svg";
import "./TestStep.css";
import { useEffect } from "react";
import httpService from "../../../services/http.service";
import NoData from "../../UI/NoData/NoData";
const TestStep = ({
  data,
  shared,
  tc_run_id,
  newTab,
  filterMain,
  reportSplit,
  setSplitReport,
}) => {
  const [filters, setFilters] = useState({});
  const [dataMain, setDataMain] = useState(data ? data : null);

  useEffect(() => {
    if (tc_run_id) {
      httpService.getTestStepReportData(tc_run_id).then((mainData) => {
        getErrorMessage(mainData);
        setDataMain(mainData);
      });
    }
  }, []);
  useEffect(() => {
    // if (firstUpdate.current) {
    //   firstUpdate.current = false;
    //   setFilters(filterData);
    //   return;
    // }
    // setTimeout(() => {
    const filterData =
      dataMain && dataMain["headers"] ? createFilters(dataMain["headers"]) : {};
    filterMain ? setFilters(JSON.parse(filterMain)) : setFilters(filterData);
    // }, 10000);
  }, [dataMain]);

  const createHeadermetaData = (dataMain) => {
    const finalRow = dataMain
      ? Object.keys(dataMain).map((val, i) => (
          <>
            <div className=" my-2 mx-0 wrapClass col-lg-4  col-md-4 col-sm-3 col-3">
              <div className="card metaDataCards shadow-sm text-left">
                <div className="card-body">
                  <div className="cardHeader">{val}</div>
                  <div className="cardHeadercontent mt-1 ms-1">
                    {typeof dataMain[val] === "object"
                      ? convertEpochtoDate(
                          dataMain[val]["value"],
                          dataMain[val]["subType"]
                        )
                      : dataMain[val]}
                  </div>
                </div>
              </div>
            </div>
          </>
        ))
      : null;

    return finalRow;
  };
  return (
    <>
      <div className={`container ms-0`}>
        <div
          className={` reportHead d-flex ${
            shared || newTab ? "" : "ms-3 w-50 mt-3"
          }`}
        >
          {!shared ? (
            <div className={`d-flex ${newTab ? " " : "ms-5"} mt-2`}>
              <label title="Copy Report Link">
                <div
                  className={`btn-sm${
                    newTab ? " " : "ms-3"
                  } btn-success me-2 reportIcons teststep`}
                  onClick={() => {
                    copyToClipboard(
                      window.location.origin +
                        "/#/autolytics/teststep-report?tc_run_id=" +
                        dataMain["tc_run_id"] +
                        "&filters=" +
                        JSON.stringify(filters)
                    );
                  }}
                >
                  <FontAwesomeIcon className="mx-2 tabIcon" icon={faCopy} />
                </div>
              </label>
              <label title="Open in New Window">
                <div
                  className="btn-sm btn-success me-2 reportIcons teststep"
                  onClick={() => {
                    window.open(
                      window.location.origin +
                        "/#/autolytics/teststep-report?tc_run_id=" +
                        dataMain["tc_run_id"] +
                        "&filters=" +
                        JSON.stringify(filters),
                      "_blank"
                    );
                  }}
                >
                  <FontAwesomeIcon
                    className="mx-2 tabIcon"
                    icon={faSquareArrowUpRight}
                  />
                </div>
              </label>
            </div>
          ) : null}
          <div className={newTab ? `ms-5` : `mx-auto`}>
            <strong
              className={
                newTab ? `ms-5 fontSizeHeadGeneral` : `fontSizeHeadGeneral`
              }
            >
              Test Step Report
            </strong>
          </div>
          {reportSplit ? (
            <div>
              {" "}
              <img
                onClick={() => {
                  document.getElementsByClassName(
                    "p-splitter-gutter"
                  )[0].style.backgroundColor = "transparent";
                  document.getElementsByClassName(
                    "p-splitter-gutter-handle"
                  )[0].style.backgroundColor = "transparent";
                  setSplitReport(false);
                }}
                src={cross}
                className="xmark"
              />
            </div>
          ) : null}
        </div>
        <div className="">
          <div className={`row ${shared || newTab ? "" : " ms-5"} mt-3`}>
            {dataMain && dataMain["metaData"]
              ? createTestCaseInfoCards(dataMain["metaData"][2])
              : null}
          </div>
        </div>
        {shared || newTab ? (
          <div className="expandFullDataTable ">
            <div className="  my-3">
              <div className="">
                <div className="row">
                  {dataMain && dataMain["metaData"]
                    ? createHeadermetaData({
                        ...dataMain["metaData"][0],
                        ...dataMain["metaData"][1],
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={`mt-4 ${
            shared || newTab
              ? "expandFullDataTable mb-4"
              : "pb-4  ps-2 ms-5 expandDataTable"
          }`}
        >
          <DataTable
            reorderableColumns
            className={
              reportSplit
                ? `splitTestStep test-stepTable`
                : `testStep test-stepTable`
            }
            onFilter={(e) => {
              setFilters(e.filters);
            }} // showGridlines
            resizableColumns
            stripedRows
            columnResizeMode="expand"
            value={dataMain ? dataMain["data"] : null}
            paginator
            rows={25}
            dataKey="id"
            filters={filters}
            filterDisplay="menu"
            responsiveLayout="scroll"
            emptyMessage={<NoData />}
            removableSort
            currentPageReportTemplate="Total {totalRecords} Records Found"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
            rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
            scrollHeight={"65vh"}
          >
            {dataMain && dataMain["headers"] ? (
              !shared ? (
                createColumns(
                  dataMain["headers"],
                  true,
                  true,
                  dataMain["data"],
                  true,
                  "maxWidthDataTable"
                )
              ) : (
                createColumns(
                  dataMain["headers"],
                  true,
                  true,
                  dataMain["data"],
                  true,
                  "maxWidthFullDataTable"
                )
              )
            ) : (
              <></>
            )}
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default TestStep;
