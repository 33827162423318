import { faFile, faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { ConvertTextToSeeMore, getToastMessage } from "../../../Helper/Helper";
import httpService from "../../../../services/http.service";
import { handleToastErrorCatch } from "../../../Helper/ToastHelper";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

export const FolderAction = ({ props }) => {
  const [showSeeMoreFolder, setShowSeeMoreFolder] = useState(false);

  return (
    <div
      className="reqLinks boldText ms-2 d-inline-flex"
      onClick={(e) =>
        props?.openFolder(
          props?.rowData?.Id?.value,
          props?.rowData?.[props?.fid.current?"Folder/Testcase":"Folder"]?.value,
          props?.rowData?.["P ID"]?.value,
          props?.rowData?.["Project Name"]?.value
        )
      }
    >
      <FontAwesomeIcon icon={faFolderOpen} className="reqIcons me-2" />{" "}
      <span>
        {ConvertTextToSeeMore(
          "Folder Name",
          props?.val,
          showSeeMoreFolder,
          setShowSeeMoreFolder,
          20
        )}
      </span>
    </div>
  );
};

export const FileAction = ({ props }) => {
  const [showSeeMoreFile, setShowSeeMoreFile] = useState(false);
  return (
    <div
      className="reqLinks boldText ms-2 d-inline-flex"
      onClick={(e) =>
        props?.openFile(
          props?.rowData?.Id?.value,
          props?.rowData?.[props?.fid.current?"Folder/Testcase":"Folder"]?.value,
          props?.rowData?.["P ID"]?.value,
          props?.rowData?.["Project Name"]?.value
        )
      }
    >
      <FontAwesomeIcon icon={faFile} className="reqIcons me-2" />{" "}
      <span>
        {ConvertTextToSeeMore(
          "Testcase Name",
          props?.val,
          showSeeMoreFile,
          setShowSeeMoreFile,
          20
        )}
      </span>
    </div>
  );
};

export const EditDeleteAction = ({ props }) => {
  const role = useSelector((state) => state.auth.userProfile.role);
  useEffect(() => {
    if (props.editType !== "editFromMlab") {
      props?.setAction(null);
    }
    props?.setEditTcId(null);
    props?.setEditFolderData(null);
  }, []);

  return (
    <div className="crud-icons">
      {role.toString().toUpperCase() !== "SUPER_ADMIN" && (
        <FontAwesomeIcon
          className={`crudIcons mx-2 notDisabledAdmin`}
          icon={faPenToSquare}
          onClick={(e) => {
            props?.editFileAction(props.val, props.rowData);
          }}
          title={"Edit"}
        />
      )}

      <FontAwesomeIcon
        className={`crudIcons mx-2 notDisabledAdmin`}
        icon={faTrashCan}
        onClick={(e) => {
          props?.deleteAction(props.val, props.rowData);
        }}
        title={"Delete"}
      />
    </div>
  );
};

export const createNewFolder = (payload, type, clearData) => {
  httpService
    .createNewFolderTestpad(payload, type)
    .then((data) => {
      getToastMessage(data);
      clearData();
    })
    .catch((err) => {
      handleToastErrorCatch(err, clearData);
    });
};

export const editFolder = (payload, clearData) => {
  httpService
    .editFolderTestpad(payload)
    .then((data) => {
      getToastMessage(data);
      clearData();
    })
    .catch((err) => {
      handleToastErrorCatch(err, clearData);
    });
};
