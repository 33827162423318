import { useState, useEffect, useRef } from "react";
import AttachedRequirements from "./AttachedRequirements";
import AttachedTestcases from "./AttachedTestcases";
import ManualProperties from "./ManualProperties";
import "./ManualTCFile.css";
import TestStepsDraft from "./TestStepsDraft";
import TestStepsManual from "./TestStepsManual";
import NoAccess from "./../../UI/NoAccess/NoAccess";
import { logPageTitleForGA } from "../../Helper/Helper";

const ManualTCFile = (props) => {
  const [activeLink, setActiveLink] = useState("testSteps");
  const [showDraftTab, setShowDraftTab] = useState(false);
  const [isShowDraftTabOpen, setIsShowDraftTabOpen] = useState(false);
  const [noAccess, setNoAccess] = useState(false);
  const draftTab = useRef(null);
  const manualTab = useRef(null);

  useEffect(() => {
    showDraftTab ? draftTab.current.click() : manualTab.current.click();
  }, [showDraftTab]);

  useEffect(() => {
    if (noAccess) {
      props.setNoAccessFile(true);
    }
  }, [noAccess]);

  useEffect(() => {
    let title = "Jewel | MLab : " + props.mtc_id;
    document.title = title;
    logPageTitleForGA(title);
  }, []);

  return (
    <div className="">
      <div className="mt-2" id={props.mtc_id}>
        <ul
          className="nav nav-pills mx-2 mb-3 manualTCList"
          id={"pills-tab"}
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <div
              className={`fontSizeGeneral nav-link manualTCPill active`}
              id={"pills-properties-tab"}
              data-toggle="pill"
              data-target={"#pills-properties"}
              type="button"
              role="tab"
              aria-selected="false"
              onClick={(e) => setActiveLink("properties")}
            >
              Properties
            </div>
          </li>
          <li className="nav-item" role="presentation">
            <div
              className={`fontSizeGeneral nav-link manualTCPill`}
              id={"pills-testSteps-tab"}
              data-toggle="pill"
              data-target={"#pills-testSteps"}
              type="button"
              role="tab"
              ref={manualTab}
              aria-selected="false"
              onClick={(e) => {
                setActiveLink("testSteps");
                setShowDraftTab(false);
              }}
            >
              Test Steps
            </div>
          </li>
          {showDraftTab || isShowDraftTabOpen ? (
            <li className="nav-item" role="presentation">
              <div
                className={`fontSizeGeneral nav-link manualTCPill`}
                id={"pills-testStepsDraft-tab"}
                data-toggle="pill"
                data-target={"#pills-testStepsDraft"}
                type="button"
                role="tab"
                ref={draftTab}
                aria-selected="false"
                onClick={(e) => setActiveLink("testStepsDraft")}
              >
                Test Steps
                <span className="ms-1 fontSizeGeneral fail-color">[Draft]</span>
              </div>
            </li>
          ) : null}
          <li className="nav-item" role="presentation">
            <div
              className={`fontSizeGeneral nav-link manualTCPill`}
              id={"pills-attachedTC-tab"}
              data-toggle="pill"
              data-target={"#pills-attachedTC"}
              type="button"
              role="tab"
              aria-selected="false"
              onClick={(e) => setActiveLink("attachedTC")}
            >
              Attached Testcases
            </div>
          </li>
          <li className="nav-item" role="presentation">
            <div
              className={`fontSizeGeneral nav-link manualTCPill`}
              id={"pills-attachedReq-tab"}
              data-toggle="pill"
              data-target={"#pills-attachedReq"}
              type="button"
              role="tab"
              aria-selected="false"
              onClick={(e) => setActiveLink("attachedReq")}
            >
              Attached Requirements
            </div>
          </li>
          {/* <li className="nav-item" role="presentation">
                        <div
                            className={`fontSizeGeneral nav-link manualTCPill`}
                            id={"pills-versionHistory-tab"}
                            data-toggle="pill"
                            data-target={"#pills-versionHistory"}
                            type="button"
                            role="tab"
                            aria-selected="false"
                            onClick={(e) => setActiveLink("versionHistory")}
                        >
                            Version History
                        </div>
                    </li> */}
        </ul>

        <div className="tab-content ms-2 me-4" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id={"pills-properties"}
            role="tabpanel"
            aria-labelledby={"pills-properties-tab"}
          >
            {activeLink === "properties" ? (
              <ManualProperties
                id={props.mtc_id}
                noAccess={noAccess}
                setNoAccess={setNoAccess}
              />
            ) : null}
          </div>
          <div
            className="tab-pane fade"
            id={"pills-testSteps"}
            role="tabpanel"
            aria-labelledby={"pills-testSteps-tab"}
          >
            <div className="mx-2">
              {activeLink === "testSteps" ? (
                <TestStepsManual
                  id={props.mtc_id}
                  setShowDraftTab={setShowDraftTab}
                  setIsShowDraftTabOpen={setIsShowDraftTabOpen}
                  noAccess={noAccess}
                  setNoAccess={setNoAccess}
                  // stepsSize={stepsSize}
                  // setStepSize={setStepSize}
                />
              ) : null}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id={"pills-testStepsDraft"}
            role="tabpanel"
            aria-labelledby={"pills-testStepsDraft-tab"}
          >
            <div className="mx-2">
              {activeLink === "testStepsDraft" ? (
                <TestStepsDraft
                  id={props.mtc_id}
                  setShowDraftTab={setShowDraftTab}
                  noAccess={noAccess}
                  setNoAccess={setNoAccess}
                />
              ) : null}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id={"pills-attachedTC"}
            role="tabpanel"
            aria-labelledby={"pills-attachedTC-tab"}
          >
            <div className="mx-2">
              {activeLink === "attachedTC" ? (
                <AttachedTestcases
                  id={props.mtc_id}
                  noAccess={noAccess}
                  setNoAccess={setNoAccess}
                />
              ) : null}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id={"pills-attachedReq"}
            role="tabpanel"
            aria-labelledby={"pills-attachedReq-tab"}
          >
            <div className="mx-2">
              {activeLink === "attachedReq" ? (
                <AttachedRequirements
                  id={props.mtc_id}
                  noAccess={noAccess}
                  setNoAccess={setNoAccess}
                />
              ) : null}
            </div>
          </div>
          {/* <div
                        className="tab-pane fade"
                        id={"pills-versionHistory"}
                        role="tabpanel"
                        aria-labelledby={"pills-versionHistory-tab"}
                    >
                        <div className="mx-2">
                            {activeLink === "versionHistory" ? <VersionHistory /> : null}
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default ManualTCFile;
