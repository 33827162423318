import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect } from "react";
import { AlabContext } from "../../Layouts/TestTool/ALab/ALab";
import { useState } from "react";
import { statementError } from "../../Helper/ToastHelper";
import {
  createNewFolder,
  editFolder,
} from "../../Layouts/TestTool/ALab/FolderActions";

const CreateFolderDialog = ({
  projectOptions,
  newFolderDialog,
  action,
  setAction,
  editFolderData,
  setEditFolderData,
  setNewFolderDialog,
}) => {
  const { currentP_Name, currentFolderID, currentP_ID } =
    useContext(AlabContext);
  const [folderName, setFolderName] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [createFolderLoading, setCreateFolderLoading] = useState(false);

  useEffect(() => {
    if (action === "edit") {
      setFolderName(editFolderData?.[currentFolderID.current?"Folder/Testcase":"Folder"]?.value);
      setSelectedProject(editFolderData?.["P ID"]?.value);
      setNewFolderDialog(true);
    }
  }, [editFolderData]);

  const onHideFolderDialogFn = () => {
    setNewFolderDialog(false);
    setCreateFolderLoading(false);
    setFolderName("");
    setSelectedProject(null);
    setEditFolderData(null);
    setAction(null);
  };

  const editFolderFn = () => {
    if (selectedProject && folderName?.length > 0) {
      let payload = {
        name: folderName,
        pid: selectedProject,
        id: editFolderData?.["Id"]?.value,
        folderType: "AUTOMATION_TC",
      };
      setCreateFolderLoading(true);
      editFolder(payload, onHideFolderDialogFn);
    } else {
      statementError("All Fields are required !");
    }
  };

  const createFolderFn = () => {
    if (!currentFolderID.current) {
      if (selectedProject && folderName.length > 0) {
        let payload = {
          name: folderName,
          pid: selectedProject,
          folderId: currentFolderID.current,
        };
        setCreateFolderLoading(true);
        createNewFolder(payload, "AUTOMATION_TC", onHideFolderDialogFn);
      } else {
        statementError("All Fields are required !");
      }
    } else {
      if (folderName.length > 0) {
        let payload = {
          name: folderName,
          pid: currentP_ID.current,
          folderId: currentFolderID.current,
        };
        setCreateFolderLoading(true);
        createNewFolder(payload, "AUTOMATION_TC", onHideFolderDialogFn);
      } else {
        statementError("All Fields are required !");
      }
    }
  };

  return (
    <div>
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Create New Folder"
        visible={newFolderDialog}
        onHide={() => {
          onHideFolderDialogFn();
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
        footer={
          <div className="text-center">
            <Button
              label={action === "edit" ? "Save" : "Create"}
              className="btn-success btn themeBtn"
              onClick={(e) => {
                action === "edit" ? editFolderFn() : createFolderFn();
              }}
              loadingIcon={"pi pi-spin pi-spinner me-2"}
              loading={createFolderLoading}
              iconPos="right"
            />
          </div>
        }
      >
        <div className="row mt-3 mx-2">
          <div className="col-md-6 col-12">
            <InputText
              type={"text"}
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              className="w-100"
              placeholder={"Folder Name"}
              disabled={createFolderLoading}
            />
          </div>
          {!currentP_Name.current ? (
            <div className="col-md-6 col-12">
              {action === "edit" ? (
                <InputText
                  type={"text"}
                  value={editFolderData?.["Project Name"]?.value}
                  className="w-100"
                  disabled={true}
                />
              ) : (
                <Dropdown
                  className="dialog-dropdown px-3 w-100"
                  options={projectOptions}
                  optionValue="P ID"
                  optionLabel="Project Name"
                  value={selectedProject}
                  onChange={(e) => {
                    setSelectedProject(e.value);
                  }}
                  filter
                  placeholder="Select Project"
                  maxSelectedLabels={1}
                  disabled={createFolderLoading}
                />
              )}
            </div>
          ) : (
            <div className="col-md-6 col-12">
              <InputText
                type={"text"}
                value={currentP_Name.current}
                className="w-100"
                disabled={true}
              />
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default CreateFolderDialog;
