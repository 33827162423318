import axios from "axios";
import { MICRO_API_URL_NOTIFICATION, MICRO_API_URL_USER } from "../../config/API_URLS";
import authHeader from "../auth-header";

class UserProfileService {
    getUserDetails=async(username)=>{
        return axios
      .get(
        MICRO_API_URL_USER.baseURL +
        "userManagement/v1/profile?username=" +
        username,
        {
          // .get(API_URLS.baseURL + "v2/requirement/same/project?id=" + id, {
          headers: authHeader(),
        }
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
    }

    setNotifications=async(payload)=>{
      return await axios
    .post(MICRO_API_URL_NOTIFICATION.baseURL + "v1/notification/preference", payload, {
      headers: authHeader(),
    })
    .then((data) => {
      return Promise.resolve(data.data ? data.data : []);
    })
    .catch((err) => 
      Promise.reject(err));
  }

  getNotifications=async(username)=>{
    return axios
  .get(
    MICRO_API_URL_NOTIFICATION.baseURL +
    "v1/notification/preference?username=" +
    username,
    {
      // .get(API_URLS.baseURL + "v2/requirement/same/project?id=" + id, {
      headers: authHeader(),
    }
  )
  .then((data) => {
    return Promise.resolve(data.data);
  })
  .catch((err) => Promise.reject(err));
}

editUserProfile=async(payload)=> {
  return await axios
    .put(
      MICRO_API_URL_USER.baseURL + "userManagement/v1/profile/update",
      payload,
      {
        headers: authHeader(),
      }
    )
    .then((data) => {
      return Promise.resolve(data.data);
    })
    .catch((err) => Promise.reject(err));
}

resetUserPassword=async(payload)=>{
  return await axios
  .post(MICRO_API_URL_USER.baseURL + "loginManagement/v1/resetPassword", payload, {
    headers: authHeader(),
  })
  .then((data) => {
    return Promise.resolve(data.data ? data.data : []);
  })
  .catch((err) => 
    Promise.reject(err));
}

setUserPic=async(formData)=>{
  return await axios
  .post(MICRO_API_URL_USER.baseURL + "userManagement/v1/profile/upload", formData, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data'
    }
  })
  .then((data) => {
    return Promise.resolve(data.data ? data.data : []);
  })
  .catch((err) => 
    Promise.reject(err));
}

getUserPic=async(username)=>{
  return axios
.get(
  MICRO_API_URL_USER.baseURL +
  "userManagement/v1/profile/pic?username=" +
  username,
  {
    // responseType: "arraybuffer",
    headers: authHeader(),
  }
)
.then((data) => {

  return Promise.resolve(data.data);
})
.catch((err) => Promise.reject(err));
}
}

export default new UserProfileService();