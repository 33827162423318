import shareReport from '../../../../assets/shareReport.svg'
import './index.css';

export const headerTemplate = () => {
    return (
        <div className='d-flex align-items-center'>
            <img src={shareReport} alt='share report' className='shareReportImg' />
            <div className='ms-1'>
                <div className='shareReportHeading'>Share Report</div>
                <div className='shareReportDesc'>Manage the users that can access your report</div>
            </div>
        </div>)
}