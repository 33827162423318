import { faTrashCanArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import './RecycleBin.css'
import ModuleData from "../ModuleData"
import { useEffect, useRef, useState } from "react"
import { connectSocket } from "../../../Helper/SocketHelper"
import { logPageTitleForGA } from "../../../Helper/Helper"

const RecycleBin = () => {
    const connectionTestpad = useRef(null);
    const connectionExecution = useRef(null);
    const [alabMessage, setAlabMessage] = useState(null);
    const [mlabMessage, setMlabMessage] = useState(null);

    useEffect(() => {
        //setting the page title to log in GA
        let title = "Jewel | Recycle Bin";
        document.title = title;
        logPageTitleForGA(title);

        connectSocket(
            "testpad",
            "recycleBin/private",
            connectionTestpad,
            notifyClient
        );
        connectSocket(
            "execution",
            "recycleBin/private",
            connectionExecution,
            notifyClient
        );

        return () => {
            connectionTestpad.current?.unsubscribe();
            connectionExecution.current?.unsubscribe();
        }
    }, []);

    const notifyClient = (e) => {
        setAlabMessage(null);
        setMlabMessage(null);
        if (e["body"]) {
            let wsBody = JSON.parse(e["body"]);
            let message = wsBody?.message;
            if (wsBody?.message?.toLowerCase().includes("alab"))
                setAlabMessage(message);
            else {
                setMlabMessage(message);
            }
        }
    };

    return (
        <div className="my-2 mx-2">
            <div className="d-flex align-items-center headerTest mx-2">
                <span className="me-2">
                    <FontAwesomeIcon
                        className=""
                        icon={faTrashCanArrowUp}
                    />
                </span>
                Recycle Bin
            </div>
            <div className="mt-3 mx-2">
                <div className="d-inline-block p-0">
                    <ul className="nav nav-pills moduleTabList" id={"pills-tab"} role="tablist">
                        <li className="nav-item mr-1" role="presentation">
                            <div
                                className="fontSizeGeneral nav-link moduleTab alabtab active"
                                id={"alab-tab"}
                                data-toggle="pill"
                                data-target={"#alab"}
                                type="button"
                                role="tab"
                                aria-selected="true"
                            >
                                ALab
                            </div>
                        </li>
                        <li className="nav-item mr-1" role="presentation">
                            <div
                                className="fontSizeGeneral nav-link moduleTab mlabtab fontSizeGeneral"
                                id={"mlab-tab"}
                                data-toggle="pill"
                                data-target={"#mlab"}
                                type="button"
                                role="tab"
                                aria-selected="false"
                            >
                                MLab
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active mt-3 mb-2"
                        id={"alab"}
                        role="tabpanel"
                        aria-labelledby={"alab-tab"}
                    >
                        <ModuleData module={"AUTOMATION"} alabMessage={alabMessage} />
                    </div>
                    <div
                        className="tab-pane fade mt-3 my-2"
                        id={"mlab"}
                        role="tabpanel"
                        aria-labelledby={"mlab-tab"}
                    >
                        <ModuleData module={"MANUAL"} mlabMessage={mlabMessage} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecycleBin