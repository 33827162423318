import { useEffect } from "react";
import "./ScrolltoTop.css";
const ScrolltoTop = () => {
  useEffect(() => {
    window.addEventListener("scroll", scrollFunction);
    return () => window.removeEventListener("scroll", scrollFunction);
  });

  const scrollFunction = () => {
    let scrollbutton = document.getElementById("scrolltop");
    if (
      document.body.scrollTop > 10 ||
      document.documentElement.scrollTop > 10
    ) {
      scrollbutton.style.display = "block";
    } else {
      scrollbutton.style.display = "none";
    }
  };

  const scrolltop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <>
      <button
        onClick={() => {
          scrolltop();
        }}
        id="scrolltop"
        title="Go to top"
      >
        ^
      </button>
    </>
  );
};

export default ScrolltoTop;
