import ManualTC from "../../Layouts/ManualTC/ManualTC";

const ManualTCPage = () => {
  return (
    <>
      <div className="container-fluid">
        <ManualTC />
      </div>
    </>
  );
};

export default ManualTCPage;
