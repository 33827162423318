import React from "react";
import "./UsageOfAutolyticsDashboard.css";
import { usageOfAutolyticsDashboardCardsData } from "../../../Helper/MockData/AutolyticsInfoPageMockData";

const UsageOfAutolyticsDashboard = () => {
  return (
    <div className="component-padding max-w-1440">
      <p className="component-heading">Usage of Autolytics</p>
      <div className="usage-autolytics-grid-wrapper">
        {usageOfAutolyticsDashboardCardsData.map((item) => {
          return (
            <div key={item.heading}>
              <img
                className="usage-autolytics-card__icon"
                src={item.icon}
                alt={item.heading}
                loading="lazy"
              />
              <p className="usage-autolytics-card__heading">{item.heading}</p>
              <p className="usage-autolytics-card__description">
                {item.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UsageOfAutolyticsDashboard;
