import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import httpService from "../../../services/http.service";
import Auth from "../../Pages/AuthPage/Auth";
import "./ChangeEmail.css";
import { getSuccessToast, statementError } from "../../Helper/ToastHelper";

const ChangeEmail = () => {
  const navigate = useNavigate();
  const locationState = useLocation().state;
  const firstName = locationState?.firstName ?? "";
  const lastName = locationState?.lastName ?? "";
  const username = locationState?.username ?? "";
  const hashedPassword = locationState?.hashedPassword ?? "";
  const companyName = locationState?.companyName ?? "";

  const [email, setEmail] = useState(locationState?.email ?? "");
  function handleChangeEmail() {
    httpService
      .changeEmail({
        username: username,
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: hashedPassword,
        company: companyName,
      })
      .then(() => {
        getSuccessToast("Email updated successfully");
        navigate("/account-verification", {
          state: {
            firstName: firstName,
            lastName: lastName,
            username: username,
            email: email,
            hashedPassword: hashedPassword,
            companyName: companyName,
          },
        });
      })
      .catch((err) => {
        statementError(err?.response?.data?.message, 5000);
      });
  }

  return (
    <Auth>
      <div className="d-flex card customSignup">
        <div className="d-flex flex-column align-items-center card-body py-5 px-lg-5 px-md-5 px-sm-4 px-xs-2 change-email-card">
          <h1>Change Email</h1>
          <h2>Please enter new Email</h2>

          <InputText
            id="change-email"
            keyfilter="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@domain.com"
            className="my-3"
          />

          <div className="d-flex mt-3 justify-content-center">
            <button
              className="mx-3 btn btn-primary px-3 "
              label="Verify OTP"
              onClick={handleChangeEmail}
              disabled={email.length === 0}
            >
              Change Email
            </button>
          </div>
        </div>
      </div>
    </Auth>
  );
};

export default ChangeEmail;
