import "./Image.css";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { loadingData } from "../../Helper/Helper";
import { Skeleton } from "primereact/skeleton";
import screenshotIcon from '../../../assets/screenshotIcon.svg';

const Image = ({ src, header }) => {
  const authURL = src + "&token=" + localStorage.getItem("usertoken");
  const [openModal, setOpenModal] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [maxSize, setMaxSize] = useState(true);
  // const [loadThumbnail, setLoadThumbnail] = useState(false);
  return (
    <>
      {src ? (
        <>
          <div
            className="d-flex justify-content-end"
            onClick={() => {
              setShowLoading(true);
              setOpenModal(true);
            }}
          >
            {/* {loadThumbnail ? (
              <div className={`d-flex justify-content-center`}>
                <Skeleton width="8rem" height="5rem"></Skeleton>
              </div>
            ) : null} */}
            <button
              className="btn-success btn mx-2 py-1 px-2 mt-1 d-flex align-items-center ssBtn"
              onClick={(e) => {
                setShowLoading(true);
                setOpenModal(true);
              }}
            >
              <span>
                <img
                  src={screenshotIcon}
                  className="me-2 screenshotIcon"
                  alt={"image3"}
                />
              </span> Screenshot
            </button>

            {/* <img
              onLoad={(e) => {
                setLoadThumbnail(false);
              }}
              src={authURL}
              className={`thumbnail ${loadThumbnail ? "displayThumb" : ""}`}
              alt={"image1"}
            /> */}
          </div>
          <Dialog
            blockScroll={true}
            header={header}
            visible={openModal}
            onHide={() => {
              setOpenModal(false);
              // setMaxSize(false);
            }}
            maximizable
            // onMaximize={(e) => {
            //   setMaxSize(e.maximized);
            // }}
            // maximized={maxSize}
            draggable={false}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "60vw",
            }}
          >
            {showLoading ? (
              <Skeleton width="100%" height="21rem"></Skeleton>
            ) : null}
            <img
              onLoad={(e) => {
                setShowLoading(false);
              }}
              src={authURL}
              className={`dialogImage ${showLoading ? "displayThumb" : ""}`}
              alt={"image2"}
            />
          </Dialog>
        </>
      ) : (
        <>
          {/* <img
          src={brokenImg}
          className="thumbnailBroken"
          alt={"image"}
        /> */}
          -
        </>
      )}{" "}
    </>
  );
};

export default Image;
