import React from "react";
import workMinutesHeroSectionImage from "../../../../assets/workMinutesHeroSectionIcon.svg";
import "./WorkMinutesHeroSection.css";

const WorkMinutesHeroSection = () => {
  return (
    <div className="work-minutes-hero-section-background">
      <div className="max-w-1440 component-padding">
        <p className="work-minutes-hero-section-heading">
          Empower Your Team Collaboration
        </p>
        <p className="work-minutes-hero-section-description">
          WorkMinutes simplifies project management. It aids in standups, retrospectives, and keeping track of achievements and concerns for projects. One may tailor it to as per requirements.
        </p>
        <img
          className="work-minutes-hero-section-image"
          src={workMinutesHeroSectionImage}
          alt="screenshot of a report"
        />
      </div>
    </div>
  );
};

export default WorkMinutesHeroSection;
