import "./SuperAdmin.css";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faUsersGear,
  faCircleCheck,
  faBan,
  faCircleXmark,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import httpService from "../../../services/http.service";
import isEqual from "lodash/isEqual";
import {
  createFilters,
  getErrorMessage,
  createColumns,
  getToastMessage,
  logPageTitleForGA,
} from "../../Helper/Helper";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Chips } from "primereact/chips";
import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Navigate } from "react-router-dom";
import Error404 from "../../Pages/Error 404/Error404";
import { Button } from "primereact/button";
import NoData from "../../UI/NoData/NoData";

const SuperAdmin = () => {
  const role = useSelector((state) => state.auth.userProfile.role);
  const [notAdmin, setNotAdmin] = useState(false);
  const [allCompanies, setAllCompanies] = useState(null);
  const [companyFilter, setCompanyFilter] = useState({});
  const [companyDetailsDialog, setCompanyDetailsDialog] = useState(false);
  const [companyDetailsData, setCompanyDetailsData] = useState(null);
  const [companyAdmins, setCompanyAdmins] = useState(null);
  const [filters, setFilters] = useState({});
  const companyName = useRef("");
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [user, setUser] = useState([]);
  const [sameCompanyUsers, setSameCompanyUsers] = useState([]);
  const [registerCompanyDialog, setRegisterCompanyDialog] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState("");
  const [companyAvailable, setCompanyAvailable] = useState(false);
  const [validCompanyname, setValidCompanyname] = useState(false);
  const [domainDiv, setDomainDiv] = useState(false);
  const [domain, setDomain] = useState([]);
  const [existingDomains, setExistingDomains] = useState([]);
  const [currentDomains, setCurrentDomains] = useState([]);
  const [domainDisabled, setDomainDisabled] = useState(true);
  const [blockedUsers, setBlockedUsers] = useState(null);
  const [blockedFilters, setBlockedFilters] = useState({});
  const [isAddAdminButtonLoading, setIsAddAdminButtonLoading] = useState(false);
  const username = useSelector((state) => state.auth.userProfile.username);
  const [isUpdateDomainBtnLoading, setIsUpdateDomainBtnLoading] =
    useState(false);
  useEffect(() => {
    if (role.toString().toUpperCase() !== "SUPER_ADMIN") {
      setNotAdmin(true);
    }
  }, [role]);

  useEffect(() => {
    let title = "Jewel | Super Admin";
    document.title = title;
    logPageTitleForGA(title);
    getAllCompanies();
  }, []);

  useEffect(() => {
    if (Object.keys(companyFilter).length === 0) {
      const filterUsersData =
        companyAdmins && companyAdmins["headers"]
          ? createFilters(companyAdmins["headers"])
          : {};
      setCompanyFilter(filterUsersData);
    }
  }, [companyAdmins]);

  useEffect(() => {
    if (Object.keys(blockedFilters).length === 0) {
      const filterUsersData =
        blockedUsers && blockedUsers["headers"]
          ? createFilters(blockedUsers["headers"])
          : {};
      setBlockedFilters(filterUsersData);
    }
  }, [blockedUsers]);

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      const filterData =
        allCompanies && allCompanies["headers"]
          ? createFilters(allCompanies["headers"])
          : {};
      setFilters(filterData);
    }
  }, [allCompanies]);

  const skeletonRows = Array.from({ length: 8 });

  const skeletonBodyTemplate = () => {
    return <Skeleton></Skeleton>;
  };

  useEffect(() => {
    if (newCompanyName.length > 0) {
      var timeout = setTimeout(() => {
        httpService.validateCompanyName(newCompanyName).then((data) => {
          setCompanyAvailable(true);
          if (data["isPresent"]) {
            setValidCompanyname(true);
          } else {
            setValidCompanyname(false);
          }
        });
      }, 500);
    } else {
      setCompanyAvailable(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [newCompanyName]);

  const addDomain = (e) => {
    e.preventDefault();

    let payload = {
      domains: existingDomains,
    };
    if (existingDomains.length === 0) {
      toast.error("No Domains entered !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "mt-5",
      });
      return;
    }
    setIsUpdateDomainBtnLoading(true);
    httpService
      .addCompanyDomainSuperAdmin(companyName.current, payload)
      .then(async (data) => {
        getToastMessage(data);

        httpService
          .companyDetailsSuperAdmin(companyName.current)
          .then(async (data) => {
            getErrorMessage(data);
            setCompanyDetailsData(data["data"]);
            setIsUpdateDomainBtnLoading(false);
          })
          .catch((err) => {
            toast.error(
              err["response"]
                ? err["response"]["data"]["message"]
                : `Something Went Wrong. 
            Please refresh the Page / Try Again`,
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: "mt-5",
              }
            );
            setIsUpdateDomainBtnLoading(false);
          });

        setDomainDiv(false);
        setDomainDisabled(true);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
                    Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
        setDomainDisabled(true);
        setIsUpdateDomainBtnLoading(false);
      });
  };

  const domain_action = (val, rowData) => {
    setCurrentDomains(rowData?.Domains?.value);
    return (
      <button
        onClick={() => {
          setDomainDiv(true);
          setDomainDisabled(true);
          setExistingDomains(rowData["Domains"]["value"]);
        }}
        className="btn btn-success themeBtn px-2 py-1 mx-2"
      >
        Edit Domains
      </button>
    );
  };

  const removeAdmin = (company, username) => {
    let temp = [];
    temp.push(username);
    let payload = {
      realCompanyName: company,
      username: temp,
    };

    httpService
      .removeAdmin(payload)
      .then(async (data) => {
        getToastMessage(data);
        getCompanyAdmins(companyName.current);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
            Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };
  const userOptionTemplate = (option) => {
    return (
      <>
        <div>
          <span>{`${option.Name} ( ${option.Username} )`}</span>
        </div>
      </>
    );
  };
  const userCompany_action = (val, data) => {
    return (
      <div>
        <FontAwesomeIcon
          title="Remove Admin"
          icon={faBan}
          onClick={(e) => {
            removeAdmin(companyName.current, data["Username"]["value"]);
          }}
          className="actionIcon fail-color mx-2"
        />
      </div>
    );
  };

  const unblockUser = (val) => {
    const user = [];
    user.push(val);

    let payload = {
      username: user,
    };

    httpService
      .unblockUsers(payload)
      .then(async (data) => {
        getToastMessage(data);
        getCompanyUsers(companyName.current);
        getCompanyAdmins(companyName.current);
        getBlockedUsers(companyName.current);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
            Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  const userBlock_action = (val, data) => {
    return (
      <div>
        <FontAwesomeIcon
          title="Unblock User"
          icon={faUserPlus}
          onClick={(e) => {
            unblockUser(data["Username"]["value"]);
          }}
          className="actionIcon pass-color mx-2"
        />
      </div>
    );
  };
  const companyActions = (val, data) => {
    companyName.current = data["Name"]["value"];

    httpService
      .companyDetailsSuperAdmin(companyName.current)
      .then(async (data) => {
        getErrorMessage(data);
        setCompanyDetailsData(data["data"]);
        setCompanyDetailsDialog(true);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
                Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });

    getCompanyAdmins(companyName.current);
    getCompanyUsers(companyName.current);
    getBlockedUsers(companyName.current);
  };

  const getCompanyAdmins = (val) => {
    httpService
      .companyAdmins(val)
      .then(async (data) => {
        getErrorMessage(data);
        setCompanyAdmins(data["data"]);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
            Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };
  const getCompanyUsers = (val) => {
    httpService
      .companyUsersSuperAdmin(val)
      .then(async (data) => {
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "failure"
        ) {
          getErrorMessage(data);
          return;
        }
        (await data["data"]["data"])
          ? setSameCompanyUsers(data["data"]["data"])
          : setSameCompanyUsers([]);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
        Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };
  const getBlockedUsers = (val) => {
    httpService
      .blockedUsersSuperAdmin(val)
      .then(async (data) => {
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "failure"
        ) {
          getErrorMessage(data);
        }
        setBlockedUsers(await data["data"]);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
            Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };
  const deleteCompany = (data) => {
    httpService
      .blockCompany(data["Name"]["value"])
      .then(async (data) => {
        getToastMessage(data);
        getAllCompanies();
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
            Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  const verifyCompany = (data) => {
    httpService
      .verifyNewCompany(data["Name"]["value"])
      .then(async (data) => {
        getToastMessage(data);
        getAllCompanies();
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
            Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };
  const companyVerifyAction = (val, data) => {
    return (
      <div>
        {val.toString().toUpperCase() === "VERIFIED" ? (
          <div>
            <FontAwesomeIcon
              className="fail-color actionIcon mx-2"
              title="Unverify Company"
              icon={faBan}
              onClick={(e) => deleteCompany(data)}
            />
            {/* <span className='disabledIcon mx-2'>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </span> */}
            <FontAwesomeIcon
              className="actionBtn mx-2"
              title="Company Access"
              onClick={() => {
                companyActions(val, data);
              }}
              icon={faPencil}
            />
          </div>
        ) : (
          <div>
            {/* <FontAwesomeIcon
                            className='disabledIcon mx-2'
                            icon={faCreativeCommonsPd}
                        /> */}
            <FontAwesomeIcon
              className="pass-color actionIcon mx-2"
              title="Verify Company"
              icon={faCircleCheck}
              onClick={(e) => verifyCompany(data)}
            />
            <FontAwesomeIcon
              title="Company Access"
              className="actionBtn mx-2"
              onClick={() => {
                companyActions(val, data);
              }}
              icon={faPencil}
            />
          </div>
        )}
      </div>
    );
  };

  const submitCompany = (e) => {
    e.preventDefault();
    if (
      newCompanyName &&
      newCompanyName.length > 0 &&
      domain &&
      domain.length > 0
    ) {
      let payload = {
        realCompany: newCompanyName.trim(),
        domains: domain,
      };

      httpService
        .createNewCompany(payload)
        .then(async (data) => {
          getToastMessage(data);
          getAllCompanies();
          setRegisterCompanyDialog(false);
          setNewCompanyName("");
          setDomain([]);
          setValidCompanyname(false);
          setCompanyAvailable(false);
        })
        .catch((err) => {
          toast.error(
            err["response"]
              ? err["response"]["data"]["message"]
              : `Something Went Wrong. 
            Please refresh the Page / Try Again`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "mt-5",
            }
          );
        });
    } else {
      toast.error(`Fill out the mandatory fields!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "mt-5",
      });
    }
  };
  const createNewCompany = () => {
    return (
      <div className="mt-2">
        <form onSubmit={(e) => submitCompany(e)} noValidate>
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="ms-2 mb-1 mt-1 pDetails-heading">
                Company Name<sup> *</sup>
              </div>

              <div className="">
                <InputText
                  type={"text"}
                  value={newCompanyName}
                  onChange={(e) => setNewCompanyName(e.target.value)}
                  className="w-100"
                  placeholder={"Company Name"}
                />
                {companyAvailable ? (
                  <div>
                    {validCompanyname ? (
                      <div className="pCheck-not mt-1">
                        <span className="me-1">
                          Company Name Already Exists
                        </span>
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </div>
                    ) : (
                      <div className="pCheck mt-1">
                        <span className="me-1">Company Name Available</span>
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="ms-1 mb-1 mt-1 pDetails-heading">
                Domain(s)<sup> *</sup>
              </div>

              <div className="w-100">
                <Chips
                  value={domain}
                  allowDuplicate={false}
                  onChange={(e) => {
                    setDomain(e.value);
                  }}
                  className="w-100 p-fluid chipsenv"
                />
              </div>
              <div className="ms-1 mb-1 mt-1 pDetails-smheading">
                Note: Press Enter to Add Multiple Domains.
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <button className="btn btn-sm stn-success themeBtn">
              REGISTER
            </button>
          </div>
        </form>
      </div>
    );
  };
  const addNewAdmin = () => {
    if (user.length > 0) {
      let payload = {
        realCompanyName: companyName.current,
        username: user,
      };
      setIsAddAdminButtonLoading(true);
      httpService
        .addAdmin(payload)
        .then(async (data) => {
          if (
            data["operation"] &&
            data["operation"].toLowerCase() === "success" &&
            data["data"]
          ) {
            for (const [key, value] of Object.entries(data["data"])) {
              toast.error(`${key} : ${value}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: "mt-5",
              });
            }
          }
          getToastMessage(data);
          setUser([]);
          getCompanyAdmins(companyName.current);
          setIsAddAdminButtonLoading(false);
        })
        .catch((err) => {
          toast.error(
            err["response"]
              ? err["response"]["data"]["message"]
              : `Something Went Wrong. 
                Please refresh the Page / Try Again`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "mt-5",
            }
          );
          setIsAddAdminButtonLoading(false);
        });
    } else {
      toast.error(`All Fields are required!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const getAllCompanies = () => {
    httpService
      .getAllCompany()
      .then(async (data) => {
        getErrorMessage(data);
        setAllCompanies(data["data"]);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
                Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  return (
    <>
      {notAdmin ? (
        <Error404 />
      ) : (
        <div className="container-fluid superAdminSection mt-4 mx-2">
          <div className="d-flex">
            <div>
              <div className="superHeader">
                <span className="me-1">
                  <FontAwesomeIcon
                    className="fontSizeHeadGeneral superAdmin-icon"
                    icon={faUsersGear}
                  />
                </span>
                <span className="fontSizeHeadGeneral ms-1">Super Admin</span>
              </div>
              <div className="mt-1 fontSizeGeneral">
                <span className="superAdminUsername pb-2">{username}</span>
              </div>
            </div>
            <div className="ms-sm-auto">
              <button
                onClick={(e) => setRegisterCompanyDialog(true)}
                className="btn btn-success themeBtn px-3 py-2 me-2 mt-1"
              >
                Register Company
              </button>
            </div>
          </div>
          <div className="mt-5">
            {allCompanies ? (
              <div>
                {/* <ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedItem(null)} /> */}
                <DataTable
                  reorderableColumns
                  onFilter={(e) => {
                    setFilters(e.filters);
                  }}
                  resizableColumns
                  stripedRows
                  columnResizeMode="expand"
                  value={allCompanies ? allCompanies["data"] : null}
                  paginator
                  rows={25}
                  dataKey="id"
                  filters={filters}
                  rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  scrollHeight={"65vh"}
                  filterDisplay="menu"
                  responsiveLayout="scroll"
                  emptyMessage={<NoData />}
                  currentPageReportTemplate="Total {totalRecords} Company(s) Found"
                  removableSort
                  className="test-stepTable"
                >
                  {createColumns(
                    allCompanies["headers"],
                    true,
                    true,
                    allCompanies["data"],
                    true,
                    null,
                    {
                      customAction: companyVerifyAction,
                    }
                  )}
                </DataTable>
              </div>
            ) : (
              <DataTable
                responsiveLayout="scroll"
                value={skeletonRows}
                className="p-datatable-striped mt-4 test-stepTable"
              >
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "5%" }}
                  body={skeletonBodyTemplate}
                ></Column>
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "25%" }}
                  body={skeletonBodyTemplate}
                ></Column>
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "20%" }}
                  body={skeletonBodyTemplate}
                ></Column>
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "40%" }}
                  body={skeletonBodyTemplate}
                ></Column>

                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "10%" }}
                  body={skeletonBodyTemplate}
                ></Column>
              </DataTable>
            )}
          </div>
          <div>
            <Dialog
              blockScroll={true}
              draggable={false}
              header={`${companyName.current}`}
              visible={companyDetailsDialog}
              onHide={() => {
                setCompanyDetailsDialog(false);
                setUser([]);
                setDomainDiv(false);
                setDomainDisabled(false);
                setExistingDomains([]);
              }}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "65vw" }}
            >
              <ul
                className="nav nav-pills mb-3"
                id={"pills-tab"}
                role="tablist"
              >
                <li className="nav-item mr-1" role="presentation">
                  <div
                    className="nav-link InfoLink active"
                    id={"pills-Info-tab-"}
                    data-toggle="pill"
                    data-target={"#pills-Info"}
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    Info
                  </div>
                </li>
                <li className="nav-item" role="presentation">
                  <div
                    className="nav-link addAdminsLink"
                    id={"pills-addAdmins-tab-"}
                    data-toggle="pill"
                    data-target={"#pills-addAdmins-"}
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Add Admins
                  </div>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id={"pills-Info"}
                  role="tabpanel"
                  aria-labelledby={"pills-Info-tab-"}
                >
                  {companyDetailsData &&
                  companyDetailsData["data"].length > 0 ? (
                    <div>
                      <DataTable
                        reorderableColumns
                        resizableColumns
                        stripedRows
                        columnResizeMode="expand"
                        value={
                          companyDetailsData["data"]
                            ? companyDetailsData["data"]
                            : null
                        }
                        dataKey="id"
                        responsiveLayout="scroll"
                        emptyMessage={<NoData />}
                        removableSort
                        className="test-stepTable"
                      >
                        {createColumns(
                          companyDetailsData["headers"],
                          false,
                          false,
                          companyDetailsData["data"],
                          false,
                          null,
                          {
                            customAction: domain_action,
                          }
                        )}
                      </DataTable>

                      {domainDiv ? (
                        <div className="mt-4 row justify-content-center d-flex">
                          <div className="col-md-10 col-12">
                            <Chips
                              value={existingDomains}
                              allowDuplicate={false}
                              onChange={(e) => {
                                setExistingDomains(e.value);
                                e?.value?.length === 0 ||
                                isEqual(e.value, currentDomains)
                                  ? setDomainDisabled(true)
                                  : setDomainDisabled(false);
                              }}
                              className="w-100 p-fluid chipsenv"
                            />
                            <div className="ms-1 mb-1 mt-1 pDetails-smheading">
                              Note: Press Enter to Add Domains.
                            </div>
                          </div>
                          <div className="col-md-2 col-12 mt-2 text-center align-items-center">
                            <Button
                              label="Update"
                              className="btn-success btn fail-btn mx-1 py-2"
                              onClick={(e) => {
                                addDomain(e);
                              }}
                              loadingIcon={"pi pi-spin pi-spinner me-2"}
                              disabled={domainDisabled}
                              loading={isUpdateDomainBtnLoading}
                              iconPos="right"
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="mt-4">
                        <div className="overHeadAnalytics text-center ms-2">
                          <div className="fontSizeHeadGeneral">Admins</div>
                        </div>
                        <div className="mt-3">
                          {companyAdmins ? (
                            <DataTable
                              reorderableColumns
                              onFilter={(e) => {
                                setCompanyFilter(e.filters);
                              }}
                              resizableColumns
                              stripedRows
                              columnResizeMode="expand"
                              value={
                                companyAdmins ? companyAdmins["data"] : null
                              }
                              paginator
                              rows={25}
                              dataKey="id"
                              filters={companyFilter}
                              rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                              scrollHeight={"65vh"}
                              filterDisplay="menu"
                              responsiveLayout="scroll"
                              emptyMessage={<NoData />}
                              currentPageReportTemplate="Total {totalRecords} User(s) Found"
                              removableSort
                              className="test-stepTable"
                            >
                              {createColumns(
                                companyAdmins["headers"],
                                true,
                                true,
                                companyAdmins["data"],
                                true,
                                null,
                                {
                                  customAction: userCompany_action,
                                }
                              )}
                            </DataTable>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="overHeadAnalytics text-center ms-2">
                          <div className="fontSizeHeadGeneral">
                            Blocked Users
                          </div>
                        </div>
                        <div className="mt-3">
                          {blockedUsers ? (
                            <DataTable
                              reorderableColumns
                              onFilter={(e) => {
                                setBlockedFilters(e.filters);
                              }}
                              resizableColumns
                              stripedRows
                              columnResizeMode="expand"
                              value={blockedUsers ? blockedUsers["data"] : null}
                              paginator
                              rows={25}
                              dataKey="id"
                              filters={blockedFilters}
                              rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                              scrollHeight={"65vh"}
                              filterDisplay="menu"
                              responsiveLayout="scroll"
                              emptyMessage={<NoData />}
                              currentPageReportTemplate="Total {totalRecords} User(s) Found"
                              removableSort
                              className="test-stepTable"
                            >
                              {createColumns(
                                blockedUsers["headers"],
                                true,
                                true,
                                blockedUsers["data"],
                                true,
                                null,
                                {
                                  customAction: userBlock_action,
                                }
                              )}
                            </DataTable>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  className="tab-pane fade"
                  id={"pills-addAdmins-"}
                  role="tabpanel"
                  aria-labelledby={"pills-addAdmins-tab-"}
                >
                  <div className="mt-4 mx-2">
                    {sameCompanyUsers.length > 0 ? (
                      <MultiSelect
                        emptyMessage={"No Active User(s) in your Company"}
                        value={user}
                        emptyFilterMessage={"No User(s) found"}
                        style={{ minWidth: "22vw", maxWidth: "22vw" }}
                        options={sameCompanyUsers}
                        onChange={(e) => {
                          setUser(e.value);
                          setSelectAllUsers(
                            e.value.length === sameCompanyUsers.length
                          );
                        }}
                        selectAll={selectAllUsers}
                        onSelectAll={(e) => {
                          setUser(
                            e.checked
                              ? []
                              : sameCompanyUsers.map((user) => user.Username)
                          );
                          setSelectAllUsers(!e.checked);
                        }}
                        virtualScrollerOptions={{ itemSize: 50 }}
                        optionLabel="Name"
                        optionValue="Username"
                        placeholder="Select User(s) to Add"
                        filter
                        maxSelectedLabels={1}
                        className="multiselect-custom w-100"
                        itemTemplate={userOptionTemplate}
                      />
                    ) : (
                      <Dropdown
                        className="dialog-dropdown"
                        style={{ minWidth: "22vw", maxWidth: "22vw" }}
                        options={[]}
                        emptyMessage="No Active User(s) in your Company"
                        placeholder="Select User(s) to Add"
                      />
                    )}
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-success themeBtn ms-2"
                      onClick={() => addNewAdmin()}
                    >
                      Add Admin
                    </button>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
          <Dialog
            blockScroll={true}
            draggable={false}
            header={"Register New Company"}
            visible={registerCompanyDialog}
            onHide={() => {
              setRegisterCompanyDialog(false);
              setNewCompanyName("");
              setDomain([]);
              setValidCompanyname(false);
              setCompanyAvailable(false);
            }}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
          >
            {createNewCompany()}
          </Dialog>
        </div>
      )}
    </>
  );
};

export default SuperAdmin;
