import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_USER_AVATAR
} from "../actions/type.js";

const user = localStorage.getItem("usertoken");
const userProfile = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { isLoggedIn: true, user, userProfile }
  : { isLoggedIn: false, user: null, userProfile: null};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        userProfile: payload.userProfile
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        userProfile: null
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        userProfile: null
      };
      case SET_USER_AVATAR:
        return {
          ...state,
          userProfile: {
            ...state.userProfile,
            avatar: action.payload,
          },
        };

    default:
      return state;
  }
}
