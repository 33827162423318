import Auth from "../../Pages/AuthPage/Auth";
import "./ForgotPass.css";
import passwordIcon from "../../../assets/passwordIcon.svg";
import { useRef, useState } from "react";
import userIcon from "../../../assets/userIcon.svg";

import { Password } from "primereact/password";
import md5Hex from "md5-hex";
import { toast } from "react-toastify";
import authService from "../../../services/auth.service";
import { Tooltip } from "primereact/tooltip";
import { getErrorMessage, getToastMessage } from "../../Helper/Helper";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

const ForgotPass = (props) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState(null);
  const conpassword = useRef("");
  const user = useRef("");
  const otp = useRef("");
  const [showOtp, setShowOtp] = useState(false);
  const [strengthCheckValue, setStrengthCheckValue] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const confirmOtpandChangePassword = () => {
    let payload = {
      username: user.current,
      otp: otp.current,
      newPassword: md5Hex(password),
      confirmPassword: md5Hex(conpassword.current),
    };
    authService.forgotPass(payload).then((data) => {
      getToastMessage(data);
      if (data["operation"] && data["operation"].toLowerCase() === "success") {
        navigate("/login");
      }
    });
  };
  const forgotPass = (e) => {
    e.preventDefault();
    if (!(conpassword.current && password && user.current)) {
      toast.error("All fields are required !", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    if (password !== conpassword.current) {
      toast.error("Password and Confirm Password should be a Perfect match", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    if (feedback === null) {
      toast.error("Password too weak ! ", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    let payload = {
      username: user.current,
    };
    authService
      .getOTP(payload)
      .then((data) => {
        getToastMessage(data);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          setShowOtp(true);
        } else {
          setShowOtp(false);
        }
      })
      .catch((err) => {
        setShowOtp(false);
      });
  };

  const forgotPswdHeader = (
    <>
      {/* <h6> */}
      {/* Pick a password */}
      <span>
        <Tooltip target=".custom-target-icon" className="signupTooltip">
          <>
            <p className="">Suggestions</p>
            <ul className="mt-0" style={{ lineHeight: "1.5" }}>
              <li>At least one lowercase</li>
              <li>At least one uppercase</li>
              <li>At least one numeric</li>
              <li>Minimum 8 characters</li>
              <li>At least one special character</li>
            </ul>
          </>
        </Tooltip>
        <FontAwesomeIcon
          icon={faCircleInfo}
          className="infoicon fa-sm ms-2 custom-target-icon"
          // data-pr-tooltip={footer}
          data-pr-position="right"
          data-pr-at="right+2 top"
        />
      </span>
      {/* </h6> */}
    </>
  );

  const strengthCheck = (password) => {
    const mediumRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");

    if (strongRegex.test(password)) {
      setFeedback('Strong');
    } else if (mediumRegex.test(password)) {
      setFeedback('Medium');
    } else {
      setFeedback(null);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    strengthCheck(e.target.value);
  };
  return (
    <>
      <Auth>
        <div className=" card customSignup">
          <div className="card-body py-5 px-lg-5 px-md-5 px-sm-4 px-xs-2">
            <div className="text-center">
              <div className="titleSignup">Trouble logging in?</div>
              <br />
              <div className="acc wrapHead">
                Enter your Email/Username, Password and we'll send you an OTP to
                reset your account password.
              </div>
              <br />
              <div id="warningCredentials"></div>
            </div>
            <form className="mt-1 formSignup" onSubmit={(e) => forgotPass(e)}>
              <div className="mt-1 inputfield">
                <img src={userIcon} />
                <input
                  type="text"
                  className="form-control"
                  id="usernameField"
                  onChange={(e) => (user.current = e.target.value)}
                  placeholder="Username"
                  disabled={showOtp}
                />
              </div>
              <div className="w-100 mt-2 inputfield">
                <img src={passwordIcon} />
                <Password
                  onChange={(e) => handlePasswordChange(e)}
                  placeholder="Password"
                  toggleMask
                  header={forgotPswdHeader}
                  feedback={false}
                  disabled={showOtp}
                  className="w-100 passField"
                />
                {password && (
                  <div>
                    {/* <span>{feedback ? feedback : "Weak"}</span> */}
                    <div className="strength-meter">
                      <div className={`strength-meter-fill strength-${feedback}`} />
                    </div>
                    <p style={{ fontWeight: 'bold' }}>Password Strength: {feedback ? feedback : "weak"}{forgotPswdHeader}</p>
                  </div>)}
              </div>
              <div className="w-100 mt-2 inputfield">
                <img src={passwordIcon} />
                <Password
                  onChange={(e) => (conpassword.current = e.target.value)}
                  placeholder="Confirm Password"
                  toggleMask
                  feedback={false}
                  disabled={showOtp}
                  className="w-100 passField"
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  disabled={showOtp}
                  className="mt-3 btn btn-primary text-center px-5"
                >
                  Reset Password
                </button>
              </div>
            </form>

            {showOtp ? (
              <>
                {" "}
                <div className="d-flex mt-3 justify-content-around ">
                  <div className="me-2 ">
                    <Password
                      onChange={(e) => (otp.current = e.target.value)}
                      placeholder="One Time Password"
                      toggleMask
                      keyfilter={"num"}
                      feedback={false}
                      className="passField "
                    />
                  </div>
                  <div
                    className=" btn btn-primary text-center"
                    onClick={() => confirmOtpandChangePassword()}
                  >
                    Confirm
                  </div>
                </div>
              </>
            ) : null}
            <hr className="mb-2" />
            <div className="text-center forgotPass">
              <Link to="/login">
                <b>Back to Login</b>
              </Link>
            </div>
          </div>
        </div>
      </Auth>
    </>
  );
};

export default ForgotPass;
