import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Extent from "./Extent";
import { useSelector } from "react-redux";
import { logPageTitleForGA } from "../../Helper/Helper";

const FullExtent = () => {
  const [id, setId] = useState(null);
  const [p_id, setP_id] = useState(null);
 
  const [filters, setFilters] = useState(null);

  const [searchParams] = useSearchParams();
  const role = useSelector((state) => state.auth.userProfile.role);
  const firstName = useSelector((state) => state.auth.userProfile.firstName);
  const lastName = useSelector((state) => state.auth.userProfile.lastName);
  const imgUrl= useSelector((state)=>state.auth.userProfile.avatar);
  const username= useSelector((state)=>state.auth.userProfile.username);

  const userDetail={
    firstName,
    lastName,
    imgUrl,
    username
  };

  useEffect(() => {
    setId(searchParams?.get("s_run_id"));
    setFilters(searchParams?.get("filters"));
    setP_id(searchParams?.get("p_id"));


    let title =
      "Jewel | Suite Run Report : " + searchParams?.get("s_run_id");
    document.title = title;
    logPageTitleForGA(title);
  }, [id]);

  return (
    <>
      {/* <div className="container"> */}
      {id ? (
        <div className=" pt-2">
          {" "}
          <Extent
            showIcon={false}
            id="Extended-Tab"
            s_run_id={id}
            filters={filters}
            p_id={p_id}
            role={role}
            userDetail={userDetail}
          />{" "}
        </div>
      ) : null}
      {/* </div> */}
    </>
  );
};

export default FullExtent;
