import React, { useEffect, useState } from "react";
import "./index.css";
import { convertEpochtoDate } from "../../../Helper/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronDown,
  faChevronRight,
  faCircleCheck,
  faPen,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import commentsService from "../../../../services/Comments/comments.service";
import { handleToastErrorCatch } from "../../../Helper/ToastHelper";
import { faEdit, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import Reactions from "./Reactions";
import Emojis from "./Emojis";
import Reply from "./Reply";
import { CommentSkeleton } from "./CommentSkeleton";
import { useSelector } from "react-redux";

const Comment = ({
  socketId,
  socketMessage,
  limit,
  id,
  comment,
  props,
  fullname,
  setSocketId,
  setSocketMessage,
}) => {
  const [isSetReplyActive, setIsSetReplyActive] = useState(false);
  const [commentReplyBody, setCommentReplyBody] = useState("");
  const [commentReplyBtnLoading, setCommentReplyBtnLoading] = useState(false);
  const [commentReplyOpenLoading, setCommentReplyOpenLoading] = useState(false);
  const [commentEditLoading, setCommentEditLoading] = useState(false);
  const [commentDeleteLoading, setCommentDeleteLoading] = useState(false);
  const [showActions, setShowActions] = useState(true);
  const [commentEdit, setCommentEdit] = useState(false);
  const [isReacted, setIsReacted] = useState(false);
  const [currentEmojiId, setCurrentEmojiId] = useState(null);
  const [showReplyComments, setShowReplyComments] = useState(false);
  const [replyComments, setReplyComments] = useState(null);
  const [currentCommentId, setCurrentCommentId] = useState(null);
  const [replyCount, setReplyCount] = useState(null);

 

  const postReplyComment = (id, text) => {
    setCommentReplyBtnLoading(true);

    let payload = {
      commentBody: text,
      commentParentId: id,
      srunId: props.s_run_id,
      pid: props.p_id,
      createdByName: fullname,
    };

    if(props.userDetail.imgUrl){
      payload.profileUrl=props.userDetail.imgUrl
    }

    commentsService
      .createComment(payload)
      .then((data) => {
        setCommentReplyBody("");
        setIsSetReplyActive(false);
        setCommentReplyBtnLoading(false);
      })
      .catch((err) =>
        handleToastErrorCatch(err, () => {
          setCommentReplyBody("");
          setCommentReplyBtnLoading(false);
          setIsSetReplyActive(false);
        })
      );
  };

  const editComment = (id, text) => {
    setCommentEditLoading(true);
    let payload = {
      commentBody: text,
      commentId: id,
      srunId: props.s_run_id,
      pid: props.p_id,
      createdByName: fullname,
    };

    commentsService
      .editComment(payload)
      .then((data) => {
        setCommentReplyBody("");
        setCommentEdit(false);
        setCommentEditLoading(false);
      })
      .catch((err) =>
        handleToastErrorCatch(err, () => {
          setCommentEdit(false);
          setIsSetReplyActive(false);
          setCommentEditLoading(false);
        })
      );
  };

  const getReplyCommentsCount = () => {
    let payload = {
      s_run_id: props.s_run_id,
      p_id: props.p_id,
    };

    commentsService
      .getAllComments(payload)
      .then((data) => {
        let obj = data?.filter(
          (item) => item?.commentId === comment?.commentId
        );

        setReplyCount(obj[0]?.childCommentsCount);
      })
      .catch((err) => handleToastErrorCatch(err));
  };

  const getReplyComments = (id) => {
    setCommentReplyOpenLoading(true);
    let payload = {
      s_run_id: props.s_run_id,
      p_id: props.p_id,
      parentId: id,
    };

    commentsService
      .getReplyComments(payload)
      .then((data) => {
        setReplyComments(data);
        getReplyCommentsCount();
        setShowReplyComments(true);
        setSocketId(null);
        setSocketMessage(null);
        setCommentReplyOpenLoading(false);
      })
      .catch((err) =>
        handleToastErrorCatch(err, () => setCommentReplyOpenLoading(false))
      );
  };

  const deleteComment = (id) => {
    setCommentDeleteLoading(true);
    commentsService
      .deleteComment(id)
      .then((data) => {
        getReplyCommentsCount();
        setCommentDeleteLoading(false);
      })
      .catch((err) =>
        handleToastErrorCatch(err, () => setCommentDeleteLoading(false))
      );
  };

  const removeEmoji = (emojiId, id) => {
    let payload = {
      commentId: id,
      fullName: fullname,
      reaction: emojiId,
    };

    commentsService
      .unReactComment(payload)
      .then((data) => {
        setIsReacted(false);
        setCurrentEmojiId(null);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  useEffect(() => {
    if (
      (socketMessage === "comment updated" ||
        socketMessage === "reaction updated") &&
      socketId === comment?.commentId
    ) {
      getReplyComments(socketId);
    }
  }, [socketId, socketMessage, comment?.commentId]);

  useEffect(() => {
    if (currentCommentId) {
      getReplyComments(currentCommentId);
    }
  }, [currentCommentId]);

  
  

  return (
    <div key={comment?.commentId}>
      <div>
        <div className="d-flex align-items-center">
          <span>
            
            <img
              src={comment?.profileUrl?`${comment?.profileUrl}&token=${localStorage.getItem("usertoken")}`:`https://api.dicebear.com/7.x/initials/svg?seed=${comment?.createdByName}`}
              className="userCommentAvatar rounded-circle mx-2"
            />
          </span>
          <span className="userFullName">{comment?.createdByName}</span>
          <span className="mx-2 commentDate">
            {convertEpochtoDate(comment?.createdAt)}
          </span>
        </div>
        <div className="my-3 mx-5">
          <div className="comment-container">
            {!isSetReplyActive && (
              <div
                className={`reaction-picker d-flex align-items-center ${
                  showActions ? "reactions-container" : ""
                }`}
              >
                <Reactions
                  parentId={comment?.commentParentId}
                  socketId={socketId}
                  socketMessage={socketMessage}
                  setIsReacted={setIsReacted}
                  setCurrentEmojiId={setCurrentEmojiId}
                  id={comment?.commentId}
                  fullname={fullname}
                  setShowActions={setShowActions}
                  props={props}
                />
                {comment?.createdBy === props.userDetail.username && showActions && (
                  <>
                    <p className="mx-2 my-0 fs-5">|</p>
                    <div className="d-flex ms-auto">
                      {!commentEditLoading ? (
                        <FontAwesomeIcon
                          className="pointer mx-2"
                          onClick={() => {
                            if (commentEdit) {
                              editComment(comment?.commentId, commentReplyBody);
                            } else {
                              setCommentEdit(true);
                              setCommentReplyBody(comment?.commentBody);
                            }
                          }}
                          icon={commentEdit ? faCircleCheck : faPen}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="fa-spin mx-2 "
                          icon={faSpinner}
                        />
                      )}
                      {commentDeleteLoading ? (
                        <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                      ) : (
                        <FontAwesomeIcon
                          className="pointer"
                          onClick={() => deleteComment(comment?.commentId)}
                          icon={faTrashCan}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
            <InputTextarea
              className="w-100 px-4 commentInput inputBorder"
              readOnly={!commentEdit}
              value={commentEdit ? commentReplyBody : comment?.commentBody}
              onChange={(e) => setCommentReplyBody(e.target.value)}
              rows={3}
              
            />
          </div>

          <div
            className={`${
              isSetReplyActive || commentEdit ? "d-none" : ""
            } d-flex`}
          >
            <Emojis
              parentId={comment?.commentParentId}
              key={comment?.commentId}
              commentId={comment?.commentId}
              removeEmoji={removeEmoji}
              reactionData={comment?.reactions}
              isReacted={isReacted}
              setIsReacted={setIsReacted}
              setCurrentEmojiId={setCurrentEmojiId}
              currentEmojiId={currentEmojiId}
              props={props}
            />

            <p
              className="replyAction pointer"
              onClick={() => setIsSetReplyActive(true)}
            >
              Reply
            </p>
            {(replyCount?replyCount>0:comment?.childCommentsCount>0) && (
              <>
                <p className="mx-2">|</p>
                {!commentReplyOpenLoading ? (
                  <p
                    onClick={() => {
                      if (showReplyComments) {
                        setShowReplyComments(false);
                        setCurrentCommentId(null);
                        setReplyComments(null);
                      } else {
                        setCurrentCommentId(comment?.commentId);
                      }
                    }}
                    className="replyCount pointer"
                  >
                    {replyCount ? replyCount : comment?.childCommentsCount}{" "}
                    replies
                    <FontAwesomeIcon
                      className="ms-2"
                      icon={showReplyComments ? faChevronDown : faChevronRight}
                    />
                  </p>
                ) : (
                  <p className="replyCount pointer">
                    {replyCount ? replyCount : comment?.childCommentsCount}{" "}
                    replies
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="ms-2 fa-spin"
                    />
                  </p>
                )}
              </>
            )}
            {/* <Reactions/> */}
          </div>

          {isSetReplyActive && (
            <div className="w-75">
              <InputTextarea
                className="w-100 commentInput inputBorder"
                value={commentReplyBody}
                placeholder="Add your comment here ..."
                onChange={(e) => setCommentReplyBody(e.target.value)}
                rows={3}
                autoResize
              />
              <div className="commentActionBtn d-flex justify-content-end">
                <Button
                  label="Cancel"
                  disabled={commentReplyBtnLoading}
                  type="button"
                  className="btn-success btn btn-sm commentBtn mx-2 mt-1 me-2 p-ThemeBtn"
                  onClick={() => {
                    setCommentReplyBody("");
                    setIsSetReplyActive(false);
                  }}
                />
                <Button
                  label="Post"
                  disabled={!commentReplyBody}
                  type="button"
                  icon={commentReplyBtnLoading ? "pi pi-spin pi-spinner me-2" : ""}
                  loading={commentReplyBtnLoading}
                  iconPos="left"
                  className="btn-success btn btn-sm commentBtn mx-2 mt-1 me-2 p-ThemeBtn"
                  onClick={() => {
                    postReplyComment(comment?.commentId, commentReplyBody);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {showReplyComments &&
        !commentEdit &&
        !isSetReplyActive &&
        (replyComments ? (
          <div key={comment?.commentId}>
            <Reply
              socketId={socketId}
              socketMessage={socketMessage}
              fullname={fullname}
              replyComment={replyComments}
              props={props}
              id={comment?.commentId}
              key={comment?.commentId}
              removeEmoji={removeEmoji}
              editComment={editComment}
              deleteComment={deleteComment}
            />
          </div>
        ) : (
          <CommentSkeleton />
        ))}
    </div>
  );
};

export default Comment;
