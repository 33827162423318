import "./Reporting.css";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useRef, useState } from "react";
import httpService from "../../../services/http.service";
import { Calendar } from "primereact/calendar";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareArrowUpRight,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import {
  convertToMMDDYYYY,
  createColumns,
  copyToClipboard,
  encodeParam,
  decodeParam,
  isValidJSON,
  createFilters,
  getErrorMessage,
} from "../../Helper/Helper";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isJwtExpired } from "jwt-check-expiration";
import { CLOSE_PROMPT, OPEN_PROMPT } from "../../../actions/type";
import AutoRefresh from "../../UI/AutoRefresh/AutoRefresh";
import {
  handleToastErrorCatch,
  statementError,
} from "../../Helper/ToastHelper";
import NoData from "../../UI/NoData/NoData";
import SecurityMetricsReport from "./SecurityMetricsReport";
import NewReportTemplate from "./NewReportTemplate";
import ShareReportDialog from "./NewReportTemplate/ShareReportDialog";
import SkeletonUtility from "../../UI/Skeleton/SkeletonUtility";
const Reporting = (props) => {
  const [showCreateReport, setShowCreateReport] = useState(false);
  const [security, setSecurity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedReport, setSelectedReport] = useState("");
  const [selectedEnv, setSelectedEnv] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [isShareLoading, setIsshareLoading] = useState(false);
  const [envData, setEnvData] = useState([]);
  const [selectedDateOption, setSelectedDateOption] = useState("");
  const [customDate, setCustomDate] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [showCustom, setShowCustom] = useState(false);
  const [edit,setEdit]=useState(false)
  const [createReportData, setCreateReportData] = useState([]);
  const [dataTableHeaderName,setDataTableHeaderName]=useState("");
  const [searchParams] = useSearchParams();
  const sharedParams = useRef("");
  const currentCardAction = useRef(null);
  const toSharePayload = useRef("");
  const navigate = useNavigate();
  const tableref = useRef();
  const ruleapipayload = useRef(null);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (props?.payload) {
      generateReport(null, props?.payload);

      const report = props?.reportData?.filter(
        (item) => item?.reportId === props.payload?.reportid
      );

      setSelectedReport(report?.[0]);
      props.editName(report?.[0]?.reportName);
      setDataTableHeaderName(report?.[0]?.reportName)
      setSelectedProject(props?.projectsDetails);
      setSelectedType(props?.payload?.testcaseType?.[0]);
      setSelectedClassification(props?.payload?.testcaseClassification?.[0]);
    }
  }, [props?.payload]);

  useEffect(() => {
    setReportData(props.reportData);
  }, [props.reportData]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const filterData = createReportData?.["data"]?.["headers"]
      ? createFilters(createReportData["data"]["headers"])
      : {};
    searchParams.get("filters") && props.shared
      ? setFilters(JSON.parse(decodeParam(searchParams.get("filters"))))
      : setFilters(filterData);
  }, [createReportData]);

  useEffect(() => {
    setProjectData(props.projectData);
  }, [props.projectData]);

  useEffect(() => {
    let dataEnv = [];

    // Extract environments and normalize them
    selectedProject.forEach((project) => {
      dataEnv = dataEnv.concat(project["env"].map((env) => env.toLowerCase()));
    });

    // Remove duplicates and format the environments
    const uniqueEnvs = Array.from(new Set(dataEnv));
    const formattedEnvs = uniqueEnvs.map((env) => ({
      label: env.charAt(0).toUpperCase() + env.slice(1),
      value: env,
    }));

    setEnvData(formattedEnvs);
  }, [selectedProject]);


  useEffect(() => {
    if (!(envData && envData.length > 0)) {
      setSelectedEnv([]);
    }
    
    const validValues = new Set(envData?.map(env => env?.value));
    const newSelectedEnv = selectedEnv?.filter(env => validValues?.has(env));
    setSelectedEnv(newSelectedEnv);
    

    if (props?.payload?.env) {
      setSelectedEnv(props?.payload?.env);
    }
  }, [envData]);

  useEffect(() => {
  
    if (props.shared) {
      sharedParams.current = searchParams.get("rule")
        ? decodeParam(searchParams.get("rule"))
            .replaceAll("%22", '"')
            .replaceAll("%20", " ")
        : null;

      const report =
        sharedParams.current && isValidJSON(sharedParams.current)
          ? generateReport(null, JSON.parse(sharedParams.current))
          : (null, (sharedParams.current = null));

      if (sharedParams.current == null) {
        statementError(
          "Maybe the report is corrupted. Ask Owner to reshare the Report"
        );
        navigate("/autolytics", { replace: true }); //dom
      }
    }
  }, []);

  if (document.getElementsByClassName("p-datatable").length > 0) {
    props.dispatch({
      type: OPEN_PROMPT,
    });
  } else {
    props.dispatch({
      type: CLOSE_PROMPT,
    });
  }

  const resetReportDetails = () => {
    setCustomDate("");
    setDateRange("");
    setSelectedDateOption("");
    setSelectedEnv([]);
    setSelectedProject([]);
    setSelectedReport("");
    setSelectedClassification(null);
    setSelectedType(null);
  };

  const generateReport = (e, sharedPayload) => {
    const eve = e ? e.preventDefault() : null;
    let selectedProjects = [];
    let selectedPids = [];
    let startDate = "";
    let endDate = "";
    if (
      (selectedProject &&
        selectedProject?.length > 0 &&
        selectedEnv &&
        selectedEnv?.length > 0 &&
        selectedReport &&
        (selectedReport?.reportName?.toLowerCase() === "testcase list report" ||
          customDate)) ||
      sharedPayload
    ) {
      selectedProject.forEach((project) => {
        selectedProjects.push(project["projectName"]);
        selectedPids.push(project["pid"]);
      });
      startDate = customDate.split("-")[0];
      endDate = customDate.split("-")[1];

      if (sharedPayload) {
        var payload = sharedPayload;
      } else if (
        selectedReport?.reportName?.toLowerCase() === "security metrics report"
      ) {
        setSecurity(true);
        var payload = {
          reportName: selectedReport["reportName"],
          reportid: selectedReport["reportId"],
          env: selectedEnv,
          project: selectedProjects,
          startTime: startDate,
          endTime: endDate,
          projectid: selectedPids,
        };
      } else if (
        selectedReport?.reportName?.toLowerCase() === "testcase list report"
      ) {
        setSecurity(false);

        var payload = {
          reportid: selectedReport["reportId"],
          env: selectedEnv,
          project: selectedProjects,
          projectid: selectedPids,
        };

        if (selectedType) {
          const type = [selectedType?.toUpperCase()];
          payload.testcaseType = type;
        }
        if (selectedClassification) {
          const classification = [selectedClassification?.toUpperCase()];
          payload.testcaseClassification = classification;
        }
      } else {
        setSecurity(false);
        var payload = {
          reportName: selectedReport["reportName"],
          reportid: selectedReport["reportId"],
          env: selectedEnv,
          project: selectedProjects,
          startTime: startDate,
          endTime: endDate,
          projectid: selectedPids,
        };
      }
      ruleapipayload.current = payload;

      //change
      setShowCreateReport(false);
      setLoading(true);
      httpService
        .getRuleApiData(payload)
        .then((data) => {
          
          getErrorMessage(data);
          tableref.current = undefined;
          toSharePayload.current = payload;
          let reportDataNew = data;
          setCreateReportData(reportDataNew);
          if(payload?.reportName){
            props.editName(payload?.reportName,props.id);
            setDataTableHeaderName(payload?.reportName);
          }
          else if(payload?.reportid===9){
            props.editName("Testcase List Report",props.id);
            setDataTableHeaderName("Testcase List Report");
          }

          setLoading(false);
          if (data?.operation?.toLowerCase() === "failure") {
            // resetReportDetails();
            setShowCreateReport(true);
            props.editName("New Report",props.id)
          }
          const resetValues = tableref.current ? (
            <>
              {" "}
              {tableref.current.reset()} {tableref.current.resetColumnOrder()}
            </>
          ) : null;
        })
        .catch((err) => {
          setCreateReportData([]);
          props.editName("New Report",props.id)
          resetReportDetails();
          const resetValues = tableref.current ? (
            <>
              {" "}
              {tableref.current.reset()} {tableref.current.resetColumnOrder()}
            </>
          ) : null;
          const shared =
            props.shared && !isJwtExpired(localStorage.getItem("usertoken"))
              ? (statementError(
                  "Maybe the report is corrupted. Ask Owner to reshare the Report"
                ),
                handleToastErrorCatch(err),
                navigate("/autolytics", { replace: true }))
              : handleToastErrorCatch(err);
          setLoading(false);
        });
    } else {
      statementError("All fields are required !");
    }
  };

  const selectDateRange = (val) => {
    setSelectedDateOption(val);

    if (val === "Custom") {
      setShowCustom(true);
      setCustomDate("");
    } else {
      setShowCustom(false);
      setDateRange("");
      setCustomDate("");
    }
    if (val === "Last 24 hours") {
      let startDate = convertToMMDDYYYY(
        new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
      );
      let endDate = convertToMMDDYYYY(new Date());
      setCustomDate(startDate + "-" + endDate);
    } else if (val === "Last 7 days") {
      let startDate = convertToMMDDYYYY(
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
      );
      let endDate = convertToMMDDYYYY(new Date());
      setCustomDate(startDate + "-" + endDate);
    } else if (val === "Last 30 days") {
      let startDate = convertToMMDDYYYY(
        new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
      );
      let endDate = convertToMMDDYYYY(new Date());
      setCustomDate(startDate + "-" + endDate);
    } else if (val === "This Year") {
      let startDate = "1/1/" + new Date().getFullYear();

      let endDate = convertToMMDDYYYY(new Date());
      setCustomDate(startDate + "-" + endDate);
    }
  };

  const createDateRange = (val) => {
    let startDate = "";
    let endDate = "";
    if (val[0]) {
      startDate = convertToMMDDYYYY(val[0]);
    }
    if (val[1]) {
      endDate = convertToMMDDYYYY(val[1]);
    }
    if (endDate === "") {
      endDate = startDate;
    }
    setDateRange(val);
    setCustomDate(startDate + "-" + endDate);
  };

  const TestcaseListInstance = ({ props }) => {
    return (
      <>
        <div
          className="instanceListText"
          onClick={() => {
            window.open(
              window.location.origin +
              `/#/testpad/${props?.rowData?.["Testcase Type"]?.value?.toLowerCase()==="automation"?"ALAB?tc_id=":"MLAB?mtc_id="}${props?.rowData?.testcaseId?.value}`,
              "_blank"
            )}}
        >
          {props?.val}
        </div>
      </>
    );
  };

  return (
    <div key={props?.id}>
      <div className="">
        {loading ? (
          <SkeletonUtility
            header={true}
            headerValue={
              <>
                <div className="headingFont">
                  {/* {props.shared
                    ? JSON.parse(sharedParams.current)["reportName"]
                      ? JSON.parse(sharedParams.current)["reportName"]
                      : "Shared Report"
                    : selectedReport["reportName"]} */}
                  {searchParams?.get("reportName") ||
                    (props.shared &&
                      JSON.parse(sharedParams.current)["reportName"]) ||
                    (props.shared
                      ? "Shared Report"
                      : dataTableHeaderName)}
                </div>
              </>
            }
            paginator={false}
            totalRows={8}
          />
        ) : (
          <div id={`dataTable-${props?.id}`} key={props?.id}>
            {createReportData["data"] && createReportData["data"]["data"] ? (
              <div key={props?.id}>
                <DataTable
                  reorderableColumns
                  resizableColumns
                  ref={tableref}
                  scrollHeight={props.shared ? "67vh" : "60vh"}
                  cellClassName="dataTable"
                  currentPageReportTemplate="Total {totalRecords} Records Found"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
                  rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                  stripedRows
                  header={
                    <>
                      <div className="d-flex flex-wrap justify-content-between">
                        <div className="ms-2">
                          <div className="d-flex flex-wrap">
                            <div className="d-flex flex-wrap ">
                              <label title="Copy Report Link">
                                <div
                                  className="btn-sm btn-success me-2 reportIcons"
                                  onClick={() => {
                                    copyToClipboard(
                                      window.location.origin +
                                        "/#/autolytics/shared-report?rule=" +
                                        encodeParam(
                                          JSON.stringify(toSharePayload.current)
                                        ) +
                                        "&filters=" +
                                        encodeParam(JSON.stringify(filters))
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="mx-2 tabIcon"
                                    icon={faCopy}
                                  />
                                </div>
                              </label>
                              <label title="Open in New Window">
                                <div
                                  className="btn-sm btn-success me-2 reportIcons"
                                  onClick={() => {
                                    window.open(
                                      window.location.origin +
                                        "/#/autolytics/shared-report?rule=" +
                                        encodeParam(
                                          JSON.stringify(toSharePayload.current)
                                        ) +
                                        "&filters=" +
                                        encodeParam(JSON.stringify(filters)),
                                      "_blank"
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="mx-2 tabIcon"
                                    icon={faSquareArrowUpRight}
                                  />
                                </div>
                              </label>

                              <AutoRefresh
                                shared={props.shared}
                                id={props.id ? props.id : null}
                                className="px-2 py-1 btn-sm "
                                refreshData={(type) => {
                                  if (type === "manual") {
                                    setLoading("true");
                                  }
                                  httpService
                                    .getRuleApiData(ruleapipayload.current)
                                    .then((data) => {
                                      getErrorMessage(data);
                                      toSharePayload.current =
                                        ruleapipayload.current;

                                      let reportData = data;

                                      setCreateReportData(reportData);
                                      if (type === "manual") {
                                        setLoading(false);
                                      }
                                      setShowCreateReport(false);
                                      if (
                                        !reportData["data"] ||
                                        reportData["data"].length == 0
                                      ) {
                                        statementError(reportData["message"]);
                                      }

                                      const resetValues = tableref.current ? (
                                        <>
                                          {" "}
                                          {tableref.current.reset()}{" "}
                                          {tableref.current.resetColumnOrder()}
                                        </>
                                      ) : null;
                                    })
                                    .catch((err) => {
                                      const resetValues = tableref.current ? (
                                        <>
                                          {" "}
                                          {tableref.current.reset()}{" "}
                                          {tableref.current.resetColumnOrder()}
                                        </>
                                      ) : null;

                                      const shared =
                                        props.shared &&
                                        !isJwtExpired(
                                          localStorage.getItem("usertoken")
                                        )
                                          ? statementError(
                                              `Maybe the report is corrupted. Ask Owner to reshare the Report`
                                            )
                                          : handleToastErrorCatch(err);
                                    });
                                }}
                              />
                              <ShareReportDialog
                                setIsShareLoading={setIsshareLoading}
                                currentCardAction={currentCardAction}
                                reportPayload={ruleapipayload.current}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="headingFont mt-1">
                          {/* {props.shared ? (
                            JSON.parse(sharedParams.current)["reportName"] ? (
                              JSON.parse(sharedParams.current)["reportName"]
                            ) : (
                              "Shared Report"
                            )
                          ) : (
                            <div className="mt-1">
                              {selectedReport["reportName"]}
                            </div>
                          )} */}
                          {searchParams?.get("reportName") ||
                            (props.shared &&
                              JSON.parse(sharedParams.current)["reportName"]) ||
                            (props.shared
                              ? "Shared Report"
                              : dataTableHeaderName)}
                        </div>
                        <div>
                          {!props.shared ? (
                            <div className="ms-sm-auto ">
                              <div
                                className="ms-sm-3 btn-success btn themeBtn"
                                onClick={() => {
                                  setShowCreateReport(true);
                                  setEdit(true);
                                }}
                              >
                                Edit Report
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  }
                  columnResizeMode="expand"
                  removableSort
                  value={createReportData["data"]["data"]}
                  paginator
                  rows={25}
                  filterDisplay="menu"
                  loading={createReportData ? false : true}
                  responsiveLayout="scroll"
                  emptyMessage={<NoData />}
                  filters={filters}
                  onFilter={(e) => {
                    setFilters(e.filters);
                  }}
                  className="test-stepTable"
                >
                  {createReportData["data"] &&
                  createReportData["data"]["headers"] ? (
                    createColumns(
                      createReportData["data"]["headers"],
                      true,
                      true,
                      createReportData["data"]["data"],
                      true,
                      null,
                      props.onAction,
                      {
                        TestcaseList:true
                      },
                      {
                        TestcaseList:TestcaseListInstance
                      }
                    )
                  ) : (
                    <></>
                  )}
                </DataTable>
              </div>
            ) : (
              <NewReportTemplate
                type="newTab"
                clickFn={() => setShowCreateReport(true)}
              />
            )}
          </div>
        )}
      </div>

      <Dialog
        blockScroll={true}
        draggable={false}
        header="Create Report"
        visible={showCreateReport}
        onHide={() => {setShowCreateReport(false);
        if(!edit){
          resetReportDetails();
        }
        setEdit(false);
      }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        <form onSubmit={(e) => generateReport(e)} noValidate>
          <div className="row mt-3 ms-4">
            <div className="col-6">
              <div className="main-dropdown">
                <div className="mb-1 dropdown-heading">
                  Report Type<sup> *</sup>
                </div>

                <div className="row">
                  <Dropdown
                    className="dialog-dropdown"
                    filter
                    filterBy="reportName"
                    style={{ minWidth: "20vw", maxWidth: "20vw" }}
                    value={selectedReport}
                    options={reportData}
                    onChange={(e) => {
                      setSelectedReport(e.value);
                    }}
                    placeholder="Select Report Type"
                    optionLabel="reportName"
                  />
                </div>
                <div className="mb-1 dropdown-heading">
                  Environment<sup> *</sup>
                </div>

                <div className="row mt-1">
                  {selectedProject && selectedProject.length > 0 ? (
                    <MultiSelect
                      className="dialog-dropdown"
                      style={{ minWidth: "20vw", maxWidth: "20vw" }}
                      value={selectedEnv} // Assuming selectedEnv is an array of environment values like ['prod', 'beta']
                      options={envData} // envData should be in the format [{ label: 'Prod', value: 'prod' }, { label: 'Beta', value: 'beta' }, ...]
                      onChange={(e) => {
                        setSelectedEnv(e.value); // e.value will be an array of selected environment values like ['prod', 'beta']
                      }}
                      maxSelectedLabels={1}
                      placeholder="Select Environment(s)"
                    />
                  ) : (
                    <Dropdown
                      className="dialog-dropdown"
                      style={{ minWidth: "20vw", maxWidth: "20vw" }}
                      options={[]}
                      emptyMessage="No Project(s) Selected"
                      placeholder="Select Environment(s)"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="mb-1 dropdown-heading">
                Project<sup> *</sup>
              </div>

              <div className="row text-center">
                <MultiSelect
                  className="dialog-dropdown"
                  filter
                  filterBy="projectName"
                  style={{ minWidth: "20vw", maxWidth: "20vw" }}
                  optionLabel="projectName"
                  value={selectedProject}
                  options={projectData}
                  onChange={(e) => {
                    setSelectedProject(e.value);
                    
                  }}
                  maxSelectedLabels={1}
                  placeholder="Select Project Name(s)"
                />
              </div>
              {selectedReport?.reportName?.toLowerCase() ===
              "testcase list report" ? (
                <>
                  <div className="mb-1 dropdown-heading">Classification</div>

                  <div className="row mt-1 text-center">
                    <Dropdown
                      className="dialog-dropdown"
                      style={{ minWidth: "20vw", maxWidth: "20vw" }}
                      options={["REGRESSION", "SMOKE"]}
                      value={selectedClassification}
                      onChange={(e) => setSelectedClassification(e.value)}
                      placeholder="Select Classification"
                      // showClear={selectedDateOption === "Custom" ? true : false}
                      // readOnlyInput
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-1 dropdown-heading">
                    Date Range<sup> *</sup>
                  </div>

                  <div className="row mt-1 text-center">
                    <Dropdown
                      className="dialog-dropdown"
                      style={{ minWidth: "20vw", maxWidth: "20vw" }}
                      options={[
                        "Last 24 hours",
                        "Last 7 days",
                        "Last 30 days",
                        "This Year",
                        "Custom",
                      ]}
                      value={selectedDateOption}
                      onChange={(e) => selectDateRange(e.value)}
                      placeholder="Select Date Range"
                      showClear={selectedDateOption === "Custom" ? true : false}
                      // readOnlyInput
                    />
                  </div>
                </>
              )}
            </div>
            {selectedReport?.reportName?.toLowerCase() ===
              "testcase list report" && (
              <div className="col-6">
                <div className="mb-1 dropdown-heading">Type</div>

                <div className="row mt-1 text-center">
                  <Dropdown
                    className="dialog-dropdown"
                    style={{ minWidth: "20vw", maxWidth: "20vw" }}
                    options={["AUTOMATION", "MANUAL"]}
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.value)}
                    placeholder="Select Type"
                    // showClear={selectedDateOption === "Custom" ? true : false}
                    // readOnlyInput
                  />
                </div>
              </div>
            )}
          </div>
          <div className="text-center">
            {showCustom ? (
              <>
                {/* <div className="mb-1 dropdown-heading">
                  Date Range<sup> *</sup>
                </div> */}
                <div className="row d-flex justify-content-center mt-3">
                  <Calendar
                    selectionMode="range"
                    // readOnlyInput
                    placeholder="Select Date Range"
                    className="dialog-dropdown"
                    style={{ minWidth: "20vw", maxWidth: "20vw" }}
                    id="icon"
                    maxDate={new Date()}
                    value={dateRange}
                    onChange={(e) => {
                      createDateRange(e.value);
                    }}
                    showIcon
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="text-center mt-4">
            <button type="submit" className="btn btn-sm stn-success themeBtn">
              GENERATE
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};
function mapStateToProps(state) {
  const { reportData } = state.autolytics;
  const { projectData } = state.autolytics;

  return {
    reportData,
    projectData,
  };
}
export default connect(mapStateToProps)(Reporting);
