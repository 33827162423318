import AutolyticsInfoHeroSection from "../../Layouts/AutolyticsInfo/AutolyticsInfoHeroSection/AutolyticsInfoHeroSection";
import UsageOfAutolyticsDashboard from "../../Layouts/AutolyticsInfo/UsageOfAutolyticsDashboard/UsageOfAutolyticsDashboard";
import FeaturesOfAutolyticsDashboard from "../../Layouts/AutolyticsInfo/FeaturesOfAutolyticsDashboard/FeaturesOfAutolyticsDashboard";
import Header from "../../UI/Header/Header";
import NewFooter from "../../UI/NewFooter/NewFooter";
import { useEffect } from "react";

export const AutolyticsInfo = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  });
  return (
    <>
      <Header />
      <AutolyticsInfoHeroSection />
      <UsageOfAutolyticsDashboard />
      <FeaturesOfAutolyticsDashboard />
      <NewFooter />
    </>
  );
};
export default AutolyticsInfo;
