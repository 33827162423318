const InfraCard = ({ children, heading }) => {
    return (
      <div class="col-md-6 py-2 d-flex align-items-stretch">
        <div class="card extentCard w-100 justify-content-start">
          <div className="extentHeading">
            <span className="card-grid-headers">{heading}</span>
          </div>
          <div className="row d-flex align-items-stretch flex-wrap justify-content-between mt-3">
            {children}
          </div>
        </div>
      </div>
    );
  };
  
  export default InfraCard;