import { useState } from "react";
import "./NotificationDialog.css";
import httpService from "../../../services/http.service";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { useSelector } from "react-redux";
import { convertEpochtoDate } from "../../Helper/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import noNotification from "../../../assets/noNotification.svg";
import { AGILECRAFTER_URL } from "../../../config/API_URLS";
import { useNavigate } from "react-router-dom";

const NotificationDialog = (props) => {
  const { notificationsData } = props;
  const unreadCount = notificationsData?.unreadCount;
  const allCount = notificationsData?.All?.length;
  const [activeTab, setActiveTab] = useState("all");
  const username = useSelector((state) => state.auth.userProfile.username);
  const [markAllAsReadDisabled, setMarkAllAsReadDisabled] = useState(false);
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const clearAllNotifications = (username) => {
    setMarkAllAsReadDisabled(true);
    httpService
      .clearAllNotifications(username)
      .then((data) => {
        props?.setOpenNotificationBar(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          props?.setOpenNotificationBar(false);
        });
      });
  };

  const clearNotification = (username, notificationId) => {
    httpService
      .clearNotification(username, notificationId)
      .then((data) => {})
      .catch((err) => {
        handleToastErrorCatch(err, () => props?.setOpenNotificationBar(false));
      });
  };
  const handleNotificationClick = (notificationId, type, status, subType) => {
    if (status !== "read") {
      clearNotification(username, notificationId);
    }
    if (type.toUpperCase() === "ADMIN") {
      window.open("/#/admin", "_blank");
    } else if (type.toUpperCase() === "REQUEST") {
      window.open("/#/admin", "_blank");
    } else if (type.toUpperCase() === "WORKLOG") {
      window.open(AGILECRAFTER_URL.baseURL + "/#/work-minutes", "_blank");
    } else if (type.toUpperCase() === "ALAB") {
      window.open("/#/recycle-bin", "_blank");
    } else if (type.toUpperCase() === "MLAB") {
      window.open("/#/recycle-bin", "_blank");
    }
    else if(type.toUpperCase() === "AUTOLYTICS"&&subType?.pid&&subType?.s_run_id){
      window.open("/#/autolytics/execution-report?s_run_id="+subType?.s_run_id+"&p_id="+subType?.pid,"_blank");;
    } 
    else if (type.toUpperCase() === "AUTOLYTICS") {
      window.open("/#/autolytics", "_blank");
    } else if (type.toUpperCase() === "REPORTING") {
      window.open("/#/autolytics/reporting", "_blank");
    }
  };
  return (
    <>
      <div className="d-flex notification-header">
        <div className="tab-nav">
          <button
            className={`d-flex tab-btn read-btn align-items-center justify-content-center ${
              activeTab === "all" ? "tab-active" : ""
            }`}
            onClick={() => handleTabClick("all")}
          >
            All ({notificationsData?.All?.length})
          </button>
          <button
            className={`d-flex tab-btn read-btn align-items-center justify-content-center ${
              activeTab === "unread" ? "tab-active" : ""
            }`}
            onClick={() => handleTabClick("unread")}
          >
            Unread ({notificationsData?.Unread?.length})
          </button>
        </div>
        <FontAwesomeIcon
          icon={faEnvelopeOpen}
          title="Mark all as read"
          className={`ms-auto me-1 mt-1 ${
            unreadCount === 0 ||
            markAllAsReadDisabled ||
            props?.markAllAsReadDisabled
              ? "mark-all-read-disabled"
              : "mark-all-read"
          }`}
          onClick={() => {
            if (
              !(
                unreadCount === 0 ||
                markAllAsReadDisabled ||
                props?.markAllAsReadDisabled
              )
            ) {
              clearAllNotifications(username);
            }
          }}
        ></FontAwesomeIcon>
      </div>

      <div className="d-flex mt-4 notification-rectangles">
        {activeTab === "all" && allCount > 0 ? (
          notificationsData?.All?.map((item) => (
            <div
              key={item?.ID}
              className={`notification-rectangle ${
                notificationsData?.Unread?.some(
                  (unreadItem) => unreadItem.ID === item.ID
                )
                  ? "unread-in-all"
                  : "read"
              }`}
              onClick={() => {
                if (
                  notificationsData?.Unread?.some(
                    (unreadItem) => unreadItem.ID === item.ID
                  )
                ) {
                  handleNotificationClick(item?.ID, item?.type,null,item?.subType);
                } else {
                  handleNotificationClick(item?.ID, item?.type, "read",item?.subType);
                }
              }}
            >
              <div className="d-flex ms-4 mt-2 message">{item?.message}</div>
              <div className="d-flex ms-4 mt-2 notification-time">
                {convertEpochtoDate(item?.createTime, "date|time")}
              </div>
              {notificationsData?.Unread?.some(
                (unreadItem) => unreadItem.ID === item.ID
              ) && (
                <FontAwesomeIcon
                  className="faDotCircle"
                  icon={faDotCircle}
                ></FontAwesomeIcon>
              )}
            </div>
          ))
        ) : activeTab === "all" && allCount === 0 ? (
          <img
            className="no-notification"
            src={noNotification}
            alt="No Notification(s)"
          />
        ) : null}

        {activeTab === "unread" && unreadCount > 0 ? (
          notificationsData?.Unread?.map((item) => (
            <div
              key={item?.ID}
              className="notification-rectangle unread-in-all"
              onClick={() => {
                handleNotificationClick(item?.ID, item?.type,null,item?.subType);
              }}
            >
              <div className="d-flex ms-4 mt-2 message">{item?.message}</div>
              <div className="d-flex ms-4 mt-2 notification-time">
                {convertEpochtoDate(item?.createTime, "date|time")}
              </div>
              <FontAwesomeIcon
                className="faDotCircle"
                icon={faDotCircle}
              ></FontAwesomeIcon>
            </div>
          ))
        ) : activeTab === "unread" && unreadCount === 0 ? (
          <img
            className="no-notification"
            src={noNotification}
            alt="No Notification(s)"
          />
        ) : null}
      </div>
    </>
  );
};

export default NotificationDialog;
