import Requirements from "../../Layouts/Requirements/Requirements";
const RequirementsPage = () => {
  return (
    <>
      <div className="container-fluid">
        <Requirements />
      </div>
    </>
  );
};
export default RequirementsPage;
