import {
  faFilter,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import httpService from "../../../../services/http.service";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";

import dashboardService from "../../../../services/Dashboard/dashboard.service";
import {
  handleToastErrorCatch,
  statementError,
} from "../../../Helper/ToastHelper";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import {
  AutomationCoverageCard,
  AverageTestExecutionCard,
  SourceAutomatedTestRuns,
  TotalTestcaseCard,
} from "./TestcaseCards";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProgressBarCard from "./ProgressBarCard";

const convertTextToFilterPill = (
  item,
  setFilteredProjects,
  setSelectedProjects,
  selectedProjects,
  filteredProjects
) => {
  return (
    <div
      key={item?.["pid"]}
      className="filterPill d-flex justify-content-between align-items-center px-2 py-1"
    >
      <p className="mx-0 my-auto">{item?.projectName}</p>
      <FontAwesomeIcon
        className="pointer mx-2"
        icon={faTimes}
        onClick={() => {
          if (filteredProjects?.length > 1) {
            setFilteredProjects(
              filteredProjects?.filter(
                (proj) => proj?.["pid"] !== item?.["pid"]
              )
            );
            setSelectedProjects(
              selectedProjects?.filter((proj) => proj !== item?.["pid"])
            );
          } else {
            statementError("At least 1 or more projects must be selected");
          }
        }}
      />
    </div>
  );
};

const TestcaseHeader = ({
  setShowAllProjects,
  setShowFilters,
  showFilters,
  selectedProjects,
  options,
  applyFilter,
  getEnvData,
  allProject,
  setFilteredProjects,
  filteredProjects,
  setSelectedProjects,
}) => {
  const filterDropdownRef = useRef(null);
  const filterButtonRef = useRef(null);
  const username = useSelector((state) => state.auth.userProfile.username);
  const handleClickOutside = (event) => {
    if (
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target) &&
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target)
    ) {
      setShowFilters(false);
    }
  };

  useEffect(() => {
    if (showFilters) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showFilters]);
  return (
    <>
      {filteredProjects?.length > 0 ? (
        <div className=" my-3 d-flex justify-content-between align-items-center">
          <p className="d-flex flex-row mx-2 my-auto">
            <div className="d-flex flex-column">
              <p className="header-count">
                Total Projects Count
              </p>
              <h1>
                List of projects assigned to <b>{username}</b>
              </h1>
            </div>
            <div className="d-flex align-items-center justify-content-center ms-3">

              <div className="d-flex align-items-center justify-content-center" style={{width: "6rem", height: "3rem", backgroundColor: "white" }}>
                <p className="header-count mt-3">{filteredProjects?.length}</p>
                <b
                  onClick={() => setShowAllProjects(true)}
                  className="fs-4 projectCountHeader pointer mx-1"
                >
                  <i className="pi pi-info-circle fs-5 pointer ms-2" onClick={() => setShowAllProjects(true)}></i>
                </b>
              </div>

            </div>
          </p >

          <div className="my-auto filter-container">
            <Button
              label="Apply Filters"
              type="button"
              onClick={() => setShowFilters(true)}
              icon={
                <FontAwesomeIcon icon={faFilter} className="filterIcon me-1" />
              }
              iconPos="left"
              ref={filterButtonRef}
              className="btn-success btn btn-sm applyFilterBtn px-2 py-2 p-applyFilterBtn d-flex align-items-center filterProject"
            />
            {showFilters && (
              <div
                className="mt-2 py-3 px-4 row filterDropdowns "
                ref={filterDropdownRef}
                onClick={(e) => e.stopPropagation()}
              >
                <MultiSelect
                  value={selectedProjects}
                  options={options}
                  onChange={(e) => {
                    setSelectedProjects(e.value);
                    const selectedObjects = e?.value?.map((value) => {
                      return allProject.find((project) => project["pid"] === value);
                    });
                    getEnvData(selectedObjects);
                  }}
                  placeholder="Select Projects"
                  maxSelectedLabels={1}
                  // display="chip"
                  filter
                  className="w-75 col-12 mx-auto mt-2 mb-3"
                />
                <div className="col-12 d-flex justify-content-end gap-2 px-0">
                  <Button
                    label="Apply"
                    type="button"
                    disabled={selectedProjects?.length === 0}
                    onClick={() => {                    
                      applyFilter("apply");
                    }}
                    className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                  />
                  <Button
                    label="Save"
                    type="button"
                    disabled={selectedProjects?.length === 0}
                    onClick={() => {                    
                      applyFilter("save");
                    }}
                    className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                  />
                </div>
              </div>
            )}
          </div>
        </div >
      ) : (
        <Skeleton width="100%" height="4rem" />
      )}
    </>
  );
};

const Testcase = ({ reportData, isActive }) => {
  const [allProject, setAllProject] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [showStabilityFilters, setShowStabilityFilters] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [options, setOptions] = useState(null);
  const chartRefTotal = useRef(null);
  const chartRefSource=useRef(null);
  const chartRefAutomation = useRef(null);
  const chartRefAverageTestExecution = useRef(null);
  const [classificationType, setClassificationType] = useState("Regression");
  const [env, setEnv] = useState(reportData?.[0]?.env?.[0]);
  const [duration, setDuration] = useState(90);
  const [durationOnApiHit, setDurationOnApiHit] = useState(90);
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [totalTestcaseData, setTotalTestcaseData] = useState(null);
  const [automationCoverageData, setAutomationCoverageData] = useState(null);
  const [stabilityData, setStabilityData] = useState(null);
  const [executionCount, setExecutionCount] = useState(null);
  const [totalTestcaseDataLoading, setTotalTestcaseDataLoading] =
    useState(true);
  const [automationCoverageDataLoading, setAutomationCoverageDataLoading] =
    useState(true);
  const [automatedTestRunsData, setAutomatedTestRunsData] = useState(null);
  const [stabilityDataLoading, setStabilityDataLoading] = useState(true);
  const [executionCountLoading, setExecutionCountLoading] = useState(true);
  const [automatedTestRunsLoading, setAutomatedTestRunsLoading] = useState(true);
  const [envOptions, setEnvOptions] = useState(null);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);



  const navigate = useNavigate();

  const projectData = useSelector((state) => state.autolytics.projectData);
  const handleClickChart = (label, elements, heading) => {
    const projectNames = filteredProjects?.map(
      (proj) => proj?.projectName
    );
    const projectId = filteredProjects?.map((proj) => proj?.["pid"]);
    const classification = label==="BOTH"?["AUTOMATION","MANUAL"]:[label?.toUpperCase()];
    const type = [classificationType?.toUpperCase()];
    const environment = [env];
    const payload = {
      reportid: 9,
      project: projectNames,
      projectid: projectId,
      env: environment,
      testcaseType: classification,

    };
    if (heading === "AutomationCoverage") {
      payload.testcaseClassification = type
    }
    if (elements?.length) {
      const selectedProjectsDetails = projectData?.filter((project) =>
        projectId.includes(project?.pid)
      );
      navigate("/autolytics/reporting", {
        state: {
          label,
          heading,
          payload,
          isActive: true,
          reportName: "Testcase List Report",
          selectedProjectsDetails,
        },
      });
      return;
    }
    return;
  };
  const handleClickIcon = (heading) => {

    const projectNames = filteredProjects?.map(
      (proj) => proj?.["Project Name"]
    );
    const projectId = filteredProjects?.map((proj) => proj?.["pid"]);
    const type = [classificationType?.toUpperCase()];
    const environment = [env];
    const payload = {
      reportid: 9,
      project: projectNames,
      projectid: projectId,
      env: environment,
    };
    if (heading === "AutomationCoverage") {
      payload.testcaseClassification = type
    }
    const selectedProjectsDetails = projectData?.filter((project) =>
      projectId.includes(project?.pid)
    );
    navigate("/autolytics/reporting", {
      state: {
        heading,
        payload,
        reportName: "Testcase List Report",
        selectedProjectsDetails,
      },
    });
    return;
  };

  const getTestcaseTabDetails = () => {
    setTotalTestcaseDataLoading(true);
    setAutomationCoverageDataLoading(true);
    setExecutionCountLoading(true);
    setStabilityDataLoading(true);
    setAutomatedTestRunsLoading(true);
    const pid = [];
    filteredProjects?.forEach((item) => pid?.push(item?.["pid"]));
    if (pid?.length > 0 && env) {
      var updatedEnv = env;
      if (envOptions.length!==0&&!envOptions?.some(option => option.value === env)) {
        setEnv(envOptions?.[0]?.value);
        updatedEnv = envOptions?.[0]?.value;
      }
      dashboardService
        .getAutomationCoverage(pid)
        .then((data) => {
          if (data?.message?.toLowerCase() === "testcase details not found")
            setTotalTestcaseData(null);
          else setTotalTestcaseData(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setTotalTestcaseDataLoading(false));
      dashboardService
        .getAutomationCoverage(pid, classificationType)
        .then((data) => {
          if (data?.message?.toLowerCase() === "testcase details not found")
            setAutomationCoverageData(null);
          else setAutomationCoverageData(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setAutomationCoverageDataLoading(false));
      dashboardService
        .getAutomationScore(pid, duration, updatedEnv)
        .then((data) => {
          if (data?.message?.toLowerCase() === "test executions not available")
            setStabilityData(null);
          else setStabilityData(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setStabilityDataLoading(false));
      dashboardService
        .getExecutionCount(pid, duration, updatedEnv)
        .then((data) => {
          setExecutionCount(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setExecutionCountLoading(false));
        dashboardService
        .getSourceAutomatedTestRuns(pid, duration, updatedEnv)
        .then((data) => {
          if (data?.message?.toLowerCase() === "test executions not available")
            setAutomatedTestRunsData(null);
          else setAutomatedTestRunsData(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setAutomatedTestRunsLoading(false));
    }
  };

  const saveFilterFunction = {
    getFilter: () => {
      if (allProject?.length > 0) {
        dashboardService
          .getSaveFilter()
          .then((data) => {
            if (data?.data) {
              setDurationOnApiHit(data?.data?.duration);
              if (data?.data?.projects?.length > 0) {
                setFilteredProjects(data?.data?.projects);
                setSelectedProjects(
                  data?.data?.projects?.map((proj) => proj?.["pid"])
                );
              } else {
                setFilteredProjects(allProject);
                setSelectedProjects(allProject?.map((proj) => proj?.["pid"]));
              }

              if (data?.data?.env) setEnv(data?.data?.env);
              if (data?.data?.duration) {
                setDuration(data?.data?.duration);
              }
            } else {
              setFilteredProjects(allProject);
              setSelectedProjects(allProject?.map((proj) => proj?.["pid"]));
            }
          })
          .catch((err) => handleToastErrorCatch(err));
      }
    },
    postFilter: (payload) => {
      dashboardService
        .postSaveFilter(payload)
        .then((data) => { })
        .catch((err) => handleToastErrorCatch(err));
    },
  };
  useEffect(() => {
    if (reportData?.length > 0 && isActive) {
      setAllProject(projectData)
      setEnv(reportData?.[0]?.env?.[0]?.toLowerCase());
    }
  }, [reportData, isActive]);

  const updateStabilityCards = (type) => {
    setStabilityDataLoading(true);
    setExecutionCountLoading(true);
    setAutomatedTestRunsLoading(true);
    setDurationOnApiHit(duration);
    setShowStabilityFilters(false);
    setShowFilters(false);
    const pid = [];
    filteredProjects?.forEach((item) => pid?.push(item?.["pid"]));
    dashboardService
      .getAutomationScore(pid, duration, env)
      .then((data) => {
        if (data?.message?.toLowerCase() === "test executions not available")
          setStabilityData(null);
        else setStabilityData(data?.data);
      })
      .catch((err) => handleToastErrorCatch(err))
      .finally(() => setStabilityDataLoading(false));
    dashboardService
      .getExecutionCount(pid, duration, env)
      .then((data) => {
        setExecutionCount(data?.data);
      })
      .catch((err) => handleToastErrorCatch(err))
      .finally(() => setExecutionCountLoading(false));
      dashboardService
      .getSourceAutomatedTestRuns(pid,duration,env)
      .then((data) => {
        if (data?.message?.toLowerCase() === "test executions not available")
          setAutomatedTestRunsData(null);
        else setAutomatedTestRunsData(data?.data);
      })
      .catch((err) => handleToastErrorCatch(err))
      .finally(() => setAutomatedTestRunsLoading(false));

    if (type === "save") {
      const payload = {
        duration: duration,
        env: env,
      };

      saveFilterFunction?.postFilter(payload);
    }
  };

  const applyFilter = (type) => {
    setShowStabilityFilters(false);
    setShowFilters(false);
    const selectedObjects = selectedProjects.map((value) => {
      const project = allProject.find((project) => project["pid"] === value);
      return project ? { projectName: project.projectName, pid: project.pid } : null;
    }).filter(Boolean);
    setFilteredProjects(selectedObjects);
    const pid = [];
    filteredProjects?.forEach((item) => pid?.push(item?.["pid"]));
    if (pid?.length > 0 && env) {
      var updatedEnv = env;
      if (envOptions.length!==0&&!envOptions?.some(option => option.value === env)) {
        setEnv(envOptions?.[0]?.value);
        updatedEnv = envOptions?.[0]?.value;
      }
    }
    if (type === "save") {
      const payload = {
        projects: selectedObjects,
        env: updatedEnv,
      };

      saveFilterFunction?.postFilter(payload);
    }
  };

  const getEnvData = (multiselectProjects) => {
    const projectsToProcess = multiselectProjects || filteredProjects;
  
    let envSet = new Set();
    projectsToProcess?.forEach((project) => {
      const matchingProjects = reportData?.filter(
        (data) => data?.pid === project?.["pid"]
      );
  
      matchingProjects?.forEach((matchingProject) => {
        matchingProject?.env?.forEach((env) => {
          envSet.add(env?.toLowerCase());
        });
      });
    });
  
    let newArr = Array.from(envSet);
  
    const transformedArray = newArr?.map((item) => ({
      label: item.charAt(0).toUpperCase() + item.slice(1),
      value: item,
    }));
    setEnvOptions(transformedArray);
  };
  

  useEffect(() => {
    setAutomationCoverageDataLoading(true);

    const pid = [];
    filteredProjects?.forEach((item) => pid?.push(item?.["pid"]));
    if (pid?.length > 0) {
      dashboardService
        .getAutomationCoverage(pid, classificationType)
        .then((data) => {
          if (data?.message?.toLowerCase() === "testcase details not found")
            setAutomationCoverageData(null);
          else setAutomationCoverageData(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setAutomationCoverageDataLoading(false));
    }
  }, [classificationType]);

  useEffect(() => {
    const option = allProject?.map((project) => ({
      label: project?.["projectName"],
      value: project?.["pid"],
    }));
    setOptions(option);

    saveFilterFunction?.getFilter();
    // setSelectedProjects(options?.map((option) => option.value));
  }, [allProject]);

  useEffect(() => {
    getEnvData();
    getTestcaseTabDetails();
  }, [filteredProjects]);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowStabilityFilters(false);
    }
  };

  useEffect(() => {
    if (showStabilityFilters) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showStabilityFilters]);

  return (
    <div className="mt-4">
      <TestcaseHeader
        setShowAllProjects={setShowAllProjects}
        setShowFilters={setShowFilters}
        showFilters={showFilters}
        getEnvData={getEnvData}
        selectedProjects={selectedProjects}
        options={options}
        env={env}
        allProject={allProject}
        applyFilter={applyFilter}
        filteredProjects={filteredProjects}
        setSelectedProjects={setSelectedProjects}
        setFilteredProjects={setFilteredProjects}
      />
      <div className="row justify-content-around mt-3">
      <TotalTestcaseCard
          totalTestcaseDataLoading={totalTestcaseDataLoading}
          totalTestcaseData={totalTestcaseData}
          chartRef={chartRefTotal}
          handleClickChart={handleClickChart}
          handleClickIcon={handleClickIcon}
          duration={durationOnApiHit}
        />
        <AutomationCoverageCard
          automationCoverageData={automationCoverageData}
          automationCoverageDataLoading={automationCoverageDataLoading}
          chartRefAutomation={chartRefAutomation}
          duration={durationOnApiHit}
          handleClickChart={handleClickChart}
          handleClickIcon={handleClickIcon}
          classificationType={classificationType}
          setClassificationType={setClassificationType}
        />
        <SourceAutomatedTestRuns
           automatedTestRunsLoading={automatedTestRunsLoading}
           automatedTestRunsData={automatedTestRunsData}
           chartRefSource={chartRefSource}
           handleClickChart={handleClickChart}
           handleClickIcon={handleClickIcon}
           duration={durationOnApiHit}
        />
        <AverageTestExecutionCard
          averageTestExecutionLoading={executionCountLoading}
          data={executionCount}
          chartRef={chartRefAverageTestExecution}
          handleClickChart={handleClickChart}
          handleClickIcon={handleClickIcon}
          duration={durationOnApiHit}
        />
      </div>
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Projects"
        visible={showAllProjects}
        onHide={() => {
          setShowAllProjects(false);
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "70vw" }}
      >
        <div className="all-projects px-3">
          {filteredProjects?.map((item) =>
            convertTextToFilterPill(
              item,
              setFilteredProjects,
              setSelectedProjects,
              selectedProjects,
              filteredProjects
            )
          )}
        </div>
      </Dialog>
      <div className="my-2">
        <div className="mt-4 d-flex justify-content-end filter-container">
          <Button
            label="Apply Filters"
            type="button"
            onClick={() => setShowStabilityFilters(true)}
            icon={
              <FontAwesomeIcon icon={faFilter} className="filterIcon me-1" />
            }
            iconPos="left"
            ref={buttonRef}
            className="btn-success btn btn-sm applyFilterBtn px-2 py-2 p-applyFilterBtn d-flex align-items-center filterConfig"
          />

          {showStabilityFilters && (
            <div
              className="mt-2 py-3 px-4 row filterConfigDropdowns "
              // onMouseLeave={() => setShowStabilityFilters(false)}
              ref={dropdownRef}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="col-10 col-xl-5 d-flex flex-column mb-2 me-2">
                <label htmlFor="envDropdown" className="dropdown-label my-2">
                  Environment<sup>*</sup>
                </label>
                <Dropdown
                  id="envDropdown"
                  className="dashboard-dialog-dropdown"
                  options={envOptions}
                  optionLabel="label"
                  value={env}
                  onChange={(e) => setEnv(e.value)}
                  placeholder="Select env"
                  maxSelectedLabels={1}
                />
              </div>
              <div className="col-10 col-xl-5 d-flex flex-column ms-2">
                <label
                  htmlFor="durationDropdown"
                  className="dropdown-label my-2"
                >
                  Duration<sup>*</sup>
                </label>
                <Dropdown
                  id="durationDropdown"
                  className="stability-dialog-dropdown"
                  options={[
                    { label: "Past 30 days", value: 30 },
                    { label: "Past 60 days", value: 60 },
                    { label: "Past 90 days", value: 90 },
                  ]}
                  value={duration}
                  optionLabel="label"
                  onChange={(e) => setDuration(e.value)}
                  placeholder="Select duration"
                  maxSelectedLabels={1}
                />
              </div>
              <div className="col-12 d-flex justify-content-end gap-2 px-0">
                <Button
                  label="Apply"
                  type="button"
                  disabled={envOptions?.length === 0}
                  onClick={() => {
                    updateStabilityCards("apply");
                  }}
                  className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                />
                <Button
                  label="Save"
                  type="button"
                  disabled={envOptions?.length === 0}
                  onClick={() => {
                    updateStabilityCards("save");
                  }}
                  className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                />
              </div>
            </div>
          )}
        </div>
        <div className="row justify-content-around my-4">
          <ProgressBarCard
            title={"Application Stability"}
            duration={durationOnApiHit}
            increment={stabilityData?.[3]?.["Application stability score increment"]}
            stabilityData={stabilityData?.[0]?.["Application stability score"]}
            isIncrementPercent={false}
            progressBarValue={(stabilityData?.[0]?.["Application stability score"] / 1000) * 100}
            stabilityDataLoading={stabilityDataLoading}
            infoText={"This score reflects the overall health and reliability of your application. A higher score indicates a more stable application with fewer functionality issues."}
          />
          <ProgressBarCard
            title={"Automation Stability"}
            duration={durationOnApiHit}
            increment={stabilityData?.[4]?.["Automation stability score increment"]}
            isIncrementPercent={false}
            progressBarValue={(stabilityData?.[1]?.["Automation stability score"] / 1000) * 100}
            stabilityData={stabilityData?.[1]?.["Automation stability score"]}
            stabilityDataLoading={stabilityDataLoading}
            infoText={"This score specifically focuses on the reliability and effectiveness of your automated testing suites. A high score indicates that your tests are consistently identifying and reporting issues without unexpected automation errors."}
          />
          <ProgressBarCard
            title={"Stability Index"}
            duration={durationOnApiHit}
            isIncrementPercent={true}
            increment={stabilityData?.[5]?.["Stability index increment"]}
            stabilityData={stabilityData?.[2]?.["Stability index"]}
            progressBarValue={stabilityData?.[2]?.["Stability index"]}
            stabilityDataLoading={stabilityDataLoading}
            infoText={"This value represents the historical stability of test suites based on their past execution results."}
          />
          <ProgressBarCard
            title={"Testcase Executed"}
            duration={durationOnApiHit}
            isIncrementPercent={true}
            stabilityData={executionCount?.percentage}
            progressBarValue={executionCount?.percentage}
            increment={executionCount?.["percentage increment"]}
            stabilityDataLoading={executionCountLoading}
            infoText={"Percentage of unique test cases executed over a specific period relative to the total executed till now."}
          />
        </div>
      </div>
    </div>
  );
};

export default Testcase;
