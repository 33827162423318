import { Button } from "primereact/button";
import React from "react";
import testpadPageHeroSectionImage from "../../../../assets/testpadPageHeroSectionImage.png";
import testpadFlowChart from "../../../../assets/testPadFlowChartIcon.svg";
import "./TestpadPageHeroSection.css";
import { Link } from "react-router-dom";

const TestpadPageHeroSection = () => {
  return (
    <div className="testpad-hero-section-background">
      <div className="max-w-1440 component-padding">
        <p className="testpad-hero-section-heading">
          Create, Automate and Manage
        </p>
        <p className="testpad-hero-section-description">
          Elevate testing efficiency with Testpad: Seamless creation,
          management, and execution of automated and manual test cases.
        </p>
        <div className="testpad-hero-section-button-wrapper">
          <Link  to={
    process.env.REACT_APP_ENV === "prod"
      ? "https://gemecosystem.com/#/gem-consultancy#contact-for-resources"
      : "https://beta.gemecosystem.com/#/gem-consultancy#contact-for-resources"
  }
  target="_blank"
  >
            <Button
              label="Request Demo"
              className="base-button primary-button hover-right-arrow"
            />
          </Link>
        </div>
        <img
          className="testpad-hero-section-image img-fluid mx-auto d-block"
          src={testpadFlowChart}
          alt="screenshot of a report"
        />
      </div>
    </div>
  );
};

export default TestpadPageHeroSection;
