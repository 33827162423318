import { faRecycle, faSpinner, faTrashCan, faTrashRestore } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.css';
import React, { useState, useEffect } from 'react'
import { Tooltip } from 'primereact/tooltip';
import DeleteDialog from '../../TestTool/ALab/DeleteDialog';
import { useRef } from 'react';
import service from '../../../../services/RecycleBin/service';
import { handleToastErrorCatch } from '../../../Helper/ToastHelper';
import { getToastMessage } from '../../../Helper/Helper';

const RestoreDeleteAction = ({ props: { val, rowData, isMultipleSelected } }) => {
    const [deletePrompt, setDeletePrompt] = useState(false);
    const [restoreLoading, setRestoreLoading] = useState(false);
    const toDeleteType = useRef(rowData?.Folder ? 'folder' : 'file')
    const [toDeleteName, setToDeleteName] = useState(rowData?.Folder ? rowData?.Folder?.value : rowData?.Testcase?.value);
    useEffect(() => { setToDeleteName(rowData?.Folder ? rowData?.Folder?.value : rowData?.Testcase?.value) }, [deletePrompt]);
    const deleteFolder = (id, setLoading) => {
        setLoading(true);
        let payload = [];
        payload.push(val);
        service
            .deleteFolder(payload)
            .then((data) => {
                getToastMessage(data);
                setDeletePrompt(false);
                setLoading(false);
            })
            .catch((err) => {
                handleToastErrorCatch(err, () => {
                    setDeletePrompt(false);
                    setLoading(false);
                })
            })
    }

    const deleteFile = (id, setLoading) => {
        setLoading(true);
        let payload = [];
        payload.push(val);
        service
            .deleteTestcase(payload)
            .then((data) => {
                getToastMessage(data);
                setDeletePrompt(false);
                setLoading(false);
            })
            .catch((err) => {
                handleToastErrorCatch(err, () => {
                    setDeletePrompt(false);
                    setLoading(false);
                })
            })
    }

    const restoreAction = () => {
        setRestoreLoading(true);
        if (toDeleteType.current === 'folder') {
            service
                .restoreFolder(val)
                .then((data) => {
                    getToastMessage(data);
                    setRestoreLoading(false);
                })
                .catch((err) => handleToastErrorCatch(err, () => setRestoreLoading(false)));
            return;
        }

        service
            .restoreTestcase(val)
            .then((data) => {
                getToastMessage(data);
                setRestoreLoading(false);
            })
            .catch((err) => handleToastErrorCatch(err, () => setRestoreLoading(false)));
    }

    return (
        <div className='d-flex'>
            <Tooltip target=".restore" className='restoreTooltip'></Tooltip>
            <Tooltip target=".delete" className='restoreTooltip'></Tooltip>

            <FontAwesomeIcon
                icon={restoreLoading ? faSpinner : faRecycle}
                className={restoreLoading ? "crudActionIcons restore me-2 fa-spin" : isMultipleSelected ? "disabledCrud restore me-2" : "crudActionIcons restore me-2"}

                data-pr-tooltip="Restore"
                onClick={() => !isMultipleSelected && restoreAction()}
                data-pr-position="bottom" />
            <FontAwesomeIcon
                icon={faTrashCan}
                className={`${restoreLoading || isMultipleSelected ? 'disabledCrud' : 'crudActionIcons'} delete ms-2`}
                onClick={() => !restoreLoading && setDeletePrompt(true)}
                data-pr-tooltip="Delete"
                data-pr-position="bottom" />
            <DeleteDialog
                header="Delete Permanently"
                killExeDialog={false}
                toDeleteType={toDeleteType}
                toDeleteId={val}
                toDeleteName={toDeleteName}
                deleteDialog={deletePrompt}
                onHideDeleteDialog={() => setDeletePrompt(false)}
                deleteFolder={deleteFolder}
                deleteFile={deleteFile}
                hookType={"useState"}
            />
        </div>
    )
}

export default RestoreDeleteAction