import "./HeroSection.css";
import heroImage from "../../../assets/heroimg.svg";
const HeroSection = () => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <h2 className="mainHead mt-5 ms-5">Explore Jewel</h2>
          <h6 className="subHead ms-5">
            Effortless Report analysis and Test monitoring at a glance.{" "}
          </h6>
          <br />
          <div
            // onClick={() => {
            //   window.open("mailto:test@gemecosystem.com", "_blank");
            // }}
            className="btn ms-5 demobtn px-3"
          >
            Request Demo
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <img
            src={heroImage}
            alt="Hero Image"
            className="mt-2 heroImg ms-2"
          ></img>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
