import React from 'react'
import MainProfile from '../../Layouts/Profile/MainProfile'

const Profile = () => {
  return (
    <div className='container-fluid'>
      <MainProfile/>
    </div>
  )
}

export default Profile
