import { faChartLine, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MultiSelect } from 'primereact/multiselect'
import React,{useState,useRef, useEffect} from 'react'
import httpService from '../../../../services/http.service'
import { useSelector } from 'react-redux'
import dashboardService from '../../../../services/Dashboard/dashboard.service'
import { handleToastErrorCatch } from '../../../Helper/ToastHelper'
import { Dropdown } from 'primereact/dropdown'
import { Skeleton } from 'primereact/skeleton'
import "./index.css";
import { Button } from 'primereact/button'
import Paginator from '../../../UI/Paginator/Paginator'
import { createColumns } from '../../../Helper/Helper'
import { DataTable } from 'primereact/datatable'
import NoData from '../../../UI/NoData/NoData'
import SkeletonUtility from '../../../UI/Skeleton/SkeletonUtility'
import { ProgressBar } from 'primereact/progressbar'
import TrendCharts from './TrendCharts'
import BreadCrumb from '../../../UI/BreadCrumb/BreadCrumb'

const Trends = ({isActive}) => {
    const [showFilters,setShowFilters]=useState(false)
    const filterButtonRef=useRef(null);
    const filterDropdownRef=useRef(null);
    const [allProject,setAllProject]=useState(null)
    const [selectedProjects, setSelectedProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [options,setOptions]=useState(null)
  const [selectedMetric,setSelectedMetric]=useState("Automation Coverage")
  const [currentState,setCurrentState]=useState("trends")
  const [trendsData,setTrendsData]=useState(null);
  const currentPage = useRef(1);
  const rowPerPage = useRef(5);
  const [pageStartValue, setPageStartValue] = useState(0);
  const [pageEndValue, setPageEndValue] = useState(5);
  const [sortValue, setSortValue] = useState(0);
  const [chartPid,setChartPid]=useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [filters,setFilters]=useState(null);

  const [breadList, setBreadList] = useState([
    { name: "Trends",data:null },
  ]);


    const projectData = useSelector((state) => state.autolytics.projectData);

    const metrics=["Automation Coverage","Smoke Automation Coverage","Unique Testcases Executed","Regression Automation Coverage","Stability Index","Application Stability Score","Automation Stability Score"]


      const getTrendsData=(metric)=>{
        if(filteredProjects?.length>0){
          setTrendsData(null)
          const pid = [];
    filteredProjects?.forEach((item) => pid?.push(item?.["pid"]));
        dashboardService
        .getTrends(metric?.toLowerCase(),1,5,pid)
        .then(data=>{
          setTrendsData(data?.data)
        })
        .catch((err)=>handleToastErrorCatch(err))
        }
      }

      useEffect(()=>{
        getTrendsData(selectedMetric);
      
      },[selectedMetric])

      const onImportTCPagination = (sortField, sortOrder, filter, filterKey) => {
        setTrendsData(null);
        let sortValueField = sortField
          ? [...sortField.toString().split(".sortValue")][0]
          : null;
        let sortOrderValue = sortOrder ? sortOrder : 0;
        let currentPageNo = currentPage.current;
        let rowPerPageCount = rowPerPage.current;
        // let searchKey = !filter ? importFilterValue : filterKey;
        // payload["key"] = searchKey;
        if(filteredProjects?.length>0){
          const pid = [];
    filteredProjects?.forEach((item) => pid?.push(item?.["pid"]));
    
        dashboardService
          .getTrends(
            selectedMetric?.toLowerCase(),currentPageNo,rowPerPageCount,pid
          )
          .then((data) => {
            if (filter) {
              setPageStartValue(0);
              setPageEndValue(
                Math.ceil(data?.data?.totalRecords / rowPerPageCount) >= 5
                  ? 5
                  : Math.ceil(data?.data?.totalRecords / rowPerPageCount)
              );
            }
            setTrendsData(data?.data ? data?.data : null);
          })
          .catch((err) => {
            handleToastErrorCatch(err);
          });
        }
      };


      const saveFilterFunction = {
        getFilter: () => {
          if (allProject?.length > 0) {
            dashboardService
              .getSaveFilter()
              .then((data) => {
                if (data?.data) {
              
                  if (data?.data?.projects?.length > 0) {
                    setFilteredProjects(data?.data?.projects);
                    setSelectedProjects(
                      data?.data?.projects?.map((proj) => proj?.["pid"])
                    );
                  } else {
                    setFilteredProjects(allProject);
                    setSelectedProjects(allProject?.map((proj) => proj?.["pid"]));
                  }
    
                } else {
                  setFilteredProjects(allProject);
                  setSelectedProjects(allProject?.map((proj) => proj?.["pid"]));
                }
              })
              .catch((err) => handleToastErrorCatch(err));
          }
        },
        postFilter: (payload) => {
          dashboardService
            .postSaveFilter(payload)
            .then((data) => {})
            .catch((err) => handleToastErrorCatch(err));
        },
      };

      const applyFilter = (type) => {
        const selectedObjects = selectedProjects.map((value) => {
          return allProject.find((project) => project["pid"] === value);
        });
        setFilteredProjects(selectedObjects);
    
        if (type === "save") {
          const payload = {
           
              projects: selectedObjects
          
          };
    
          saveFilterFunction?.postFilter(payload);
        }
      };
    
      useEffect(() => {
        const option = allProject?.map((project) => ({
          label: project?.["projectName"],
          value: project?.["pid"],
        }));
        setOptions(option);
    
        saveFilterFunction?.getFilter();
       
      }, [allProject]);

      useEffect(()=>{
        getTrendsData(selectedMetric);
      },[filteredProjects])

      useEffect(()=>{
if(isActive){
  setAllProject(projectData)
}
      },[projectData,isActive])


      const handleClickOutside = (event) => {
        if (
          filterDropdownRef.current &&
          !filterDropdownRef.current.contains(event.target) &&
          filterButtonRef.current &&
          !filterButtonRef.current.contains(event.target)
        ) {
          setShowFilters(false);
        }
      };
    
      useEffect(() => {
        if (showFilters) {
          document.addEventListener('click', handleClickOutside);
        } else {
          document.removeEventListener('click', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [showFilters]);

      const openTrendChart=(data,type,list)=>{
        setCurrentState(type.toLowerCase());
      }

      const crudProgress = ({ props }) => {
        // Function to format the value
        const formatValue = (val) => {
            const numericValue = parseFloat(val); // Convert string to number
            if (Number.isInteger(numericValue)) {
                return numericValue; // If the number is an integer, return it as is
            }
            return parseFloat(numericValue.toFixed(2)); // Otherwise, return it with two decimal places
        };
    
        const formattedValue = formatValue(props?.val);
    
        return (
          <>
            {selectedMetric === "Automation Stability Score" || selectedMetric === "Application Stability Score" ? (
              <div className='d-flex justify-content-center align-items-center'>
                <span className='mx-1'>{formattedValue}</span>
              </div>
            ) : (
              <div className='d-flex justify-content-center align-items-center'>
                <ProgressBar
                  className="dashboard-progress-bar w-50 mx-1"
                  color="green"
                  value={formattedValue}
                  showValue={false}
                ></ProgressBar>
                <span className='mx-1'>{formattedValue}%</span>
              </div>
            )}
          </>
        );
    };
    
    

      const crudChart = ( {props} ) => {
        const env = props?.rowData?.Environment?.value?.[0]!==undefined?props?.rowData?.Environment?.value?.[0]:null;
        return (
          <>
            <div        
            >
              <FontAwesomeIcon icon={faChartLine} onClick={()=>{
                setChartPid(props?.rowData?.pid)
                setBreadList([...breadList,{name:selectedMetric,data:props?.rowData?.pid,env:env}])
                }} className="mx-2 fs-5 pointer" />
            </div>
          </>
        );
      };

  return (
    <div>
      <div className='my-3'>
      <BreadCrumb
            breadList={breadList}
            setBreadList={setBreadList}
            component={selectedMetric}
            onClickEvent={openTrendChart}
            classname="trends"
          />
      </div>
      {filteredProjects?(
        breadList?.length===1?(
          <>
        <div className='d-flex justify-content-between align-items-center my-3'>
        <div className="my-auto filter-container">
              <Button
                label="Apply Filters"
                type="button"
                onClick={() => setShowFilters(true)}
                icon={
                  <FontAwesomeIcon icon={faFilter} className="filterIcon me-1" />
                }
                iconPos="left"
                ref={filterButtonRef}
                className="btn-success btn btn-sm applyFilterBtn px-2 py-2 p-applyFilterBtn d-flex align-items-center filterProject"
              />
              {showFilters && (
                <div
                  className="mt-2 py-3 px-4 row filterDropdowns "
                  ref={filterDropdownRef}
                  onClick={(e) => e.stopPropagation()}
                >
                  <MultiSelect
                    value={selectedProjects}
                    options={options}
                    onChange={(e) => setSelectedProjects(e.value)}
                    placeholder="Select Projects"
                    maxSelectedLabels={1}
                    // display="chip"
                    filter
                    className="w-75 col-12 mx-auto mt-2 mb-3"
                  />
                  <div className="col-12 d-flex justify-content-end gap-2 px-0">
                    <Button
                      label="Apply"
                      type="button"
                      disabled={selectedProjects?.length === 0}
                      onClick={() => applyFilter("apply")}
                      className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                    />
                    <Button
                      label="Save"
                      type="button"
                      disabled={selectedProjects?.length === 0}
                      onClick={() => applyFilter("save")}
                      className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                    />
                  </div>
                </div>
              )}
          </div>
          <div>
          <Dropdown
                className="dashboard-dialog-dropdown ms-3"
                options={metrics}
                value={selectedMetric}
                onChange={(e) => setSelectedMetric(e.value)}
                placeholder="Select"
                maxSelectedLabels={1}
                // disabled={addUserButtonLoading}
              />
          </div>
        </div>
        <div>
        {trendsData?.data ? (
              <div>
                <DataTable
                  dataKey="id"
                  reorderableColumns
                  onFilter={(e) => {
                    setFilters(e.filters);
                  }}
                  filters={filters}
                  removableSort
                  resizableColumns
                  stripedRows
                  
                  rows={25}
                  filterDisplay="menu"
                  responsiveLayout="scroll"
                  emptyMessage={<NoData />}
                  
                  showGridlines
                  className="test-stepTable"
                  header={
                    <p className='text-center m-0'>{selectedMetric}</p>
                  }
                  value={
                    trendsData?.data
                      ? trendsData?.data
                      : null
                  }
                  dragSelection
                  footer={
                    <Paginator
                      currentPage={currentPage}
                      totalElements={trendsData?.["totalRecords"]}
                      rowsPerPage={rowPerPage}
                      onPagination={onImportTCPagination}
                      pageStartValue={pageStartValue}
                      setPageStartValue={setPageStartValue}
                      pageEndValue={pageEndValue}
                      setPageEndValue={setPageEndValue}
                      sortColumn={sortColumn}
                      sortValue={sortValue}
                    />
                  }
                  scrollHeight={"48vh"}
                >

                  {trendsData?.data ? (
                    createColumns(
                      trendsData?.["headers"],
                      true,
                      true,
                      trendsData?.["data"],
                      false,
                      "",
                      null,
                      {
                        metricProgressBar:true,
                        trends:true
                      },{
                        metricProgressBar:crudProgress,
                        trends:crudChart
                      }
                      
                    )
                  ) : (
                    <></>
                  )}
                </DataTable>
              </div>
            ) : (
              <SkeletonUtility
                paginator={true}
                header={true}
                headerValue={selectedMetric}
                currentPage={currentPage}
                totalElements={"..."}
                rowsPerPage={rowPerPage}
                pageStartValue={pageStartValue}
                pageEndValue={pageEndValue}
              />
            )}
        </div>
        </>
        ):(
          <TrendCharts metric={selectedMetric} pid={breadList?.[breadList.length-1]?.data} env={breadList?.[breadList?.length-1]?.env} allProject={allProject} options={options}/>
        )
      ):(
        <Skeleton width='10rem' height='10rem'/>
          )}
    </div>
  )
}

export default Trends
