import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import { Link } from "react-router-dom";
import downArrowIcon from "../../../../assets/downArrowIcon.svg";
import upArrowIcon from "../../../../assets/upArrowIcon.svg";
import "./mobileNavDrawer.css";
import { headerLinksData } from "../../../Helper/MockData/LandingPageMockData";

const MobileNavDrawer = ({ open, setOpen, windowHash }) => {
  return (
    <Sidebar position="right" visible={open} onHide={() => setOpen(false)}>
      <div className="mt-3">
        {headerLinksData.map((item) => {
          return (
            <div key={item.id} className="mobile-header-link-wrapper">
              <Link
                className={`mobile-header-link ${
                  item.hash === windowHash && " active-mobile-header-link"
                }`}
                to={item.link}
                target={item.openInNewTab && "_blank"}
              >
                <p
                  className={`${
                    item.hash === windowHash && " active-mobile-header-link"
                  }`}
                >
                  {item.name}
                </p>
              </Link>
            </div>
          );
        })}
        <div className="mt-5 d-flex col-gap">
          <Link to="/signup" className="flex-grow">
            <Button
              label="Sign up"
              className="base-button secondary-button  width-full "
            />
          </Link>
          <Link to="/login" className="flex-grow">
            <Button
              label="Login"
              className="base-button primary-button  width-full"
            />
          </Link>
        </div>
      </div>
    </Sidebar>
  );
};

export default MobileNavDrawer;
